import PrintIcon from "@mui/icons-material/Print";
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import NoData from "../../components/NoData";

// import pdf from "../../assets/images/files-icon/pdf.png";

import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import csv from "../../assets/images/files-icon/csv.png";
import doc from "../../assets/images/files-icon/doc.png";
import jpg from "../../assets/images/files-icon/jpg.png";
import pdf from "../../assets/images/files-icon/pdf.png";
import png from "../../assets/images/files-icon/png.png";
import text from "../../assets/images/files-icon/text.png";
import xls from "../../assets/images/files-icon/xls.png";
import userImg from "../../assets/images/user-2.svg";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import { GetSignature_Json_Redux } from "../../redux/ak_api_helper";
import {
  setOpenDocumentPreviewModal,
  setOpenSignDocument,
} from "../../redux/modalSlice";

// import Signature from "../../assets/images/icons/icons8-autograph-24.png"
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ReactToPrint from "react-to-print";
import Comments from "../../components/Comments";
import CustomLoader from "../../components/CustomLoader";
import TimelineModal from "../../modals/TimelineModal";
import {
  GetAllSentMessagesByFolder_Json_Redux,
  GetMessageAttachments_Json_Redux,
} from "../../redux/api_helper";
import {
  downloadShareboxDocument,
  getApprovalCertificate,
  getApprovalCommentsHtml,
  getMessageHtml,
  getPortalMessageComments,
  getPortalMessageDocuments,
} from "../../redux/dev_chetan_api_helper";
import {
  setDocDetailForPreviewURL,
  setInboxMessageHtmlLoading,
  setOpenFromInbox,
  setPortalDocIdForSignDocModal,
  setPortalMessageDocumentsLoading,
  setshareboxDocForPreviewURL,
} from "../../redux/dev_chetan_slice";
import AttachmentStatusComponant from "../../user/componants/requestComponant/AttachmentStatusComponant";
import { DownloadBase64File } from "../../utils/RequestsFunction";
import ClientRequestDetails from "./ClientRequestDetails";
// import { Tooltip } from '@mui/material/Tooltip';

export default function IncomngMessageViewer() {
  const location = useLocation();

  const {
    PortalDocID: portalDocID,
    Subject,
    Section,
    ReceivedDate,
    SendDate,
    ReferenceName,
    ClientName,
    customerId,
    Issued,
    Viewed,
    ViewDate,
    ExpiryDate,
    ActionBy,
    Status,
    EmailID,
    SendMessage,
    MessageID,
    messageStatus,
    isClient,
    senderName,
    TabIndex,
    data,
  } = location?.state || {};

  // console.log("✌️portalDocID --->", portalDocID);

  const portalObj = {
    accid: localStorage.getItem("agrno")
      ? localStorage.getItem("agrno")
      : "0003",
    email: localStorage.getItem("Email")
      ? localStorage.getItem("Email")
      : "patrick@docusoft.net",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "UGF0cmljazEyMy4=",
    portalUserEmail: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
  };

  const signBody = {
    accid: localStorage.getItem("accIdClient"),
    sPassword: localStorage.getItem("passwordClient"),
    username: localStorage.getItem("EmailClient"),
  };

  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  // const portalDocID = searchParams.get("PortalDocID");
  // const Subject = searchParams.get("Subject");
  // const Section = searchParams.get("Section");
  // const ReceivedDate = searchParams.get("ReceivedDate");
  // const SendDate = searchParams.get("SendDate");
  // const ReferenceName = searchParams.get("ReferenceName");
  // const ClientName = searchParams.get("ClientName");
  // const customerId = searchParams.get("customerId");
  // const Issued = searchParams.get("Issued");
  // const Viewed = searchParams.get("Viewed");
  // const ViewDate = searchParams.get("ViewDate");
  // const ExpiryDate = searchParams.get("ExpiryDate");
  // const ActionBy = searchParams.get("ActionBy");
  // const Status = searchParams.get("Status");
  // const EmailID = searchParams.get("EmailID");
  // const SendMessage = searchParams.get("Inbox");
  // const MessageID = searchParams.get("MessageID");
  // const messageStatus = searchParams.get("messageStatus");
  // const isClient = searchParams.get("isClient");
  // const senderName = searchParams.get("senderName");
  // const TabIndex = searchParams.get("TabIndex");

  const clientLoginStatus = localStorage.getItem("LoginClient");

  // Attachment Dropudoun
  const [EditanchorEl, EditsetAnchorEl] = React.useState({});
  // const Editopen = Boolean(EditanchorEl);

  const EdithandleClick = (event, index) => {
    EditsetAnchorEl({ ...EditanchorEl, [index]: event.currentTarget });
  };

  const EdithandleClose = (index) => {
    let temp = { ...EditanchorEl };
    delete temp[index];
    EditsetAnchorEl(temp);
  };

  const PreviewAttachment = (data) => {
    EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const DocusoftedDocumnet = (data) => {
    if (getAllSentMessages.length > 0) {
      let res = getAllSentMessages.filter(
        (m) => m["Message ID"].toString() === MessageID
      );
    }
  };

  useEffect(() => {
    const userredirect = location.pathname.includes("/user");
    if (!portalDocID) {
      if (userredirect) navigate("/user/portal-messages");
      else navigate("/client/portalmessage/inboxes");
    }
  }, [portalDocID]);

  const DownloadAttachment = (data) => {
    EditsetAnchorEl(null);
    dispatch(
      downloadShareboxDocument(
        data.PortalDocId,
        data.Attachid,
        data.DocExtension,
        data.PortalName
      )
    );
  };

  const PreviewNewTab = (data) => {
    EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setshareboxDocForPreviewURL(url));
    window.open(url, "_blank");
  };

  // Button DropDoun
  const [EditanchorE2, EditsetAnchorE2] = React.useState(null);
  const {
    inboxMessageHtml,
    portalMessageDocuments,
    portalMessageComments,
    approvalCertificateBase64,
    portalApprovalCommentsHtml,
    inboxMessageHtmlLoading,
    portalMessageDocumentsLoading,
  } = useSelector((state) => state.dev_chetan);

  // console.log('✌️portalMessageDocuments --->', portalMessageDocuments);
  const { GetMessageAttachments } = useSelector((state) => state.docu);
  // console.log("GetMessageAttachments --->", GetMessageAttachments);

  let certificateUrl = `data:application/pdf;base64,${approvalCertificateBase64}`;

  function groupByPortalDocId(documents) {
    return documents.reduce((acc, doc) => {
      if (!acc[doc.PortalDocId]) {
        acc[doc.PortalDocId] = [];
      }
      acc[doc.PortalDocId].push(doc);
      return acc;
    }, {});
  }

  const [value, setValue] = React.useState("1");

  const Item = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.5),

    color: theme.palette.text.secondary,
  }));

  let objReq = useLocalStorage("accid");

  const PageLoadAPI = useCallback(async () => {
    let sentObj = {
      accid: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      ccode: "",
      emailAddress: EmailID || customerId,
      folder: 171,
    };
    dispatch(setInboxMessageHtmlLoading(true));

    if (clientLoginStatus === null) {
      await dispatch(
        GetAllSentMessagesByFolder_Json_Redux(sentObj, function (res) { })
      );
      await dispatch(GetMessageAttachments_Json_Redux(portalDocID));
    }

    await dispatch(getMessageHtml(portalDocID));
    await dispatch(getPortalMessageDocuments(portalDocID));
    await dispatch(getPortalMessageComments(portalDocID));

    // setIsLoding(false);
    dispatch(setInboxMessageHtmlLoading(false));

    // getIpAddress();
    if (messageStatus === "Approved")
      await dispatch(getApprovalCertificate(portalDocID));
    await dispatch(getApprovalCommentsHtml(portalDocID));
  }, [portalDocID]);

  useMemo(() => {
    PageLoadAPI();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return () => {
      dispatch(setInboxMessageHtmlLoading(true));
      dispatch(setPortalMessageDocumentsLoading(true));
    };
  }, [portalDocID]);

  const docTypeIcon = (target) => {
    switch (target) {
      case ".pdf":
        return pdf;
      case ".txt":
        return text;
      case ".docx":
        return doc;
      case ".jpg":
        return jpg;
      case ".jpeg":
        return jpg;
      case ".png":
        return png;
      case ".xls":
        return xls;
      case ".csv":
        return csv;
      default:
        return text;
    }
  };

  const downloadFile = (e, doc) => {
    e.stopPropagation();
    dispatch(
      downloadShareboxDocument(
        portalDocID,
        doc.Attachid,
        doc.DocExtension,
        doc.PortalName
      )
    );
  };

  const downloadAllDocs = (e) => {
    portalMessageDocuments.forEach((doc) => {
      downloadFile(e, doc);
    });
  };

  const handlePreview = (data) => {
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${portalDocID}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
    dispatch(setDocDetailForPreviewURL(data));
  };

  const handlePrint = () => {
    window.print();
  };

  // tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // accorion
  const [expanded, setExpandedAccordion] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const DownLoadCertificate = (e) => {
    let doc = certificateUrl.split(",")[1];
    if (doc) {
      let base64Doc = {
        DocData: doc,
        DocExtension: ".pdf",
      };
      DownloadBase64File(doc, "Certificate.pdf");
    }
  };

  const componentRef = useRef();

  return (
    <>
      <div ref={componentRef} className="px-0">
        {inboxMessageHtmlLoading ? (
          <CustomLoader />
        ) : (
          <>
            {messageStatus === "Expired" && (
              <Box className="mb-3">
                <Alert variant="filled" severity="error">
                  {/* <AlertTitle>Warning</AlertTitle>  */}
                  This message has expired
                </Alert>
              </Box>
            )}

            <Box sx={{ width: "100%", typography: "body1", marginTop: "12px" }}>
              <TabContext value={value}>
                <Box className="clearfix">
                  {messageStatus != "Expired" &&
                    GetMessageAttachments.length === 0 &&
                    portalMessageDocuments.length === 0 && (
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        className="custom-tabs mb-0 flex-wrap-tabs min-h-auto"
                      >
                        <Tab label="Message" value="1" />

                        <Tab label="Request" value="2" />
                      </TabList>
                    )}
                </Box>

                <TabPanel value="1" className="p-0">
                  <Box className="clearfix">
                    <Box className="white-box">
                      <Box className="d-flex align-items-center justify-content-between">
                        <h2 className="title semibold">
                          {Subject ? Subject : "Not Available"}
                        </h2>

                        {isClient &&
                          !ReferenceName &&
                          messageStatus === "Pending" &&
                          portalMessageDocuments.length > 0 && (
                            <Box className="d-flex justify-content-end">
                              <Button
                                variant="contained"
                                checkCircleIcon
                                size="small"
                                // startIcon={<CheckCircleIcon />}
                                className="btn-blue me-2 mb-3"
                                // onClick={() => {
                                //   dispatch(
                                //     GetSignature_Json_Redux(clientBodyaccidData)
                                //   );
                                //   dispatch(setOpenSignDocument(true));
                                // }}

                                onClick={() => {
                                  dispatch(
                                    GetSignature_Json_Redux({
                                      ...signBody,
                                    })
                                  );
                                  dispatch(setOpenSignDocument(true));
                                  dispatch(
                                    setPortalDocIdForSignDocModal(portalDocID)
                                  );
                                  dispatch(setOpenFromInbox(true));
                                }}
                              >
                                Action
                              </Button>
                            </Box>
                          )}
                      </Box>

                      {/* <hr className="m-0" /> */}

                      <Box className="mt-1">
                        <Box className="d-flex justify-content-between align-items-center flex-wrap">
                          <Box className="d-flex align-items-center mb-3 mb-sm-0">
                            <Box className="me-3">
                              {userImg && (
                                <img
                                  src={userImg}
                                  height={35}
                                  width={35}
                                  style={{
                                    borderRadius: "50%",
                                    background: "#eea706",
                                  }}
                                />
                              )}
                            </Box>

                            <Box>
                              <Typography
                                variant="h3"
                                className="font-14 lato-semibold"
                              >
                                {ClientName ? ClientName : senderName}
                              </Typography>

                              <Typography variant="p">
                                {EmailID && EmailID}
                                {customerId && customerId}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="d-flex ">
                            <Box className="d-flex flex-wrap">
                              {ReferenceName ? (
                                <>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Section</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {Section && Section.slice(3)}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Send Date</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {SendDate}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">View On</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {ViewDate}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Reference</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {ReferenceName}
                                    </p>
                                  </Box>
                                </>
                              ) : ClientName ? (
                                <>
                                  {clientLoginStatus ? (
                                    <Box className="me-3 border-end pe-3 text-center">
                                      <p className="lato-semibold">Section</p>
                                      <p className="text-gray font-12 lato-medium">
                                        {Section && Section !== "Not available"
                                          ? Section.slice(3)
                                          : "Not available"}
                                      </p>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Issued on</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {Issued}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Viewed on</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {Viewed}
                                    </p>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Section</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {Section && Section.slice(3)}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">
                                      Recived Date
                                    </p>
                                    <p className="text-gray font-12 lato-medium">
                                      {ReceivedDate}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Action By</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {ActionBy === "Invalid date"
                                        ? "Not Assigned"
                                        : ActionBy}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="lato-semibold">Expiry Date</p>
                                    <p className="text-gray font-12 lato-medium">
                                      {ExpiryDate}
                                    </p>
                                  </Box>
                                </>
                              )}
                            </Box>

                            <ReactToPrint
                              trigger={() => (
                                <Tooltip title="Preview" arrow placement="top">
                                <Button
                                  className="min-width-auto "
                                  size="small"
                                  onClick={handlePrint}
                                >
                                  <PrintIcon className="text-black" />
                                </Button>
                                </Tooltip>
                              )}
                              content={() => componentRef.current}
                            />
                            {!clientLoginStatus && (
                              <TimelineModal location={location} />
                            )}
                          </Box>
                        </Box>

                        <hr className="m-2" />
                      </Box>

                      <Box>
                        <Box className="font-12">
                          <div className="mb-2 font-13">
                            {/* {inboxMessageHtml} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: inboxMessageHtml,
                              }}
                            ></div>
                          </div>
                          <Typography className="mb-2 font-13"></Typography>

                          {/* <hr /> */}
                        </Box>
                      </Box>
                      {/* </Grid> */}

                      <Box sx={{ width: "100%" }}>
                        <Grid container rowSpacing={1}>
                          {portalMessageDocuments.length > 0 && (
                            <Grid
                              item
                              xs={12}
                              lg={messageStatus === "Expired" ? 12 : 12}
                              className="pt-0"
                            >
                              <Item>
                                <Box
                                  className="d-flex justify-content-between mb-0 align-items-center"
                                  sx
                                >
                                  <Typography
                                    variant="h3"
                                    className="font-18 text-black lato-semibold mb-0"
                                  >
                                    Attachments
                                  </Typography>
                                  {portalMessageDocuments.length > 1 && (
                                    <Button
                                      onClick={downloadAllDocs}
                                      variant="contained"
                                      className="btn-blue mb-2"
                                      size="small"
                                      startIcon={<DownloadIcon />}
                                    >
                                      Download All
                                    </Button>
                                  )}
                                </Box>

                                {/* message Document */}
                                <Grid container spacing={2} className="main-email-attachment w-100 mt-1">
                                  {clientLoginStatus ? (
                                    portalMessageDocumentsLoading ? (
                                      <CustomLoader />
                                    ) : (
                                      portalMessageDocuments.length > 0 &&
                                      portalMessageDocuments.map((doc, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                          <Box
                                            className="email-attachment w-100 d-flex align-items-center justify-content-between p-0"
                                            title={doc?.PortalName || "Not Available"}
                                            onDoubleClick={() => handlePreview(doc)}
                                          >
                                            <Box className="d-flex align-items-center">
                                              <img
                                                src={docTypeIcon(doc.DocExtension)}
                                                style={{ width: 28 }}
                                                alt="documents"
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                  fontWeight: "550",
                                                }}
                                                className="d-flex align-items-center ms-2"
                                              >
                                                {doc?.PortalName || "Not Available"}
                                              </Typography>
                                            </Box>
                                            <Box className="d-flex align-items-center">
                                              <Tooltip slots={{
                                                transition: Zoom,
                                              }} title="Download" arrow placement="top">
                                                <Button
                                                  className="min-width-auto"
                                                  size="small"
                                                  onClick={(e) => downloadFile(e, doc)}
                                                >
                                                  <DownloadIcon className="text-blue font-20" />
                                                </Button>
                                              </Tooltip>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      ))
                                    )
                                  ) : null}
                                </Grid>

                              </Item>
                            </Grid>
                          )}
                          <hr className="" />
                          {!clientLoginStatus && (
                            <Grid item xs={12}>
                              <Box className="request-details-accordian d-flex flex-wrap ">
                                {!clientLoginStatus &&
                                  // Array.isArray(
                                  //   GetMessageAttachments[portalDocID]
                                  // ) &&
                                  // GetMessageAttachments[portalDocID].map(
                                  GetMessageAttachments.map((itm, index) => (
                                    <AttachmentStatusComponant
                                      key={itm.id || index}
                                      portalDocId={portalDocID}
                                      itm={itm}
                                      index={index}
                                      EdithandleClick={EdithandleClick}
                                      EditanchorEl={EditanchorEl}
                                      EditsetAnchorEl={EditsetAnchorEl}
                                      MessageID={MessageID}
                                    />
                                  ))}
                              </Box>
                            </Grid>
                          )}

                          <Grid item xs={12} className="pt-0">
                            <Item>
                              {(messageStatus === "For Info" ||
                                messageStatus === "Pending" ||
                                ReferenceName ||
                                ClientName) && (
                                  <Comments
                                    portalDocID={portalDocID}
                                    portalMessageComments={portalMessageComments}
                                    ClientName={ClientName}
                                    customerId={customerId}
                                  />
                                )}
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    {/* {(messageStatus === "For Info" ||
                      messageStatus === "Pending" ||
                      ReferenceName ||
                      ClientName) && (
                      <Comments
                        portalDocID={portalDocID}
                        portalMessageComments={portalMessageComments}
                        ClientName={ClientName}
                        customerId={customerId}
                      />
                    )} */}

                    {messageStatus === "Approved" && (
                      <Box className="white-box 02">
                        <Box className="d-flex align-items-center gap-3">
                          <Typography
                            className="font-18 text-black lato-semibold"
                            variant="h3"
                          >

                            Certificate of approval
                          </Typography>
                          <Button
                            onClick={DownLoadCertificate}
                            variant="contained"
                            sx={{
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            className="btn-blue font-12"
                            startIcon={<DownloadIcon />}
                          >

                            Download Certificate
                          </Button>
                        </Box>

                        <hr />

                        <Box>
                          <Grid container spacing={3} className="mt-4">
                            <Grid
                              item
                              sm={6}
                              sx={{
                                height: "400px",
                                overflow: "auto",
                              }}
                              className="pe-2"
                            >
                              <Card sx={{ border: "1px solid black" }}>
                                {certificateUrl.split(",")[1] ===
                                  "undefined" ? (
                                  <>
                                    <CustomLoader />
                                  </>
                                ) : (
                                  <iframe
                                    src={certificateUrl}
                                    width="100%" // Set the width
                                    height="700px" // Set the height
                                    frameBorder="0" // Set frameborder to 0
                                    allowFullScreen // Allow fullscreen mode
                                    title="Embedded Content" // Set the title for accessibility
                                  ></iframe>
                                )}
                              </Card>
                            </Grid>
                            <Grid item sm={6}>
                              <Box>
                                <Box className="mb-2">
                                  <Typography
                                    variant="h2"
                                    className="font-18 lato-semibold"
                                  >
                                    Comments
                                  </Typography>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: portalApprovalCommentsHtml,
                                    }}
                                    className="single-user-comments"
                                  ></div>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </TabPanel>

                <TabPanel value="2" className="p-0">
                  <ClientRequestDetails />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
      </div>
    </>
  );
}
