import PrintIcon from "@mui/icons-material/Print";
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

// import pdf from "../../assets/images/files-icon/pdf.png";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import userImg from "../../assets/images/user-2.svg";
import { setOpenDocumentPreviewModal } from "../../redux/modalSlice";

// import Signature from "../../assets/images/icons/icons8-autograph-24.png"
import { TabContext, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Comments from "../../components/Comments";
import CustomLoader from "../../components/CustomLoader";
import DocumentPreviewModal from "../../modals/DocumentPreviewModal";
import SignDocument from "../../modals/SignDocument";
import { Ad_DirectMessageApiBody } from "../../redux/adityaReducer";
import {
  Dmessage_Get_Sign_Action,
  Dmessage_GetAllCommentsByToken_Json_Action,
  Dmessage_GetAllDetailsByMessageId_Json_Action,
  Dmessage_GetApprovalsAndCommentsHtmlByToken_Json_Action,
  Dmessage_GetCertificateNewByToken_Json_Action,
  Dmessage_GetMessageDocumentsByToken_Json_Action,
  Dmessage_MarkMessageAsViewedByToken_Json_Action,
} from "../../redux/dev_aditya_action";
import {
  setInboxMessageHtmlLoading,
  setOpenFromInbox,
  setPortalDocIdForSignDocModal,
  setPortalMessageDocumentsLoading,
  setshareboxDocForPreviewURL,
} from "../../redux/dev_chetan_slice";
import { GetDocTypeIcon2Requests } from "../../utils/RequestsFunction";

export default function DirectMessageViewer() {
  const location = useLocation();
  const [token, setToken] = useState({});

  const portalObj = {
    accid: localStorage.getItem("agrno")
      ? localStorage.getItem("agrno")
      : "0003",
    email: localStorage.getItem("Email")
      ? localStorage.getItem("Email")
      : "patrick@docusoft.net",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "UGF0cmljazEyMy4=",
    portalUserEmail: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
  };

  const { PortalUsersInbox, Message } = useSelector(
    ({ aditya }) => aditya?.directMessageAllData
  );
  const { openDocumentPreviewModal } = useSelector((state) => state.modals);
  const { ad_MessageAttachmentList, ad_Dm_Api_Body, requestcommentList } =
    useSelector(({ aditya }) => aditya);
  // console.log("✌️ad_MessageAttachmentList --->", ad_MessageAttachmentList);
  // console.log("✌️PortalUsersInbox --->", PortalUsersInbox);
  // const { PortalUsersInbox, Message } = directMessageAllData;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const Code = searchParams.get("Code");
  const portalDocID = searchParams.get("PortalDocID");
  const ReferenceName = searchParams.get("ReferenceName");
  const ClientName = searchParams.get("ClientName");
  const customerId = searchParams.get("customerId");

  const [dmCommentHtml, setDmCommentHtml] = useState(null);
  const [dmCommentJson, setDmCommentJson] = useState(null);
  const [openSignModal, setOpenSignModal] = useState(false);

  useEffect(() => {
    if (Code) {
      const param = atob(Code).split("#");
      if (param && param.length > 0) {
        let newb = {
          accid: param[0],
          token: param[1],
          // messageId: "78a14bcc80f744369c4a0424df1173de",
          messageId: param[2],
        };
        dispatch(Ad_DirectMessageApiBody(newb));
        setToken(newb);
        dispatch(setInboxMessageHtmlLoading(true));
        dispatch(setPortalMessageDocumentsLoading(true));
        pageLoadData(newb);
      }
    }
  }, [Code]);

  const pageLoadData = async (newb) => {
    const param = atob(Code).split("#");

    !PortalUsersInbox &&
      (await dispatch(
        Dmessage_GetAllDetailsByMessageId_Json_Action(newb, (msgDetail) => {
          // console.log("✌️msgDetail--->", msgDetail);
          // mark view Message
          !msgDetail?.PortalUsersInbox?.ViewDate &&
            dispatch(
              Dmessage_MarkMessageAsViewedByToken_Json_Action(
                newb,
                (markView) => {
                  // console.log("markView --->", markView);
                }
              )
            );
        })
      ));

    // get comment html
    await dispatch(
      Dmessage_GetApprovalsAndCommentsHtmlByToken_Json_Action(
        newb,
        (commentHtml) => {
          setDmCommentHtml(JSON.parse(commentHtml));
          // console.log("✌️commentHtml --->", commentHtml);
        }
      )
    );

    // get comment json
    await dispatch(
      Dmessage_GetAllCommentsByToken_Json_Action(newb, (commentJson) => {
        // console.log("✌️commentJson --->", commentJson);
        setDmCommentJson(commentJson);
      })
    );

    // message Document List
    Object.keys(ad_MessageAttachmentList).length === 0 &&
      (await dispatch(
        Dmessage_GetMessageDocumentsByToken_Json_Action(
          newb,
          (AttachmentJson) => {
            // console.log("AttachmentJson --->", AttachmentJson);
          }
        )
      ));
    // }
    dispatch(setInboxMessageHtmlLoading(false));
    dispatch(setPortalMessageDocumentsLoading(false));
  };

  // Attachment Dropudoun

  const PreviewNewTab = (data) => {
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setshareboxDocForPreviewURL(url));
    window.open(url, "_blank");
  };

  const { approvalCertificateBase64, inboxMessageHtmlLoading } = useSelector(
    (state) => state.dev_chetan
  );

  let certificateUrl = `data:application/pdf;base64,${approvalCertificateBase64}`;

  const [value, setValue] = React.useState("1");

  const Item = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.5),

    color: theme.palette.text.secondary,
  }));

  const downloadFile = async (e, doc) => {
    // console.log("✌️doc --->", doc);
    e.stopPropagation();
    try {
      if (doc?.Attachment) {
        let ankr = document.createElement("a");
        ankr.href = `data:application/octet-stream;base64,${doc?.Attachment}`;
        ankr.download = doc.PortalName;
        ankr.click();
      } else {
        toast.warn("Attachment not found");
      }
    } catch (error) {
      toast.warn("Something went wrong to download attachment");
    }
  };

  const downloadAllDocs = (e) => {
    ad_MessageAttachmentList.forEach((doc) => {
      downloadFile(e, doc);
    });
  };

  const handlePreview = (data) => {
    console.log("✌️data --->", data);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${ad_Dm_Api_Body.accid}&attachment=${data.AttachId}&Id=${ad_Dm_Api_Body?.messageId}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const handlePrint = () => {
    window.print();
  };

  // tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formattedDate = (timestamp) => dayjs(timestamp).format("DD/MM/YYYY");

  const componentRef = useRef();

  const status = (() => {
    if (PortalUsersInbox?.Expired === "Yes") {
      return "Expired";
    }
    if (PortalUsersInbox?.ForApproval === "No") {
      return "For Info";
    }
    if (
      PortalUsersInbox?.FileApproved === null &&
      PortalUsersInbox?.ForApproval === "Yes"
    ) {
      return "Pending";
    }
    if (
      PortalUsersInbox?.FileApproved === "Yes" &&
      PortalUsersInbox?.ForApproval === "Yes"
    ) {
      return "Approved";
    }
    if (
      PortalUsersInbox?.FileApproved === "Yes" &&
      PortalUsersInbox?.ForApproval === "No"
    ) {
      return "Disapproved";
    }
    return "Pending";
  })();

  useEffect(() => {
    if (status === "Approved") {
      dispatch(Dmessage_GetCertificateNewByToken_Json_Action(token, () => {}));
    }
  }, [status]);

  const handleCloseSign = () => {
    setOpenSignModal(!openSignModal);
  };

  return (
    <Box ref={componentRef} className="px-2 pb-5">
      {inboxMessageHtmlLoading ? (
        <CustomLoader />
      ) : (
        <Box className="container">
          <>
            {status == "Expired" && (
              <Box className="mb-3">
                <Alert variant="filled" severity="error">
                  This message has expired
                </Alert>
              </Box>
            )}

            <Box sx={{ width: "100%", typography: "body1", marginTop: "12px" }}>
              <TabContext value={value}>
                {/* <Box className="clearfix">
                  {status !== "Expired" && (
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="custom-tabs mb-0 flex-wrap-tabs min-h-auto"
                    >
                      <Tab label="Message" value="1" />

                      <Tab label="Request" value="2" />
                    </TabList>
                  )}
                </Box> */}

                <TabPanel value="1" className="p-0">
                  <Box className="clearfix">
                    <Box className="white-box">
                      <Box className="d-flex align-items-center justify-content-between">
                        <h2 className="title">
                          {PortalUsersInbox?.Subject
                            ? PortalUsersInbox?.Subject
                            : "Not Available"}
                        </h2>

                        {!ReferenceName &&
                          !PortalUsersInbox?.Template &&
                          status === "Pending" && (
                            <Box className="d-flex justify-content-end">
                              <Button
                                variant="contained"
                                // startIcon={checkCircleIcon}
                                size="small"
                                className="btn-blue me-2 mb-3"
                                onClick={() => {
                                  dispatch(Dmessage_Get_Sign_Action(token));
                                  // dispatch(setOpenSignDocument(true));
                                  setOpenSignModal(true);
                                  dispatch(
                                    setPortalDocIdForSignDocModal(portalDocID)
                                  );
                                  dispatch(setOpenFromInbox(true));
                                }}
                              >
                                Action
                              </Button>
                            </Box>
                          )}
                      </Box>

                      <Box className="mt-1">
                        <Box className="d-flex justify-content-between align-items-center flex-wrap">
                          <Box className="d-flex align-items-center mb-3 mb-sm-0 flex-wrap">
                            <Box className="me-3">
                              {userImg && (
                                <img
                                  src={userImg}
                                  height={35}
                                  width={35}
                                  style={{
                                    borderRadius: "50%",
                                    background: "#eea706",
                                  }}
                                />
                              )}
                            </Box>

                            <Box>
                              <Typography
                                variant="h3"
                                className="font-14 lato-semibold"
                              >
                                {PortalUsersInbox?.CName
                                  ? PortalUsersInbox?.CName
                                  : "Not Available"}
                              </Typography>

                              <Typography variant="p">
                                {PortalUsersInbox?.EmailID
                                  ? PortalUsersInbox?.EmailID
                                  : "Not Available"}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="d-flex ">
                            <Box className="d-flex flex-wrap">
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="lato-semibold">Section</p>
                                <p className="text-gray font-12 lato-medium">
                                  {PortalUsersInbox?.ItemName
                                    ? PortalUsersInbox?.ItemName
                                    : "No Date Available"}
                                </p>
                              </Box>
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="lato-semibold">Recived Date</p>
                                <p className="text-gray font-12 lato-medium">
                                  {PortalUsersInbox?.ViewDate
                                    ? formattedDate(PortalUsersInbox?.ViewDate)
                                    : "No Date Available"}
                                </p>
                              </Box>
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="lato-semibold">Action By</p>
                                <p className="text-gray font-12 lato-medium">
                                  {PortalUsersInbox?.ADate
                                    ? formattedDate(PortalUsersInbox?.ADate)
                                    : "No Date Available"}
                                </p>
                              </Box>
                              <Box className="me-3 border-end pe-3 text-center">
                                <p className="lato-semibold">Expiry Date</p>
                                <p className="text-gray font-12 lato-medium">
                                  {PortalUsersInbox?.ExpiryDate
                                    ? formattedDate(
                                        PortalUsersInbox?.ExpiryDate
                                      )
                                    : "No Date Available"}
                                </p>
                              </Box>
                            </Box>

                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  className="min-width-auto ms-1"
                                  size="small"
                                  onClick={handlePrint}
                                >
                                  <PrintIcon className="text-black" />
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                            {/* {!clientLoginStatus && (
                              <TimelineModal location={location} />
                            )} */}
                          </Box>
                        </Box>

                        <hr className="m-2" />
                      </Box>

                      <Box>
                        <Box className="font-12">
                          <div className="mb-2 font-13">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: Message?.Message,
                              }}
                            ></div>
                          </div>
                          <Typography className="mb-2 font-13"></Typography>
                        </Box>
                      </Box>
                      {/* </Grid> */}

                      <Box sx={{ width: "100%" }}>
                        <Grid container rowSpacing={1}>
                          {/* Show Document list */}
                          {ad_MessageAttachmentList.length > 0 && (
                            <Grid item xs={12} lg={12} className="pt-0">
                              <Item>
                                <Box
                                  className="d-flex justify-content-between mb-0 align-items-center"
                                  sx
                                >
                                  <Typography
                                    variant="h3"
                                    className="font-18 text-black lato-semibold mb-0"
                                  >
                                    Attachments
                                  </Typography>
                                  {ad_MessageAttachmentList.length > 1 && (
                                    <Button
                                      onClick={downloadAllDocs}
                                      variant="contained"
                                      className="btn-blue mb-2"
                                      size="small"
                                      startIcon={<DownloadIcon />}
                                    >
                                      Download All
                                    </Button>
                                  )}
                                </Box>

                                {/* message Document */}

                                <Box className="d-flex flex-wrap main-email-attachment w-100 mt-1">
                                  {ad_MessageAttachmentList.length > 0 &&
                                    ad_MessageAttachmentList.map((doc) => {
                                      return (
                                        <>
                                          {/* <Tooltip arrow> */}
                                          <Box
                                            className="email-attachment mb-2 "
                                            title={
                                              doc && doc.PortalName
                                                ? doc.PortalName
                                                : "Not Available"
                                            }
                                            onDoubleClick={() =>
                                              handlePreview(doc)
                                            }
                                          >
                                            <Box>
                                              <img
                                                src={GetDocTypeIcon2Requests(
                                                  doc.DocExtension
                                                )}
                                                style={{ width: 28 }}
                                                alt="documents"
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                  fontWeight: "550",
                                                }}
                                                className="d-flex align-items-center"
                                              >
                                                <span className="d-inline-block ms-2">
                                                  {doc && doc.PortalName
                                                    ? doc.PortalName
                                                    : "Not Available"}
                                                </span>
                                              </Typography>
                                            </Box>
                                            <Box className="download">
                                              <Tooltip title="Download" arrow>
                                                <Button
                                                  className="min-width-auto"
                                                  size="small"
                                                  onClick={(e) =>
                                                    downloadFile(e, doc)
                                                  }
                                                >
                                                  <DownloadIcon className="text-blue font-20" />
                                                </Button>
                                              </Tooltip>
                                            </Box>
                                          </Box>
                                          {/* </Tooltip> */}
                                        </>
                                      );
                                    })}
                                </Box>
                              </Item>
                            </Grid>
                          )}
                          {/* Show comments */}
                          <Grid item xs={12} className="pt-0">
                            <Item sx={{ marginRight: "20px" }}>
                              {(status === "For Info" ||
                                status === "Pending") && (
                                <Comments
                                  portalDocID={portalDocID}
                                  portalMessageComments={dmCommentJson}
                                  ClientName={ClientName}
                                  customerId={customerId}
                                />
                              )}
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    {status === "Approved" && (
                      <Box className="white-box 02">
                        <Box className="d-flex justify-content-between align-items-center">
                          <Typography
                            className="font-18 text-black lato-semibold"
                            variant="h3"
                          >
                            Certificate of approval
                          </Typography>
                        </Box>

                        <hr />

                        <Box className="m-0 p-0">
                          <Grid container spacing={3} className="m-0 p-0">
                            <Grid
                              item
                              xs={12}
                              lg={6}
                              // sx={{
                              //   height: "400px",
                              //   overflow: "auto",
                              // }}
                              // className="pe-2"
                            >
                              <Card sx={{ border: "1px solid black" }}>
                                {certificateUrl.split(",")[1] ===
                                "undefined" ? (
                                  <>
                                    <CustomLoader />
                                  </>
                                ) : (
                                  <iframe
                                    src={certificateUrl}
                                    width="100%"
                                    height="700px"
                                    frameBorder="0"
                                    allowFullScreen
                                    title="Embedded Content"
                                  />
                                )}
                              </Card>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Box>
                                <Box className="mb-2">
                                  <Typography
                                    variant="h2"
                                    className="font-18 lato-semibold"
                                  >
                                    Comments
                                  </Typography>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: dmCommentHtml,
                                    }}
                                    // className="single-user-comments"
                                  ></div>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </TabPanel>

                {/* <TabPanel value="2" className="p-0">
                  <ClientRequestDetails />
                </TabPanel> */}
              </TabContext>
            </Box>
          </>
        </Box>
      )}

      {/* Modal */}
      <DocumentPreviewModal open={openDocumentPreviewModal} />
      <SignDocument
        open={openSignModal}
        location={location}
        handleCloseSign={handleCloseSign}
      />
    </Box>
  );
}
