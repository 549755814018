import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import ToggleButton from "@mui/material/ToggleButton";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import AddIcon from '@mui/icons-material/Add';
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import MarkunreadIcon from '@mui/icons-material/Markunread';
// import "./ClientDetails.css";
// import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
// import CompanyDetails from './CompanyDetails';
// import ClientOverview from './ClientOverview';
//import Utils from "../../services/Utils";
// import CommanCLS from '../../services/CommanService';
// import UdfCard from './UdfCard';
import { useLocation, useSearchParams } from "react-router-dom";
// import DocumentList from './Document';
// import DocumentList from './DocumentList';
// import UploadDocument from './UploadDocument';
import ClientAddress from "./ClientAddress";
// import Contact from './Contact';
// import CompaniesHouse from './CompaniesHouse';
// import CustomBreadCrumbs from '../../components/CustomBreadCrumbs';
// import TaskList from './TaskList';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import EditClientdetails from './EditClientdetails';
// import EditReference from './EditReference';
// import { toast } from 'react-toastify';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
// import UploadDocForClient from './UploadDocForClient';
import CustomLoader from "../../components/CustomLoader";
import PersonIcon from "@mui/icons-material/Person";
// import { useDispatch, useSelector } from "react-redux";
// import { clearExplorerSearchDocRedux, setOpenDocumentModalByRedux } from '../../redux/reducers/counterSlice';
// import HtmlEditorDX from '../../components/HtmlEditor';
// import { Height } from '@mui/icons-material';
// import Fileformat from '../../images/files-icon/pdf.png';
// import { DialogTitle } from '@mui/material';
// import { Label } from '@mui/icons-material';
// import { fetchContactListByFolderRedux, fetchSupplierListOrderByFavourite } from '../../redux/reducers/api_helper';
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Ad_SetClientIdReducer,
  EditCompanyOpenMdalReducer,
} from "../../redux/adityaReducer";
import {
  getClientIdMethod_Redux,
  getFolderObject_Redux,
} from "../../redux/akSlice";
import { devApi } from "../../redux/AxiosInstance";
import { ad_DeleteClient_Json_DeleteSuppliers_Action } from "../../redux/dev_aditya_action";
import ClientOverview from "./ClientOverview";
import ClientsContacts from "./ClientsContacts";
import CompanyDetails from "./CompanyDetails";
import UdfCard from "./UdfCard";
import { handleOpenContactModal } from "../../redux/docuSlice";

const agrno = localStorage.getItem("agrno");
const Email = localStorage.getItem("Email");
const password = localStorage.getItem("password");
const folderId = localStorage.getItem("FolderId");

function ClientDetails() {
  const location = useLocation();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = searchParams.get("val");
  // const test = searchParams.get("OrgNo");
  const paramFOlderId = searchParams.get("folder");

  const originatorNo = searchParams.get("OrgNo");

  const { globalSearchDocs } =
    location?.state !== null ? location?.state : { globalSearchDocs: [] };

  const [selected, setSelected] = React.useState(false);
  const [value, setValue] = React.useState(tabValue ? tabValue : "1");
  const [clientDetails, setClientDetails] = useState({});
  const [allContactList, setAllContactList] = useState([]);

  const [companyDetails, setCompanyDetails] = useState([]);
   const { ad_ClientId } = useSelector(({aditya}) => aditya);
 console.log('✌️ad_ClientId --->', ad_ClientId);

  const [companyEditDetails, setCompanyEditDetails] = useState([]);
  const baseUrl = "https://docusms.uk/dsdesktopwebservice.asmx/";

  const clientWebUrl = "https://docusms.uk/dswebclientmanager.asmx/";
  const baseUrlPractice =
    "https://practicetest.docusoftweb.com/PracticeServices.asmx/";
  const dispatch = useDispatch();
  // let Clsprect = new CommanCLS(baseUrlPractice, agrno, Email, password);
  // let Cls = new CommanCLS(baseUrl, agrno, Email, password);

  // let webClientCLS = new CommanCLS(clientWebUrl, agrno, Email, password);

  // const [templateDataMarkup, setTemplateDataMarkup] = React.useState([]);
  // const [editorContentValue, setEditorContentValue] = React.useState([]);

  useEffect(() => {
    folderId && dispatch(getFolderObject_Redux(`${folderId}`));
    originatorNo && dispatch(getClientIdMethod_Redux(`${originatorNo}`));
  }, [folderId, originatorNo]);

  // upload document modal start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpenUploadDocument = () => {
    // dispatch(setOpenDocumentModalByRedux(true));
  };
  // upload document modal end

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const Suppliers = () => {
    // let obj = {
    //   agrno: agrno,
    //   Email: Email,
    //   password: password,
    //   CCodeList: originatorNo ? originatorNo : ""
    // };
    // try {
    //   Cls.Suppliers(obj, (sts, data) => {
    //     if (sts) {
    //       if (data) {
    //         console.log("Suppliers", data);
    //         if(data){
    //           toast.error(data);
    //           setTimeout(() => {
    //             navigate("/dashboard/Connections");
    //             dispatch(fetchSupplierListOrderByFavourite(folderId));
    //           },1000);
    //         } else {
    //             console.log("Failed to delete client!");
    //         }
    //        setAnchorEl(null);
    //       }
    //     }
    //   });
    // } catch (err) {
    //   console.log("Error while calling Json_GetCRMContactUDFValues", err);
    // }
  };
  const handleDelete = () => {
    dispatch(
      ad_DeleteClient_Json_DeleteSuppliers_Action(
        { CCodeList: originatorNo },
        (res) => {
          console.log(res, "=== res");
          if (res?.d) {
            toast.success(res?.d);
          }
          navigate("/user/contact/companies");
        }
      )
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Json_GetToFavourites = async () => {
    let obj = {
      agrno: agrno,
      Email: Email,
      password: password,
    };
    try {
      const res = await devApi.post("/Json_GetToFavourites", obj);
      if (res.data && res.data.d) {
        let favouriteUser = JSON.parse(res.data.d).Table;
        if (favouriteUser.length > 0) {
          let ans = favouriteUser
            .map((itm) => itm.OriginatorNo.trim())
            .includes(originatorNo.trim());
          if (ans) {
            setSelected(true);
          } else {
            setSelected(false);
          }
        } else {
          setSelected(false);
        }
      }
    } catch (err) {
      console.log("Error while calling Json_GetToFavourites", err);
    }
  };

  const Json_RemoveToFavourite = async () => {
    let obj = {
      agrno: agrno,
      Email: Email,
      password: password,
      OrgNo: originatorNo,
      ProjectID: folderId,
    };
    await devApi.post("/Json_RemoveToFavourite", obj);
    // toast.success("Removed from favourites");
    setSelected(false);
  };

  const Json_AddToFavourite = async () => {
    let obj = {
      agrno: agrno,
      Email: Email,
      password: password,
      ProjectID: folderId,
      OrgNo: originatorNo,
    };
    await devApi.post("/Json_AddToFavourite", obj);
    // toast.success("Added to favourites");
    // dispatch(fetchSupplierListOrderByFavourite());
    setSelected(true);
  };

  const Json_GetClientCardDetails = async () => {
    let obj = {
      Email: Email,
      agrno: agrno,
      intProjectId: folderId,
      password: password,
      strOrignatorNumber: originatorNo,
    };
    try {
      const res = await devApi.post("/Json_GetClientCardDetails", obj);
      if (res.data && res.data.d) {
        let json = JSON.parse(res.data.d);
        setClientDetails(json);
        setCompanyDetails(json.Table1);
        setCompanyEditDetails(json.Table1);
        setAllContactList(json.Table6);
      }
    } catch (err) {
      console.log("Error while calling Json_GetClientCardDetails", err);
    }
  };

  useEffect(() => {
    Json_GetClientCardDetails();
    Json_GetToFavourites();
  }, []);

  // edit client modal
  const [Referance, setReferance] = React.useState(false);
  const handleClickReferance = (e, originatorNo) => {
    // setReferance(true);
    dispatch(EditCompanyOpenMdalReducer(true));
  };
  const EditCLientHandleClose = () => {
    setReferance(false);
    dispatch(EditCompanyOpenMdalReducer(true));
  };

  const [Notesopen, NotessetOpen] = React.useState(false);
  const NoteshandleClickOpen = () => {
    NotessetOpen(true);
  };
  const NoteshandleClose = () => {
    NotessetOpen(false);
  };

  const SaveStickyNotes = () => {
    try {
      //  console.log(editorContentValue)
      // let o = {
      //     ItemId: selectedDocument["Registration No."],
      //     strStickyNotes: window.btoa(editorContentValue)
      // }
      // cls.Json_SetItemStickyNotes(o, function (sts, data) {
      //     if (sts && data) {
      //         if (data === "Success") {
      //             Json_GetItemStickyNotes();
      //             toast.success("Updated !");
      //         }
      //     }
      // })
    } catch (error) {
      console.log({ Status: false, mgs: "Data not found", Error: error });
    }
  };

  function handleGoBack() {
    // dispatch(clearExplorerSearchDocRedux());
    navigate("/dashboard/Connections");
  }

  const PortalMessageBtn = () => {
    dispatch(getFolderObject_Redux(`${value.FolderID}`));
    dispatch(getClientIdMethod_Redux(`${value.ClientID}`));
    navigate(
      `/user/portal-messages?argno=${originatorNo}&folderId=${paramFOlderId}`
    );
  };

  return (
    <>
      <Box className="container-fluid p-0 mt-2">
        {/* <CustomBreadCrumbs tabs={[{ tabLink: "/dashboard/Connections", tabName: "Connections" }, { tabLink: "/dashboard/clientDetails", tabName: "Client Details" }]} /> */}

        {globalSearchDocs.length === 0 && (
          <Box className="d-flex align-items-center justify-content-between flex-wrap">
            <Box className="d-flex flex-wrap align-items-center">
              <Typography variant="h5" className="title semibold me-3 mb-2">
                {clientDetails.Table1 &&
                  clientDetails?.Table1[0]?.OriginatorName}
              </Typography>

              <ToggleButton
                value="check"
                selected={selected}
                onChange={() => {
                  //setSelected(!selected);
                  if (selected) {
                    Json_RemoveToFavourite();
                  } else {
                    Json_AddToFavourite();
                  }
                }}
                className="mb-2 p-1 btn-favorite"
              >
                <FavoriteIcon />
              </ToggleButton>
            </Box>

            <Box className="d-flex align-items-center  gap-1 flex-wrap">
              <Button
                className="btn-blue "
                size="small"
                startIcon={<MarkunreadIcon />}
                onClick={PortalMessageBtn}
              >
                Portal Messages
              </Button>

              <Button
                className="btn-blue "
                size="small"
                startIcon={<BorderColorIcon />}
                onClick={(e) => handleClickReferance(e, originatorNo)}
              >
                Edit Client
              </Button>
              {/* <Button className='btn-blue-2 me-2 mb-1' size="small" startIcon={<GroupAddIcon />}>Add Client</Button> */}
              <Button
                className="btn-blue "
                size="small"
                startIcon={<NoteAltIcon />}
                onClick={NoteshandleClickOpen}
              >
                Notes
              </Button>
              <Button
                className="btn-blue"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleClickOpenUploadDocument}
              >
                Add Document
              </Button>

              <Button
                className="btn-blue"
                size="small"
                startIcon={<PersonIcon />}
                onClick={() => {
                  dispatch(handleOpenContactModal(true));
                  dispatch(Ad_SetClientIdReducer(originatorNo));
                }}
              >
                Add Contact
              </Button>

              <div>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="min-width-auto mb-1"
                  startIcon={<MoreVertIcon />}
                ></Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  className="custom-dropdown"
                >
                  <MenuItem className="ps-1" onClick={handleDelete}>
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    
                    Delete Client
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </Box>
        )}
        {/* <hr /> */}
        <Box sx={{ width: "100%", typography: "body1" }} className="">
          <TabContext value={value}>
            <Box className="mb-1">
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="custom-tabs"
              >
                <Tab label="General" value="1" />
                <Tab label="Address" value="2" />
                <Tab label="Contact" value="3" />
                {/* <Tab label="Tasks" value="4" />
                <Tab label="Documents" value="5" />
                <Tab label="Companies House" value="6" />
                <Tab label="Requested Document" value="7" /> */}
              </TabList>
            </Box>
            <TabPanel value="1" className="p-0">
              {clientDetails && clientDetails?.Table3?.length > 0 ? (
                <>
                  <Box className="general-tab white-box">
                    <Box className="row mb-2">
                      {/* For CompanyDetails */}
                      <CompanyDetails
                        companyDetails={companyDetails}
                        originatorNo={originatorNo}
                      />
                      {/* <CompanyDetails companyDetails={companyDetails} originatorNo={originatorNo} Cls={Cls} /> */}
                      {/* For ClientOverview */}
                      <ClientOverview originatorNo={originatorNo} />
                      {/* <ClientOverview Cls={Cls} webClientCLS={webClientCLS} locationState={{ agrno: agrno, Email: Email, password: password, folderId: folderId, originatorNo: originatorNo }} /> */}
                    </Box>
                    <hr className="mt-0" />
                  </Box>
                  <Box className="main-accordian">
                    <UdfCard data={clientDetails} />
                    {/* For UDFs */}
                  </Box>
                </>
              ) : (
                <CustomLoader />
              )}
            </TabPanel>

            <TabPanel value="2" className="p-0">
              <ClientAddress originatorNo={originatorNo}></ClientAddress>
            </TabPanel>
            <TabPanel value="3" className="p-0 relative">
              <ClientsContacts allContactList={allContactList} />
              {/* <Contact clientId={clientDetails.Table1 && clientDetails?.Table1[0]?.OriginatorNo} clientName={clientDetails.Table1 && clientDetails?.Table1[0]?.OriginatorName}></Contact> */}
            </TabPanel>
            {/* <TabPanel value="4" className="p-0 relative">
              Taskist here
              <TaskList clientName={clientDetails.Table1 && clientDetails?.Table1[0]?.OriginatorNo}></TaskList>
            </TabPanel> */}

            {/* <TabPanel value="5" className="p-0 relative">
              DocumentList here
              <DocumentList clientId={originatorNo} globalSearchDocs={globalSearchDocs} ></DocumentList>
            </TabPanel> */}

            {/* <TabPanel value="5">
                        <DocumentList/>
                    </TabPanel> */}
            {/* <TabPanel value="6" className="p-0">
              CompaniesHouse here
              <CompaniesHouse></CompaniesHouse>
            </TabPanel>
            <TabPanel value="7">
              <RequestedDocs Email={companyDetails[0]?.Email} />
            </TabPanel> */}
          </TabContext>
        </Box>
      </Box>

      {/* edit Referance modal */}
      <Dialog
        open={Referance}
        onClose={EditCLientHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal full-modal"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="dropdown-box">
            <Typography variant="h4" className="font-18 bold text-black">
              Edit Client
            </Typography>
          </Box>

          {/*  */}
          <Button onClick={EditCLientHandleClose}>
            <span className="material-symbols-outlined text-black">cancel</span>
          </Button>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            EditReference here
            {/* <EditReference companyEditDetails={companyEditDetails}></EditReference> */}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={EditCLientHandleClose}>Disagree</Button>
                    <Button onClick={EditCLientHandleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
      </Dialog>

      {/* notes modal */}
      <Dialog
        open={Notesopen}
        onClose={NoteshandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
      >
        <Box className="d-flex align-items-center p-3 justify-content-between modal-head">
          <Box className="clearfix">
            <Typography className="font-16 bold">Add Notes</Typography>
          </Box>

          {/*  */}
          <Button onClick={NoteshandleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="mb-4">
            <Box className="mt-1">
              HTML editor here
              {/* < DataNotFound/> */}
              {/* {<HtmlEditorDX sx={{ Height: "200px !important" }} templateDataMarkup={templateDataMarkup} setTemplateDataMarkup={setTemplateDataMarkup} setEditorContentValue={setEditorContentValue}></HtmlEditorDX>} */}
            </Box>
          </DialogContentText>

          <DialogActions>
            <Button
              className="btn-blue"
              startIcon={<AddIcon />}
              onClick={SaveStickyNotes}
            >
              Add Notes
            </Button>
            <Button
              onClick={NoteshandleClose}
              className="btn-red"
              startIcon={<CloseIcon />}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ClientDetails;
