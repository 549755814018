import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import CustomeSignature from "../components/CustomeSignature";
import {
  fetchdocuRequestData,
  SignRequestedDocument_Json,
} from "../redux/api_helper";
import {
  ad_client_ApproveMessageNew_Json,
  ad_client_DisapproveMessageNew_Json,
  ad_SetPubUserSign_Json_Action,
  addCommentClientrequest,
  Dmessage_ApproveMessageNewByToken_Json_Action,
  Dmessage_CommentAddedByToken_Json_Action,
  Dmessage_GetAllCommentsByToken_Json_Action,
  Dmessage_GetMessageDocumentsByToken_Json_Action,
  Dmessage_Poral_Document_Sign_Action,
  GetSignatureDocumentAction,
} from "../redux/dev_aditya_action";
import {
  downloadShareboxDocument,
  getPortalMessageDocuments,
  savePortalMessgeComment,
} from "../redux/dev_chetan_api_helper";
import {
  setOpenFromInbox,
  setshareboxDocForPreviewURL,
} from "../redux/dev_chetan_slice";
import {
  setOpenDocumentPreviewModal,
  setOpenSignDocument,
} from "../redux/modalSlice";
import HtmlEditorDX from "../user/pages/create-request/HtmlEditor";
import {
  clientBodyaccIdData,
  RequestDownloadFile,
} from "../utils/RequestsFunction";
import { portalUser } from "../redux/AxiosInstance";

function SignDocument({ open, location, handleCloseSign }) {
  const { PortalDocID, customerId, EmailID } = location?.state || {};
  const agrno = localStorage.getItem("accIdClient");
  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [commentHtml, setCommentHtml] = useState();
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] =
    useState(true);
  const { signDocument, ad_Dm_Api_Body } = useSelector(({ aditya }) => aditya);

  const [openSignatur, setOpenSignatur] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const Addopen = Boolean(AddanchorEl);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenSignDocument(false));
    setSignaturePreview(true);
    setSignatureSetup(false);
    handleCloseSign && handleCloseSign();
  };

  const handleChangeSignature = () => {
    // dispatch(SignatureRemoved(obj12));
    setSignaturePreview(false);
    setSignatureSetup(true);
  };

  const handleDisapproveBtn = () => {
    console.log("Clicked Disapprove Button");
    let comment = commentHtml
      ? commentHtml
      : "I disapprove the message and its associated attachments.";
    let disApproveBody = {
      messageId: PortalDocID,
      sMessage: comment,
    };
    dispatch(
      ad_client_DisapproveMessageNew_Json(disApproveBody, (res) => {
        let commentBody = {
          messageID: PortalDocID,
          response: comment,
        };
        dispatch(
          savePortalMessgeComment(commentBody, (res) => {
            setCommentHtml("");
          })
        );
      })
    );
  };

  const handleMergeDocument = () => { };

  const TokenApproveMessage = async () => {
    let arr = [];
    portalMessageDocuments.forEach((element) => {
      return arr.push(true);
    });

    let comment = commentHtml
      ? commentHtml
      : "I approve the message and its associated attachments.";
    let ApproveBody = {
      ...ad_Dm_Api_Body,
      smessage: comment,
      attachmentApprovals: arr,
    };
    dispatch(
      Dmessage_ApproveMessageNewByToken_Json_Action(
        ApproveBody,
        async (res) => {
          console.log("✌️res --->", res);
          if (!res) {
            setTimeout(() => {
              TokenApproveMessage();
            }, 5000);
            return true;
          } else {
            let comBody = {
              ...ad_Dm_Api_Body,
              response: comment,
              username: "",
            };

            await dispatch(
              Dmessage_CommentAddedByToken_Json_Action(comBody, (res) => {
                dispatch(
                  Dmessage_GetAllCommentsByToken_Json_Action(
                    ad_Dm_Api_Body,
                    () => { }
                  )
                );
                setCommentHtml("");
              })
            );

            await dispatch(
              Dmessage_GetMessageDocumentsByToken_Json_Action(
                ad_Dm_Api_Body,
                (res) => { }
              )
            );
            handleCloseSign();
            // dispatch(
            //   addCommentClientrequest(commentBody, (res) => {
            //     setCommentHtml("");
            //     dispatch(getPortalMessageDocuments(PortalDocID));
            //     toast.success("All documents processed successfully!");
            //   })
            // );
            // dispatch(setOpenSignDocument(false));
            return false;
          }
        }
      )
    );
  };

  const ApproveMessage = async () => {
    let arr = [];
    portalMessageDocuments.forEach((element) => {
      return arr.push(true);
    });

    let comment = commentHtml
      ? commentHtml
      : "I approve the message and its associated attachments.";

    let commentBody = {
      messageID: PortalDocID,
      comment: comment.trim(),
    };

    let ApproveBody = {
      messageID: PortalDocID,
      smessage: comment,
      attachmentApprovals: arr,
    };
    dispatch(
      ad_client_ApproveMessageNew_Json(ApproveBody, (res) => {
        console.log("✌️res --->", res);
        if (!res) {
          setTimeout(() => {
            ApproveMessage();
          }, 5000);
          return true;
        } else {
          dispatch(
            addCommentClientrequest(commentBody, (res) => {
              setCommentHtml("");
              dispatch(getPortalMessageDocuments(PortalDocID));
              toast.success("All documents processed successfully!");
            })
          );
          dispatch(setOpenSignDocument(false));
          return false;
        }
      })
    );
  };

  const ToSet_Stage_CompletedDate_Json = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    try {
      portalUser.post("ToSet_Stage_CompletedDate_Json", {
        accId: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "",
        username: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "",
        sPassword: localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "",
        OnboardingGUID: sessionStorage.getItem("OnboardingGUID") ? sessionStorage.getItem("OnboardingGUID") : "",
        StageID: "2",
        StageCompletedOn: `${year}-${month}-${day}`,
      });
    } catch (error) {
      console.error("Error while calling ToSet_Stage_CompletedDate_Json", error);
    }
  }

  const ApproveMessage_For_Proposal = async () => {
    let arr = [];
    messageDocs.forEach((element) => {
      return arr.push(true);
    });

    let comment = commentHtml
      ? commentHtml
      : "I approve the message and its associated attachments.";

    let commentBody = {
      messageID: sessionStorage.getItem("PortalDocId"),
      comment: comment.trim(),
    };

    let ApproveBody = {
      messageID: sessionStorage.getItem("PortalDocId"),
      smessage: comment,
      attachmentApprovals: arr,
    };
    dispatch(
      ad_client_ApproveMessageNew_Json(ApproveBody, (res) => {
        console.log("✌️res --->", res);
        if (!res) {
          setTimeout(() => {
            ApproveMessage();
          }, 5000);
          return true;
        } else {
          ToSet_Stage_CompletedDate_Json();
          dispatch(
            addCommentClientrequest(commentBody, (res) => {
              setCommentHtml("");
              dispatch(
                getPortalMessageDocuments(sessionStorage.getItem("PortalDocId"))
              );
              toast.success("All documents processed successfully!");
            })
          );
          dispatch(setOpenSignDocument(false));
          return false;
        }
      })
    );
  };

  const handleChangeSignatureSaved_Json = async () => {
    // direct message sign in document

    let signObj;
    if (portalMessageDocuments.length > 0) {
      const allDoc = portalMessageDocuments
        .filter((signDoc) => signDoc.ForSigning)
        .map((signDoc) => {
          const body = { messageId: PortalDocID, attachid: signDoc?.Attachid };
          if (handleCloseSign) {
            let body = {
              ...ad_Dm_Api_Body,
              attachid: signDoc?.AttachId,
            };
            return dispatch(
              Dmessage_Poral_Document_Sign_Action(body, (res) => { })
            );
          } else {
            return dispatch(ad_SetPubUserSign_Json_Action(body, (res) => { }));
          }
        });

      try {
        await Promise.all(allDoc);
        if (handleCloseSign) {
          TokenApproveMessage();
        } else ApproveMessage();
      } catch (error) {
        console.error("Error processing documents:", error);
        toast.error("Failed to process some documents."); // Show error toast
      }
    } else {
      signObj = {
        ...clientBodyaccIdData,
        DocTempId: portalAllRequest.DocTempId ? portalAllRequest.DocTempId : "",
        DocId: portalAllRequest.DocId ? portalAllRequest.DocId : "",
      };
      let obj = {
        DocTempId: portalAllRequest.DocTempId,
        DocId: portalAllRequest.DocId,
      };

      dispatch(
        SignRequestedDocument_Json(signObj, function (res) {
          if (res.length > 0) {
            toast.error(res);
          } else {
            toast.success("Document Signed");
            dispatch(fetchdocuRequestData(EmailID));
            dispatch(GetSignatureDocumentAction(obj, (res) => { }));

            dispatch(setOpenSignDocument(false));
          }
        })
      );
    }
  };

  const handleChangeSignatureSaved_Json_for_proposal = async () => {
    let signObj;
    if (messageDocs.length > 0) {
      const allDoc = messageDocs
        .filter((signDoc) => signDoc.ForSigning)
        .map((signDoc) => {
          const body = {
            messageId: sessionStorage.getItem("PortalDocId"),
            attachid: signDoc?.Attachid,
          };
          return dispatch(ad_SetPubUserSign_Json_Action(body, (res) => { }));
        });

      try {
        await Promise.all(allDoc);
        // await dispatch(
        //   savePortalMessgeComment(commentBody, (res) => {
        //     setCommentHtml("");
        //   })
        // );

        const response = await ApproveMessage_For_Proposal();
        if (response) {
        }
      } catch (error) {
        console.error("Error processing documents:", error);
        toast.error("Failed to process some documents."); // Show error toast
      }
    } else {
      signObj = {
        ...clientBodyaccIdData,
        DocTempId: portalAllRequest.DocTempId ? portalAllRequest.DocTempId : "",
        DocId: portalAllRequest.DocId ? portalAllRequest.DocId : "",
      };
      let obj = {
        DocTempId: portalAllRequest.DocTempId,
        DocId: portalAllRequest.DocId,
      };

      dispatch(
        SignRequestedDocument_Json(signObj, function (res) {
          if (res.length > 0) {
            toast.error(res);
          } else {
            toast.success("Document Signed");
            dispatch(fetchdocuRequestData(EmailID));
            dispatch(GetSignatureDocumentAction(obj, (res) => { }));

            dispatch(setOpenSignDocument(false));
          }
        })
      );
    }
  };

  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };

  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };

  let portalAllRequest = useSelector(
    (state) => state.docu.fetchDocumentRequest
  );
  let portalAllRequestTest = useSelector((state) => state.docu);

  const {
    portalMessageDocuments,
    portalDocIdForSignDocModal,
    openFromInbox,
    openFromOnboardingProposal,
  } = useSelector((state) => state.dev_chetan);

  const { messageDocs } = useSelector(
    (state) => state.dev_chetan.proposalCompData
  );

  //  Aakash Singh
  const { signature } = useSelector((state) => state.ak);
  const obj12 = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };

  useEffect(() => {
    return () => {
      dispatch(setOpenFromInbox(false));
    };
  }, []);

  const base64String = signature ? signature : false;
  const format = "png";
  const ImageBox = styled(Box)({
    // width: "100%",
    height: "auto",
    border: "1px solid black",
    borderRadius: "10px",
    background: "#fff",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
  });

  const downloadFile = (e, doc) => {
    if (doc.Attachment) {
      let ankr = document.createElement("a");
      ankr.href = `data:application/octet-stream;base64,${doc?.Attachment}`;
      ankr.download = doc.PortalName;
      ankr.click();
    } else {
      e.stopPropagation();
      dispatch(
        downloadShareboxDocument(
          PortalDocID,
          doc.Attachid,
          doc.DocExtension,
          doc.PortalName
        )
      );
    }
  };

  const handlePreview = (data) => {
    console.log("✌️data --->", data);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${agrno ? agrno : ad_Dm_Api_Body?.accid
      }&attachment=${data?.Attachid ? data?.Attachid : data?.AttachId}&Id=${PortalDocID ? PortalDocID : ad_Dm_Api_Body?.messageId
      }&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const handleCommentHtml = (e, editor) => {
    setCommentHtml(e);
  };

  const handleSendApprovalComment = () => {
    setCommentHtml("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
      sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "600px" } }}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="clearfix">
          <DialogTitle className="p-2 ms-2">
            {"Sign & Approve Document"}
          </DialogTitle>
        </Box>

        {/*  */}
        <Button onClick={handleClose} autoFocus className="p-0">
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent>
        {openFromOnboardingProposal ? (
          <Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h3"
                  className="font-12 mb-1 lato-semibold text-black "
                >
                  {"Attachment List"}
                </Typography>

                {signatureSetup && (
                  <>
                    <Button className="" onClick={AddhandleClick}>
                      <MoreVertIcon />
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={AddanchorEl}
                      open={Addopen}
                      onClose={AddhandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="custom-dropdown"
                    >
                      <MenuItem onClick={AddhandleClose}>
                        <DownloadIcon className="" />
                        Download Selected
                      </MenuItem>
                      <MenuItem onClick={AddhandleClose}>
                        <FolderIcon className="" /> Download All
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>

              {messageDocs.length > 0
                ? messageDocs.map((doc, index) => (
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      boxShadow: "none",
                    }}
                    className="white-box d-flex align-items-center justify-content-between p-2"
                    onDoubleClick={() => handlePreview(doc)}
                    key={index}
                  >
                    <Box className="d-flex">
                      <img
                        src={pdf}
                        style={{ width: 30 }}
                        alt="documents"
                        className="me-2"
                      />
                      {/* {console.log('test for priview for jay', portalAllRequest)} */}
                      <Typography className="d-flex align-items-center font-13 lato-semibold">
                        {doc.PortalName ? doc.PortalName : "Not Available"}
                      </Typography>
                    </Box>

                    <Box>
                      {doc?.ForSigning && (
                        <span className="badge text-bg-primary">
                          For Sign
                        </span>
                      )}
                      <Tooltip title="Download" arrow>
                        <Button className="ms-2 min-width-auto" size="small">
                          <DownloadIcon
                            className="text-blue"
                            onClick={(e) => downloadFile(e, doc)}
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                ))
                : "No Attachments"}

              {/* <Box
                sx={{
                  border: "1px solid black",
                  marginTop: "10px",
                  borderRadius: "10px",
                  background: "#7a7a7a",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    fontSize: "12px",
                    color: "#fff",
                    fontWeight: "700",
                    padding: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={pdf} className=" m-auto" height={24} width={24} />

                    <Typography sx={{ marginLeft: "10px" }}>
                      {portalAllRequest.DocName + portalAllRequest.DocExtension}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
            </Box>

            <hr />

            <HtmlEditorDX
              templateDataMarkup={commentHtml}
              handleEditorChange={handleCommentHtml}
            />

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    // className="font-12 mb-1 lato-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Setting"}
                  </Typography>

                  <IconButton
                    sx={{ marginTop: "-15px" }}
                    onClick={handleChangeSignature}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Box
                    sx={{
                      // border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {base64String && base64String.length > 0 ? (
                      <ImageBox
                        component="img"
                        src={`data:image/${format};base64,${base64String}`}
                        alt="Base64 Image"
                        sx={{ width: 300, height: 95 }}
                      />
                    ) : (
                      <h2 className="title font-18">No Signature</h2>
                    )}
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-red mb-3"
                      onClick={handleDisapproveBtn}
                    >
                      Disapprove{" "}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleChangeSignatureSaved_Json_for_proposal}
                    >
                      Sign & Approve{" "}
                    </Button>

                    {/* <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleMergeDocument}
                    >
                      Merge Document
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleSendApprovalComment}
                    >
                      Comment{" "}
                    </Button> */}
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                  // setOpenSignatur={setOpenSignatur}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : openFromInbox ? (
          <Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h3"
                  className="font-12 mb-1 lato-semibold text-black "
                >
                  {"Attachment List"} bbbb
                </Typography>

                {signatureSetup && (
                  <>
                    <Button className="" onClick={AddhandleClick}>
                      <MoreVertIcon />
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={AddanchorEl}
                      open={Addopen}
                      onClose={AddhandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="custom-dropdown"
                    >
                      <MenuItem onClick={AddhandleClose}>
                        <DownloadIcon className="" />
                        Download Selected
                      </MenuItem>
                      <MenuItem onClick={AddhandleClose}>
                        <FolderIcon className="" /> Download All
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>

              {portalMessageDocuments.length > 0
                ? portalMessageDocuments.map((doc, index) => (
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      boxShadow: "none",
                    }}
                    className="white-box d-flex align-items-center justify-content-between p-2"
                    onDoubleClick={() => handlePreview(doc)}
                    key={index}
                  >
                    <Box className="d-flex">
                      <img
                        src={pdf}
                        style={{ width: 30 }}
                        alt="documents"
                        className="me-2"
                      />
                      {/* {console.log('test for priview for jay', portalAllRequest)} */}
                      <Typography className="d-flex align-items-center font-13 lato-semibold">
                        {doc.PortalName ? doc.PortalName : "Not Available"}
                      </Typography>
                    </Box>

                    <Box>
                      {doc?.ForSigning && (
                        <span className="badge text-bg-primary">
                          For Sign
                        </span>
                      )}
                      <Tooltip title="Download" arrow>
                        <Button className="ms-2 min-width-auto" size="small">
                          <DownloadIcon
                            className="text-blue"
                            onClick={(e) => downloadFile(e, doc)}
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                ))
                : "No Attachments"}

              {/* <Box
                sx={{
                  border: "1px solid black",
                  marginTop: "10px",
                  borderRadius: "10px",
                  background: "#7a7a7a",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    fontSize: "12px",
                    color: "#fff",
                    fontWeight: "700",
                    padding: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={pdf} className=" m-auto" height={24} width={24} />

                    <Typography sx={{ marginLeft: "10px" }}>
                      {portalAllRequest.DocName + portalAllRequest.DocExtension}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
            </Box>

            <hr />

            <HtmlEditorDX
              templateDataMarkup={commentHtml}
              handleEditorChange={handleCommentHtml}
            />

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    // className="font-12 mb-1 lato-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Setting"}
                  </Typography>

                  {!handleClose && (
                    <IconButton
                      sx={{ marginTop: "-15px" }}
                      onClick={handleChangeSignature}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      // border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {base64String && base64String.length > 0 ? (
                      <ImageBox
                        component="img"
                        src={`data:image/${format};base64,${base64String}`}
                        alt="Base64 Image"
                        sx={{ width: 300, height: 95 }}
                      />
                    ) : (
                      <h2 className="title font-18">No Signature</h2>
                    )}
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-red mb-3"
                      onClick={handleDisapproveBtn}
                    >
                      Disapprove{" "}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Sign & Approve{" "}
                    </Button>

                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleMergeDocument}
                    >
                      Merge Document
                    </Button>

                    {/* <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleSendApprovalComment}
                    >
                      Comment{" "}
                    </Button> */}
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                  // setOpenSignatur={setOpenSignatur}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            {portalAllRequest && (
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    className="font-12 mb-1 lato-semibold text-black "
                  >
                    {"Attachment List"}
                  </Typography>

                  {signatureSetup && (
                    <>
                      <Button className="" onClick={AddhandleClick}>
                        <MoreVertIcon />
                      </Button>

                      <Menu
                        id="basic-menu"
                        anchorEl={AddanchorEl}
                        open={Addopen}
                        onClose={AddhandleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        className="custom-dropdown"
                      >
                        <MenuItem onClick={AddhandleClose}>
                          <DownloadIcon className="" />
                          Download Selected
                        </MenuItem>
                        <MenuItem onClick={AddhandleClose}>
                          <FolderIcon className="" /> Download All
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>

                {signDocument && Object.keys(signDocument).length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      boxShadow: "none",
                    }}
                    className="white-box d-flex align-items-center justify-content-between p-2"
                  >
                    <Box className="d-flex">
                      <img
                        src={pdf}
                        style={{ width: 30 }}
                        alt="documents"
                        className="me-2"
                      />
                      <Typography className="d-flex align-items-center font-13 lato-semibold">
                        {signDocument.DocName + "." + signDocument.DocExtension}
                      </Typography>
                    </Box>

                    <Box>
                      <Tooltip title="Download" arrow>
                        <Button className="ms-2 min-width-auto" size="small">
                          <DownloadIcon
                            className="text-blue"
                            onClick={(e) =>
                              RequestDownloadFile(e, signDocument)
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                )}

                {portalMessageDocuments.length > 0 &&
                  portalMessageDocuments.map(
                    (doc) =>
                      doc.ForSigning && (
                        <Box
                          sx={{
                            border: "1px solid #f1f1f1",
                            boxShadow: "none",
                          }}
                          className="white-box d-flex align-items-center justify-content-between p-2"
                          onDoubleClick={() => handlePreview(doc)}
                        >
                          <Box className="d-flex">
                            <img
                              src={pdf}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                            {/* {console.log('test for priview for jay', portalAllRequest)} */}
                            <Typography className="d-flex align-items-center font-13 lato-semibold">
                              {doc.PortalName
                                ? doc.PortalName
                                : "Not Available"}
                            </Typography>
                          </Box>

                          <Box>
                            <Tooltip title="Download" arrow>
                              <Button
                                className="ms-2 min-width-auto"
                                size="small"
                              >
                                <DownloadIcon
                                  className="text-blue"
                                  onClick={(e) => downloadFile(e, doc)}
                                />
                              </Button>
                            </Tooltip>
                          </Box>
                        </Box>
                      )
                  )}
              </Box>
            )}

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    className="mb-2"
                    // className="font-12 mb-1 lato-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Setting"}
                  </Typography>

                  {base64String && base64String.length <= 1 && (
                    <IconButton
                      sx={{ marginTop: "-15px" }}
                      onClick={handleChangeSignature}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      // border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {base64String && base64String.length > 0 ? (
                      <ImageBox
                        component="img"
                        src={`data:image/${format};base64,${base64String}`}
                        alt="Base64 Image"
                        sx={{ width: 300, height: 95 }}
                      />
                    ) : (
                      <h2 className="title font-18">No Signature</h2>
                    )}
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Sign & Approve{" "}
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SignDocument;
