import { Box, Button, Pagination, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomePortalMessageTable from "../../../components/CustomePortalMessageTable";
import CustomLoader from "../../../components/CustomLoader";
import NoData from "../../../components/NoData";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import { GetRetractedMessages_Json_Redux } from "../../../redux/api_helper";
import AdvanceFilter from "./AdvanceFilter";
import DataGridComposeMessage from "../../../components/DataGridComposeMessage";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

function RetractedMessages({ value }) {
  let dispatch = useDispatch();
  const { getRetractedMessages, getRetractedMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const gridRef = useRef();

  const [originalData, setOriginalData] = useState([]); // Holds unfiltered data
  const [filteredData, setFilteredData] = useState([]); // Holds filtered data
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [filterEnable, setFilterEnable] = useState(false);
  const itemsPerPage = 5; // Number of items per page

  let objReq = useLocalStorage("accid");

  let retrackObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    ccode: "",
    emailAddress: "",
  };

  useEffect(() => {
    dispatch(GetRetractedMessages_Json_Redux(retrackObj, function (res) {}));
  }, [dispatch]);

  // const TableHeder = [
  //   "Subject",
  //   "Recipient",
  //   "Approval Requested",
  //   "Issued On",
  //   "Due Date",
  //   "Status",
  //   "Action",
  // ];

  const TableHeader = [
    {
      index: 1,
      tabHeader: "Type",
    },
    {
      index: 2,
      tabHeader: "Subject",
    },
    {
      index: 3,
      tabHeader: "Recipient",
    },
    {
      index: 4,
      tabHeader: "Issued On",
    },
    {
      index: 5,
      tabHeader: "Due Date",
    },
    {
      index: 6,
      tabHeader: "Status",
    },
    {
      index: 7,
      tabHeader: "Action",
    },
  ];

  // Create a JSON object from the array using the map method
  // Create a JSON object from the array using the map method
  const jsonObjectArray = useMemo(() => {
    return getRetractedMessages.map((item) => {
      const approvedDate = item["Date Approved"]
        ? moment(item["Date Approved"]).format("DD/MM/yyyy")
        : "Not available";
      const viewedDate = item.Viewed
        ? moment(item.Viewed).format("DD/MM/yyyy")
        : "Not available";

      let viewStatus = "Pending";
      if (!item.Viewed) viewStatus = "Unread";
      else if (item.Viewed && item.Approved === "Yes") viewStatus = "Approved";
      else if (item.Viewed && item.Approved === "No")
        viewStatus = "Disapproved";

      return {
        Subject: item.Subject || "Not available",
        Recipient: item["Client Name"] || "Not available",
        ApprovalRequested: item["To be Approved"] || "Not available",
        IssuedOn: item.Issued
          ? moment(item.Issued).format("DD/MM/yyyy")
          : "Not available",
        DueDate: item["Due Date"]
          ? moment(item["Due Date"]).format("DD/MM/yyyy")
          : "Not available",
        Status: viewStatus,
        MessageNo: item["Message No"] || "Not available",
        MessageID: item["Message ID"] || "Not available",
        ReferenceName: item["Client Name"] || "Not available",
        History: [
          { date: "Section", customerId: item.Section || "Not available" },
          { date: "Date Approved", customerId: approvedDate },
          { date: "Viewed On", customerId: viewedDate },
          {
            date: "Email ID",
            customerId: item?.Emailid || "Not available",
          },
          {
            date: "Sent By",
            customerId: item["Sent/Received By"] || "Not available",
          },
        ],
      };
    });
  }, [getRetractedMessages]);

  useEffect(() => {
    setOriginalData(jsonObjectArray);
    setFilteredData(jsonObjectArray);
  }, [jsonObjectArray]);

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filteredData]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClearFilters = () => {
    if (gridRef.current) {
      gridRef.current.instance.clearFilter(); // Clears all filters
    }
  };

  return (
    <>
      <Box className="d-flex align-items-center flex-wrap justify-content-between white-box py-0 pe-0 mb-0 relative mb-2">
        {/* <AdvanceFilter
          setFilteredData={setFilteredData}
          originalData={originalData}
          setCurrentPage={setCurrentPage}
          tabIndex={value}
        /> */}

        <Box className="d-flex flex-wrap search-area mt-3">
          {/* <PortalMessagesFilters filterType={value} /> */}

          <Tooltip title="Filter" arrow>
            <Button
              className="min-width-auto"
              onClick={() => {
                setFilterEnable(!filterEnable);
                handleClearFilters();
              }}
            >
              <FilterAltIcon className="text-black font-18" />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Box>
        {getRetractedMessagesLoding ? (
          <>
            <Box>
              <CustomLoader />
            </Box>
          </>
        ) : filteredData && filteredData.length > 0 ? (
          <>
            {/* <CustomePortalMessageTable
              TableHeder={TableHeder}
              TableData={paginatedData}
              value={value}
            /> */}
            <DataGridComposeMessage
              TableHeader={TableHeader}
              TableData={filteredData}
              value={value}
              filterEnable={filterEnable}
              gridRef={gridRef}
            />
          </>
        ) : (
          <NoData messsage="No Message here" />
        )}
      </Box>
      {/* <nav aria-label="Page navigation example">
        <Box className="mt-2">
          <Pagination
            count={totalPages}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="custom-pagination"
          />
        </Box>
      </nav> */}
    </>
  );
}

export default RetractedMessages;
