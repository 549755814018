import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchdocuRequestData,
  RequestedDocumentCreated_Json,
  RequestedDocumentCreated_Json_Redux,
  RequestedDocumentCreatedNew_Json,
  sonamConfirmMessage1,
} from "../../redux/api_helper";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../../redux/dev_sonam_api_helper";
import { setRequestDocumentData } from "../../redux/dev_sonam_slice";
import { GetDocTypeIconRequests } from "../../utils/RequestsFunction";
import { useLocation } from "react-router-dom";

const ReplaceRequestDoc = ({ accordionItem, setPreview, getDoc }) => {
  const LoginClient = localStorage.getItem("LoginClient");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const EmailID = searchParams.get("EmailID");

  const clientBody = {
    accId: localStorage.getItem("accIdClient"),
    username: localStorage.getItem("EmailClient"),
    sPassword: localStorage.getItem("passwordClient"),
  };

  const userBody = {
    accId: localStorage.getItem("accid"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };

  const dispatch = useDispatch();

  // const [preview, setPreview] = useState(""); // For previewing the selected file

  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const uploadImage = async (e) => {
    let file = e.target.files[0];
    if (!file) return; // If no file selected, do nothing

    setPreview(file);
    const base64File = await fileToBase64(file); // Convert the file to base64

    if (LoginClient) {
      let body = {
        ...clientBody,
        emailID: localStorage.getItem("EmailClient"),
        docTempId: accordionItem.DocTempId,
        DocExtension: "." + file.name.split(".").pop(),
        docID: accordionItem.DocId,
        attachment: base64File,
      };

      // Send the data to the backend
      dispatch(
        RequestedDocumentCreatedNew_Json(body, (res) => {
          console.log("Server response:", res);
          if (res) {
            let uploadobj = {
              ...clientBody,
              docTempId: accordionItem?.DocTempId,
              docID: accordionItem?.DocId,
              statusId: "3",
            };

            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                uploadobj,
                function (res) {
                  dispatch(fetchdocuRequestData(EmailID));
                  getDoc();
                }
              )
            );
          }
        })
      );
    } else {
      let body = {
        ...userBody,
        emailID: localStorage.getItem("EmailId"),
        docTempId: accordionItem.DocTempId,
        docID: accordionItem.DocId,
        DocExtension: "." + file.name.split(".").pop(),
        attachment: base64File, // Attach base64 file
      };

      dispatch(
        RequestedDocumentCreated_Json_Redux(body, (res) => {
          if (res) {
            let uploadobj = {
              ...userBody,
              emailid: localStorage.getItem("EmailId"),
              sPassword: "0",
              docTempId: accordionItem?.DocTempId,
              docID: accordionItem?.DocId,
              statusId: "4", // Assuming status 4 means file uploaded
            };

            // Update document status in the backend
            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                uploadobj,
                (res) => {
                  dispatch(fetchdocuRequestData(customerId)); // Refresh the request data
                }
              )
            );
          }
        })
      );
    }
  };

  // Helper function to convert a file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract base64 string without prefix
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Button
      component="label"
      variant="text"
      startIcon={<AttachFileIcon />}
      className="btn-blue"
      sx={{
        padding: "5px",
        fontSize: "12px",
        marginRight: "10px",
      }}
    >
      Replace Document
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => uploadImage(e)} // Handle file upload
      />
    </Button>
  );
};

export default ReplaceRequestDoc;
