import {
  AgreementListDetails_Redux,
  ContectDetails,
  CRMContactUDFValues_Redux,
  Json_GetFolders,
  My_profile1,
  My_signature1,
  SentConatactData,
  setAllContact_Redux,
  setAllPortalSendMessages,
  setAllUserFromBookingData,
  setCalendlyLink,
  setCleintContactDetailas,
  setClientContactDetailasProfile,
  setcompanyAddress_Redux,
  setcompanyCardDitiels_Redux,
  setConfigs_Redux,
  setConfigs_Redux1ComReference,
  setJsonConfig_Redux,
  UDF_Add_Redux,
  UdfNameId_Redux,
} from "./akSlice";
import {
  docuSms,
  docuSmsClient,
  portalClient,
  portalUser,
  dsdesktopwebservice,
  WhatsAppservice,
  calendlyService,
  PracticeServices,
  practiceTest,
  PracticeServicess
} from "./AxiosInstance";

const clientData = {
  accid: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient")
}
export const Json_GetConfiguration_Redux = (reqObj) => async (dispatch) => {
  try {
    let res = await docuSmsClient.post("/Json_GetConfiguration", reqObj);
    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      setConfigs_Redux({
        roles: allConfigs?.Table4,
        managers: allConfigs?.Table3,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetFolders_Redux = () => async (dispatch) => {
  let requestBody = {
    agrno: localStorage.getItem("agrno"),
    Email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };
  try {
    let res = await docuSmsClient.post("/Json_GetFolders", requestBody);
    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      Json_GetFolders({
        GetFolders: allConfigs.Table,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetConfiguration = () => async (dispatch) => {
  let requestBody = {
    agrno: localStorage.getItem("agrno"),
    Email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };
  try {
    let res = await docuSmsClient.post("/Json_GetConfiguration", requestBody);
    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      setJsonConfig_Redux({
        bussiness: allConfigs.Table1,
        sources: allConfigs.Table2,
        mangers: allConfigs.Table3,
        companyStatus: allConfigs.Table,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetConfiguration_Redux1 = (reqObj) => async (dispatch) => {
  try {
    let res = await docuSms.post("/Json_GetAllClientList", reqObj);
    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      setConfigs_Redux1ComReference({
        CompanyReference: allConfigs?.Table,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const UDF_Add_Redux1 = (obj12) => async (dispatch) => {

  try {

    let res = await portalUser.post("/GetPortalClientCardDetails_Json", obj12)
    let allConfigs = JSON.parse(res?.data?.d);

    console.log('testing by jp gurjar' , allConfigs )

    // console.log("udf_Add_Redux", allConfigs.Table6[3] ?  allConfigs.Table6[3] :  allConfigs.Table6[0]);
    dispatch(
      UDF_Add_Redux({

        data: allConfigs,
        originFullDetails: allConfigs.Table6[3] ? allConfigs.Table6[3] : allConfigs.Table6[0]
      })
    );
    let test = allConfigs.Table6[3] ? allConfigs.Table6[6] : allConfigs.Table6[0];
    return test;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const My_Profile_Redux = (obj1) => async (dispatch) => {
  try {
    let res = await portalUser.post("/GetPortalUserProfilesettings_Json", obj1);
    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      My_profile1({
        My_profile: allConfigs,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const Two_Factor_Authentication1 = (obj12) => async (dispatch) => {
  try {
    let res = await portalUser.post(
      "/TwoFactorAuthenticationUpdated_Json",
      obj12
    );
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const AddContect_Redux = (formData) => async (dispatch) => {
  try {
    let res = await practiceTest.post("/AddContact", formData);
    return res;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const AddContect_ReduxPubUser = (formData) => async (dispatch) => {
  try {
    let res = await PracticeServices.post("/AddContactByPubUser", formData);
    return res;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const addCompany_Redux = (clientdata) => async (dispatch) => {
  try {
    let res = await docuSms.post("/Json_AddClient", clientdata);
    return res;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const SetClientAddress_Redux = (obj) => async (dispatch) => {
  try {
    let res = await docuSms.post("/Json_SetClientAddress", obj);
    return res;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Upload_Signature = (obj123) => async (dispatch) => {
  try {
    let res = await portalUser.post("/SignatureSavedNew_Json", obj123);
    // let allConfigs = JSON.parse(res?.data?.d);
    return res;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const SignatureRemoved = () => async (dispatch) => {
  try {
    let res = await portalUser.post("/SignatureRemovedNew_Json", clientData);
    return res.data;
    // let allConfigs = JSON.parse(res?.data?.d);
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const portal_Redux = (obj1) => async (dispatch) => {
  try {
    let res = await portalClient.post("/PortalUserAccountCreated_Json", obj1);
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const GetSignature_Json_Redux = (clientBodyaccidData) => async (dispatch) => {
  try {
    let res = await portalUser.post("/GetSignatureNew_Json", clientBodyaccidData);

    dispatch(
      My_signature1({
        signature: res.data.d,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetClientCardDetails_Redux =
  (clientCarddata) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post(
        "/Json_GetClientCardDetails",
        clientCarddata
      );
      let allConfigs = JSON.parse(res?.data?.d);
      dispatch(
        setcompanyCardDitiels_Redux({
          companyCardDitiels: allConfigs.Table1[0],
          companyCardDitielsAddresses: allConfigs.Table2[1]
            ? allConfigs.Table2[1]
            : allConfigs.Table2[0],
          // companyCardDitielsAddresses:allConfigs.Table2[0]
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };

export const Json_GetClientAddresses_Redux =
  (clientAddres) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post(
        "/Json_GetClientAddresses",
        clientAddres
      );
      let allConfigs = JSON.parse(res?.data?.d);
      // console.log(allConfigs,'erhuhfurgutygug');
      dispatch(
        setcompanyAddress_Redux({
          companyAddress: allConfigs.Table[0],
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };

export const Json_UpdateClient_Redux = (clientAddres) => async (dispatch) => {
  try {
    let res = await practiceTest.post("/Json_UpdateClient", clientAddres);
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetAllContactsByClientID_Redux =
  (contectData) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post(
        "/Json_GetAllContactsByClientID",
        contectData
      );
      let allConfigs = JSON.parse(res?.data?.d);
      dispatch(
        setAllContact_Redux({
          allContact: allConfigs,
        })
      );
      return allConfigs;
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };

export const Json_UpdateContact_Redux1 = (contactData) => async (dispatch) => {
  try {
    let res = await docuSmsClient.post("/Json_UpdateContact", contactData);
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const Json_GetAgreementListDetails_Redux1 =
  (obj1) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post("/Json_GetAgreementListDetails", obj1);
      let allConfigs = JSON.parse(res?.data?.d);
      dispatch(
        AgreementListDetails_Redux({
          AgreementListDetails: allConfigs.Table[0],
        })
      );
      return allConfigs;
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };

export const Json_UpdateContactField_Redux1 =
  (updateProfileData) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post(
        "/Json_UpdateContactField",
        updateProfileData
      );
      let allConfigs = JSON.parse(res?.data?.d);
      return allConfigs;
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };

export const Json_Portal_GetClientListByEmail_Redux =
  () => async (dispatch) => {
    try {
      const response = await practiceTest.post(
        "/Json_Portal_GetClientListByEmail",
        {
          agrno: localStorage.getItem('accIdClient') ? localStorage.getItem('accIdClient') : '',
          ContactEmail: localStorage.getItem('EmailClient') ? localStorage.getItem('EmailClient') : ''
        }
      );
      if (response?.data?.d) {
        const result = JSON.parse(response?.data?.d);
        // dispatch(setMessageReadUnreadCountDetails(result));
        dispatch(
          UdfNameId_Redux({
            UdfNameId: result?.ContactList,
          })
        );


        return result?.ContactList
      } else {
        // dispatch(setMessageReadUnreadCountDetails([]));
      }
    } catch (err) {
      console.log("Error while calling GetCertificate_Json", err);
    }
  };
export const ContectDetails_Redux = (reqObj) => async (dispatch) => {

  try {
    let res = await docuSms.post("/Json_Contacts_Mobile_GetAllUDF", reqObj);
    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      ContectDetails({
        ContectDetails1: allConfigs,
      })
    );
    return allConfigs;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
export const Json_GetCRMContactUDFValues_Redux1 =
  (updateProfileData) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post(
        "/Json_GetCRMContactUDFValues",
        updateProfileData
      );
      let allConfigs = JSON.parse(res?.data?.d);
      dispatch(
        CRMContactUDFValues_Redux({
          CRMContactUDFValues: allConfigs,
        })
      );
      return allConfigs;
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };


export const Json_CRMSaveUDFValues =
  (updateProfileUDF) => async (dispatch) => {
    try {
      let res = await docuSmsClient.post(
        "/Json_CRMSaveUDFValues",
        updateProfileUDF
      );
      let allConfigs = JSON.parse(res?.data?.d);
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetUsersEmailSignature_Json",
        error
      );
    }
  };
// Jayprakash api slice .........

// contscat varifid Start
export const GetPortalContactDetailsByEmail = (obj12) => async (dispatch) => {
  try {
    let res = await portalUser.post("/GetPortalContactDetailsByEmail_Json", obj12);

    let allConfigs = JSON.parse(res?.data?.d);

    // console.log('All Confing Data' , allConfigs.Table1[0])

    dispatch(
      setCleintContactDetailas({
        getClientContactDetailas: allConfigs.Table1[0]

      })
    )

    // console.log('GetPortalContactDetailsByEmail' , allConfigs.Table1[0])
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};
// conatct varified end

export const SentMessageOfConatct = (obj12) => async (dispatch) => {
  try {
    let res = await portalUser.post("/PortalUsersOutboxNew_Json", obj12);

    let allConfigs = JSON.parse(res?.data?.d);
    dispatch(
      SentConatactData({
        conatactData: allConfigs,
      })
    );
    dispatch(
      setAllPortalSendMessages({
        conatactData: allConfigs,
      })
    );
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const SentWhatappValidationOtp = (obj1) => async (dispatch) => {
  try {
    let res = await WhatsAppservice.post("/sendOTP", obj1);
    return res;
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const DeleteWhatappValidationOtp = (obj1) => async (dispatch) => {
  try {
    let res = await WhatsAppservice.post("/deleteOtpByNumber", obj1);

    let allConfigs = JSON.parse(res?.data?.d);

  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const SendWhatappValidOtp = (obj1) => async (dispatch) => {
  try {
    let res = await WhatsAppservice.post("/verifyOTP", obj1);
    return res
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

// Book Metting Api 

export const GetCalendlyCredentialsForAllUsers = (obj1) => async (dispatch) => {
  try {
    // let res = await portalUser.post("/GetPortalManagers_Json", obj1);
    let res = await portalUser.post("/GetCalendlyCredentialsForAllUsers_Json", obj1);
    let allConfigs = JSON.parse(res?.data?.d);
    allConfigs = allConfigs.Table1

    dispatch(
      setAllUserFromBookingData({
        getAllUserFromBookingData: allConfigs,
      })
    );


  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

// Show Calandry

// calandly Intrgreation start


export const JsonGetCalendlyCredentials  = (obj1) => async (dispatch) => {
  try {

    let res = await PracticeServices.post("/Json_GetCalendlyCredentials", obj1);
    let allConfigs = JSON.parse(res?.data?.d);
    let Data = allConfigs.Table[0]
    console.log('JsonGetCalendlyCredentials' , Data)

    localStorage.setItem('CalendlyClientkey' , Data.CalendlyClientId);
    localStorage.setItem('CalendlySecretKey' , Data.CalendlySecretKey);

   
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

export const JsonUpdateCalendlyCredentials  = (obj1) => async (dispatch) => {
  try {

    let res = await PracticeServices.post("/Json_UpdateCalendlyCredentials", obj1);
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs


   
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

// calandly Intrgreation end


export const ShowCalendly= (meetingLink) => async (dispatch) => {
  try {
    dispatch(
      setCalendlyLink({
        getCalendlyLink: meetingLink
      })
    )
  } catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
};

// Show Calandly Usee


// profile Updates Query 

export const GetClientContactDetailasProfile = (obj) => async (dispatch) => {

  try {
    let res = await portalUser.post("/GetContactAddressDetails_Json", obj);
    let allConfigs = JSON.parse(res?.data?.d);

    dispatch(
      setClientContactDetailasProfile({
        getClientContactDetailasProfile: allConfigs
      })
    )

  }
  catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }

}

export const updateClientContactDetailasProfile = (obj) => async (dispatch) => {

  try {
    let res = await portalUser.post("/UpdateContactDetails_Json", obj);
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs;
  }
  catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }

}

export const getClientAMlChekDetailasProfile = (obj) => async (dispatch) => {
  try {
    let res = await portalUser.post("/GetPortalClientUDFDetails_Json", obj);
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs;
  }
  catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }
}

export const getPortalContactVerification_Json = async (obj) => {
  try {
    let res = await portalUser.post("/GetPortalContactVerification_Json", obj);
    let allConfigs = JSON.parse(res?.data?.d);
    return allConfigs;
  }
  catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }

}
export const UpdatedPortalContactVerification_Json = async (obj) => {
  try {
    let res = await portalUser.post("/UpdatedPortalContactVerification_Json", obj);
    // let allConfigs = JSON.parse(res?.data?.d);
    return res;
  }
  catch (error) {
    console.log(
      "Network error: No response received from server, GetUsersEmailSignature_Json",
      error
    );
  }

}

