import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    shareboxDocuments: [],
    isShareDocLoading: true,
    shareboxDocForPreviewURL: '',
    docDetailForPreview:null,
    allContacts: [],
    allContactsLoding: true,
    CRMContactUDFValues_Redux: [],
    allContactsByClientID_Redux: [],
    allMessagesContactsDetails_Redux: [],
    shareBoxMessageId: "",
    portalUserInboxMessage: [],
    allPortalUserInboxMessage: [],
    isInboxMessageLoading: true,
    inboxMessageHtml: "",
    portalMessageDocuments: [],
    portalMessageDocumentsLoading: true,
    portalMessageComments: "undefined",
    portalApprovalCommentsHtml: "",
    portalDocIdForSignDocModal: "",
    openFromInbox: false,
    inboxMessageHtmlLoading: true,
    portalSections: [],
    contactRequestDocCount: [],
    messageReadUnreadCountDetails: [],
    isContactRequestDocCountLoading: true,
    isMessageReadUnreadCountDetailsLoading: true,
    clientListByProjectId: [],
    proposalCompData: {
        messageData: [],
        messageDocs: [],
        approvalsAndComments:"",
        approvalCertificate:""
    },
    additionalInformation: {
        surveyDetailsList: [], 
        isSurveyDetailsListLoading: true,
    },
    onBoardingDetails: {
        clientListByEmail: [],
        onBoardingDetailsByClientId: [],
        portalMessageDetailsByTaskId: []
    },
    openFromOnboardingProposal: false,
    isOpenOnboardingAlert: false,
};

const dev_chetan_slice = createSlice({
    name: 'Docu_Request',
    initialState,
    reducers: {
        setShareBoxDocuments: (state,action)=>{
            state.shareboxDocuments = action.payload;
            state.isShareDocLoading = false;
        },
        setshareboxDocForPreviewURL: (state,action)=>{
            state.shareboxDocForPreviewURL = action.payload;
        },
        setDocDetailForPreviewURL: (state,action)=>{
            state.docDetailForPreview = action.payload;
        },
        setAllContacts: (state,action)=>{
            state.allContacts = action.payload;
            state.allContactsLoding = false;
        },
        setCRMContactUDFValues_Redux: (state,action)=>{
            state.CRMContactUDFValues_Redux = action.payload;
        },
        setAllContactsByClientID: (state,action)=>{
            state.allContactsByClientID_Redux = action.payload;
        },
        setAllMessagesContactsDetails_Redux: (state,action)=>{
            state.allMessagesContactsDetails_Redux = action.payload;
        },
        setShareBoxMessageId: (state,action)=>{
            state.shareBoxMessageId = action.payload;
        },
        setPortalUserInboxMessage: (state,action)=>{
            state.portalUserInboxMessage = action.payload;
            state.isInboxMessageLoading = false;
        },
        setAllPortalUserInboxMessage: (state,action)=>{
            state.allPortalUserInboxMessage = action.payload;
            state.isInboxMessageLoading = false;
        },
        setInboxMessageLoading: (state,action)=>{
            state.isInboxMessageLoading = action.payload;
        },
        setInboxMessageHtml: (state,action)=>{
            state.inboxMessageHtml = action.payload;
        },
        setPortalMessageDocuments: (state,action)=>{
            state.portalMessageDocuments = action.payload;
        },
        setPortalMessageDocumentsLoading: (state,action)=>{
            state.portalMessageDocumentsLoading = action.payload;
        },
        setPortalMessageComments: (state,action)=>{
            state.portalMessageComments = action.payload;
        },
        setApprovalCertificateBase64: (state,action)=>{
            state.approvalCertificateBase64 = action.payload;
        },
        setPortalApprovalCommentsHtml: (state,action)=>{
            state.portalApprovalCommentsHtml = action.payload;
        },
        setPortalDocIdForSignDocModal: (state,action)=>{
            state.portalDocIdForSignDocModal = action.payload;
        },
        setOpenFromInbox: (state,action)=>{
            state.openFromInbox = action.payload;
        },
        setInboxMessageHtmlLoading: (state,action)=>{
            state.inboxMessageHtmlLoading = action.payload;
        },
        setPortalSections: (state,action)=>{
            state.portalSections = action.payload;
        },
        setContactRequestDocCount: (state,action)=>{
            state.contactRequestDocCount = action.payload;
            state.isContactRequestDocCountLoading = false;
        },
        setMessageReadUnreadCountDetails: (state,action)=>{
            state.messageReadUnreadCountDetails = action.payload;
            state.isMessageReadUnreadCountDetailsLoading = false;
        },
        setClientListByProjectId: (state,action)=>{
            state.clientListByProjectId = action.payload;
        },
        setProposalCompData: (state,action)=>{
            const {name, value} = action.payload
            state.proposalCompData[name] = value;
        },
        setAdditionalInformation: (state,action)=>{
            const {name, value} = action.payload
            state.additionalInformation[name] = value;
        },
        setOnBoardingDetails: (state,action)=>{
            const {name, value} = action.payload
            state.onBoardingDetails[name] = value;
        },
        setOpenFromOnboardingProposal: (state,action)=>{
            state.openFromOnboardingProposal = action.payload
        },
        setIsOpenOnboardingAlert: (state,action)=>{
            state.isOpenOnboardingAlert = action.payload
        },
    }
});

export const { setShareBoxDocuments, setshareboxDocForPreviewURL, setDocDetailForPreviewURL, setAllContacts, setCRMContactUDFValues_Redux, setAllContactsByClientID, setAllMessagesContactsDetails_Redux, setShareBoxMessageId, setPortalUserInboxMessage, setAllPortalUserInboxMessage, setInboxMessageHtml, setPortalMessageDocuments, setPortalMessageDocumentsLoading, setPortalMessageComments, setApprovalCertificateBase64, setPortalApprovalCommentsHtml, setPortalDocIdForSignDocModal, setOpenFromInbox, setInboxMessageLoading,setInboxMessageHtmlLoading, setPortalSections, setContactRequestDocCount, setMessageReadUnreadCountDetails, setClientListByProjectId, setProposalCompData, setAdditionalInformation, setOnBoardingDetails, setOpenFromOnboardingProposal, setIsOpenOnboardingAlert } = dev_chetan_slice.actions

export default dev_chetan_slice.reducer