import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchdocuRequestData,
  RequestedDocumentCreated_Json,
  RequestedDocumentCreated_Json_Redux,
  RequestedDocumentCreatedNew_Json,
  sonamConfirmMessage1,
} from "../../redux/api_helper";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../../redux/dev_sonam_api_helper";
import { setRequestDocumentData } from "../../redux/dev_sonam_slice";
const UploadRequestDoc = ({ accordionItem, setPreview }) => {
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const customerId = searchParams.get("customerId");
  // const EmailID = searchParams.get("EmailID");
  const { customerId, EmailID } = location?.state || {};
  const LoginClient = localStorage.getItem("LoginClient");

  const clientBody = {
    accId: localStorage.getItem("accIdClient"),
    username: localStorage.getItem("EmailClient"),
    sPassword: localStorage.getItem("passwordClient")
      ? localStorage.getItem("passwordClient")
      : "All",
  };

  const userBody = {
    accId: localStorage.getItem("accid"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
    emailid: customerId,
  };

  const dispatch = useDispatch();

  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const selectFile = (file, index, accordionItem) => {
    if (!file) {
      return;
    }
    if (file) {
      const reader = new FileReader();

      // Convert file to base64 string
      reader.onloadend = () => {
        const base64String = reader.result;
        // setBase64(base64String);  // Store the base64 string in state
        setPreview(base64String); // Set image preview
      };

      reader.readAsDataURL(file); // Read file as Data URL (base64)
    }

    if (accordionItem.FileWithExtension) {
      dispatch(
        (sonamConfirmMessage1 =
          (`Are you sure you want to replace this file?`,
          accordionItem.DocName + "." + accordionItem.DocExtension,
          file.name,
          function (res) {
            if (res) {
              let uploadobj = {
                docTempId: accordionItem?.DocTempId,
                docID: accordionItem?.DocId,
                statusId: "4",
              };

              dispatch(
                UpdateRequestedDocStatusAction_Json_ReduxClient(
                  uploadobj,
                  function (res) {
                    dispatch(fetchdocuRequestData(customerId));
                  }
                )
              );
              processFile(file, index, accordionItem);
            }
          }))
      );
      return;
    } else {
      let statusobj = {
        docTempId: accordionItem?.DocTempId,
        docID: accordionItem?.DocId,
        statusId: "3",
      };

      dispatch(
        UpdateRequestedDocStatusAction_Json_ReduxClient(
          statusobj,
          function (res) {
            // console.log(res, "UpdateRequestedDocStatusAction_Json");
          }
        )
      );
      processFile(file, index, accordionItem);
    }
  };

  const uploadImage = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    // if (!file.type.includes("pdf"))
    //   return toast.error("Please upload a PDF document");

    setPreview(file);
    if (LoginClient) {
      let body = {
        ...clientBody,
        emailID: localStorage.getItem("EmailClient"),
        docTempId: `${accordionItem.DocTempId}`,
        docID: `${accordionItem.DocId}`,
        DocExtension: "." + file.name.split(".").pop(),
      };
      const base64File = await fileToBase64(file);
      body.attachment = base64File;
      dispatch(
        RequestedDocumentCreatedNew_Json(body, (res) => {
          if (res) {
            let uploadobj = {
              ...clientBody,
              docTempId: accordionItem?.DocTempId,
              docID: accordionItem?.DocId,
              statusId: "3",
            };

            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                uploadobj,
                function (res) {
                  dispatch(fetchdocuRequestData(EmailID));
                }
              )
            );
          }
        })
      );
    } else {
      let body = {
        ...userBody,
        emailID: accordionItem?.EmailId,
        docTempId: accordionItem?.DocTempId,
        DocExtension: "." + file.name.split(".").pop(),
        docID: accordionItem?.DocId,
      };

      const base64File = await fileToBase64(file);
      body.attachment = base64File;
      dispatch(
        RequestedDocumentCreated_Json_Redux(body, function (res) {
          if (res) {
            let uploadobj = {
              ...userBody,
              sPassword: "0",
              docTempId: accordionItem?.DocTempId,
              docID: accordionItem?.DocId,
              statusId: "3",
            };

            dispatch(
              UpdateRequestedDocStatusAction_Json_ReduxClient(
                uploadobj,
                function (res) {
                  dispatch(fetchdocuRequestData(customerId));
                }
              )
            );
          }
        })
      );
    }
  };

  const processFile = (file, index, accordionItem) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const extension = getFileExtension(file.name);
      const base64WithoutPrefix = reader.result.split(",")[1];

      const newFile = {
        name: file.name,
        size: file.size,
        base64: base64WithoutPrefix,
        extension,
      };
      if (newFile) {
        RequestedDocumentCreated_Json(accordionItem, extension, newFile.base64);

        const updatedAccordionData = portalAllRequestAll.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              fileStore: [newFile],
              FileExtenson: extension,
              FileWithExtension: newFile.name,
              uploadStatus: "Document Uploaded",
              FileOnlyExtension: extension,
            };
          }
          return item;
        });
        dispatch(setRequestDocumentData(updatedAccordionData));
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
  };

  const getFileExtension = (filename) => {
    const extension = filename.slice(
      ((filename.lastIndexOf(".") - 1) >>> 0) + 1
    );
    return extension ? `${extension}` : "";
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Button
      component="label"
      variant="text"
      startIcon={<AttachFileIcon />}
      className="btn-blue ms-2"
      sx={{
        padding: "5px",
        fontSize: "12px",
        // marginRight: "10px",
      }}
    >
      Upload File
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) =>
          // selectFile(e.target.files[0], accordionIndex, accordionItem)
          uploadImage(e)
        }
      />
    </Button>
  );
};

export default UploadRequestDoc;
