import React, { useEffect, useState } from "react";
import AutoComplete from "../../../components/AutoComplete";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DrawIcon from "@mui/icons-material/Draw";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import pdf from "../../../assets/images/files-icon/pdf.png";
import GetFileType from "../../../components/FileType";
import {
  CheckDocumentForFormFilling_Json_Redux,
  CheckDocumentForSignature_Json_Redux,
  GetPortalRequestDocuments_Json_Redux,
} from "../../../redux/api_helper";
import { setRequestDocument_Redux } from "../../../redux/docuSlice";
import CreateTemplate from "../Templates/CreateTemplate";
import DounloadFile from "./DownloadFile";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const agrno = localStorage.getItem("agrno");
const password = localStorage.getItem("password");
const FolderId = localStorage.getItem("FolderId");
const Email = localStorage.getItem("Email");

function RequestDocuments() {
  let redx = useSelector((state) => state.docu.ComposePortalMgsObj);
  let dispatch = useDispatch();

  let docRe = useSelector((state) => state.docu.RequestDocumnets);
  let docRe222 = useSelector((state) => state.docu.RequestDocumnets);
  let main = useSelector((state) => state.docu);
  const { PortalRequestDocuments } = useSelector((state) => state.docu);

  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [editTemplate, setEditTemplate] = React.useState(false);

  const CheckDocumentForSignature_Json = (dt, callback) => {
    try {
      if (redx.ToEmail[0]) {
        let o = {
          emailid: redx.ToEmail[0]["E-Mail"],
          sPassword: "0",
          docTempId: dt.DocTempId,
          docID: dt.DocId,
        };
        dispatch(
          CheckDocumentForSignature_Json_Redux(o, function (res) {
            if (res) {
              console.log("CheckDocumentForSignature_Json", res);
              callback(res);
            }
          })
        );
      } else {
        toast.error("Please select the Email ");
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server CheckDocumentForSignature_Json",
        error
      );
    }
  };

  const getTemplatesObject = (value) => {
    setIntervalId(null);
    clearInterval(intervalId);

    if (!value) {
      console.log("No value selected");
      dispatch(setRequestDocument_Redux(null));
      return;
    }

    try {
      dispatch(setRequestDocument_Redux(value));
      setSelectedTemplate({ action: "Edit", data: value });

      const obj = {
        id: value.DocTempID,
      };
      dispatch(GetPortalRequestDocuments_Json_Redux(obj, (res) => {}));
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalUsersRequestedDocs_Json_Redux",
        error
      );
    }
  };

  useEffect(() => {
    if (docRe222 && docRe222.DocTempID) {
      getTemplatesObject(docRe222);
    }
  }, [docRe222, docRe]);

  const [intervalId, setIntervalId] = React.useState(null);

  const handleCheckStatus = (data) => {
    // Clear any existing interval
    if (intervalId) {
      clearInterval(intervalId);
    }

    const newIntervalId = setInterval(() => {
      checkSignatureStatus(data);
    }, 2000);

    setIntervalId(newIntervalId);

    console.log("GetPortalRequestDocuments_Json1133", newIntervalId);
  };

  const checkSignatureStatus = (e) => {
    console.log("GetPortalRequestDocuments_Json1133", intervalId);
    if (e.RequestDocType === 1) {
      CheckDocumentForSignature_Json(e, (data) => {
        console.log("GetPortalRequestDocuments_Json11", data, intervalId);
        if (data.length > 0) {
          if (data[0].RequestDocStatus === 1) {
            setIntervalId(null); // Clear the intervalId state
            if (docRe && docRe.DocTempID) {
              getTemplatesObject(docRe);
            }
            clearInterval(intervalId);
            return () => clearInterval(intervalId);
          }
        }
      });
    }
  };

  const handleClickOpenModal = () => {
    setEditTemplate(true);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // modal
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    dispatch(setRequestDocument_Redux(""));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    console.log("Hello click", panel, event, isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  // Function to format the date to "dd/mm/yyyy"
  function formatDate(num) {
    const currentDate = new Date();
    const reminderDate = new Date(currentDate);
    reminderDate.setDate(reminderDate.getDate() + num);

    const day = String(reminderDate.getDate()).padStart(2, "0");
    const month = String(reminderDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = reminderDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const HandleClickSignature = (fileData) => {
    try {
      if (
        redx.ToEmail &&
        redx.ToEmail.length > 0 &&
        redx.ToEmail[0]["E-Mail"]
      ) {
        console.log("add signature", fileData);
        let url = `https://Signing.docuengager.com/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sEmailId=${redx.ToEmail[0]["E-Mail"]}&sendemail=&clientname=${redx.Client.Client}&option=upload&DocTempId=${fileData.DocTempId}&DocId=${fileData.DocId}&to=${redx.ToEmail[0]["E-Mail"]}&template=${fileData.DocTempId}&rwndrnd=0.8166129123678032`;
        window.open(url);
        //handleCheckStatus(fileData.item)
      } else {
        toast.error("Please select the email");
      }
    } catch (error) {
      console.log("add signature", error);
    }
  };

  function byteArrayToBase64(byteArray) {
    let binaryString = "";
    for (let i = 0; i < byteArray.length; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }
    return btoa(binaryString);
  }

  let uniq = {};

  const [openView, setOpenView] = useState(false);
  const [base64Data, setBase64Data] = useState("");
  const [fileType, setFileType] = useState("");

  const handleClickDownload = (data, fileType) => {
    try {
      console.log("base 64 and type", data, fileType);

      if (data) {
        //const byteArray = new Uint8Array(data);
        const base64 = data; //byteArrayToBase64(byteArray);

        console.log("base 64 and type", fileType, base64);
        setBase64Data(base64);
        setFileType(fileType);
        if (fileType)
          if (
            fileType === "xlsx" ||
            fileType === "doc" ||
            fileType === "docx"
          ) {
            // Check if the file type is an Excel or Word document
            // Trigger direct download
            let xlx = "vnd.ms-excel";
            let msword = "msword";
            let fix = fileType === "xlsx" ? xlx : msword;
            const link = document.createElement("a");
            link.href = `data:application/${fix};base64,${base64}`;
            link.download = `downloaded_file.${fileType}`; // Set the desired filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            // For other file types, open the dialog
            setOpenView(true);
            //setBase64Data(base64);
            //  setFileType(fileType);
          }
      }
    } catch (error) {
      console.log("Download failed:", error);
    }
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  return (
    <div>
      <DounloadFile
        open={openView}
        onClose={handleCloseView}
        base64Data={base64Data}
        fileType={fileType}
      />

      {/* <h2 className="title mt-4 mb-3">Request Documents</h2> */}
      <Box className="d-flex flex-wrap align-items-center pt-1">
        {/* <AutoComplete ComData={main.PortalDocReqTemplatesLiset} target="TemplateName" comFun={(e, v) => getTemplatesObject(v)} defaultValue={docRe && Object.keys(docRe).length !== 0 && docRe && docRe.TemplateName ? docRe.TemplateName : ""} lable={'Select Template'} className='font-12' /> */}
        <Box className="custom-autocomplete col-12 col-sm-12 mb-lg-0 mb-2 col-md-6 col-lg-4 col-xl-4">
          <AutoComplete
            ComData={main.PortalDocReqTemplatesLiset.filter(
              (obj) =>
                !uniq[obj.TemplateName] && (uniq[obj.TemplateName] = true)
            )}
            target="TemplateName"
            comFun={(e, v) => getTemplatesObject(v)}
            defaultValue={
              docRe &&
              Object.keys(docRe).length !== 0 &&
              docRe &&
              docRe.TemplateName
                ? docRe.TemplateName
                : ""
            }
            lable={"Select Template"}
            className="font-12 w-100 mb-2"
          />
        </Box>
        

        <Box className="ms-2 d-flex align-items-center flex-wrap gap-1">
        {docRe && (
          <Box>
            <Button
              className="btn-blue me-2"
              variant="contained"
              startIcon={<CreateIcon />}
              onClick={handleClickOpenModal}
            >
              Edit
            </Button>
          </Box>
        )}
          <Button
            className="btn-blue me-2"
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddCircleOutlineIcon />}
          >
            Create New Template
          </Button>
          <Button
            className="btn-blue"
            variant="contained"
            onClick={handleClickOpenModal}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Request
          </Button>
        </Box>
      </Box>

      <Box className="mt-2">
        <div className="main-accordian main-accordian-2">
          {docRe && PortalRequestDocuments && PortalRequestDocuments.length > 0
            ? PortalRequestDocuments.map((item, index) => {
                const panelId = `panel${index + 1}`;
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === panelId}
                    onChange={handleChange(panelId)}
                    className="accordian-box"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${panelId}-content`}
                      id={`${panelId}-header`}
                    >
                      <Tooltip title="For Info" arrow>
                        <Button size="small" className="min-width-auto me-1">
                          <InfoIcon className="font-18 text-black" />
                        </Button>
                      </Tooltip>

                      <Typography>{item.DocName}</Typography>

                      {item.SignTemplateId && (
                        <Tooltip title="" arrow>
                          <Button size="small" className="min-width-auto ms-2">
                            <VerifiedIcon className="font-20 text-success" />
                          </Button>
                        </Tooltip>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="d-flex justify-content-end pt-2">
                        <Grid container rowSpacing={0} columnSpacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="border-end"
                          >
                            <span>Expire In:</span>
                            <span className="badge rounded-pill bg-light-primary lato-medium pt-1">
                              {formatDate(item.ExpiryInDays)}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                          >
                            <span>Remind In:</span>
                            <span className="badge rounded-pill bg-light-primary lato-medium pt-1">
                              {formatDate(item.RemindIn)}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <hr />
                            <Box className="mb-3">
                              <Box className="d-flex align-items-center">
                                {item.DocExt && (
                                  <>
                                    <GetFileType
                                      Type={item.DocExt ? item.DocExt : ""}
                                    ></GetFileType>
                                    <Typography className="ms-1 font-12 lato-medium">
                                      {item.DocName
                                        ? item.DocName + "." + item.DocExt
                                        : ""}

                                      <DownloadForOfflineIcon
                                        onClick={() =>
                                          handleClickDownload(
                                            item.DocData,
                                            item.DocExt
                                          )
                                        }
                                      ></DownloadForOfflineIcon>
                                    </Typography>
                                  </>
                                )}
                              </Box>

                              {item.RequestDocType === 1 && (
                                <Button
                                  component="label"
                                  role={undefined}
                                  variant="contained"
                                  tabIndex={-1}
                                  startIcon={<DrawIcon />}
                                  size="small"
                                  className="btn-blue"
                                  onClick={() => HandleClickSignature(item)}
                                >
                                  Signature
                                </Button>
                              )}
                            </Box>
                            {item.FileName && (
                              <Box
                                sx={{
                                  border: "1px solid #f1f1f1",
                                  boxShadow: "none",
                                }}
                                className="white-box d-flex align-items-center justify-content-between p-2"
                              >
                                <Box className="d-flex">
                                  <img
                                    src={pdf}
                                    style={{ width: 30 }}
                                    alt="documents"
                                    className="me-2"
                                  />
                                  <Typography className="d-flex align-items-center font-13 lato-semibold">
                                    {item.FileName}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Tooltip title="Close" arrow>
                                    <Button
                                      className="ms-2 min-width-auto"
                                      size="small"
                                    >
                                      <CloseIcon className="text-red font-20" />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                        <hr />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : null}
        </div>
      </Box>

      {/* modal */}

      <Dialog
        // sx={{ maxWidth: '1600px', width: '100%' }}
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-request-document modal-lg"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Edit Template"}</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleCloseModal} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateTemplate
              isHideTitle={false}
              selectedTemplate={selectedTemplate}
              EditTemplate={editTemplate}
              TempFolderid={redx?.Folder}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-request-document modal-lg"
        maxWidth="lg"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Create New Template"}</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateTemplate isHideTitle={false} handleClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RequestDocuments;
