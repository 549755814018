import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArchiveIcon from "@mui/icons-material/Archive";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GroupsIcon from "@mui/icons-material/Groups";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import SettingsIcon from '@mui/icons-material/Settings';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CreateIcon from '@mui/icons-material/Create';

import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Tooltip
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Setting from "../../src/assets/images/icons/Setting.png";
import user from "../assets/images/user-2.svg";
import { setNewMessage_Redux, setNewRequest_Redux } from "../redux/docuSlice";
import {
  setOpenBookMeetingModal,
  setOpenComposeMessageModal
} from "../redux/modalSlice";
import { AppBar } from "../style-utils/sidebarNavUtils";
import UploadDocumentModal from "../modals/UploadDocumentModal";

function CustomAppbar({ open, handleDrawerOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = window.location;
  const [activeTab, setActiveTab] = useState(0);

  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const [txtType, setTxtType] = useState("Create New ");
  const Addopen = Boolean(AddanchorEl);
  const [openDocModal , setOpenDocModal] = useState(false)

  const handleCloseDocUploadModal = ()=>{
    setOpenDocModal(!openDocModal)
  }
  const { openComposeMessageModal } = useSelector((state) => state.modals);

  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };
  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  //
  const [NotificationanchorEl, setNotificationAnchorEl] = React.useState(null);
  const openNotification = Boolean(NotificationanchorEl);
  const NotificationhandleClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const NotificationhandleClose = () => {
    setNotificationAnchorEl(null);
  };

  // dropdown
  const [NewRequestanchorEl, NewRequestsetAnchorEl] = React.useState(null);
  const NewRequestopen = Boolean(NewRequestanchorEl);

  const NewRequesthandleClick = (event) => {
    NewRequestsetAnchorEl(event.currentTarget);
  };

  const NewRequesthandleClose = (selectedText) => {
    NewRequestsetAnchorEl(null);
    setTxtType(selectedText || "Select Option"); // Fallback value
    dispatch(setNewRequest_Redux(false));
    dispatch(setNewMessage_Redux(true));
  };

  const HandleMenuClose = () => {
    NewRequestsetAnchorEl(null);
    // dispatch(setNewRequest_Redux(true));
    // dispatch(setNewMessage_Redux(true));
  };

  const CreateNewRequest = (selectedText) => {
    NewRequestsetAnchorEl(null);
    setTxtType(selectedText || "Select Option");
    dispatch(setNewRequest_Redux(true));
    dispatch(setNewMessage_Redux(false));
  };

  const getPageTitle = () => {
    switch (true) {
      case pathname.includes("templates"):
        return "Template List";
      case pathname.includes("portalmessage/sent"):
        return "Portal Sent Messages ";
      case pathname.includes("share-box"):
        return "Share Box";
      case pathname.includes("contacts/contact-details"):
        return "Contact Detail";
      case pathname.includes("contact/contacts"):
        return "Contact List";
      case pathname.includes("contact/companies"):
        return "Company List";
      case pathname.includes("user/client-details"):
        return "Company Details";
      case pathname.includes("incoming-message-viewer"):
        return "Message Detail";
      case pathname.includes("all-requests"):
        return "All Request";
      default:
        return "Portal Messages";
    }
  };

  return (
    <>
      <AppBar position="fixed" className="header" open={open} color="inherit">
        <Toolbar className="header-top">
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton> */}

          {pathname !== "/user/portal-messages" &&
            pathname !== "/client/portalmessage/inboxes" && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className="border me-4 btn-primary"
                size="small"
                onClick={() => window.location.search.includes('client/onboarding') ? navigate('client/portalmessage/inboxes') : navigate(-1)}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}

          <h2 className="title mb-0 nowrap d-none d-sm-block font-16">
            {getPageTitle()}
          </h2>

          <Box className="w-100">
            <Box className="d-flex align-items-center justify-content-end w-100">
              {window.location.pathname &&
                window.location.pathname.split("/")[1] === "client" && (
                  <>
                   <Tooltip title="Upload Document" arrow>
                      <Button
                        id="basic-button"
                        aria-controls={
                          NewRequestopen ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={NewRequestopen ? "true" : undefined}
                        className="btn-blue me-2"
                        startIcon={<UploadFileOutlinedIcon />}
                        onClick={() => {
                          setOpenDocModal(!openDocModal)
                        }}
                      >
                        <span className="d-md-block d-none">Upload Document</span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Book Meeting" arrow>
                      <Button
                        id="basic-button"
                        aria-controls={
                          NewRequestopen ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={NewRequestopen ? "true" : undefined}
                        className="btn-blue me-2"
                        startIcon={<GroupsIcon />}
                        onClick={() => {
                          dispatch(setOpenBookMeetingModal(true));
                        }}
                      >
                        <span className="d-md-block d-none">Book Meeting</span>
                      </Button>
                    </Tooltip>

                    <Tooltip title={txtType ? txtType : "New Message"} arrow>
                      <Button
                        variant="contained"
                        className="btn-blue me-2"
                        onClick={() =>
                          dispatch(
                            setOpenComposeMessageModal(!openComposeMessageModal)
                          )
                        }
                        startIcon={<MarkUnreadChatAltIcon />}
                      >
                        <span className="d-md-block d-none">
                          {txtType ? txtType : "New Message"}
                        </span>
                      </Button>
                    </Tooltip>
                  </>
                )}

              {window.location.pathname &&
                window.location.pathname.split("/")[1] === "user" && (
                  <Box>
                    {/* <Button
                      id="basic-button"
                      aria-controls={NewRequestopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={NewRequestopen ? "true" : undefined}
                      className="btn-blue me-2"
                      endIcon={<GroupsIcon />}
                      onClick={() => dispatch(setOpenIntegrationModal(true))}
                    >
                      <span className="d-none d-md-block">Integration</span>
                    </Button> */}

                    <Button
                      id="basic-button"
                      aria-controls={NewRequestopen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={NewRequestopen ? "true" : undefined}
                      onClick={NewRequesthandleClick}
                      className="btn-blue"
                      endIcon={<KeyboardArrowDownIcon />}
                      startIcon={<CreateIcon />}
                      sx={{ height: "30px" }}
                    >
                      <span className="d-md-block font-12">{txtType}</span>
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={NewRequestanchorEl}
                      open={NewRequestopen}
                      onClose={HandleMenuClose}
                      MenuListProps={{ "aria-labelledby": "basic-button" }}
                      className="custom-dropdown"
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/user/create-new-request");
                          CreateNewRequest("Document Request");
                        }}
                      >
                        <ListItemIcon>
                          <MarkEmailReadIcon fontSize="small" />
                        </ListItemIcon>
                        Document Request
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          navigate("/user/create-new-request");
                          NewRequesthandleClose("Portal Message");
                        }}
                      >
                        <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        Portal Message
                      </MenuItem>
                    </Menu>
                  </Box>
                )}

              <Box className="ms-0">
                <Tooltip title="Notifications" arrow>
                  <IconButton
                    id="basic-button"
                    aria-controls={openNotification ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNotification ? "true" : undefined}
                    onClick={NotificationhandleClick}
                  >
                    <NotificationsIcon />
                    <span className="notificaiton-icon"></span>
                  </IconButton>
                </Tooltip>
                {/* <Menu
                  id="basic-menu"
                  anchorEl={NotificationanchorEl}
                  open={openNotification}
                  onClose={NotificationhandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                  PaperProps={{ sx: { width: "280px" } }}
                >
                  {Array.from({ length: 5 }, (_, i) => (
                    <div key={i}>
                      <MenuItem className="py-2">
                        <ListItemIcon className="relative">
                          <InsertDriveFileIcon />
                        </ListItemIcon>
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-13 text-wrap mb-2"
                          >
                            This is a subject line of message
                          </Typography>
                          <Typography variant="body1" className="font-10">
                            01/06/2024
                          </Typography>
                        </Box>
                      </MenuItem>
                    </div>
                  ))}
                </Menu> */}
                <Menu
                  id="basic-menu"
                  anchorEl={NotificationanchorEl}
                  open={openNotification}
                  onClose={NotificationhandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                  PaperProps={{ sx: { width: "300px" } }}
                >
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    // indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    className="pt-0 border-bottom"
                  >
                    <Tab className="bold" label="New" />
                    <Tab label="Unarchived" />
                    <Tab label="Viewed" />
                  </Tabs>

                  {activeTab === 0 && (
                    <Box>
                      <MenuItem className="p-2 border-bottom">
                        <Box>
                          <ListItemIcon>
                            <InsertDriveFileIcon className="font-20" />
                          </ListItemIcon>
                        </Box>
                        <Box>
                          <Box
                            className="d-flex align-items-center"
                            sx={{ height: "15px" }}
                          >
                            <p variant="body1" className=" text-wrap">
                              This is a subject line of message
                            </p>
                            <Tooltip tilte="Archive" arrow>
                              <IconButton>
                                <ArchiveIcon className="font-22" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <span className="font-10" sx={{ color: "#808080" }}>
                            01/06/2024
                          </span>
                        </Box>
                      </MenuItem>

                      <MenuItem className="p-2 border-bottom">
                        <Box>
                          <ListItemIcon>
                            <InsertDriveFileIcon className="font-20" />
                          </ListItemIcon>
                        </Box>
                        <Box>
                          <Box
                            className="d-flex align-items-center"
                            sx={{ height: "15px" }}
                          >
                            <p variant="body1" className=" text-wrap">
                              This is a subject line of message
                            </p>
                            <Tooltip tilte="Archive" arrow>
                              <IconButton>
                                <ArchiveIcon className="font-22" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <span className="font-10" sx={{ color: "#808080" }}>
                            01/06/2024
                          </span>
                        </Box>
                      </MenuItem>
                    </Box>
                  )}

                  {activeTab === 1 && (
                    <Box>
                      <MenuItem className="p-2 border-bottom">
                        <Box>
                          <ListItemIcon>
                            <InsertDriveFileIcon className="font-20" />
                          </ListItemIcon>
                        </Box>
                        <Box>
                          <Box
                            className="d-flex align-items-center"
                            sx={{ height: "15px" }}
                          >
                            <p variant="body1" className=" text-wrap">
                              This is a subject line of message
                            </p>
                            <Tooltip tilte="UnArchive" arrow>
                              <IconButton>
                                <UnarchiveIcon className="font-22" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <span className="font-10" sx={{ color: "#808080" }}>
                            01/06/2024
                          </span>
                        </Box>
                      </MenuItem>

                      <MenuItem className="p-2 border-bottom">
                        <Box>
                          <ListItemIcon>
                            <InsertDriveFileIcon className="font-20" />
                          </ListItemIcon>
                        </Box>
                        <Box>
                          <Box
                            className="d-flex align-items-center"
                            sx={{ height: "15px" }}
                          >
                            <p variant="body1" className=" text-wrap">
                              This is a subject line of message
                            </p>
                            <Tooltip tilte="UnArchive" arrow>
                              <IconButton>
                                <UnarchiveIcon className="font-22" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <span className="font-10" sx={{ color: "#808080" }}>
                            01/06/2024
                          </span>
                        </Box>
                      </MenuItem>
                    </Box>
                  )}

                  {activeTab === 2 && (
                    <Box>
                      <MenuItem className="p-2  border-bottom">
                        <Box>
                          <ListItemIcon>
                            <InsertDriveFileIcon className="font-20" />
                          </ListItemIcon>
                        </Box>
                        <Box>
                          <Box
                            className="d-flex align-items-center"
                            sx={{ height: "15px" }}
                          >
                            <p variant="body1" className=" text-wrap">
                              This is a subject line of message
                            </p>
                            <Tooltip tilte="Archive" arrow>
                              <IconButton>
                                <ArchiveIcon className="font-22" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <span className="font-10" sx={{ color: "#808080" }}>
                            01/06/2024
                          </span>
                        </Box>
                      </MenuItem>

                      <MenuItem className="p-2 border-bottom">
                        <Box>
                          <ListItemIcon>
                            <InsertDriveFileIcon className="font-20" />
                          </ListItemIcon>
                        </Box>
                        <Box>
                          <Box
                            className="d-flex align-items-center"
                            sx={{ height: "15px" }}
                          >
                            <p variant="body1" className=" text-wrap">
                              This is a subject line of message
                            </p>
                            <Tooltip tilte="Archive" arrow>
                              <IconButton>
                                <ArchiveIcon className="font-22" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <span className="font-10" sx={{ color: "#808080" }}>
                            01/06/2024
                          </span>
                        </Box>
                      </MenuItem>
                    </Box>
                  )}
                </Menu>
              </Box>

              <Box>
                <Avatar
                  alt="Remy Sharp"
                  src={user}
                  sx={{ width: 25, height: 25, marginLeft: "0px" }}
                  onClick={AddhandleClick}
                  className="pointer"
                />
                <Menu
                  PaperProps={{ sx: { width: "200px" } }}
                  id="basic-menu"
                  anchorEl={AddanchorEl}
                  open={Addopen}
                  onClose={AddhandleClose}
                  className="custom-dropdown"
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {window.location.pathname &&
                    window.location.pathname.split("/")[1] === "client" && (
                      <MenuItem
                        onClick={() => {
                          navigate("/client/client-Profile");
                          AddhandleClose();
                        }}
                      >
                        <Avatar
                          sx={{ width: 23, height: 23 }}
                          className="me-3"
                        />{" "}
                        Profile
                      </MenuItem>
                    )}

                  {window.location.pathname &&
                    window.location.pathname.split("/")[1] === "user" && (
                      <MenuItem
                        onClick={() => {
                          navigate("/user/user-setting");
                          AddhandleClose();
                        }}
                      >
                        <ListItemIcon>
                          <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        Setting{" "}
                      </MenuItem>
                    )} 
                  <MenuItem
                    onClick={() => {
                      localStorage.clear();
                      AddhandleClose();
                      if (window.location.pathname.includes("/client")) {
                        navigate("/client/ClientLogin");
                      } else if (window.location.pathname.includes("/user")) {
                        navigate("/user/Login");
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <UploadDocumentModal open={openDocModal} handleClose={handleCloseDocUploadModal}/>
    </>
  );
}

export default memo(CustomAppbar);
