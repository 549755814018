import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import {
  fetchdocuRequestData,
  GetDocumentRequests_Json_Redux,
} from "../../redux/api_helper";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, TextField } from "@mui/material";
import { NavLink, useSearchParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";
import SearchIcon from "@mui/icons-material/Search";

const pageSizes = [10, 25, 50, 100];
export default function NewRequest() {
  let objReq = useLocalStorage("accid");
  const [loading, setLoading] = React.useState(true);
  //Remove Object and add new with replace name
  let newObject = { ...objReq, accId: objReq.accid };
  delete newObject.accid;
  let dispatch = useDispatch();
  const [getAllRequests, setAllRequests] = React.useState([]);
  const [searchParams] = useSearchParams();
  const emailForFilter = searchParams.get("email");
  let { docuRequestDataClient: portalAllRequest } = useSelector(
    (state) => state.docu
  );
  const [recentRequest, setResentRequest] = useState([]);
  const [searchString, setSearchString] = useState("");

  // React.useEffect(() => {
  //   setLoading(true);
  //   dispatch(
  //     GetDocumentRequests_Json_Redux(newObject, function (res) {
  //       setLoading(false);
  //       if (emailForFilter) {
  //         let filteredData = res.filter(
  //           (itm) => itm.EmailId === emailForFilter
  //         );
  //         setAllRequests(filteredData);
  //       } else {
  //         setAllRequests(res);
  //       }
  //     })
  //   );
  // }, [dispatch, emailForFilter]);

  useEffect(() => {
    dispatch(fetchdocuRequestData());
  }, [dispatch]);

  useEffect(() => {
    if (searchString.length > 2) {
      setLoading(true);

      let fltData = portalAllRequest.filter((d) =>
        d.TemplateName.trim()
          .toLowerCase()
          .includes(searchString.trim().toLowerCase())
      );
      setResentRequest(fltData);
      setLoading(false);
    } else {
      setResentRequest(portalAllRequest);
      setLoading(false);
    }
  }, [portalAllRequest, searchString]);
  // e2fcf214f3974576880ce250035b0329

  return (
    <Box className="mt-2">
      <Box className="d-flex flex-wrap align-items-center justify-content-between mb-0">
        {/* <h2 className="title">New Request</h2> */}
        {/* <Box
          className="search-input"
          sx={{
            width: 340,
            maxWidth: "100%",
          }}
        >
          <SearchIcon />

          <TextField
            fullWidth
            label="Search"
            size="small"
            onChange={(e) => setSearchString(e.target.value)}
          />
        </Box> */}
      </Box>
      <Box className="white-box">
        {loading ? (
          <CustomLoader />
        ) : recentRequest.length > 0 ? (
          <DataGrid
            dataSource={recentRequest}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            className="table-grid table-grid2 table-height  "
          >
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Column
              dataField="Individual"
              caption="#"
              dataType="string"
              cellRender={(data) => {
                const pageIndex = data.component.pageIndex();
                return <span>{pageIndex * 10 + data.rowIndex + 1}</span>;
              }}
            />

            <Column
              dataField="TemplateName"
              caption="Template Name"
              dataType="string"
            />

            <Column
              dataField="DocName"
              caption="Document Name"
              dataType="string"
            />

            <Column
              dataField="DocTempId"
              caption="Action"
              cellRender={(data) => {
                return (
                  <NavLink
                    // to={`/user/all-invites?id=${data.data.DocTempId}&request=${data.data.Request}&EmailId=${data.data.EmailId}`}
                    to={`/client/request-detail?id=${data.data.DocTempId}&request=${data.data.Request}&EmailId=${data.data.EmailId}&PortalDocId=${data.data.PortalDocId}`}
                  >
                    <Button className="btn-blue" size="small" startIcon={<VisibilityIcon />}>
                      View Detail
                    </Button>
                  </NavLink>
                );
              }}
            />
            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
            <Paging defaultPageSize={20} />
          </DataGrid>
        ) : (
          <NoData messsage="No Data here" />
        )}
      </Box>
    </Box>
  );
}
