import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CamposeMessage from "../client/pages/CamposeMessage";
import ApproveDateModal from "./ApproveDateModal";
import BookMeeting from "./BookMeeting";
import CalendryMeetingModal from "./CalendlyMeetingModal";
import CompanyModal from "./CompanyModal";
import CompanyOpenNewModal from "./CompanyOpenNewModal";
import ContactDetailsModal from "./ContactDetailsModal";
import ContactModal from "./ContactModal";
import CreateContactModal from "./CreateContactModal";
import CreateNewInviteModal from "./CreateNewInviteModal";
import DocumentPreviewModal from "./DocumentPreviewModal";
import DraftButtonModal from "./DraftButtonModal";
import EditContactModal from "./EditContactModal";
import IntegrationModal from "./IntegrationModal";
import PendingMesssageModal from "./PendingMesssageModal";
import SignDocument from "./SignDocument";
import UploadFileModal from "./UploadFileModal";
import WhatsAppValidModal from "./WhatsAppValidModal";
import ClientContactModal from "./ClientContactModal";
import RtkUpdateContactModal from "./RtkUpdateContactModal";

function AllModals() {
  const { openCreateContactModal, openInviteModal, openContactModal, openContactModalForClient } =
    useSelector((state) => state.docu);
  const {
    openContactDetailModal,
    openDocumentPreviewModal,
    openUploadDocument,
    openApproveModal,
    openSignDocument,
    openComposeMessageModal,
    openCompanyModal,
    openBookMeetingModal,
    openWhatsAppValidModal,
    getWhatappNumber,
    getAllUserFromBookingData,
    getCalendryMeetingModal,
    openIntegrationModal,
  } = useSelector((state) => state.modals);
  const {
    editCompanyModal,
    editContactModal,
    draftFormData,
    OpenPendingMessageModal,
    RtkEditContactModalOpen
  } = useSelector(({ aditya }) => aditya);

  const location = useLocation();

  return (
    <>
      <CreateContactModal open={openCreateContactModal} />
      <CreateNewInviteModal open={openInviteModal} />
      <ContactModal open={openContactModal} />
      <ContactDetailsModal open={openContactDetailModal} />
      <DocumentPreviewModal open={openDocumentPreviewModal} />
      <UploadFileModal open={openUploadDocument} />
      <ApproveDateModal open={openApproveModal} location={location} />
      <SignDocument open={openSignDocument} location={location} />
      <CompanyModal open={openCompanyModal} />
      <BookMeeting open={openBookMeetingModal} />
      <WhatsAppValidModal open={openWhatsAppValidModal} />
      <CalendryMeetingModal open={getCalendryMeetingModal} />
      <IntegrationModal open={openIntegrationModal} />
      <CompanyOpenNewModal open={editCompanyModal} />
      <EditContactModal open={editContactModal} />
      {RtkEditContactModalOpen && <RtkUpdateContactModal open={RtkEditContactModalOpen}/>}
      {openContactModalForClient && (<ClientContactModal open={openContactModalForClient} />)}
      {OpenPendingMessageModal && (
        <PendingMesssageModal open={editContactModal} />
      )}
      {openComposeMessageModal && (
        <Box className="compose-message ">
          <CamposeMessage />
        </Box>
      )}
      {Object.keys(draftFormData).length > 0 && <DraftButtonModal />}
    </>
  );
}

export default AllModals;
