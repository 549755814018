import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect } from 'react';

function UdfCard({ data }) {
    useEffect(() => {
        data?.Table3.map(itm => {
            if (itm.TextControlValue === "Date" && itm.UdfValue) {
                const months = {
                    Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
                    Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
                  };
                const [month, day, year] = itm.UdfValue.trim().split(/\s+/);
                itm.UdfValue = `${day}/${months[month]}/${year}`;
            }
        });
    }, []);
    return (
        <>
        
        {
            Object.keys(data).length > 0 &&
            <Grid container spacing={2}> 
                {data?.Table.map((item, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={4}> 
                        <Accordion className='accordian-box' defaultExpanded={i === 0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${i}-content`}
                                id={`panel${i}-header`}
                                className='lato-semibold'
                            >
                                {item.TagName}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className='table-responsive'>
                                    <table className='table'>
                                        <tbody>
                                            {data?.Table3.map((tableData, idx) => (
                                                item.TagId === tableData.Tag && (
                                                    <tr key={idx}>
                                                        <th>{tableData.Name}</th>
                                                        <td>{tableData.UdfValue === "undefined" ? "" : tableData.UdfValue}</td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
        }
        </>
        
    )
}

export default UdfCard
