import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../components/AutoComplete";
import { AllCountriesList } from "../custom-hook/AllCountriesList";
import { Rtk_GetAllContactPubUser, RtkEditContactModalReducer } from "../redux/adityaReducer";
import { useEffect } from "react";
import { portalClient } from "../redux/AxiosInstance";
import { toast } from "react-toastify";
import axios from "axios";

const RtkUpdateContactModal = ({open}) => {
    const {selectedContactRtk} = useSelector((state)=>state.aditya)
    const dispatch = useDispatch()
  const [client, setClient] = useState(selectedContactRtk || {
    agrno: localStorage.getItem("accIdClient") || "",
    Email: localStorage.getItem("EmailClient") || "",
    password: localStorage.getItem("passwordClient") || "",
    accid: localStorage.getItem("accIdClient") || "",
    Salutation: selectedContactRtk ?.Salutation,
    FirstName: selectedContactRtk ?.FirstName,
    LastName: selectedContactRtk ?.LastName,
    Add1:selectedContactRtk ?.Add1,
    Add2: selectedContactRtk ?.Add2,
    Add3: selectedContactRtk ?.Add3,
    Town: selectedContactRtk ?.Town,
    PostCode: selectedContactRtk ?.PostCode,
    Country: selectedContactRtk ?.Country,
    ManagerName: selectedContactRtk ?.ManagerName,
    Role: selectedContactRtk ?.Role,
    Tel: selectedContactRtk ?.Tel,
    Mobile:selectedContactRtk ?.Mobile,
    CCode: selectedContactRtk ? selectedContactRtk["CCode"] : "",
    maincontact: selectedContactRtk ?.maincontact, 
    greeting: selectedContactRtk ?.greeting,
    contactEmail: selectedContactRtk ?.EMailId,
    AssignedManager: selectedContactRtk ?.AssignedManager, 
    CActive: selectedContactRtk ?.CActive, 
    note: selectedContactRtk ?.note,
  });
  useEffect(() => {
    if (selectedContactRtk) {
      setClient({
        agrno: localStorage.getItem("accIdClient") || "",
        Email: localStorage.getItem("EmailClient") || "",
        password: localStorage.getItem("passwordClient") || "",
        accid: localStorage.getItem("accIdClient") || "",
        Salutation: selectedContactRtk.Salutation || "",
        FirstName: selectedContactRtk.FirstName || "",
        LastName: selectedContactRtk.LastName || "",
        Add1: selectedContactRtk.Add1 || "",
        Add2: selectedContactRtk.Add2 || "",
        Add3: selectedContactRtk.Add3 || "",
        Town: selectedContactRtk.Town || "",
        PostCode: selectedContactRtk.PostCode || "",
        Country: selectedContactRtk.Country || "",
        ManagerName: selectedContactRtk.ManagerName || "",
        Role: selectedContactRtk.Role || "",
        Tel: selectedContactRtk.Tel || "",
        Mobile: selectedContactRtk.Mobile || "",
        CCode: selectedContactRtk["CCode"] || "",
        maincontact: selectedContactRtk.maincontact || "", 
        greeting: selectedContactRtk.greeting || "",
        contactEmail: selectedContactRtk.EMailId || "",
        AssignedManager: selectedContactRtk.AssignedManager || "", 
        CActive: selectedContactRtk.CActive || "", 
        note: selectedContactRtk.note || "",
      });
    }
  }, [selectedContactRtk]);

  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };
 const handleClose = ()=>{
  dispatch(RtkEditContactModalReducer(false))
 }

const handleSubmit = async () => {
  const payload = {
    accId: client.accid,
    username: client.Email,
    sPassword: client.password,
    Add1: client.Add1,
    Add2: client.Add2,
    Add3: client.Add3,
    Town: client.Town,
    State: "", // Add logic if State is available
    PostCode: client.PostCode,
    Country: client.Country,
    ManagerName: client.ManagerName,
  };

  console.log(payload, "Payload for update API");

  try {
    const response = await axios.post("https://portal.docuengager.com/PortalUserServices.asmx/UpdateContactDetails_Json", payload);
    console.log(response, "Response of update");
    // if(response) dispatch(Rtk_GetAllContactPubUser())
    handleClose();
    toast.success("Contact updated successfully");
  } catch (e) {
    console.log(e, "Error in updateContact");
    toast.error("Failed to update contact");
  }
};


  const titleData = [
    { label: "Mr", year: 1994 },
    { label: "Mrs", year: 1972 },
    { label: "Miss", year: 1974 },
    { label: "Ms", year: 2008 },
    { label: "Dr", year: 1957 },
    { label: "Hr", year: 1957 },
    { label: "N/A", year: 1957 },
  ];

  console.log(selectedContactRtk , "selectedContactRtk");
  
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Update Client</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Title */}
          <Grid item xs={12} sm={2}>
            <Box className="custom-autocomplete">
                       <AutoComplete
                         ComData={titleData}
                         target="label"
                         defaultValue={client.Salutation}
                         comFun={(e, newValue) => {
                           newValue && setClient({ ...client, Salutation: newValue.label });
                         }}
                         lable="Title"
                       />
             </Box>
          </Grid>

          {/* First Name */}
          <Grid item xs={12} sm={5}>
            <TextField
              label="First Name *"
              name="FirstName"
              value={client.FirstName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={5}>
            <TextField
              label="Last Name *"
              name="LastName"
              value={client.LastName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Company */}
          <Grid item xs={12}>
            <TextField
              label="Company"
              name="CCode"
              value={client.CCode}
              onChange={handleChange}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Email & Mobile Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email *"
              name="contactEmail"
              value={client?.contactEmail}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile Number *"
              name="Mobile"
              value={client.Mobile}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Telephone */}
          <Grid item xs={12}>
            <TextField
              label="Telephone"
              name="Tel"
              value={client.Tel}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* Address Accordion */}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Address
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Address Line 1"
                      name="Add1"
                      value={client.Add1}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Address Line 2"
                      name="Add2"
                      value={client.Add2}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Address Line 3"
                      name="Add3"
                      value={client.Add3}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="City"
                      name="Town"
                      value={client.Town}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <Box
                      display="flex custom-autocomplete"
                      alignItems="center"
                      mb={2}
                    >
                      <AutoComplete
                        ComData={AllCountriesList}
                        defaultValue={client.Country}
                        target="label"
                        comFun={(e, newValue) => {
                          newValue &&
                            setClient({
                              ...client,
                              Country: newValue.label,
                            });
                        }}
                        lable="Country"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Zip Code"
                      name="PostCode"
                      value={client.PostCode}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Buttons */}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="error" onClick={handleClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Update Client
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RtkUpdateContactModal;
