import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenContactForClientModal, handleOpenContactModal } from "../redux/docuSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateContect from "../pages/CreateContect";
import CreateCompany from "../pages/CreateCompany";
import {
  AddContect_ReduxPubUser,
  Json_UpdateContact_Redux1,
  portal_Redux,
} from "../redux/ak_api_helper";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import { toast } from "react-toastify";
import { Title } from "@mui/icons-material";
import { getClientmanagerobj_Redux } from "../redux/akSlice";
import CreateIcon from "@mui/icons-material/Create";
import ClientCreateContect from "../client/pages/ClientCreateContect";
import { ad_GetAllContactsByCCode_Json } from "../redux/dev_aditya_action";

export default function ClientContactModal({ open }) {
  const dispatch = useDispatch();
  const { allContact } = useSelector((state) => state.ak);
  const { getContactEmailID } = useSelector((state) => state.ak);
  const { bussiness, sources, mangers, companyStatus } = useSelector(
    (state) => state.ak
  );
  const { ad_ClientId } = useSelector(({ aditya }) => aditya);
  const { roles } = useSelector((state) => state.ak);
  const reqObj = useLocalStorage("required");
  const selectedoriginator = JSON.parse(localStorage.getItem('selectedoriginator') )
  console.log(selectedoriginator , "selectedoriginatorrtk");
  
  const [formData, setFormData] = React.useState({
    agrno: localStorage.getItem("accIdClient") || "",
    Email: localStorage.getItem("EmailClient") || "",
    password: localStorage.getItem("passwordClient") || "",
    accid: localStorage.getItem("accIdClient") || "",
    Salutation: "",
    FirstName: "",
    LastName: "",
    Add1: "",
    Add2: "",
    Add3: "",
    Town: "",
    PostCode: "",
    Country: "",
    ManagerName: "",
    Role: "",
    Tel: "",
    Mobile: "",
    CCode: selectedoriginator ? selectedoriginator["OriginatorNo"] : "",
    maincontact: 'False', // Pre-filled as "No"
    greeting: "",
    contactEmail: "",
    AssignedManager: 0, // Default value
    CActive: "Yes", // Pre-filled as "Yes"
    note: "",
  });
  
  const [getEmailContact, setgetEmailContact] = React.useState({});
  const isEmpty = Object.keys(getEmailContact).length === 0;
  const [manager, setManager] = useState(null);
  const [rolestest, setrolestest] = useState(null);
  const [contactStatus, setcontactStatus] = useState(null);
  useEffect(() => {
    const foundEmailContact = allContact.Table?.find(
      (EmailContact) => EmailContact["E-Mail"] == getContactEmailID
    );
    if (foundEmailContact) {
      setgetEmailContact(foundEmailContact || {});

      const foundManager = mangers.find(
        (manager) =>
          manager.UserId ==
          (foundEmailContact && foundEmailContact["Assigned Manager"]
            ? foundEmailContact["Assigned Manager"]
            : " ")
      );
      setManager(foundManager || { UserName: "" });

      const foundsetrolestest = roles.find(
        (setrolestest) =>
          setrolestest.RoleName ==
          (foundEmailContact && foundEmailContact.Role
            ? foundEmailContact.Role
            : " ")
      );
      setcontactStatus(foundsetrolestest || { RoleName: "" });
      const foundcontactStatus = companyStatus.find(
        (contactStatus) =>
          contactStatus.StatusName ==
          (foundEmailContact && foundEmailContact.StatusName
            ? foundEmailContact.StatusName
            : " ")
      );
      setcontactStatus(foundcontactStatus || { StatusName: "" });

      try {
        if (getEmailContact) {
          // setFormData({
          //   Add1: foundEmailContact["Address 1"] || "",
          //   Add2: foundEmailContact["Address 2"] || "",
          //   Add3: foundEmailContact["Address 3"] || "",
          //   AssignedManager: foundEmailContact["Assigned Manager"] || 1,
          //   CActive: foundEmailContact.Active || "Yes",
          //   CCode: selectedoriginator ? selectedoriginator['OriginatorNo'] : '',
          //   Country: foundEmailContact.Country || "",
          //   Email: reqObj.Email,
          //   FirstName: foundEmailContact["First Name"] || "",
          //   LastName: foundEmailContact["Last Name"] || "",
          //   ManagerName: "",
          //   Mobile: foundEmailContact.Mobile || "",
          //   PostCode: foundEmailContact.Postcode || "",
          //   // email: foundEmailContact["E-Mail"] || "",
          //   Role: foundEmailContact.Role || "",
          //   Salutation: contactStatus.StatusName || "",
          //   Tel: foundEmailContact.Tel || "",
          //   Town: foundEmailContact.Town || "",
          //   accid: foundEmailContact.OriginatorNo || reqObj.agrno,
          //   agrno: foundEmailContact.OriginatorNo || reqObj.agrno,
          //   maincontact:  false,
          //   note: foundEmailContact.Note || "",
          //   password: reqObj.password,
          //   // Company : selectedoriginator ?  selectedoriginator['Company Name'] : '',
          //   Title: foundEmailContact.Title || "",
          //   contactEmail : foundEmailContact["E-Mail"] || ""
          // });
        }
      } catch (err) {
        console.log("trycatch", err);
      }
    } else {
      console.log("No contact found with the given email ID.");
    }
  }, [allContact, getContactEmailID]);
  const payloadForgetContacts = {
    accId : formData?.accid,
    username : formData?.Email,
    sPassword : formData?.password,
    CCode : selectedoriginator?.OriginatorNo
  }
  useEffect(()=>{
    dispatch(ad_GetAllContactsByCCode_Json(payloadForgetContacts))
  },[])

  const [openNewModal, setOpenNewModal] = React.useState(false);
  const handleClose = () => {
    dispatch(handleOpenContactForClientModal(false));
  };

  const obj1 = {
    accid: reqObj.agrno,
    email: reqObj.Email,
    password: reqObj.password,
    PresetMemorableData: true,
    IssueReminders: true,
    ExcludeMessageLink: true,
    KeepSignedIn: false,
    AllowUpload: false,
    ChangeProfile: true,
    LoggedIn: false,
    Blocked: false,
    emailAddress: formData.email,
    // ccode: clientIddata ? clientIddata : "",
    ccode: formData.CCode ? formData.CCode : "",
    clientName: formData.Company,
    // clientName: clientNames ? clientNames : "",
  };

  // let contactData = {
  //   agrno: reqObj.agrno,
  //   Email: reqObj.Email,
  //   password: reqObj.password,
  //   FirstName: formData.FirstName ? formData.FirstName : "",
  //   LastName: formData.LastName ? formData.LastName : "",
  //   Add1: formData.Add1 ? formData.Add1 : "",
  //   Add2: formData.Add2 ? formData.Add2 : "",
  //   Add3: formData.Add3 ? formData.Add3 : "",
  //   Town: formData.Town ? formData.Town : "",
  //   PostCode: formData.PostCode ? formData.PostCode : "",
  //   Country: formData.Country ? formData.Country : "United Kingdom",
  //   ManagerName: formData.FirstName + " " + formData.LastName,
  //   Role: formData.Role ? formData.Role : "",
  //   Tel: formData.Tel ? formData.Tel : "",
  //   Mobile: formData.Mobile ? formData.Mobile : "",
  //   greeting: formData.greeting ? formData.greeting : "",
  //   Contactemail: formData.email ? formData.email : "",
  //   note: formData.note ? formData.note : "",
  //   emailupdate: formData.email ? formData.email : "",
  //   CActive: formData.CActive === false ? "Yes" : "Yes",
  //   AssignedManager: formData.AssignedManager ? formData.AssignedManager : -1,
  //   maincontact: formData.maincontact ? formData.maincontact : false,
  //   CCode: getEmailContact.OriginatorNo ? getEmailContact.OriginatorNo : "-1",
  //   Salutation: formData.Title ? formData.Title : "",
  //   accid: reqObj.agrno,
  // };
  // const handleUpdateContact = () => {
  //   dispatch(Json_UpdateContact_Redux1(contactData));
  // };

  const handleCreateContact = () => {
    
    const payloadForgetContacts = {
      accId : formData?.accid,
      username : formData?.Email,
      sPassword : formData?.password,
      CCode : selectedoriginator?.OriginatorNo
    }
    // Check if mandatory fields are filled
    if (
      !formData.FirstName ||
      !formData.LastName ||
      !formData.Email 
     
    ) {
      toast.error("Please fill in all required fields ");
      return;
    }

    // Proceed to create contact if validation passes
    dispatch(AddContect_ReduxPubUser(formData))
      .then(() => {
        toast.success("Contact added successfully");
        dispatch(ad_GetAllContactsByCCode_Json(payloadForgetContacts))
        // setTimeout(() => {
        //   dispatch(portal_Redux(obj1)).then(() => {
        //     //  toast.success("Portal Create successfully"); //when uncomments this line when proper response comes from server .
        //   });
        // }, 3000);
        handleClose();
      })
      .catch((error) => {
        console.error("Failed to add contact:", error);
        toast.error("Failed to add contact");
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{ad_ClientId ? "Add Contact" : "Create Contact"}</DialogTitle>
          </Box>
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ClientCreateContect
              formData={formData}
              setformData={setFormData}
              manager={manager}
              setManager={setManager}
              rolestest={rolestest}
              ad_ClientId={ad_ClientId}
            />
          </DialogContentText>

          <DialogActions className="pt-4 px-0">
            <Button
              onClick={handleClose}
              startIcon={<CloseIcon />}
              className="btn-red"
              variant="outlined"
            >
              Cancel
            </Button>
            {isEmpty ? (
              <Button
                onClick={handleCreateContact}
                autoFocus
                startIcon={<CreateIcon />}
                variant="outlined"
                className="btn-blue"
              >
                {ad_ClientId ? "Add Contact" : "Create Contact"}
              </Button>
            ) : (
              <Button
                // onClick={handleUpdateContact}
                autoFocus
                variant="outlined"
                className="btn-blue"
              >
                Update Contact
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
