import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Box, FormControl } from "@mui/material";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector, useDispatch } from "react-redux";

import dayjs from "dayjs";
import { ad_Json_GetConfiguration_Action } from "../../../redux/dev_aditya_action";
import { AllCountriesList } from "../../../custom-hook/AllCountriesList";

const ContactMainForm = ({
  setContact,
  contact,
  clientNames,
  userContactDetails,
  setContactDetails,
  contactlistdata,
  Importcontactdata,
  setImportcontactdata,
  contactDetails,
}) => {
  const dispatch = useDispatch();
  const setDefaultRole11 = useSelector((state) => state.counter);

  const [Dataset, setDataset] = useState("");
  const [SetDefaultDate, setSetDefaultDate] = useState("");
  const [errors, setErrors] = useState({});
  const [manger, setManger] = useState({}); // State to hold folders data
  const [managerList, setMangerList] = useState([]); // State to hold folders data

  const [setRoll, setSetRoll] = useState({});

  const [mainCountry, setMainCountry] = useState({
    code: "AD",
    label: "Andorra",
    phone: "376",
  });

  const [selectedTitle, setSelectedTitle] = useState({
    label: "Mr",
    year: 1994,
  });
  const [dateIni, setDateIni] = useState(true);
  const [dob, setDob] = useState(null);
  const [rollList, setRollList] = useState([]);

  useEffect(() => {
    dispatch(
      ad_Json_GetConfiguration_Action((res) => {
        console.log(res, "======= res");
        setRollList(res?.Table4);
        setMangerList(res?.Table3);
        setManger(res?.Table3[0]);
      })
    );
  }, []);

  useEffect(() => {

    // Set Title
    if (userContactDetails?.Title) {
      const res = titleData.find(
        (item) => item.label === userContactDetails.Title
      );
      // console.log("Title matched:", res);
      if (res) {
        setSelectedTitle(res);
      }
    }

    // Set Main Country
    if (userContactDetails?.Maincontactcountry) {
      const countryRes = AllCountriesList.find(
        (item) => item.label === userContactDetails.mainCountry
      );
      // console.log("Country matched:", countryRes);
      if (countryRes) {
        setMainCountry(countryRes);
      }
    }

    // Set DOB
    if (dateIni && userContactDetails?.BirthDate) {
      const birthDateString = userContactDetails.BirthDate;
      const birthDateTimestamp = birthDateString
        ? parseInt(birthDateString.match(/\d+/)[0], 10)
        : null;
      const birthDate = birthDateTimestamp ? dayjs(birthDateTimestamp) : null;
      setDateIni(false);
      if (birthDate && birthDate.isValid()) {
        console.log("Initial BirthDate:", birthDate);
        setDob(birthDate);
      } else {
        console.log("Invalid initial BirthDate:", birthDateString);
        setDob(null);
      }
    }
  }, [userContactDetails]);

  useEffect(() => {
    // Set Role
    if (rollList.length > 0 && userContactDetails?.RolesData) {
      const rollData = rollList.find(
        (item) => item.RoleName === userContactDetails.RolesData
      );
      // console.log("rollData matched:", rollData);
      if (rollData) {
        setSetRoll(rollData);
      }
    }

    // Set Manager
    if (managerList.length > 0 && userContactDetails?.MainUserId) {
      const ManagerData = managerList.find(
        (item) =>
          Number(item?.UserId) === Number(userContactDetails?.MainUserId)
      );
      if (ManagerData) {
        setManger(ManagerData);
      }
    }
  }, [rollList, managerList]);

  const handleInputOnDateChange = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format("YYYY/MM/DD") : null;
    const updatedDetails = { ...userContactDetails, BirthDate: formattedDate };

    console.log("New date selected:", formattedDate);
    setContactDetails(updatedDetails); // Update `userContactDetails`
    setDob(newDate); // Update dob state with the new date selected
  };

  const InputTitle = useCallback(() => {
    return (
      <Grid item xs={12} md={6}>
        <Box className="custom-autocomplete">
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={titleData}
              key="uniques-manager"
              target="label"
              getOptionLabel={(option) => option?.label}
              defaultValue={
                Object.keys(selectedTitle).length !== 0 && selectedTitle
                  ? selectedTitle
                  : ""
              }
              onChange={onChangetitle}
              clearOnEscape
              renderInput={(params) => (
                <TextField
                  {...params}
                  key="titlemain"
                  name="Title"
                  label="Title"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Box>
      </Grid>
    );
  }, [selectedTitle]);

  const onChange = (e) => {
    e.preventDefault();
    let data = { ...userContactDetails };
    let name = e.target.name;
    let val = e.target.value;
    data = { ...data, [name]: val };
    setContactDetails(data);

    if (name === "EmailName" && val.trim() !== "") {
      const emailExists = contactlistdata.some(
        (contact) => contact.EMailId == val.trim()
      );
      if (emailExists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Email already exists",
        }));
      } else if (!validateEmail(val)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email address",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error message if validation succeeds or if val is empty
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear error message if val is empty
      }));
    }
  };
  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onChangeUser = (event, value) => {
    event.preventDefault();
    if (value) {
      let data = { ...userContactDetails };
      data = {
        ...data,
        ["MainUserId"]: value.UserId,
        ["MainUserName"]: value.UserName,
      };
      console.log("dataOnchange111", value);
      setContactDetails(data);
      setManger(value);
    }
  };

  const onChangeRoles = (event, value) => {
    event.preventDefault();
    if (value) {
      let data = { ...userContactDetails };
      data = { ...data, ["RolesData"]: value.RoleName };
      console.log(data, "onChangeRoles");
      setContactDetails(data);
      setSetRoll(value);
    } else {
    }
  };

  const onChangecountry = (event, value) => {
    event.preventDefault();
    if (value) {
      let data = { ...userContactDetails };
      data = { ...data, ["mainCountry"]: value.label };
      setContactDetails(data);
      setMainCountry(value);
    }
  };

  const handleAdvancedInactive = (event) => {
    const isChecked = event.target.checked;
    const newData = {
      ...userContactDetails,
      Inactive: isChecked,
      MainContact: isChecked ? false : userContactDetails.MainContact, // Uncheck the other checkbox if Inactive is checked
    };
    setContactDetails(newData);
  };

  const handleAdvancedSettingChange = (event) => {
    const isChecked = event.target.checked;
    const newData = {
      ...userContactDetails,
      MainContact: isChecked,
      Inactive: isChecked ? false : userContactDetails.Inactive, // Uncheck the other checkbox if MainContact is checked
    };
    setContactDetails(newData);
  };

  const handleAdvancedCreatePortal = (event) => {
    let data = { ...userContactDetails };
    let name = event.target.name;
    let val = event.target.checked;
    data = { ...data, [name]: val };
    console.log(data, "dataOnchange", event);
    setContactDetails(data);
  };

  function formatDate(dateString) {
    const milliseconds = parseInt(dateString.match(/-?\d+/)[0]);
    const date = new Date(milliseconds);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  }

  useEffect(() => {
    // console.log(contact, setDefaultRole11,"contactlistsona","setdef",SetDefaultRole,userContactDetails);
    if (contact) {
      // setContactDetails(null);
      setImportcontactdata(null);
      let data2 = { ...userContactDetails };
      data2 = {
        ...data2,
        ["Title"]: contact.Salutation,
        ["FirstName"]: contact.FirstName,
        ["LastName"]: contact.LastName,
        ["ReferenceName"]: "",
        ["MainContact"]: contact.MainContact,
        ["Inactive"]: contact.CActive === "Yes" ? false : true,
        ["GreetingName"]: contact.Greeting,
        ["EmailName"]: contact.EMailId,
        ["MainUserId"]: -1,
        ["MainLine1Name"]: contact.Add1,
        ["MainLine2Name"]: contact.Add2,
        ["MainLine3Name"]: contact.Add3,
        ["MainTownName"]: contact.Town,
        ["MainPostcodeName"]: contact.PostCode,
        ["Maincontactcountry"]: "",
        ["MainTelephoneName"]: contact.Tel,
        ["MainMobileName"]: contact.Mobile,
        ["mainCountry"]: "",
        ["billingsCountry"]: "",
        ["ragistersCountry"]: "",
        ["ReferenceID"]: clientNames,
        ["BirthDate"]: Dataset,
      };
      setContactDetails(data2);
      // setContactFilled(true);
    }
    if (Importcontactdata) {
      setContact(null);
      let data = { ...userContactDetails };
      data = {
        ...data,
        ["Title"]: Importcontactdata.Salutation,
        ["FirstName"]: Importcontactdata.FirstName,
        ["LastName"]: Importcontactdata.LastName,
        ["ReferenceName"]: "",
        ["MainContact"]: Importcontactdata.MainContact,
        ["Inactive"]: Importcontactdata.CActive,
        ["GreetingName"]: Importcontactdata.Greeting,
        ["EmailName"]: Importcontactdata.EMailId,
        ["MainUserId"]: -1,
        ["MainLine1Name"]: Importcontactdata.address_line_1,
        ["MainLine2Name"]: Importcontactdata.address_line_2,
        ["MainLine3Name"]: Importcontactdata.Add3,
        ["MainTownName"]: Importcontactdata.locality,
        ["MainPostcodeName"]: Importcontactdata.postal_code,
        ["Maincontactcountry"]: "",
        ["MainTelephoneName"]: Importcontactdata.Tel,
        ["MainMobileName"]: Importcontactdata.Mobile,
        ["mainCountry"]: "",
        ["billingsCountry"]: "",
        ["ragistersCountry"]: "",
        ["ReferenceID"]: clientNames,
        ["BirthDate"]: Dataset,
      };
      setContactDetails(data);
    }
    if (contactDetails && contactDetails.length > 0) {
      const filtercontact = titleData.find(
        (obj) => obj.label == contactDetails[0].Title
      );

      //setSetDefaultTitle(filtercontact);
      // dispatch(setSetDefaultTitleSonam(filtercontact));
      if (contactDetails[0]["Date Of Birth"]) {
        // Extract timestamp from the date string
        const timestamp = parseInt(
          contactDetails[0]["Date Of Birth"].slice(6, -2)
        );

        // Create a new Date object from the timestamp
        const datetimestamp = new Date(timestamp);

        // Check if the Date object is valid
        if (!isNaN(datetimestamp)) {
          // Convert the Date object to dayjs
          const defaultDateObject = dayjs(datetimestamp);

          // Check if the dayjs object is valid
          if (defaultDateObject.isValid()) {
            // Set the default date object
            setSetDefaultDate(defaultDateObject);
          } else {
            console.log("Invalid date."); // Log an error message if the date is invalid
          }
        } else {
          console.log("Invalid timestamp."); // Log an error message if the timestamp is invalid
        }
      }
    }
    const defaultCountryLabel = AllCountriesList.find(
      (country) => country.label === "United Kingdom"
    );
  }, [contact, Importcontactdata, dispatch, AllCountriesList]);

  const onChangetitle = (event, value) => {
    setSelectedTitle(value); // Update selected title
    event.preventDefault();
    let data = { ...userContactDetails };
    data = { ...data, Title: value ? value.label : "" };
    setContactDetails(data);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <InputTitle />
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            key={`firstnamekey`}
            id="firstnameid"
            variant="outlined"
            label="First Name"
            name="FirstName"
            value={userContactDetails.FirstName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            key={`lastnamekey`}
            id="lastnameid"
            label="Last Name"
            variant="outlined"
            name="LastName"
            value={userContactDetails.LastName}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="custom-autocomplete">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                // options={titleData}
                key={`uniques-roles`}
                options={rollList}
                getOptionLabel={(option) => option.RoleName}
                value={Object.keys(setRoll).length > 0 ? setRoll : null}
                // defaultValue={Object.keys(setRoll).length >0  ?setRoll:""}
                onChange={onChangeRoles}
                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={`titlemain`}
                    name="Role"
                    size="small"
                    // onChange={onChange}
                    label="Role"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "DatePicker",
                "TimePicker",
                "DateTimePicker",
                "DateRangePicker",
              ]}
            >
              <DatePicker
                // className="custom-date-2"
                dateFormat="MM/DD/YYYY"
                value={dob || null} // Ensure dob is either a Dayjs object or null
                onChange={(e) => handleInputOnDateChange(e)}
                label="Birth date"
                size="small"
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "12px", // Adjust font size here
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "12px", // Adjust label font size
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-wrap align-items-center"
        >
          <FormControlLabel
            key={`inactive`}
            control={
              <Switch
                name="Inactive"
                checked={userContactDetails.Inactive}
                onChange={handleAdvancedInactive}
              />
            }
            label="In Active"
          />

          {!(
            contactDetails &&
            contactDetails.length > 0 &&
            contactDetails[0]["Portal User"]
          ) && (
              <FormControlLabel
                key={`createportal`}
                control={
                  <Switch
                    name="CreatePortal"
                    checked={userContactDetails.CreatePortal}
                    onChange={handleAdvancedCreatePortal}
                  />
                }
                label="Create Portal"
              />
            )}

          <FormControlLabel
            key={`maincheckbox`}
            control={
              <Switch
                name="MainContact"
                checked={userContactDetails.MainContact}
                onChange={handleAdvancedSettingChange}
              />
            }
            label="Main Contact"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            key={`Greetingkey`}
            id="Greetingid"
            label="Greeting"
            variant="outlined"
            name="GreetingName"
            value={userContactDetails.GreetingName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            key={`Emailkey`}
            id="Emailid"
            label="Email"
            variant="outlined"
            name="EmailName"
            value={userContactDetails.EmailName}
            onChange={onChange}
            error={!!errors["EmailName"]} // Set error state based on whether there is an error message
            helperText={errors["EmailName"]} // Display error message if there is one
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="custom-autocomplete">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                key={`maincontact-manager`}
                options={managerList}
                getOptionLabel={(option) => option?.UserName}
                value={manger}
                onChange={onChangeUser}
                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={`Managermain`}
                    name="Manager"
                    size="small"
                    // onChange={onChangeUser}
                    label="Manager"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Line1key`}
            size="small"
            id="Line1id"
            label="Line1"
            variant="outlined"
            name="MainLine1Name"
            value={userContactDetails.MainLine1Name}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Line2key`}
            size="small"
            id="Line2id"
            label="Line2"
            variant="outlined"
            name="MainLine2Name"
            value={userContactDetails.MainLine2Name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Line3key`}
            id="Line3id"
            label="Line3"
            size="small"
            variant="outlined"
            name="MainLine3Name"
            value={userContactDetails.MainLine3Name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Townkey`}
            size="small"
            id="Townid"
            label="Town"
            variant="outlined"
            name="MainTownName"
            value={userContactDetails.MainTownName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Postcodekey`}
            // type="number"
            id="Postcodeid"
            size="small"
            label="Postcode"
            variant="outlined"
            name="MainPostcodeName"
            value={userContactDetails.MainPostcodeName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="custom-autocomplete">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                options={AllCountriesList}
                key={`maincontact-Country`}
                getOptionLabel={(option) => option.label}
                value={mainCountry}
                onChange={onChangecountry}
                // value={defaultUser || null}
                // onChange={onChangeuser}
                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={`Countrymain`}
                    size="small"
                    name="Country"
                    // onChange={onChangecountry}
                    label="Country"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Telephonekey`}
            type="number"
            id="Telephoneid"
            label="Telephone"
            size="small"
            variant="outlined"
            name="MainTelephoneName"
            value={userContactDetails.MainTelephoneName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            key={`Mobilekey`}
            type="number"
            id="Mobileid"
            label="Mobile No"
            variant="outlined"
            name="MainMobileName"
            size="small"
            value={userContactDetails.MainMobileName}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactMainForm;

const titleData = [
  { label: "Mr", year: 1994 },
  { label: "Mrs", year: 1972 },
  { label: "Miss", year: 1974 },
  { label: "Ms", year: 2008 },
  { label: "Dr", year: 1957 },
  { label: "Hr", year: 1957 },
  { label: "N/A", year: 1957 },
];
