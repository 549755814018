import API from "./APIsCall";



export default class AllServiceParamerter extends API {
    constructor(agrno, Email, password, APIUrl) {
        super(APIUrl);
        var Obj = {};
         Obj.agrno = agrno;
         Obj.Email = Email;
         Obj.password = password;
        this.MainObj = Obj; 

        var ObjAccid = {};
        ObjAccid.accid = agrno;
        ObjAccid.email = Email;
        ObjAccid.password = password;
        this.MainObjAccid = ObjAccid; 
      
    }

    CreateNewServiceParamObject(name, value, mainBool) {
        var ParamName = "Param_" + name;       
        var Obj = this.MainObj;
        if (mainBool) {
            this[ParamName] = { ...Obj, ...value };
        } else if (value) {
            this[ParamName] = value;
        } else {
            this[ParamName] = Obj;
        }     
    }

    
    CreateNewServiceParamObjectAccid(name, value, mainBool) {
        var ParamName = "Param_" + name;       
        var Obj = this.MainObjAccid;
        if (mainBool) {
            this[ParamName] = { ...Obj, ...value };
        } else if (value) {
            this[ParamName] = value;
        } else {
            this[ParamName] = Obj;
        }     
    }

	 
}