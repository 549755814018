import AllService from "./AllService";
export default class CommanCLS extends AllService {

    constructor(agrno, Email, password, APIUrl) {
        super(agrno, Email, password, APIUrl);
    }

    Json_getViewerToken(obj, callBack) {
        super.CreateNewServiceParamObject("Json_getViewerToken", obj, true);
        super.CallNewService("Json_getViewerToken", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    //PortalUsersRequestedDocs2_Json
    // PortalUsersRequestedDocs_Json (obj, callBack) {
    //     super.CreateNewServiceParamObject("PortalUsersRequestedDocs_Json ", obj, false);
    //     super.CallNewService("PortalUsersRequestedDocs_Json ", function (status, Data) {
    //         if (status) {
    //             return callBack(true, Data);
    //         }
    //         else {
    //             return callBack(false, []);
    //         }
    //     })
    // }
    PortalUsersRequestedDocs_Json(obj, callBack) {
        super.CreateNewServiceParamObject("PortalUsersRequestedDocs2_Json ", obj, false);
        super.CallNewService("PortalUsersRequestedDocs2_Json ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    RequestedDocumentCreated_Json(obj, callBack) {
        super.CreateNewServiceParamObject("RequestedDocumentCreated_Json ", obj, false);
        super.CallNewService("RequestedDocumentCreated_Json ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }

    RequestedDocumentAsByteArray_Json(obj, callBack) {
        super.CreateNewServiceParamObject("RequestedDocumentAsByteArray_Json ", obj, false);
        super.CallNewService("RequestedDocumentAsByteArray_Json ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }

    Json_Portal_GetClientListByEmail(obj, callBack) {
        super.CreateNewServiceParamObject("Json_Portal_GetClientListByEmail ", obj, false);
        super.CallNewService("Json_Portal_GetClientListByEmail ", function (status, Data) {
            if (status) {
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }

    GetAllFolders_Json(callBack) {
        super.CreateNewServiceParamObjectAccid("GetAllFolders_Json ");
        super.CallNewService("GetAllFolders_Json ", function (status, Data) {
            if (status) {
                console.log("GetAllFolders_Json....",Data)
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    GetClientsByFolder_Json(obj,callBack) {
        super.CreateNewServiceParamObjectAccid("GetClientsByFolder_Json",obj,true);
        super.CallNewService("GetClientsByFolder_Json", function (status, Data) {
            if (status) {
                console.log("GetClientsByFolder_Json....",Data)
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
    }
    Json_GetWebTemplates(callBack) {
        try {
            super.CreateNewServiceParamObject("Json_GetWebTemplates");
        super.CallNewService("Json_GetWebTemplates", function (status, Data) {
            if (status) {
                console.log("Json_GetWebTemplates....",Data)
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
        } catch (error) {
            console.log("NetworkError Json_GetWebTemplates:",error)
        }        
    }
    Json_GetForwardUserList(obj,callBack) {
        try {
            super.CreateNewServiceParamObject("Json_GetForwardUserList",obj,false);
        super.CallNewService("Json_GetForwardUserList", function (status, Data) {
            if (status) {
                console.log("Json_GetForwardUserList....",Data)
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
        } catch (error) {
            console.log("NetworkError Json_GetWebTemplates:",error)
        }        
    }
    GetSectionsByFolder_Json(obj,callBack) {
        try {
            super.CreateNewServiceParamObject("GetSectionsByFolder_Json",obj,false);
        super.CallNewService("GetSectionsByFolder_Json", function (status, Data) {
            if (status) {
                console.log("GetSectionsByFolder_Json....",Data)
                return callBack(true, Data);
            }
            else {
                return callBack(false, []);
            }
        })
        } catch (error) {
            console.log("NetworkError Json_GetWebTemplates:",error)
        }        
    }
    //     Json_Portal_GetClientListByEmail (obj, callBack) {
    //         super.CreateNewServiceParamObject("Json_Portal_GetClientListByEmail ", obj, false);
    //         super.CallNewService("Json_Portal_GetClientListByEmail ", function (status, Data) {
    //             if (status) {
    //                 return callBack(true, Data);
    //             }
    //             else {
    //                 return callBack(false, []);
    //             }
    //         })
    //     }
}