import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Switch,
  Button,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const UserUdfEditField = ({ udfFieId, changeFun }) => {

  const handleInputChange = () => { };
  const selectManager = [];

  const renderTextField = (data, type) => {
    return (
      <TextField
        fullWidth
        //   label={data.Name}
        variant="outlined"
        type={type}
        id={`outlined-size-small`}
        defaultValue={data.UdfValue || ""}
        size="small"
        // onChange={(e) => changeFun(e, data?.UserDefFieldID)}
        onBlur={(e) => changeFun(e, data?.UserDefFieldID)}
        InputProps={{
          readOnly: data?.blnProcess ? !data.blnProcess : !data?.switchValue,
        }}
        name={data.Name}
      />
    );
  };

  const renderDatePicker = (data) => {
    let formattedDate = null;

    if (data?.UdfValue) {
      console.log("✌️data?.UdfValue --->", data?.UdfValue);

      // Attempt to parse using both formats
      const parsedDate = dayjs(data.UdfValue, [
        "MMM DD YYYY hh:mmA", // For format like "Jan 31 2025 12:00AM"
        "DD/MM/YYYY",         // For format like "30/01/2025"
      ]);

      // Check if the parsed date is valid
      if (parsedDate.isValid()) {
        formattedDate = parsedDate; // Keep it as a valid Day.js object
      }
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DemoContainer
          components={[
            "DatePicker",
            "TimePicker",
            "DateTimePicker",
            "DateRangePicker",
          ]}
        >
          <DatePicker
            // className="custom-date-2"
            format="DD/MM/YYYY"
            value={formattedDate} // Use a valid Day.js object here
            id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_${data.TextControlValue}_UDF`}
            onChange={(e) => changeFun(e, data.UserDefFieldID)}
            InputProps={{
              readOnly: true,
            }}
            disabled={data?.blnProcess ? !data.blnProcess : !data?.switchValue}
            size="small"
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "12px", // Adjust font size here
                paddingTop: '10px',
                paddingBottom: '10px',
              },
              "& .MuiInputLabel-root": {
                fontSize: "12px", // Adjust label font size
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  };

  const renderAutocomplete = (data, options) => (
    <>
      <Box className="custom-autocomplete"> 
        <Autocomplete
          id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_UDF`}
          options={options}
          defaultValue={data?.UdfValue ? data?.UdfValue : "Select"}
          onChange={(event, value) => {
            changeFun(event, data?.UserDefFieldID, value);
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          disabled={data?.blnProcess ? !data.blnProcess : !data?.switchValue}
          size="small"
          style={{width: '200px'}}
        />
      </Box>
    </>
  );

  const renderDynamicInput = (data) => {
    switch (data?.UserDefFieldTypeID) {
      case 1: // Text input
        switch (data?.TextControlValue) {
          case "Integer":
            return renderTextField(data, "number");
          case "String":
            return renderTextField(data, "text");
          case "Decimal":
          case "Currency":
            return renderTextField(data, "text");
          case "Date":
            return renderDatePicker(data);
          case "Memo":
            return renderTextField(data, "text");
        }
        break;

      case 2: // Select input (Autocomplete)
        const options =
          data?.Options?.split("@;")?.filter((option) => option.trim()) || [];
        return renderAutocomplete(data, options);

      case 10: // Boolean Switch
        return (
          <FormControlLabel
            control={
              <Switch
                id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_UDF`}
                onChange={handleInputChange}
              />
            }
            label={data.Name}
          />
        );

      case 15: // ComboBox
        const selectOptions =
          selectManager.map((option) => option.ForwardTo) || [];
        return renderAutocomplete(data, selectOptions);

      default:
        return null;
    }
  };

  return <div className="">{renderDynamicInput(udfFieId)}</div>;
};

export default UserUdfEditField;
