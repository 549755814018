/* eslint-disable no-loop-func */
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import {
  clientBodyaccIdData,
  clientBodyaccidData,
  userBodyaccId,
} from "../utils/RequestsFunction";
import {
  docuEngagerClient,
  docuSms,
  portalClient,
  portalUser,
  PracticeServices,
  practiceTest,
  practiceTestOnlyForTesting,
} from "./AxiosInstance";
import { getMessagerequest } from "./dev_aditya_action";
import {
  setAdditionalInformation,
  setAllContacts,
  setAllContactsByClientID,
  setAllMessagesContactsDetails_Redux,
  setAllPortalUserInboxMessage,
  setApprovalCertificateBase64,
  setClientListByProjectId,
  setContactRequestDocCount,
  setCRMContactUDFValues_Redux,
  setInboxMessageHtml,
  setInboxMessageHtmlLoading,
  setMessageReadUnreadCountDetails,
  setPortalApprovalCommentsHtml,
  setPortalMessageComments,
  setPortalMessageDocuments,
  setPortalMessageDocumentsLoading,
  setPortalSections,
  setPortalUserInboxMessage,
  setProposalCompData,
  setShareBoxDocuments,
  setShareBoxMessageId,
} from "./dev_chetan_slice";
import { setfetchDocumentRequest } from "./docuSlice";
let rqObj = {
  agrno: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  password: localStorage.getItem("password")
    ? localStorage.getItem("password")
    : "UGF0cmljazEyMy4=",
  Email: localStorage.getItem("Email")
    ? localStorage.getItem("Email")
    : "patrick@docusoft.net",
};
let portalObj = {
  accId: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  email: localStorage.getItem("Email")
    ? localStorage.getItem("Email")
    : "patrick@docusoft.net",
  password: localStorage.getItem("password")
    ? localStorage.getItem("password")
    : "UGF0cmljazEyMy4=",
};
let portalUserObj = {
  accid: localStorage.getItem("agrno") ? localStorage.getItem("agrno") : "0003",
  email: localStorage.getItem("Email")
    ? localStorage.getItem("Email")
    : "patrick@docusoft.net",
  password: localStorage.getItem("password")
    ? localStorage.getItem("password")
    : "UGF0cmljazEyMy4=",
};
let objForClient = {
  accid: localStorage.getItem("accIdClient") || localStorage.getItem('agrno'),
  username: localStorage.getItem("EmailClient") || localStorage.getItem('Email'),
  sPassword: localStorage.getItem("passwordClient") || localStorage.getItem('password')
};
let clientEmail = "patrick.docusoft@outlook.com";

const userBody = {
  accid: localStorage.getItem("accid") ? localStorage.getItem("accid") : "0003",
  email: localStorage.getItem("Email"),
  EmailId: localStorage.getItem("EmailId"),
  password: localStorage.getItem("password"),
};
const userBody_2 = {
  accid: localStorage.getItem("accid") ? localStorage.getItem("accid") : "0003",
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

const clientBody = {
  accId: localStorage.getItem("accIdClient")
    ? localStorage.getItem("accIdClient")
    : "0003",
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient")
    ? localStorage.getItem("passwordClient")
    : "All",
};

const LoginClient = localStorage.getItem("LoginClient");
const isClientLogin = Boolean(localStorage.getItem("LoginClient"));

export const getShareBoxMessageId = () => async (dispatch) => {
  if (LoginClient) {
    try {
      const res = await portalUser.post(
        "/ShareBoxMessageExists_Json",
        clientBodyaccidData
      );
      if (res?.data?.d) {
        dispatch(setShareBoxMessageId(res?.data?.d));
      } else {
        dispatch(setShareBoxMessageId(""));
      }
    } catch (err) {
      console.log("Error while calling GetShareboxDocuments_Json", err);
    }
  } else {
    try {
      const res = await portalClient.post(
        "/ShareBoxMessageExists_Json",
        portalObj
      );
      if (res?.data?.d) {
        dispatch(setShareBoxMessageId(res?.data?.d));
      } else {
        dispatch(setShareBoxMessageId(""));
      }
    } catch (err) {
      console.log("Error while calling GetShareboxDocuments_Json", err);
    }
  }
};

export const getShareBoxDocuments = () => async (dispatch) => {
  if (!LoginClient) {
    try {
      const res = await portalClient.post(
        "/GetShareboxDocuments_Json",
        userBodyaccId
      );
      if (res?.data?.d) {
        const result = JSON.parse(res?.data?.d);
        const sortedDocs = result.sort((a, b) =>
          moment(b.Added).diff(moment(a.Added))
        );
        dispatch(setShareBoxDocuments(sortedDocs));
      } else {
        dispatch(setShareBoxDocuments([]));
      }
    } catch (err) {
      console.log("Error while calling GetShareboxDocuments_Json", err);
    }
  } else {
    try {
      const res = await portalUser.post(
        "/GetShareboxDocuments_Json",
        clientBody
      );
      if (res?.data?.d) {
        const result = JSON.parse(res?.data?.d);
        const sortedDocs = result.sort((a, b) =>
          moment(b.Added).diff(moment(a.Added))
        );
        dispatch(setShareBoxDocuments(sortedDocs));
      } else {
        dispatch(setShareBoxDocuments([]));
      }
    } catch (err) {
      console.log("Error while calling GetShareboxDocuments_Json", err);
    }
  }
};

export const saveShareBoxDocument =
  (allFiles, shareBoxMessageId) => async (dispatch) => {
    const results = await Promise.all(
      Object.values(allFiles).map(async (file) => {
        if (file instanceof File) {
          const byteArray = new Uint8Array(await file.arrayBuffer());
          return portalClient.post("/ShareboxDocumentCreated2_Json", {
            ...userBodyaccId,
            messageID: shareBoxMessageId || "",
            DocFileName: file.name,
            bytes: Array.from(byteArray),
          });
        }
      })
    );

    // Filter out undefined results (if any) and dispatch success or error messages
    const successfulResults = results.filter(Boolean);
    dispatch(getShareBoxDocuments());
    successfulResults.length
      ? toast.success(
        `${successfulResults.length} Document(s) uploaded successfully`
      )
      : toast.error("Unable to upload document(s), please try again");
  };

export const removeShareboxDocument = (data) => async (dispatch) => {
  try {
    const res = await portalClient.post("/ShareboxDocumentDeleted_Json", {
      ...portalObj,
      messageID: data.PortalDocID,
      attachId: data.AttachID,
    });
    if (Boolean(res?.data?.d)) {
      toast.success("Document removed");
      return "Done";
    } else {
      toast.error("Something went wrong please try again");
      return "Done";
    }
  } catch (err) {
    console.log("Error while calling ShareboxDocumentDeleted_Json", err);
  }
};

export const downloadShareboxDocument =
  (PortalDocID, AttachID, DocExtension, docName) => async (dispatch) => {
    try {
      let res;
      if (isClientLogin) {
        res = await portalUser.post("/GetAttachmentNew_Json", {
          ...objForClient,
          messageId: PortalDocID,
          attachid: AttachID,
          extension: DocExtension,
        });
      } else {
        res = await portalClient.post("/GetAttachment_Json", {
          ...portalUserObj,
          messageId: PortalDocID,
          attachid: AttachID,
          extension: DocExtension,
        });
      }
      if (res?.data?.d && res?.data?.d !== "No Data Exist") {
        let ankr = document.createElement("a");
        ankr.href = `data:application/octet-stream;base64,${res?.data?.d}`;
        ankr.download = docName;
        ankr.click();
      }
    } catch (err) {
      console.log("Error while calling ShareboxDocumentDeleted_Json", err);
    }
  };

export const getContactsListByFolder = (selectedFolder) => async (dispatch) => {
  try {
    const res = await practiceTest.post("/Json_GetContactListByFolder", {
      ...rqObj,
      intFolderId: selectedFolder?.FolderID
        ? selectedFolder?.FolderID
        : selectedFolder,
    });

    if (res?.data?.d) {
      const contacts = JSON.parse(res?.data?.d)?.Table;
      dispatch(setAllContacts(contacts));
      return contacts;
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getCRMContactUDFValues = (obj) => async (dispatch) => {
  try {
    const res = await docuSms.post("/Json_GetCRMContactUDFValues", {
      ...rqObj,
      ...obj,
    });
    if (res?.data?.d) {
      const result = JSON.parse(res?.data?.d);
      dispatch(setCRMContactUDFValues_Redux(result));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getAllSentMessages = (obj) => async (dispatch) => {
  try {
    const res = await portalClient.post("/Json_GetAllSentMessages", {
      ...rqObj,
      ...obj,
    });

    if (res?.data?.d) {
      const result = JSON.parse(res?.data?.d);
      console.log("Data hase beem Restore", result);
      dispatch(setAllMessagesContactsDetails_Redux(result));
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getAllReceivedMessages = () => async (dispatch) => {
  try {
    const res = await portalClient.post("/Json_GetAllReceivedMessages", {
      ...rqObj,
      emailAddress: "",
      ccode: "originatorNo",
      folder: "folderid",
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      // dispatch(setCRMContactUDFValues_Redux(res));
      console.log("Json_GetAllReceivedMessages", res);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const portalUserAccountCreated_Json = (body) => async (dispatch) => {
  try {
    const res = await portalClient.post("/PortalUserAccountCreated_Json", {
      ...userBody_2,
      PresetMemorableData: true,
      IssueReminders: false,
      ExcludeMessageLink: true,
      KeepSignedIn: true,
      AllowUpload: true,
      ChangeProfile: true,
      LoggedIn: false,
      Blocked: false,
      ...body,
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      toast.success("Portal Account Created Successfully !");
      // dispatch(setCRMContactUDFValues_Redux(res));
      console.log("PortalUserAccountCreated_Json", res);
      return res;
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const portalUserAccountUpdated_Json = (obj) => async (dispatch) => {
  try {
    const res = await portalClient.post("/PortalUserAccountUpdated_Json", {
      ...portalUserObj,
      ...obj,
    });
    if (res?.data?.d) {
      // toast.success("Portal account Blocked successfully!");
      // dispatch(setCRMContactUDFValues_Redux(res));
      console.log("portal api from redux", res?.data?.d);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getAllContactsByClientID =
  (client_id, folder_id) => async (dispatch) => {
    try {
      const res = await docuSms.post("/Json_GetAllContactsByClientID", {
        ...rqObj,
        ClientID: client_id,
        ProjectID: folder_id,
      });
      if (res?.data?.d) {
        const parsedData = JSON.parse(res?.data?.d).Table;
        dispatch(setAllContactsByClientID(parsedData));
        return parsedData;
      }
    } catch (err) {
      console.log("Error while calling GetShareboxDocuments_Json", err);
    }
  };

export const getSupplierContact = () => async (dispatch) => {
  try {
    const res = await docuSms.post("/SupplierContact", {
      ...rqObj,
      ClientID: "originatorNo",
      ProjectID: "folder_id",
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      console.log("SupplierContact", res);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const updateContactVerify = () => async (dispatch) => {
  try {
    const res = await docuSms.post("/Json_UpdateContactVerify", {
      ...rqObj,
      Contactemail: "",
      BnkAccNumber: "",
      BnkSrCode: "",
      DrvLicNumber: "",
      NatInsNumber: "",
      PassportNumber: "",
    });
    if (res?.data?.d) {
      const res = JSON.parse(res?.data?.d);
      console.log("Json_UpdateContactVerify", res);
    }
  } catch (err) {
    console.log("Error while calling GetShareboxDocuments_Json", err);
  }
};

export const getPortalUserInboxMessages = () => async (dispatch) => {
  try {
    if (LoginClient) {
      let body = {
        ...clientBody,
        filterBy: "All",
      };
      const response = await portalUser.post("/PortalUsersInbox_Json", body);
      if (response?.data?.d) {
        const result = JSON.parse(response?.data?.d);
        dispatch(setPortalUserInboxMessage(result));
        dispatch(setAllPortalUserInboxMessage(result));
      } else {
        dispatch(setPortalUserInboxMessage([]));
        dispatch(setAllPortalUserInboxMessage([]));
      }
    }

    // const response = await portalUser.post("/PortalUsersInbox_Json", {
    //   accId: localStorage.getItem("agrno")
    //     ? localStorage.getItem("agrno")
    //     : "0003",
    //   email: localStorage.getItem("Email")
    //     ? localStorage.getItem("Email")
    //     : "patrick@docusoft.net",
    //   password: localStorage.getItem("password")
    //     ? localStorage.getItem("password")
    //     : "UGF0cmljazEyMy4=",
    //   username: localStorage.getItem("EmailClient")
    //     ? localStorage.getItem("EmailClient")
    //     : "",
    //   sPassword: "0",
    //   filterBy: "all",
    // });
    // if (response?.data?.d) {
    //   const result = JSON.parse(response?.data?.d);
    //   dispatch(setPortalUserInboxMessage(result));
    //   dispatch(setAllPortalUserInboxMessage(result));
    // } else {
    //   dispatch(setPortalUserInboxMessage([]));
    //   dispatch(setAllPortalUserInboxMessage([]));
    // }
  } catch (err) {
    console.log("Error while calling PortalUsersInbox_Json", err);
  }
};

export const getPortalUsersInboxNew_Json = () => async (dispatch) => {
  try {
    const response = await portalUser.post("/PortalUsersInboxNew_Json", {
      accId: localStorage.getItem("accIdClient")
        ? localStorage.getItem("accIdClient")
        : "0003",
      username: localStorage.getItem("EmailClient")
        ? localStorage.getItem("EmailClient")
        : "patrick.docusoft@outlook.com",
      sPassword: localStorage.getItem("passwordClient")
        ? localStorage.getItem("passwordClient")
        : "UzBVQjFTS1Q=",
      filterBy: "All",
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setPortalUserInboxMessage(result));
      dispatch(setAllPortalUserInboxMessage(result));
    } else {
      dispatch(setPortalUserInboxMessage([]));
      dispatch(setAllPortalUserInboxMessage([]));
    }
  } catch (err) {
    console.log("Error while calling PortalUsersInbox_Json", err);
  }
};

export const getMessageHtml = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if (Boolean(localStorage.getItem("LoginClient"))) {
      response = await portalUser.post("/GetMessageHtmlNew_Json", {
        ...objForClient,
        messageId: portalDocId,
      });
    } else {
      response = await portalClient.post("/GetMessageHtml_Json", {
        ...portalUserObj,
        messageId: portalDocId,
      });
    }
    if (response?.data?.d) {
      const result = response?.data?.d;
      dispatch(setInboxMessageHtml(result));
      dispatch(setInboxMessageHtmlLoading(false));
    }
  } catch (err) {
    console.log("Error while calling GetMessageHtml_Json", err);
  }
};

export const markMessageAsViewed = (idToDispatch) => async (dispatch) => {
  // console.log(idToDispatch,"============ idToDispatch");

  if (LoginClient) {
    try {
      const ipResponse = await axios.get("https://api.ipify.org/?format=json");

      if (ipResponse && ipResponse?.data && ipResponse.data?.ip) {
        const response = await portalUser.post("/MarkMessageAsViewedNew_Json", {
          ...clientBody,
          email: "",
          password: "",
          messageid: idToDispatch ? idToDispatch : "",
          ipAddress: ipResponse.data?.ip ? ipResponse.data?.ip : "",
        });
        if (response?.data?.d) {
          if (Boolean(response?.data?.d))
            // dispatch(getPortalUserInboxMessages());
          dispatch(getPortalUsersInboxNew_Json());
        }
      }
    } catch (err) {
      console.log("Error while calling GetMessageHtml_Json", err);
    }
  } else {
    try {
      const ipResponse = await axios.get("https://api.ipify.org/?format=json");

      if (ipResponse && ipResponse?.data && ipResponse.data?.ip) {
        const response = await portalUser.post("/MarkMessageAsViewed_Json", {
          ...userBodyaccId,
          username: "",
          sPassword: "",
          messageid: idToDispatch ? idToDispatch : "",
          ipAddress: ipResponse.data?.ip ? ipResponse.data?.ip : "",
        });
        if (response?.data?.d) {
          if (Boolean(response?.data?.d))
            dispatch(getPortalUserInboxMessages());
        }
      }
    } catch (err) {
      console.log("Error while calling GetMessageHtml_Json", err);
    }
  }
};

export const savePortalMessgeComment =
  (body, callBack, customerId) => async (dispatch) => {
    console.log("body --->", body, objForClient);
    try {
      let response;

      response = await portalUser.post("/ResponseAdded_Json", {
        ...userBody_2,
        ...body,
      });

      if (Boolean(response?.data?.d)) {
        toast.success("Comment added successfully");
        dispatch(getPortalMessageComments(body?.messageID));
        callBack(response?.data?.d);
        return;
      } else {
        toast.error("Something went wrong please try again");
      }
    } catch (err) {
      console.log("Error while calling ResponseAdded_Json", err);
    }
  };

export const getPortalMessageDocuments = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if (isClientLogin) {
      response = await portalUser.post("/GetMessageDocumentsNew_Json", {
        ...objForClient,
        messageId: portalDocId,
      });
      if (response?.data?.d) {
        let result = JSON.parse(response?.data?.d);
        // console.log(result, "========== result");

        dispatch(setPortalMessageDocuments(result));
        dispatch(setfetchDocumentRequest([]));
        dispatch(setPortalMessageDocumentsLoading(false));
      } else {
        dispatch(setPortalMessageDocumentsLoading(false));
        dispatch(setPortalMessageDocuments([]));
      }
    } else {
      response = await portalUser.post("/GetMessageDocuments_Json", {
        ...portalUserObj,
        messageId: portalDocId,
      });
    }
    if (response?.data?.d) {
      let result = JSON.parse(response?.data?.d);
      dispatch(setPortalMessageDocuments(result));
      dispatch(setPortalMessageDocumentsLoading(false));
    } else {
      dispatch(setPortalMessageDocumentsLoading(false));
      dispatch(setPortalMessageDocuments([]));
    }
  } catch (err) {
    console.log("Error while calling ResponseAdded_Json", err);
  }
};

export const getPortalMessageComments = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if (isClientLogin) {
      response = await portalUser.post("/GetCommentsHtmlNew_Json", {
        ...objForClient,
        messageId: portalDocId,
      });
    } else {
      response = await portalUser.post("/GetCommentsHtml_Json", {
        ...portalUserObj,
        messageId: portalDocId,
      });
    }
    if (response?.data?.d) {
      // dispatch(setPortalMessageComments(response?.data?.d));
      dispatch(getMessagerequest({ messageID: portalDocId }, () => { }));
    } else {
      dispatch(setPortalMessageComments(""));
    }
  } catch (err) {
    console.log("Error while calling GetCommentsHtml_Json", err);
  }
};

export const getApprovalCertificate = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if (LoginClient) {
      response = await portalUser.post("/GetCertificateNew_Json", {
        ...objForClient,
        messageId: portalDocId,
      });
    } else {
      response = await portalClient.post("/GetCertificate_Json", {
        ...portalUserObj,
        messageId: portalDocId,
      });
    }
    if (response?.data?.d) {
      dispatch(setApprovalCertificateBase64(response?.data?.d));
    } else {
      dispatch(setApprovalCertificateBase64("undefined"));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const getApprovalCommentsHtml = (portalDocId) => async (dispatch) => {
  try {
    let response;
    if (isClientLogin) {
      response = await portalUser.post("/GetApprovalsAndCommentsHtmlNew_Json", {
        ...objForClient,
        messageId: portalDocId,
      });
    } else {
      response = await portalUser.post("/GetApprovalsAndCommentsHtml_Json", {
        ...portalUserObj,
        messageId: portalDocId,
      });
    }
    if (response?.data?.d) {
      dispatch(setPortalApprovalCommentsHtml(response?.data?.d));
    } else {
      dispatch(setPortalApprovalCommentsHtml(""));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const getPortalSections = () => async (dispatch) => {
  try {
    const response = await portalUser.post(
      "/GetPortalSections_Json",
      portalUserObj
    );
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setPortalSections(result));
    } else {
      dispatch(setPortalSections([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const RequestStatusTotalCountByContact_Json =
  (username) => async (dispatch) => {
    try {
      const response = await portalUser.post(
        "/RequestStatusTotalCountByContact_Json",
        {
          ...portalObj,
          username: username ? username : "patrick.docusoft@outlook.com",
        }
      );
      if (response?.data?.d) {
        const result = JSON.parse(response?.data?.d);
        dispatch(setContactRequestDocCount(result));
      } else {
        dispatch(setContactRequestDocCount([]));
      }
    } catch (err) {
      console.log("Error while calling GetCertificate_Json", err);
    }
  };

export const GetPortalInformationTotalByContact_Json =
  (username) => async (dispatch) => {
    try {
      const response = await docuEngagerClient.post(
        "/GetPortalInformationTotalByContact_Json",
        {
          ...portalObj,
          username: username ? username : "patrick.docusoft@outlook.com",
        }
      );
      if (response?.data?.d) {
        const result = JSON.parse(response?.data?.d);
        dispatch(setMessageReadUnreadCountDetails(result));
      } else {
        dispatch(setMessageReadUnreadCountDetails([]));
      }
    } catch (err) {
      console.log("Error while calling GetCertificate_Json", err);
    }
  };

export const RequestStatusTotalCount_Json = () => async (dispatch) => {
  try {
    const response = await portalUser.post("/RequestStatusTotalCount_Json", {
      ...portalObj,
    });
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const Json_Portal_GetClientListByEmail = () => async (dispatch) => {
  try {
    const response = await practiceTest.post(
      "/Json_Portal_GetClientListByEmail",
      {
        agrno: "0003",
        ContactEmail: "dbeckhamportal@gmail.com",
      }
    );
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      console.log("Json_Portal_GetClientListByEmail", result);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const Json_GetSupplierListByProject =
  (selectedFolder) => async (dispatch) => {
    try {
      const response = await practiceTestOnlyForTesting.post(
        "/Json_GetSupplierListByProject",
        {
          ...rqObj,
          ProjectId: selectedFolder
            ? selectedFolder.toString()
            : localStorage.getItem("FolderId"),
        }
      );
      if (response?.data?.d) {
        const result = JSON.parse(response?.data?.d);
        // console.log('Json_GetSupplierListByProject', result.Table);
        dispatch(setClientListByProjectId(result.Table));
        // dispatch(setMessageReadUnreadCountDetails(result));
      } else {
        // dispatch(setMessageReadUnreadCountDetails([]));
      }
    } catch (err) {
      console.log("Error while calling GetCertificate_Json", err);
    }
  };

export const GetMessageDocumentsNew_Json = (msgId) => async (dispatch) => {
  let obj = {
    accid: localStorage.getItem('accIdClient') || localStorage.getItem('agrno'),
    username: localStorage.getItem('EmailClient') || localStorage.getItem('Email'),
    sPassword: localStorage.getItem('passwordClient') || localStorage.getItem('password'),
    messageId: msgId
  }
  try {
    const response = await portalUser.post(
      "/GetMessageDocumentsNew_Json",
      obj
    );
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      // console.log("GetMessageDocumentsNew_Json", result);
      dispatch(setProposalCompData({ name: "messageDocs", value: result }));
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const PortalUsersInboxNewByMsgId_Json_Test = (msgId) => async (dispatch) => {
  let obj = {
    accId: localStorage.getItem('accIdClient') || localStorage.getItem('agrno'),
    username: localStorage.getItem('EmailClient') || localStorage.getItem('Email'),
    sPassword: localStorage.getItem('passwordClient') || localStorage.getItem('password'),
    messageId: msgId
  }
  try {
    const response = await portalUser.post(
      "/PortalUsersInboxNewByMsgId_Json",
      obj
    );
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setProposalCompData({ name: "messageData", value: result }));
      // console.log("PortalUsersInboxNewByMsgId_Json", result);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const PortalUsersInboxNewByMsgId_Json_User = (msgId,emailid) => async (dispatch) => {
  let obj = {
    accId:localStorage.getItem('agrno') || "",
    email:localStorage.getItem('Email') || "",
    password:localStorage.getItem('password') || "",
    emailid:emailid,
    sPassword:"",
    messageId:msgId,
  }
  try {
    const response = await docuEngagerClient.post(
      "/PortalUsersInboxNewByMsgId_Json",
      obj
    );
    if (response?.data?.d) {
      const result = JSON.parse(response?.data?.d);
      dispatch(setProposalCompData({ name: "messageData", value: result }));
      // console.log("PortalUsersInboxNewByMsgId_Json", result);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const GetApprovalsAndCommentsHtmlNew_Json = (msgId) => async (dispatch) => {
  let obj = {
    accid: localStorage.getItem('accIdClient') || localStorage.getItem('agrno'),
    username: localStorage.getItem('EmailClient') || localStorage.getItem('Email'),
    sPassword: localStorage.getItem('passwordClient') || localStorage.getItem('password'),
    messageId: msgId
  }
  try {
    const response = await portalUser.post(
      "/GetApprovalsAndCommentsHtmlNew_Json",
      obj
    );
    if (response?.data?.d) {
      const result = response?.data?.d;
      dispatch(setProposalCompData({ name: "approvalsAndComments", value: result }));
      // dispatch(setProposalCompData({name: "messageData", value: result}));
      // console.log("PortalUsersInboxNewByMsgId_Json", result);
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const GetCertificateNew_Json = (msgId) => async (dispatch) => {
  let obj = {
    accid: localStorage.getItem('accIdClient') || localStorage.getItem('agrno'),
    username: localStorage.getItem('EmailClient') || localStorage.getItem('Email'),
    sPassword: localStorage.getItem('passwordClient') || localStorage.getItem('password'),
    messageId: msgId
  }
  try {
    const response = await portalUser.post(
      "/GetCertificateNew_Json",
      obj
    );
    if (response?.data?.d) {
      const result = response?.data?.d;
      dispatch(setProposalCompData({ name: "approvalCertificate", value: result }));
      // dispatch(setProposalCompData({name: "messageData", value: result}));
      // dispatch(setMessageReadUnreadCountDetails(result));
    } else {
      // dispatch(setMessageReadUnreadCountDetails([]));
    }
  } catch (err) {
    console.log("Error while calling GetCertificate_Json", err);
  }
};

export const Json_ToGetSurveyDetailsByPubUser = (guid) => async (dispatch) => {
  try {
    const response = await PracticeServices.post(
      "/Json_ToGetSurveyDetailsByPubUser",
      {
        agrno: localStorage.getItem("accIdClient"),
        Email: localStorage.getItem("EmailClient"),
        password: localStorage.getItem("passwordClient"),
        OnboardingGUID: guid
      }
    );
    if (response?.data?.d) {
      const result = response?.data?.d;
      if (result.length > 0 && JSON.parse(result).Table[0].SurveyDetails && JSON.parse(JSON.parse(result).Table[0].SurveyDetails).length > 0) {
        const data = JSON.parse(JSON.parse(result).Table[0].SurveyDetails)
        dispatch(setAdditionalInformation({ name: "surveyDetailsList", value: data }));
        dispatch(setAdditionalInformation({ name: "isSurveyDetailsListLoading", value: false }));
      } else {
        dispatch(setAdditionalInformation({ name: "surveyDetailsList", value: [] }));
        dispatch(setAdditionalInformation({ name: "isSurveyDetailsListLoading", value: false }));
      }
    } else {
      dispatch(setAdditionalInformation({ name: "surveyDetailsList", value: [] }));
      dispatch(setAdditionalInformation({ name: "isSurveyDetailsListLoading", value: false }));
    }
  } catch (err) {
    dispatch(setAdditionalInformation({ name: "surveyDetailsList", value: [] }));
    dispatch(setAdditionalInformation({ name: "isSurveyDetailsListLoading", value: false }));
    console.log("Error while calling GetCertificate_Json", err);
  }
};