/* eslint-disable no-unused-vars */
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EmailIcon from "@mui/icons-material/Email";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import VerifiedIcon from "@mui/icons-material/Verified";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import user from "../../assets/images/user-2.svg";
import CommanCLS from "../../services/CommanService";
// import TestPDF from "../TestPDF";
// import AMLCheck from "../AMLCheck";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
// import ContactcardUDF from "./ContactcardUDF";
import { Grid } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AllCountriesList } from "../../custom-hook/AllCountriesList";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import { EditContactOpenMdalReducer } from "../../redux/adityaReducer";
import { ad_Json_deleteSupplierContact_Action } from "../../redux/dev_aditya_action";
import {
  GetPortalInformationTotalByContact_Json,
  RequestStatusTotalCountByContact_Json,
  getAllContactsByClientID,
  getCRMContactUDFValues,
  portalUserAccountCreated_Json,
  portalUserAccountUpdated_Json,
} from "../../redux/dev_chetan_api_helper";
import { setAllContactsByClientID } from "../../redux/dev_chetan_slice";
import {
  setNewMessage_Redux,
  setNewRequest_Redux,
} from "../../redux/docuSlice";
import { userBodyDAtaa } from "../../utils/RequestsFunction";
import AddContacts from "../componants/contact/AddContacts";
// import { fetchSupplierListOrderByFavourite } from "../../redux/reducers/api_helper";
// import DeleteIcon from '@mui/icons-material/Delete';
const label = { inputProps: { "aria-label": "Switch demo" } };
// const [nextDate, setNextDate] = useState("");

const agrno = localStorage.getItem("agrno");
const Email = localStorage.getItem("Email");
const password = localStorage.getItem("Password");
const folderId = localStorage.getItem("FolderId");

function ContactDetails() {
  const navigate = useNavigate();
  const localObj = useLocalStorage();
  const [searchParams, setSearchParams] = useSearchParams();
  const originatorNo = searchParams.get("orgNo");
  const contactNo = searchParams.get("contactNo");
  const emailAddress = searchParams.get("emailAddress");
  const paramFolderId = searchParams.get("folderId");
  const fromPotalContactsTable = searchParams.get('fromPotalContactsTable')

  const {
    allContactsByClientID_Redux,
    allMessagesContactsDetails_Redux,
    contactRequestDocCount,
    messageReadUnreadCountDetails,
    isContactRequestDocCountLoading,
    isMessageReadUnreadCountDetailsLoading,
  } = useSelector((state) => state.dev_chetan);
    const { rtk_getAllContactsPubUser } = useSelector((state) => state.aditya);
  
console.log(rtk_getAllContactsPubUser , "allCOntacts")

  let contactData;
  let issetCountry = "United Kingdom";
  let isVerfy = false;
  if (allContactsByClientID_Redux.length > 0) {
    contactData = allContactsByClientID_Redux.filter(
      (itm) => Number(itm.ContactNo) === Number(contactNo)
    );
    issetCountry =
      AllCountriesList &&
      AllCountriesList?.length > 0 &&
      AllCountriesList.find(
        (country) => country?.label == contactData[0]?.Country
      );
    if (
      contactData.length > 0 &&
      contactData[0] &&
      (contactData[0]["Bank Check Document ID"] ||
        contactData[0]["Bank Checked Date"] ||
        contactData[0]["License Check Document ID"] ||
        contactData[0]["License Checked Date"] ||
        contactData[0]["NI Check Document ID"] ||
        contactData[0]["NI Checked Date"] ||
        contactData[0]["Passport Check Document ID"] ||
        contactData[0]["Passport Checked Date"])
    ) {
      isVerfy = true;
    }
  }

  let ToBeApproved = "0";
  let LastMsgSend = "";
  let TotalSendMsg = "0";
  
  if (allMessagesContactsDetails_Redux.length > 0) {
    ToBeApproved = allMessagesContactsDetails_Redux.filter(
      (item) =>
        item["To be Approved"] === "Yes" &&
        item["Approved"] === "No" &&
        item["Date Approved"] == null
    ).length;

    allMessagesContactsDetails_Redux.forEach((item) => {
      if (item.Issued) {
        const formattedDate = moment(item.Issued).format("DD/MM/YYYY");
        LastMsgSend = formattedDate;
      }
    });

    TotalSendMsg = allMessagesContactsDetails_Redux.length;
  }

  const [value, setValue] = React.useState("1");

  const [contactDetails, setContactDetails] = useState([]);
  const dispatch = useDispatch();
  // dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selected, setSelected] = React.useState(true);
  // AML check modal
  const [isAMLChkOpen, setisAMLChkOpen] = React.useState(false);
  const [isPortalUser, setIsPortalUser] = useState(false);
  // const [issetCountry, setIssetCountry] = useState("");
  const [isInactive, setIsInactive] = useState(false);
  // const [isVerfy, setIsVerfy] = useState(false);
  // const [TotalSendMsg, setTotalSendMsg] = useState(0);
  const [TotalRecieveMsg, setTotalRecieveMsg] = useState(0);
  // const [LastMsgSend, setLastMsgSend] = useState("");
  // const [ToBeApproved, setToBeApproved] = useState(0);
  const [open5, setOpen5] = React.useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [amlDetails, setAmlDetails] = useState({
    bankAccNo: "",
    bankSrNo: "",
    drivingLicNo: "",
    NiNumber: "",
    passportNo: "",
  });

  const [currentDate, setCurrentDate] = useState(""); // Initialize with the current date in "dd/mm/yyyy" format

  const [verificationModal, setVerificationModalOpen] = React.useState(false);

  const [isViewerModalOpen, setIsViewerModalOpen] = useState(false);

  const clientWebUrl = "https://docusms.uk/dswebclientmanager.asmx/";

  let webClientCLS = new CommanCLS(clientWebUrl, agrno, Email, password);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // SendPortalMessage();
  };

  const SupplierContact = () => {
    let obj = {
      agrno: agrno,
      Email: Email,
      password: password,
      ClientID: originatorNo ? originatorNo : "",
      ContactEmail: contactDetails[0]["E-Mail"],
    };
    try {
      //   Cls.SupplierContact(obj, (sts, data) => {
      //     if (sts) {
      //       if (data) {
      //         console.log("SupplierContact", data);
      //         if (data == "Success") {
      //           toast.error("Contact deleted Successfully !");
      //           setTimeout(() => {
      //             navigate("/dashboard/Connections");
      //           }, 1000);
      //         } else {
      //           toast.error("Deleting this contact is not allowed because it has attached documents. Please remove the attached documents before proceeding with the deletion.");
      //         }
      //         setAnchorEl(null);
      //       }
      //     }
      //   });
    } catch (err) {
      console.log("Error while calling Json_GetCRMContactUDFValues", err);
    }
  };

  const handleDelete = () => {
    // Clsprect.ConfirmMessage("Are you sure you want to delete this contact ? ", function (res) {
    //   if (res) {
    //     SupplierContact();
    //   }
    // })
    let body = {
      ClientID: originatorNo,
      ContactEmail: emailAddress,
    };
    dispatch(
      ad_Json_deleteSupplierContact_Action(body, (res) => {
        if (res?.d) {
          toast.success(res?.d);
        }
        navigate("/user/contact/contacts");
      })
    );
  };

  const handleChangeBlock = () => {
    setOpen5(true);
  };
  const handleClickOpen = () => {
    setAmlDetails({
      bankAccNo: "",
      bankSrNo: "",
      drivingLicNo: "",
      NiNumber: "",
      passportNo: "",
    });
    setisAMLChkOpen(true);
  };

  const PortalUserAccountCreated_Json = async () => {
    if (contactData && contactData.length > 0) {
      if (contactData[0]["E-Mail"].includes("@NoEmail")) {
        toast.error("Please use a valid email address");
      } else if (contactData[0].Active == "No") {
        toast.error("Contact In-Active");
      } else {
        let body = {
          emailAddress: contactData[0]["E-Mail"],
          ccode: contactData[0]["OriginatorNo"],
          clientName:
            contactData[0]["First Name"] + " " + contactData[0]["Last Name"],
        };
        try {
          const res = await dispatch(portalUserAccountCreated_Json(body));
          if (res) {
            dispatch(
              getAllContactsByClientID(
                originatorNo,
                paramFolderId ? paramFolderId : localObj.FolderId
              )
            );
          }
        } catch (err) {
          console.log("Error while calling PortalUserAccountCreated_Json", err);
        }
      }
    }
  };

  const PortalUserAccountUpdated_Json = () => {
    setOpen5(false);
    setSelected(!selected);
    let obj = {
      IssueReminders: false,
      ExcludeMessageLink: true,
      KeepSignedIn: true,
      AllowUpload: true,
      ChangeProfile: true,
      LoggedIn: false,
      Blocked: selected,
      emailAddress: contactData[0]["E-Mail"] ? contactData[0]["E-Mail"] : "",
    };
    dispatch(portalUserAccountUpdated_Json(obj));
    return;
  };

  const [open51, setOpen51] = React.useState(false);

  const handleClickOpen51 = () => {
    setOpen51(true);
  };

  const handleClose51 = () => {
    setOpen51(false);
  };

  useEffect(() => {
    dispatch(
      getAllContactsByClientID(
        originatorNo,
        paramFolderId ? paramFolderId : localObj.FolderId || '15'
      )
    );
    dispatch(
      getCRMContactUDFValues({
        ProjectId: paramFolderId ? paramFolderId : localObj.FolderId || '15',
        ClientId: originatorNo,
        ContactId: contactNo,
      })
    );
    // dispatch(
    //   getAllSentMessages({
    //     emailAddress: emailAddress,
    //     ccode: originatorNo,
    //     folder: localObj.FolderId,
    //   })
    // );
  }, []);

  useMemo(() => {
    if (contactData && contactData.length > 0 && contactData[0]["E-Mail"]) {
      if (isContactRequestDocCountLoading)
        dispatch(
          RequestStatusTotalCountByContact_Json(contactData[0]["E-Mail"])
        );
      if (isMessageReadUnreadCountDetailsLoading)
        dispatch(
          GetPortalInformationTotalByContact_Json(contactData[0]["E-Mail"])
        );
    }
  }, [contactData]);

  const ClickViewPortal = () => {
    let fname = `contactData[0]["First Name"] + " " + ${contactData[0]["Last Name"]}`;
    userBodyDAtaa && contactData[0]
      ? window.open(
          `https://portal.docusoftweb.com/UserSettings.aspx?accid=${
            userBodyDAtaa?.accid
          }&email=${userBodyDAtaa?.email}&check=${
            userBodyDAtaa?.password
          }&host=http://www.mydocusoft.com&practice=${localStorage.getItem(
            "companyName"
          )}&newclient=${contactData[0]["OriginatorNo"]}&newemail=${
            contactData[0]["E-Mail"]
          }&clientname=${
            contactData[0]["Company Name"]
          }&contactname=${fname}&option=view`,
          "_blank"
        )
      : console.log("Missing required data for opening the link.");
  };

  const SendPortalMessage = () => {
    dispatch(setNewRequest_Redux(true));
    dispatch(setNewMessage_Redux(false));
    sessionStorage.setItem("contactData", JSON.stringify(contactData));

    userBodyDAtaa && contactData[0]
      ? navigate(
          `/user/create-new-request?folder=${contactData[0]?.Folder}&refrence=${contactData[0]["Company Name"]} (${contactData[0]?.OriginatorNo})&user=${contactData[0]["E-Mail"]}&OriginatorNo=${contactData[0]?.OriginatorNo}`,
          "_blank"
        )
      : console.log("Missing required data for opening the link.");
  };

  return (
    <Box className="container-fluid p-0 mt-2">
      <Box className="d-flex align-items-center justify-content-between flex-wrap">
        <Box className="d-flex flex-wrap align-items-center">
          <ArrowBackIosIcon
            className="mb-2 pointer"
            onClick={() => {
              navigate("/user/contact/contacts");
              dispatch(setAllContactsByClientID([]));
            }}
          />

          <h2 className="title me-3 mb-2">
            {contactData && contactData.length > 0
              ? contactData[0]["Company Name"]
              : "Loading..."}
          </h2>
        </Box>

        <Box className="d-flex align-items-center  flex-wrap">
          <Button
            className="btn-blue me-2 mb-1"
            size="small"
            startIcon={<BorderColorIcon />}
            onClick={() => {
              // handleClickOpen51();
              dispatch(EditContactOpenMdalReducer(true));
              // handleClose51()
            }}
          >
            Edit Contacts
          </Button>

          <Button
            className="btn-blue me-2 mb-1"
            size="small"
            startIcon={<GroupAddIcon />}
            onClick={() =>
              navigate(`/user/client-details?OrgNo=${originatorNo}`)
            }
          >
            Client Card
          </Button>

          <Button
            className="btn-blue me-2 mb-1"
            onClick={handleClickOpen}
            size="small"
            startIcon={<FactCheckIcon />}
          >
            AML Check
          </Button>

          <Button
            className="btn-blue me-2 mb-1"
            onClick={setVerificationModalOpen}
            size="small"
            startIcon={<FactCheckIcon />}
          >
            Verification
          </Button>

          <div>
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="min-width-auto mb-1"
            >
              <MoreVertIcon />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="custom-dropdown"
            >
              {/* <MenuItem className="ps-1" onClick={handleClose}>
                <ListItemIcon>
                  <MarkEmailReadIcon fontSize="small" />
                </ListItemIcon>
                Send Email
              </MenuItem> */}
              <MenuItem className="ps-1" onClick={SendPortalMessage
              }>
                <ListItemIcon>
                  <MarkAsUnreadIcon fontSize="small" />
                </ListItemIcon>
                Send Portal Messages
              </MenuItem>
              <MenuItem className="ps-1" onClick={handleDelete}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                Delete Contact
              </MenuItem>
            </Menu>
          </div>
        </Box>
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }} className="mt-2">
        <TabContext value={value}>
          <Box className="d-none">
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="custom-tabs"
            >
              <Tab label="General" value="1" />
              <Tab label="Address" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className="p-0">
            <Box className="general-tab">
              <Box className="row">
                <Box className="col-xl-4 col-lg-4 col-md-12 d-flex">
                  {contactData && contactData.length > 0 ? (
                    contactData.map((item, key) => {
                        
                      return (
                        <Box className="white-box w-100" key={key}>
                          <Box className="d-flex align-items-center">
                            <Box className="relative m-0 me-4">
                              <Box className="client-img">
                                <img
                                  src={item.imgPath ? item.imgPath : user}
                                  alt=""
                                />
                              </Box>

                              <Tooltip
                                title={
                                  contactData[0].Country
                                    ? contactData[0].Country
                                    : ""
                                }
                                arrow
                              >
                                {issetCountry ? (
                                  <Box className="country-flage">
                                    <img
                                      srcSet={`https://flagcdn.com/w40/${issetCountry.code.toLowerCase()}.png 2x`}
                                      src={`https://flagcdn.com/w20/${issetCountry.code.toLowerCase()}.png`}
                                      className=""
                                      alt=""
                                    />
                                  </Box>
                                ) : (
                                  <Box className="country-flage">
                                    <img src={""} className="" />
                                  </Box>
                                )}
                              </Tooltip>
                              {isVerfy && (
                                <VerifiedIcon className="user-register" />
                              )}
                            </Box>
                            <Box className="clearfix">
                              <Typography
                                variant="h5"
                                className="mb-1 bold d-flex align-items-center"
                                gutterBottom
                              >
                                {contactData &&
                                contactData[0].Active === "No" ? (
                                  <CircleIcon className="text-danger me-1 font-16" />
                                ) : (
                                  <CircleIcon className="text-success me-1 font-16" />
                                )}{" "}
                                {item["First Name"]
                                  ? item["First Name"]
                                  : "Not Available"}{" "}
                                {item["Last Name"]
                                  ? item["Last Name"]
                                  : "Not Available"}
                              </Typography>

                              <Typography
                                variant="body1"
                                className="mb-0 "
                                gutterBottom
                              >
                                <span className="bold">Role:</span>{" "}
                                {item.Role ? item.Role : "Not Available"}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="d-flex flex-wrap contact-availability mb-2 gap-3">
                            <Box>
                              <Button
                                variant="contained"
                                className="btn-blue "
                                onClick={SendPortalMessage}
                              >
                                Send Portal Message
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                variant="contained"
                                className="btn-blue "
                                onClick={ClickViewPortal}
                              >
                                View Portal
                              </Button>
                            </Box>

                            {item["Portal User"] ? (
                              <>
                                <Box className="contact-availability-box">
                                  {item["Portal User"] ? (
                                    <CheckCircleIcon />
                                  ) : (
                                    <CancelIcon />
                                  )}
                                  <Typography
                                    variant="h5"
                                    className="mb-0"
                                    gutterBottom
                                  >
                                    Portal User
                                  </Typography>
                                </Box>
                              </>
                            ) : (
                              <>
                                <>
                                  <Box className="contact-availability-box inactive">
                                    <CancelIcon />
                                    <Typography
                                      variant="h5"
                                      className="mb-0"
                                      gutterBottom
                                    >
                                      Not Portal User
                                    </Typography>
                                  </Box>
                                </>
                              </>
                            )}
                          </Box>

                          <Box className="card-box d-flex mt-2">
                            <FmdGoodIcon className="me-2 text-danger" />

                            <Box className="">
                              <p className="font-12 bold mb-0 text-black">
                                Address
                              </p>
                              <p className="mb-0 font-12 text-gray">
                                {item["Address 1"]
                                  ? item["Address 1"]
                                  : "Not Available"}
                              </p>
                            </Box>
                          </Box>

                          <Box className="card-box d-flex mt-2">
                            <EmailIcon className="me-2 text-danger" />

                            <Box className="">
                              <p className="font-12 bold mb-0 text-black">
                                Email
                              </p>
                              <p className="mb-0 font-12 text-gray">
                                {item["E-Mail"]
                                  ? item["E-Mail"]
                                  : "Not Available"}
                              </p>
                            </Box>
                          </Box>

                          <Box className="card-box d-flex mt-2">
                            <LocalPhoneIcon className="me-2 text-danger" />

                            <Box className="">
                              <p className="font-12 bold mb-0 text-black">
                                Phone
                              </p>
                              <p className="mb-0 font-12 text-gray">
                                {item.Tel ? item.Tel : "00000 00000"},{" "}
                                {item.Mobile ? item.Mobile : "00000 00000"}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Box className="white-box w-100">
                      <Box className="d-flex align-items-center">
                        <Box className="relative m-0 me-4">
                          <Box className="client-img">
                            <img src={user} />
                          </Box>

                          <Tooltip title="UK" arrow>
                            <Box className="country-flage">
                              <img src={""} className="" />
                            </Box>
                          </Tooltip>

                          <VerifiedIcon className="user-register" />
                        </Box>
                        <Box className="clearfix">
                          <Typography
                            variant="h5"
                            className="mb-1 bold d-flex align-items-center"
                            gutterBottom
                          >
                            <CircleIcon className="text-success me-1 font-16" />{" "}
                            {"Loading..."}
                          </Typography>

                          <Typography
                            variant="body1"
                            className="mb-0 "
                            gutterBottom
                          >
                            <span className="bold">Role:</span> {"Loading..."}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="d-flex flex-wrap contact-availability mb-2">
                        <Box
                          className={
                            contactData &&
                            contactData.length > 0 &&
                            contactData[0]["Main Contact"]
                              ? "contact-availability-box active"
                              : "contact-availability-box inactive"
                          }
                        >
                          {contactData &&
                          contactData.length > 0 &&
                          contactData[0]["Main Contact"] ? (
                            <CheckCircleIcon />
                          ) : (
                            <CancelIcon />
                          )}
                          <Typography
                            variant="h5"
                            className="mb-0 "
                            gutterBottom
                          >
                            Main Contact
                          </Typography>
                        </Box>

                        <Box
                          className={
                            contactData &&
                            contactData.length > 0 &&
                            contactData[0]["Portal User"]
                              ? "contact-availability-box active"
                              : "contact-availability-box inactive"
                          }
                        >
                          {contactData &&
                          contactData.length > 0 &&
                          contactData[0]["Portal User"] ? (
                            <CheckCircleIcon />
                          ) : (
                            <CancelIcon />
                          )}
                          <Typography
                            variant="h5"
                            className="mb-0 "
                            gutterBottom
                          >
                            Portal User
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="card-box d-flex mt-2">
                        <FmdGoodIcon className="me-2 text-black" />
                        <Box className="">
                          <p className="font-16 lato-semibold mb-1 text-black">
                            Address
                          </p>
                          <p className="mb-0 font-12 text-gray">
                            {"Loading..."}
                          </p>
                        </Box>
                      </Box>

                      <Box className="card-box d-flex mt-2">
                        <LocalPhoneIcon className="me-2 text-black" />
                        <Box className="">
                          <p className="font-16 lato-semibold mb-1 text-black">
                            Phone
                          </p>
                          <p className="mb-0 font-12 text-gray">
                            {"Loading..."}
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* cold end */}

                <Box className="col-xl-8 col-lg-8 col-md-12 d-flex">
                  <Box className="white-box w-100">
                    <Box className="contact-detail-row mb-4 flex-wrap flex-sm-nowrap gap-2 relative">
                      {!(
                        contactData &&
                        contactData.length > 0 &&
                        contactData[0]["Portal User"]
                      ) && (
                        <Box className="subscribe-box">
                          <Typography variant="h4" className="mb-3 font-20">
                            This contact does not have a portal account. Please
                            create one to view the stats.
                          </Typography>
                          <Button
                            variant="outlined"
                            className="btn-blue"
                            onClick={PortalUserAccountCreated_Json}
                          >
                            Create Portal Account
                          </Button>
                        </Box>
                      )}
                      <Box className="contact-detail-box">
                        <HowToRegIcon />
                        <Typography variant="h4" className="mb-0 " gutterBottom>
                          Portal Status
                          {/* <Switch {...label} defaultChecked size="small" /> */}
                        </Typography>
                        <Typography
                          variant="Body2"
                          className="mb-0 "
                          gutterBottom
                        >
                          {selected ? "Unblock" : "Block"}
                          {/* Unblock */}
                        </Typography>

                        <Box className="mt-2">
                          <ToggleButton
                            className="toggle-btn"
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeBlock();
                            }}
                            size="small"
                          >
                            {selected === true ? "Block" : "Unblock"}
                            {/* Block */}
                          </ToggleButton>
                        </Box>
                      </Box>

                      <Box className="contact-detail-box">
                        <RequestQuoteIcon />
                        <Typography variant="h4" className="mb-0 " gutterBottom>
                          Messages Pending Approval
                        </Typography>

                        <Typography
                          variant="Body1"
                          className="mb-0 "
                          gutterBottom
                        >
                          {messageReadUnreadCountDetails.length > 0
                            ? messageReadUnreadCountDetails[0].Pending
                            : "0"}
                        </Typography>
                      </Box>

                      <Box className="contact-detail-box">
                        <FactCheckIcon />
                        <Typography variant="h4" className="mb-0 " gutterBottom>
                          Total Messages Sent
                        </Typography>

                        <Typography
                          variant="Body1"
                          className="mb-0 "
                          gutterBottom
                        >
                          {TotalSendMsg}
                        </Typography>
                      </Box>

                      <Box className="contact-detail-box">
                        <FolderSharedIcon />
                        <Typography variant="h4" className="mb-0 " gutterBottom>
                          Total Messages Received
                        </Typography>

                        <Typography
                          variant="Body1"
                          className="mb-0 "
                          gutterBottom
                        >
                          {messageReadUnreadCountDetails.length > 0
                            ? messageReadUnreadCountDetails[0].TotalRecords
                            : "0"}
                        </Typography>
                      </Box>

                      <Box
                        className="contact-detail-box me-2"
                        onClick={() =>
                          navigate(
                            `/user/all-requests?email=${contactData[0]["E-Mail"]}`
                          )
                        }
                      >
                        <CalendarMonthIcon />
                        <Typography variant="h4" className="mb-0 " gutterBottom>
                          Number of Document Request
                        </Typography>

                        <Typography
                          variant="Body1"
                          className="mb-0 "
                          gutterBottom
                        >
                          {contactRequestDocCount.length}
                        </Typography>
                      </Box>
                    </Box>

                    {/* test */}
                    <Box className="card-box d-flex">
                      <EditNoteIcon className="me-2 text-danger" />
                      <Box className="">
                        <p className="font-16 bold mb-1 text-black">Notes</p>
                        <p className="mb-0 font-12 text-gray">
                          {contactData && contactData.length > 0
                            ? contactData[0]["Note"]
                            : ""}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  {/* white box end */}
                </Box>

                {/* cold end */}
              </Box>
              {/* row end */}
            </Box>
            {/* white box end */}

            <Grid container spacing={3}>
              <Grid item lg={4} className="mb-3">
                <Box className="">
                  <Box className="main-accordian">{/* <ContactUDF /> */}</Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          {/* tab end */}
        </TabContext>
      </Box>

      {selected === true ? (
        <Dialog
          open={open5}
          onClose={handleClose5}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-modal custom-modal-sm"
          // sx={{ maxWidth: '550px' }}
        >
          <Box className="d-flex align-items-center justify-content-between modal-head">
            <div>
              <Typography variant="h4" className="font-18 bold mb-0 text-black">
                Confirmation
              </Typography>
            </div>
            <Button onClick={handleClose5} autoFocus sx={{ minWidth: 30 }}>
              <span className="material-symbols-outlined text-black">
                {/* No */}
              </span>
            </Button>
          </Box>

          <DialogContent className="">
            <Typography variant="h4" className="font-18 bold mb-0 text-black">
              Do You want to block portal access for this portal user ?
            </Typography>

            <hr />

            <DialogActions>
              <Button
                autoFocus
                variant="contained"
                className="btn-blue-2"
                onClick={PortalUserAccountUpdated_Json}
              >
                Yes
              </Button>
              <Button
                autoFocus
                variant="outlined"
                className="btn btn-red"
                onClick={handleClose5}
              >
                No
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open5}
          onClose={handleClose5}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-modal custom-modal-sm"
        >
          <Box className="d-flex align-items-center justify-content-between modal-head">
            <div>
              <Typography variant="h4" className="font-18 bold mb-0 text-black">
                Confirmation
              </Typography>
            </div>
            <Button onClick={handleClose5} autoFocus sx={{ minWidth: 30 }}>
              <span className="material-symbols-outlined text-black">
                {/* No */}
              </span>
            </Button>
          </Box>

          <DialogContent className="">
            <Typography variant="h4" className="font-18 bold mb-0 text-black">
              Do You want to Unblock portal access for this portal user ?
            </Typography>
          </DialogContent>

          <hr />

          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              className="btn-blue-2"
              onClick={PortalUserAccountUpdated_Json}
            >
              Yes
            </Button>
            <Button
              autoFocus
              variant="outlined"
              className="btn-red"
              onClick={handleClose5}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Edit Contact popup */}
      <Dialog
        open={open51}
        onClose={handleClose51}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal full-modal"
      >
        {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}

        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="dropdown-box">
            <Typography variant="h4" className="font-18 bold text-black mb-0">
              Edit Contact asasasasas
            </Typography>
          </Box>

          {/*  */}
          <Button onClick={handleClose51}>
            <span className="material-symbols-outlined text-black">cancel</span>
          </Button>
        </Box>

        {/* <hr /> */}

        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            {/* <h3>Add Contacts here</h3> */}
            <AddContacts
              handleClose51={handleClose51}
              contactDetails={contactData}
            />
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose5}>Disagree</Button>
                    <Button onClick={handleClose5} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
      </Dialog>

      <Dialog
        open={isViewerModalOpen}
        onClose={() => setIsViewerModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="dropdown-box">
            <Typography variant="h4" className="font-18 text-black">
              Driving License Verification
            </Typography>
          </Box>

          <Button
            onClick={() => setIsViewerModalOpen(false)}
            autoFocus
            sx={{ minWidth: 30 }}
            className="p-0"
          >
            <span className="material-symbols-outlined text-black">cancel</span>
          </Button>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h3>Test PDF here</h3>
            {/* <TestPDF /> */}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* viewer modal end */}
    </Box>
  );
}

export default ContactDetails;

// select title
const selectMR = [
  { label: "Mr" },
  { label: "Mrs" },
  { label: "Miss" },
  { label: "Ms" },
  { label: "Dr" },
];

// rfce
