import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import FlagIcon from "@mui/icons-material/Flag";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";

import PersonIcon from "@mui/icons-material/Person";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientContactDetailasProfile,
  updateClientContactDetailasProfile,
  UpdatedPortalContactVerification_Json,
} from "../redux/ak_api_helper";
import { toast } from "react-toastify";
import * as Yup from "yup";
import DocuTextField from "../cmp-utils/DocuTextField";
import EditUDFClientCard from "./EditUDFClientCard";

export const ContactViewEdit = (profile) => {

  const onBoardingDetailsByClientId = sessionStorage.getItem("onBoardingDetailsByClientId") ? JSON.parse(sessionStorage.getItem("onBoardingDetailsByClientId")) : [] ;

  const getClientContactDetailas = useSelector(
    (state) =>
      state.ak.getClientContactDetailasProfile
        .getClientContactDetailasProfile || ""
  );

  // console.log('testing by jay data for Contact Detailas' , getClientContactDetailas)

  const { GetFolders, udf1 } = useSelector((state) => state.ak);

  const dispatch = useDispatch();

  const [editModal, setEditModal] = useState(true);
  const [isAmlCheck, setIsAmlCheck] = useState(true);
  const [editButtonShow, setEditButtonShow] = useState(true);  
  const [buttonText , setButtonText] = useState('Update')

  const [amlData, setAmlData] = useState({
    "Bank Account Number": "",
    "Passport Number": "",
    "Bank SR Code": "",
    "Personal UTR Number": "",
    "Driving License Number": "",
    "Photo ID Verified": "Verified",
    "NI Number": "",
    "Address Verified": "Not Verified",
  });
  const [amlDataPreviouse, setAmlDataPreviouse] = useState({
    "Bank Account Number": "",
    "Passport Number": "",
    "Bank SR Code": "",
    "Personal UTR Number": "",
    "Driving License Number": "",
    "Photo ID Verified": "Verified",
    "NI Number": "",
    "Address Verified": "Not Verified",
  });
  const [formData, setFormData] = useState({
    Active: "",
    Add1: "",
    Add2: "",
    Add3: "",
    "Assigned Manager": "",
    "Company Name": "",
    ContactNo: "",
    Country: "",
    State: "",
    "E-Mail": "",
    "First Name": "",
    Folder: "",
    Greeting: "",
    "Last Name": "",
    "Main Contact": "",
    ManagerName: "",
    Mobile: "",
    OriginatorNo: "",
    OtherEmail: "",
    PostCode: "",
    Role: "",
    Tel: "",
    Title: "",
    Town: "",
  });

  const AMLValidation = Yup.object({
    "Bank Account Number": Yup.string().required(
      "Bank Account Number is required"
    ),
    "Passport Number": Yup.string().required("Passport Number is required"),
    "Bank SR Code": Yup.string().required("Bank SR Code is required"),
    "Driving License Number": Yup.string().required(
      "Driving License Number is required"
    ),
    "NI Number": Yup.string().required("NI Number is required"),
  });

  const hanldeEditClick = () => {
    setEditButtonShow(!editButtonShow);
    setEditModal(!editModal);
    setButtonText('Update')
  };

  const handleChangeAml = (e) => {
    const { name, value } = e.target;
    setAmlData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateContactDetailas = () => {
    let newObject = { ...formData, ...obj1 };
    setButtonText('Update')
    try {
      dispatch(updateClientContactDetailasProfile(newObject)).then(() =>
        dispatch(GetClientContactDetailasProfile(obj1))
      );
    } catch (error) { }
    setEditButtonShow(!editButtonShow);
    setEditModal(!editModal);
  };


  const obj1 = {
    accId: localStorage.getItem("accIdClient")
      ? localStorage.getItem("accIdClient")
      : "",
    username: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
    sPassword: localStorage.getItem("passwordClient")
      ? localStorage.getItem("passwordClient")
      : "",
  };

  useEffect(() => {
    dispatch(GetClientContactDetailasProfile(obj1))
  }, [])



  async function updateAmlCheck() {
    const bodyData = { ...obj1 };
    bodyData.Accid = obj1.accId;
    bodyData.Contactemail = "aakashr.diploma2022@ssism.org";
    bodyData.bankAccount = amlData?.["Bank Account Number"];
    bodyData.bankSrCode = amlData?.["Bank SR Code"];
    bodyData.driveLic = amlData?.["Driving License Number"];
    bodyData.niNumber = amlData?.["NI Number"];
    bodyData.passport = amlData?.["Passport Number"];
    delete bodyData.accId;
    const res = await UpdatedPortalContactVerification_Json(bodyData);
    if (res?.data?.d) {
      setIsAmlCheck(true);
      toast.success("AML details updated");
      setAmlDataPreviouse({ ...amlData });
    } else {
      toast.error("Something went wrong !");
    }
  }

  useEffect(() => {
    setFormData({
      ManagerName:
        (getClientContactDetailas &&
          getClientContactDetailas[0].ManagerName) ||
        "",
      Add1:
        (getClientContactDetailas &&
          getClientContactDetailas[0]["Add1"]) ||
        "",
      Add2:
        (getClientContactDetailas &&
          getClientContactDetailas[0]["Add2"]) ||
        "",
      Add3:
        (getClientContactDetailas &&
          getClientContactDetailas[0]["Add3"]) ||
        "",
      Country:
        (getClientContactDetailas &&
          getClientContactDetailas[0].Country) ||
        "",
      State:
        (getClientContactDetailas &&
          getClientContactDetailas[0].State) ||
        "",
      Town:
        (getClientContactDetailas &&
          getClientContactDetailas[0].Town) ||
        "",
      PostCode:
        (getClientContactDetailas &&
          getClientContactDetailas[0].PostCode) ||
        "",
    });




    if (profile.profile == 'ProfileSetupPageOnbording' && 
      getClientContactDetailas && 
      getClientContactDetailas.length > 0 && 
      ['Add1', 'Add3', 'Country', 'State', 'Town', 'PostCode'].every(
          key => getClientContactDetailas[0][key] === '' 
      )
  ) {
      setEditModal(false);
      setButtonText('Submit')
  }

     
  }, [getClientContactDetailas]);


  return (
    <Grid >
      {editModal ? (
        <Box className="white-box">
          <div className=" d-flex justify-content-between ">
            <h2 className="title font-18">Contact Details</h2>
            <IconButton
              onClick={() => {
                hanldeEditClick();
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
          {/* <hr /> */}

          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    First Name
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {(onBoardingDetailsByClientId &&
                      onBoardingDetailsByClientId[0]?.["FirstName"]) ||
                      ""}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    Address Line 1
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {(getClientContactDetailas &&
                      getClientContactDetailas[0]?.Add1) ||
                      ""}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    Address Line 3
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {getClientContactDetailas &&
                      getClientContactDetailas[0]?.Add3}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <FlagIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    State
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {getClientContactDetailas &&
                      getClientContactDetailas[0]?.State}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex">
                <LocalPostOfficeIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Box>
                    <Typography className="lato-medium font-12">
                      Post Code
                    </Typography>
                    <Typography className="font-12 text-gray">
                      {getClientContactDetailas &&
                        getClientContactDetailas[0]?.PostCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    Last Name
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {onBoardingDetailsByClientId &&
                      onBoardingDetailsByClientId[0]?.["LastName"]}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationOnIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    Address Line 2
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {getClientContactDetailas &&
                      getClientContactDetailas[0]?.Add2}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <LocationCityIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    Town
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {getClientContactDetailas &&
                      getClientContactDetailas[0]?.Town}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <FlagIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    Country
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {getClientContactDetailas &&
                      getClientContactDetailas[0]?.Country}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-2">
                <PersonIcon className="font-20 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium font-12">
                    ManagerName
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {(getClientContactDetailas &&
                      getClientContactDetailas[0]?.ManagerName) ||
                      ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="white-box">
          <div className="d-flex justify-content-between">
            <h2 className="title font-18">Contact Details </h2>
            <div>
              <IconButton
                onClick={() => {
                  hanldeEditClick();
                }}
              >
                <CloseIcon />
              </IconButton>

              <Button
                id="basic-button"
                aria-haspopup="true"
                className="btn-blue "
                onClick={() => {
                  updateContactDetailas();
                }}
                startIcon={<DoneAllIcon />}
              >
              {buttonText}
              </Button>
            </div>
          </div>
          {/* <hr /> */}

          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>

              <DocuTextField
                // fieldLabel={'Email Address'}
                size={"small"}
                fieldLabel={"Address Line 1"}
                fieldValue={formData.Add1}
                id={"Add1"}
                fieldName={"Add1"}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"Address Line 2"}
                fieldValue={formData.Add2}
                id={"Add2"}
                fieldName={"Add2"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"Address Line 3"}
                fieldValue={formData.Add3}
                id={"Add3"}
                fieldName={"Add3"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"Town"}
                fieldValue={formData.Town}
                id={"Town"}
                fieldName={"Town"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"State"}
                fieldValue={formData.State}
                id={"State"}
                fieldName={"State"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"Country"}
                fieldValue={formData.Country}
                id={"Country"}
                fieldName={"Country"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"Postcode"}
                fieldValue={formData.PostCode}
                id={"PostCode"}
                fieldName={"PostCode"}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} cl={4} xxl={4}>
              <DocuTextField
                size={"small"}
                fieldLabel={"ManagerName"}
                fieldValue={formData.ManagerName}
                id={"ManagerName"}
                fieldName={"ManagerName"}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      )}

    </Grid>
  );
};
