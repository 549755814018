import moment from 'moment';
import { useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Tooltip } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';

function CustomDateRangePicker({ onChange, strText, tooltipTlt, setDateObj }) {
    const [state, setState] = useState({
        start: moment(),
        end: moment(),
    });
    const [isSelect,setIsSelect] = useState(false);
  
    const { start, end } = state;

    const handleCallback = (start, end) => {
        if(end._i==="clear"){
            setIsSelect(false);
            setDateObj({ startDate: "clear", endDate: "clear" });
            return;
        }
        setIsSelect(true);
        setState({ start, end });
        onChange({ startDate: start.format('DD-MM-YYYY'), endDate: end.format('DD-MM-YYYY') });
        setDateObj({ startDate: start._d, endDate: end._d });
    };

    const label = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY');

    // console.log('test label 123 ', label === `${moment().format('DD-MM-YYYY')} - ${moment().format('DD-MM-YYYY')}`);
    return (
        <Box className='date-unerline'>
            <DateRangePicker
             className="custom-dropdown "
                initialSettings={{
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                    ranges: {
                        "Clear Filter": [
                            moment().toDate(),"clear"
                        ],
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                            moment().subtract(1, 'days').toDate(),
                            moment().subtract(1, 'days').toDate(),
                        ],
                        'Last 7 Days': [
                            moment().subtract(6, 'days').toDate(),
                            moment().toDate(),
                        ],
                        'Last 30 Days': [
                            moment().subtract(29, 'days').toDate(),
                            moment().toDate(),
                        ],
                        'This Month': [
                            moment().startOf('month').toDate(),
                            moment().endOf('month').toDate(),
                        ],
                        'Last Month': [
                            moment().subtract(1, 'month').startOf('month').toDate(),
                            moment().subtract(1, 'month').endOf('month').toDate(),
                        ],
                    },
                }}
                onCallback={handleCallback}
            >
                <div className='pointer me-2 d-flex align-items-center' id="reportrange">
                    <CalendarMonthIcon className='me-2 text-red' />
                    <Tooltip title={tooltipTlt} arrow>
                    <span className='font-12'>{isSelect?label: strText}</span> <i className="fa fa-caret-down"></i>
                    </Tooltip>
                </div>
            </DateRangePicker>
        </Box>
    );
}

export default CustomDateRangePicker;
