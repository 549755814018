import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { Box, Button, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../../components/CustomLoader";
import DataGridComposeMessage from "../../../components/DataGridComposeMessage";
import NoData from "../../../components/NoData";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import { OpenPendingMessageModalReducer } from "../../../redux/adityaReducer";
import { GetAllSentMessages_Json_Redux } from "../../../redux/api_helper";
import AdvanceFilter from "./AdvanceFilter";
import PortalMessagesFilters from "./PortalMessagesFilters";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomePortalMessageTable from "../../../components/CustomePortalMessageTable";

const TableHeader = [
  {
    index: 1,
    tabHeader: "Type",
  },
  {
    index: 2,
    tabHeader: "Subject",
  },
  {
    index: 3,
    tabHeader: "Recipient",
  },
  {
    index: 4,
    tabHeader: "Issued On",
  },
  {
    index: 5,
    tabHeader: "Due Date",
  },
  {
    index: 6,
    tabHeader: "Status",
  },
  {
    index: 7,
    tabHeader: "Action",
  },
];

function SentMessages({ value }) {
  const dispatch = useDispatch();
  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const { OpenPendingMessageModal } = useSelector(({ aditya }) => aditya);
  const gridRef = useRef(null);
  
  const { ad_OpenChModal, ad_CompanyHouseData } = useSelector(
    ({ aditya }) => aditya
  );
  // console.log('✌️ad_CompanyHouseData --->', ad_CompanyHouseData);

  const [originalData, setOriginalData] = useState([]); // Holds unfiltered data
  const [filteredData, setFilteredData] = useState([]); // Holds filtered data
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [filterEnable, setFilterEnable] = useState(false);
  const itemsPerPage = 10; // Number of items per page

  const objReq = useLocalStorage("accid");

  const sentObj = {    
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
    ccode: "",
    // emailAddress: "",
  };

  useEffect(() => {
    if (getAllSentMessages.length === 0) {
      dispatch(GetAllSentMessages_Json_Redux(sentObj,()=>{}));
    }
  }, [dispatch]);

  const jsonObjectArray = useMemo(() => {
    return getAllSentMessages.map((item) => {
      const approvedDate = item["Date Approved"]
        ? moment(item["Date Approved"]).format("DD/MM/yyyy")
        : "Not available";
      const viewedDate = item.Viewed
        ? moment(item.Viewed).format("DD/MM/yyyy")
        : "Not available";

      let viewStatus = "Pending";
      if (!item.Viewed) viewStatus = "Unread";
      else if (item.Viewed && item.Approved === "Yes") viewStatus = "Approved";
      else if (item.Viewed && item.Approved === "No")
        viewStatus = "Disapproved";

      return {
        Subject: item.Subject || "Not available",
        Recipient: item["Client Name"] || "Not available",
        ApprovalRequested: item["To be Approved"] || "Not available",
        IssuedOn: item.Issued
          ? moment(item.Issued).format("DD/MM/yyyy")
          : "Not available",
        DueDate: item["Due Date"]
          ? moment(item["Due Date"]).format("DD/MM/yyyy")
          : "Not available",
        Status: viewStatus,
        MessageNo: item["Message No"] || "Not available",
        MessageID: item["Message ID"] || "Not available",
        ReferenceName: item["Client Name"] || "Not available",
        History: [
          { date: "Section", customerId: item.Section || "Not available" },
          { date: "Date Approved", customerId: approvedDate },
          { date: "Viewed On", customerId: viewedDate },
          {
            date: "Email ID",
            customerId: item.EMailId || "Not available",
          },
          {
            date: "Sent By",
            customerId: item["Sent/Received By"] || "Not available",
          },
        ],
      };
    });
  }, [getAllSentMessages]);

  useEffect(() => {
    setOriginalData(jsonObjectArray);
    setFilteredData(jsonObjectArray);
  }, [jsonObjectArray]);

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filteredData]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  // paginatedData

  const handleClearFilters = () => {
    if (gridRef.current) {
      gridRef.current.instance.clearFilter(); // Clears all filters
    }
  };

  return (
    <div>
      <Box className="d-flex align-items-center flex-wrap justify-content-between white-box py-0 ps-0 pe-0 mb-0 relative">
        {/* <Box className="mb-2 d-flex align-items-center">

          <AdvanceFilter
            setFilteredData={setFilteredData}
            originalData={originalData}
            setCurrentPage={setCurrentPage}
            tabIndex={value}
          />

          <Button
            onClick={() => dispatch(OpenPendingMessageModalReducer(true))}
            className="btn-blue ms-md-1"
          >
            <ScheduleSendOutlinedIcon />
            Pending Messages
          </Button>

          <Tooltip title="Filter" arrow>
            <Button className="min-width-auto" onClick={() => setFilterEnable(!filterEnable)}>
              <FilterAltIcon className="text-black font-18" />
            </Button>
          </Tooltip>

        </Box>  */}

        <Box className="d-flex flex-wrap search-area mt-3">
          <PortalMessagesFilters filterType={value} />

          <Tooltip title="Pending Messages" arrow>
            <Button
              className="min-width-auto"
              onClick={() => dispatch(OpenPendingMessageModalReducer(true))}
            >
              <ScheduleSendOutlinedIcon className="text-black font-18" />
            </Button>
          </Tooltip>

          <Tooltip title="Filter" arrow>
            <Button
              className="min-width-auto"
              onClick={() => {
                setFilterEnable(!filterEnable);
                handleClearFilters();
              }}
            >
              <FilterAltIcon className="text-black font-18" />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        {getAllSentMessagesLoding ? (
          <CustomLoader />
        ) : paginatedData.length > 0 ? (
          // <CustomePortalMessageTable
          //   TableHeder={[
          //     "Type",
          //     "Subject",
          //     "Recipient",
          //     "Issued On",
          //     "Due Date",
          //     "Status",
          //     "Action",
          //   ]}
          //   TableData={paginatedData} // Pass paginated data
          //   value={value}
          // />
          <DataGridComposeMessage
            TableHeader={TableHeader}
            TableData={filteredData}
            value={value}
            filterEnable={filterEnable}
            gridRef={gridRef}
          />
        ) : (
          <NoData message="No Message here" />
        )}
      </Box>
      {/* <nav aria-label="Page navigation example">
        <Box className="mt-2">
          <Pagination
            count={totalPages}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            className="custom-pagination"
          />
        </Box>
      </nav> */}
    </div>
  );
}

export default SentMessages;
