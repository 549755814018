import ContactPageIcon from "@mui/icons-material/ContactPage";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetAllFolders_Json_Redux } from "../../redux/api_helper";
import { getContactsListByFolder } from "../../redux/dev_chetan_api_helper";
import {
  setContactRequestDocCount,
  setMessageReadUnreadCountDetails,
} from "../../redux/dev_chetan_slice";
import { handleOpenContactForClientModal } from "../../redux/docuSlice";
import { setOpenCompanyModal } from "../../redux/modalSlice";
import { EditContactOpenMdalReducer, Rtk_Save_selectedContact, RtkEditContactModalReducer } from "../../redux/adityaReducer";
import RtkUpdateContactModal from "../../modals/RtkUpdateContactModal";

const pageSizes = [10, 25, 50, 100];

export default function ClientContactGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allContacts, allContactsLoding } = useSelector(
    (state) => state.dev_chetan
  );
  const {selectedContactRtk} = useSelector((state)=>state.aditya)
  const { rtk_getAllContactsPubUser } = useSelector((state) => state.aditya);
  const [isLoding, setIsLoding] = useState(false);
  const [view, setView] = React.useState("list");
  const [activeBtn, setActiveBtn] = useState(2);
  const [searchQuery, setSearchQuery] = useState("");
  const [getFolder, setGetFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [makeAdminModalOpen, setMakeAdminModalOpen] = useState(false);
  console.log(selectedContactRtk, "selectedContactRtk");

  const realContacts =
    rtk_getAllContactsPubUser?.map((contact) => ({
      ...contact,
      displayedFields: {
        CCode: contact.CCode || "",
        Salutation: contact.Salutation || "",
        FirstName: contact.FirstName || "",
        LastName: contact.LastName || "",
        EMailId: contact.EMailId || "",
        Add1: contact.Add1 || "",
        Add2: contact.Add2 || "",
        Add3: contact.Add3 || "",
        PostCode: contact.PostCode || "",
        Town: contact.Town || "",
        Country: contact.Country || "",
        Mobile: contact.Mobile || "",
        Tel: contact.Tel || "",
      },
    })) || [];

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolders(res);
        setSelectedFolder(res[0]);
      })
    );
  }, [dispatch]);

  const getFolderObject = (event, value) => {
    setSelectedFolder(value);
  };

  const filteredContacts = allContacts?.filter((contact) =>
    Object.values(contact).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClick = (clickbutton) => () => {
    setActiveBtn(clickbutton);
  };

  useEffect(() => {
    if (allContacts) {
      setIsLoding(true);
    }
  } , []);

  const [collapsed, setCollapsed] = useState(true);
  const onContentReady = useCallback(
    (e) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const handleRowDoubleClick = (e) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${e.data.CCode}&contactNo=${e.data.ContactNo}&emailAddress=${e.data["EMailId"]}&fromPotalContactsTable=${true}`
    );
    dispatch(setMessageReadUnreadCountDetails([]));
    dispatch(setContactRequestDocCount([]));
  };

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleCardClick = (contact) => {
    navigate(
      `/user/contact/contacts/contact-details?orgNo=${contact.OriginatorNo}&contactNo=${contact.ContactNo}&emailAddress=${contact["E-Mail"]}&folderId=${selectedFolder?.FolderID}`
    );
  };

  React.useEffect(() => {
    if (selectedFolder) {
      dispatch(getContactsListByFolder(selectedFolder));
    }
  }, [selectedFolder]);

  const handleClickOpen = () => {
    dispatch(handleOpenContactForClientModal(true));
  };
  const handleOpenCompanymodal = () => {
    dispatch(setOpenCompanyModal(true));
  };

  // Centralized menu handlers
  const handleMenuOpen = (event, contact) => {
    setAnchorEl(event.currentTarget);
    dispatch(Rtk_Save_selectedContact(contact))
    setSelectedContact(contact);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedContact(null);
  };

  const handleMenuClick = (action) => {
    if (action === "update") {
      dispatch(Rtk_Save_selectedContact(selectedContact));  // Save selected contact in Redux
      dispatch(EditContactOpenMdalReducer(true));  // Open the modal
    }
    handleMenuClose();
  };
  

  const handleMakeAdminClick = () => {
    setAnchorEl(null)
    setMakeAdminModalOpen(true);
    handleMenuClose();
  };

  // Handle Modal Close
  const handleCloseMakeAdminModal = () => {
    setMakeAdminModalOpen(false);
  };

  // Handle Confirm Make Admin
  const handleConfirmMakeAdmin = () => {
    console.log("Make Admin Confirmed for", selectedContact);
    setMakeAdminModalOpen(false);
  };

  const ActionColumn = ({ data }) => (
    <IconButton onClick={(event) => handleMenuOpen(event, data)}>
      <MoreVertIcon />
    </IconButton>
  );

  const ActiveOrAdminUpdation = async(action) =>{
  try { 
    
   const response = ""
  }catch(e){
    console.error("Error in Active or Admin Updation: ", e);
  }
  }

 const  handleUpdateContact = () =>{
  setAnchorEl(null)
  dispatch(RtkEditContactModalReducer(true))
 }

  return (
    <Box mt={3} className="relative">
      <Box className="search-area">
        <Box className="d-flex align-items-center justify-content-end flex-wrap">
          <Box className="search-input me-2">
            <SearchIcon />
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              label="Search"
              size="small"
            />
          </Box>

          <Button
            variant="outlined"
            className="btn-blue mb-0"
            onClick={handleClickOpen}
            startIcon={<ContactPageIcon />}
          >
            Create Contact
          </Button>
        </Box>
      </Box>

      {activeBtn === 2 && (
        <DataGrid
          dataSource={realContacts}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          className="table-grid table-height"
          onRowDblClick={handleRowDoubleClick}
          width="100%"
          columnAutoWidth={true}
        >
          <Column dataField="CCode" caption="Originator No" dataType="string" />
          <Column dataField="Salutation" caption="Salutation" dataType="string" />
          <Column dataField="FirstName" caption="First Name" dataType="string" />
          <Column dataField="LastName" caption="Last Name" dataType="string" />
          <Column dataField="EMailId" caption="E-Mail" dataType="string" />
          <Column dataField="Add1" caption="Address 1" dataType="string" />
          <Column dataField="Add2" caption="Address 2" dataType="string" />
          <Column dataField="Add3" caption="Address 3" dataType="string" />
          <Column dataField="PostCode" caption="Post Code" dataType="string" />
          <Column dataField="Town" caption="Town" dataType="string" />
          <Column dataField="Country" caption="Country" dataType="string" />
          <Column dataField="Mobile" caption="Mobile Number" dataType="string" />
          <Column dataField="Tel" caption="Telephone" dataType="string" />

          <Column caption="Action" cellRender={ActionColumn} />

          <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
          <Paging defaultPageSize={10} />
        </DataGrid>
      )}

      {/* Centralized Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleMakeAdminClick}>Make Admin</MenuItem>
        <MenuItem onClick={() => handleUpdateContact()}>Update Contact</MenuItem>
        <MenuItem onClick={handleMenuClose}>Inactive Contact</MenuItem>
      </Menu>

      <Dialog open={makeAdminModalOpen} onClose={handleCloseMakeAdminModal}>
        <DialogTitle>Make Admin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
            {selectedContactRtk?.FirstName} {selectedContactRtk?.LastName} will be made administrator, 
            and you will no longer be the admin for {selectedContactRtk?.CCode}.
            Do you wish to proceed?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMakeAdminModal} color="secondary">Cancel</Button>
          <Button onClick={handleConfirmMakeAdmin} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
