import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, DialogTitle, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AutoComplete from "../../components/AutoComplete";
import CheckboxesMultiSelectTags from "../../components/CheckBoxesMultiSelectTags";
import {
  GetPortalManagers_Json,
  GetPortalSections_Json,
  MessageUploaded_Json,
  PortalUsersCompanyList_Json,
} from "../../redux/api_helper";
import { setOpenComposeMessageModal } from "../../redux/modalSlice";
import HtmlEditorDX from "../../user/pages/create-request/HtmlEditor";
import { clientBodyaccidData } from "../../utils/RequestsFunction";

const CamposeMessage = React.memo(({ setOpenComposeMessageBox }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [portalUserCC, setPortalUserCC] = React.useState([]);
  const [portalUserTo, setPortalUserTo] = React.useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [CompanyList, setCompanyList] = useState([]);
  const [fileStore, setFileStore] = useState([]);
  const [mainObject, setMainObject] = useState({
    Folder: "",
    Section: "",
    CompanyData: "",
    Client: "",
    FromUser: "",
    CCEmail: [],
    Attachment: [],
    ToEmail: "",
    Subject: "",
    TextEditor: "",
    FromUserData: localStorage.getItem("Email"),
  });
  let dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setOpenComposeMessageModal(false));
    setAnchorEl(null);
  };
  const Item = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.5),

    color: theme.palette.text.secondary,
  }));

  const menuItems = [
    { text: "Profile", icon: <AttachFileIcon /> },
    { text: "My account", icon: <AttachFileIcon /> },
    { text: "Logout", icon: <AttachFileIcon /> },
  ];

  const getSectionObject = (event, value) => {
    handleObjectDispatch("Section", value);
  };
  const getCompanyObject = (event, value) => {
    // console.log(event, value);
    // settxtSectionData(value);
    handleObjectDispatch("CompanyData", value);
  };
  const getForwardUsernObject = (event, value) => {
    handleObjectDispatch("FromUser", value);
  };

  const getTemplatesObject = (event, value) => {
    handleObjectDispatch("CCEmail", value);
  };
  const handleEditorChange = (content, editor) => {
    handleObjectDispatch("TextEditor", content);
  };
  const filterCCEmails = (toEmails, ccEmails) => {
    const toEmailIDs = new Set(toEmails.map((user) => user.UserID));
    return ccEmails.filter((user) => !toEmailIDs.has(user.UserID));
  };
  useEffect(() => {
    if (mainObject.FromUser && mainObject.FromUser.length > 0) {
      const updatedCCEmails = filterCCEmails(mainObject.FromUser, portalUserCC);
      if (JSON.stringify(updatedCCEmails) !== JSON.stringify(portalUserCC)) {
        setPortalUserCC(updatedCCEmails);
      }
    }
  }, [mainObject.FromUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let objectdata = {
          accid: localStorage.getItem("agrno"),
          email: localStorage.getItem("Email"),
          password: localStorage.getItem("password"),
        };

        // First API call
        await dispatch(
          GetPortalManagers_Json(objectdata, function (res) {
            console.log("GetPortalManagers_Json", res);
            setPortalUserTo(res);
            setPortalUserCC(res);
          })
        );

        // Second API call (waits for the first to complete)
        await dispatch(
          GetPortalSections_Json(objectdata, function (res) {
            console.log("GetPortalSections_Json", res);
            setSectionList(res);
          })
        );

        // Prepare the third API call data
        const companydata = {
          accId: localStorage.getItem("agrno"),
          email: localStorage.getItem("Email"),
          password: localStorage.getItem("password"),
          username: localStorage.getItem("EmailClient")
            ? localStorage.getItem("EmailClient")
            : "patrick.docusoft@outlook.com",
          sPassword: localStorage.getItem("passwordClient")
            ? localStorage.getItem("passwordClient")
            : "UGF0cmljazEyMy4=",
        };

        // Third API call (waits for the second to complete)
        await dispatch(
          PortalUsersCompanyList_Json(companydata, function (res) {
            setCompanyList(res);
          })
        );

        console.log("All API calls completed successfully!");
      } catch (error) {
        console.error("Error in API calls:", error);
      }
    };

    fetchData(); // Call the async function
  }, [dispatch]);
  
  const handleObjectDispatch = (objectType, value) => {
    // Update mainObject state
    setMainObject((prevState) => ({
      ...prevState,
      [objectType]: value,
    }));
  };
  const MessagePublished = () => {
    const ccEmail = mainObject.CCEmail.map((obj) => obj["UserName"]).join(",");
    const ccEmailID = mainObject.CCEmail.map((obj) => obj["UserID"]).join(",");
    // const ToEmail = mainObject.FromUser.map((obj) => obj["UserID"]);
    const AttachFile = mainObject.Attachment.map((obj) => obj["name"]).join(
      ","
    );
    const AttachFileBase64 = mainObject.Attachment.map(
      (obj) => obj["base64"]
    ).join(",");

    let obj = {
      ...clientBodyaccidData,
      sectionID: mainObject.Section ? mainObject.Section.ItemTypeID : "",
      emailaddress: localStorage.getItem("EmailClient")
        ? localStorage.getItem("EmailClient")
        : "patrick.docusoft@outlook.com", //loginusr email
      ccode: mainObject.CompanyData["CCode"], //"PatDem",
      recipients: mainObject.FromUser["UserID"],
      subject: mainObject.Subject ? mainObject.Subject : "",
      ccnames: ccEmail ? ccEmail : "",
      ccids: ccEmailID ? ccEmailID : "",
      filenames: AttachFile,
      attachments: AttachFileBase64,
      message: btoa(mainObject.TextEditor ? mainObject.TextEditor : ""),
    };

    dispatch(
      MessageUploaded_Json(obj, function (res) {
        if (res) {
          console.log("MessagePublished_Jsoniodt", res);

          //  if(res.d==""){

          //  }
        }
      })
    );
  };

  const selectFile = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const updatedFileStore = [...fileStore];

    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Split to get only the base64 part

        updatedFileStore.push({
          name: file.name,
          size: file.size,
          base64: base64String, // Store the base64 string
        });

        setFileStore(updatedFileStore);
        handleObjectDispatch("Attachment", updatedFileStore);
      };

      reader.readAsDataURL(file); // This will trigger reader.onloadend
    });
  };

  const removeFile = (index) => {
    const updatedFileStore = fileStore.filter((_, idx) => idx !== index);
    setFileStore(updatedFileStore);
    handleObjectDispatch("Attachment", updatedFileStore);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box className="mb-3">
          <Box className="d-flex align-items-center justify-content-between modal-head p-0">
            <Box className="clearfix">
              <DialogTitle className="font-16 py-2">
                {"Compose Message"}
              </DialogTitle>
            </Box>

            {/*  */}
            <Button
              onClick={handleClose}
              autoFocus
              className="text-white min-width-auto me-1"
            >
              <CloseIcon className="font-18" />
            </Button>
          </Box>
          <Box className="px-4">
            <Box display="flex custom-autocomplete" alignItems="center" mb={2}>
              <AutoComplete
                lable="Section"
                ComData={sectionList}
                target="ItemName"
                comFun={getSectionObject}
                defaultValue={
                  Object.keys(mainObject).length !== 0 && mainObject.Section
                    ? mainObject?.Section
                    : ""
                }
              ></AutoComplete>
            </Box>
            <Box display="flex custom-autocomplete" alignItems="center" mb={2}>
              <AutoComplete
                lable="From"
                ComData={CompanyList}
                target="CName"
                comFun={getCompanyObject}
                defaultValue={
                  Object.keys(mainObject).length !== 0 && mainObject.CompanyData
                    ? mainObject?.CompanyData
                    : ""
                }
              ></AutoComplete>
            </Box>

            <Box display="flex" alignItems="center" className="w-100" mb={2}>
              <Box className="w-100 custom-autocomplete">
                <AutoComplete
                  lable="To"
                  className="w-100"
                  ComData={portalUserTo}
                  target="UserName"
                  comFun={getForwardUsernObject}
                  defaultValue={
                    Object.keys(mainObject).length !== 0 && mainObject.FromUser
                      ? mainObject?.FromUser
                      : ""
                  }
                ></AutoComplete>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mb={2} className="w-100">
              <Box className="w-100">
                <CheckboxesMultiSelectTags
                  defaultValue={
                    portalUserCC && portalUserCC && portalUserCC.length > 0
                      ? portalUserCC[0]
                      : []
                  }
                  portalUserTo={portalUserCC ? portalUserCC : ""}
                  comFun={getTemplatesObject}
                  label={"CC"}
                  fildName={"UserName"}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
              <TextField
                label="Subject"
                variant="outlined" // Set variant to outlined or filled
                value={mainObject ? mainObject?.Subject : ""}
                fullWidth
                size="small"
                onChange={(e) => {
                  handleObjectDispatch("Subject", e.target.value);
                }}
              />
            </Box>
            <Box className="compose-editor mb-2">
              <HtmlEditorDX
                handleEditorChange={handleEditorChange}
              ></HtmlEditorDX>
            </Box>

            <Box className="mb-3"></Box>
            <Box className="compose-attachments">
              {fileStore &&
                fileStore.length > 0 &&
                fileStore.map((file, index) => (
                  <Box
                    key={index}
                    sx={{ background: "#f3f3f3" }}
                    className="mt-1 me-2"
                  >
                    <Box
                      className="d-flex align-items-center mt-1 font-12"
                      sx={{
                        color: "#2b2c34",
                        fontWeight: "700",
                      }}
                    >
                      <AttachFileIcon className="font-16 mx-2" />
                      {file.name} , {file.size} KB
                      <IconButton onClick={() => removeFile(index)}>
                        <CloseIcon className="text-red font-16" />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
            </Box>

            <Box className="d-flex align-items-center justify-content-start">
              <Button
                variant="text"
                color="primary"
                startIcon={<SendIcon />}
                onClick={MessagePublished}
                className="btn-blue me-2"
                disabled={
                  !(
                    mainObject.Section &&
                    // Remove mainObject.FromUserData from condition
                    mainObject.Subject &&
                    mainObject.TextEditor
                  )
                }
              >
                Send
              </Button>

              <Button
                component="label"
                variant="text"
                startIcon={<AttachFileIcon />}
                className="btn-blue"
              >
                Upload File
                <input
                  type="file"
                  style={{ display: "none" }}
                  multiple
                  onChange={(e) => {
                    selectFile(e);
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default CamposeMessage;
