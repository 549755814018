import { Button } from "@mui/base";
import CommentIcon from "@mui/icons-material/Comment";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import user from "../assets/images/user-2.svg";
import {
  addCommentClientrequest,
  Dmessage_CommentAddedByToken_Json_Action,
  Dmessage_GetAllCommentsByToken_Json_Action,
  getMessagerequest,
} from "../redux/dev_aditya_action";
import { savePortalMessgeComment } from "../redux/dev_chetan_api_helper";
import HtmlEditorDX from "../user/pages/create-request/HtmlEditor";
import { GetrequestTime } from "../utils/RequestsFunction";
import { getPortalMessageComments } from "../redux/dev_sonam_api_helper";
import { useSearchParams } from "react-router-dom";

function Comments({
  portalDocID,
  portalMessageComments,
  ClientName,
  customerId,
  DirectApiBody,
  formMessageViewer="No"
}) {
  const { requestcommentList, ad_Dm_Api_Body } = useSelector(
    (state) => state.aditya
  );
  const isClient = localStorage.getItem("LoginClient");
  const [searchParams] = useSearchParams();
  const Code = searchParams.get("Code");

  const [comment, setComment] = useState("");
  const [editorText, setEditorText] = useState("");
  const dispatch = useDispatch();

  const handleEditorChange = (e, editor) => {
    let text = editor.getContent({ format: "text" });
    setComment(text);
    setEditorText(e);
  };

  const saveMessageComment = () => {
    if (comment && comment.trim().length > 0) {
      if (isClient && formMessageViewer === "No") {
        let body = {
          messageID: portalDocID,
          comment: comment.trim(),
        };
        dispatch(
          addCommentClientrequest(body, (res) => {
            console.log("✌️res --->", res);
            dispatch(
              getMessagerequest({ messageID: portalDocID }, (res) => {
                setComment("");
                setEditorText("");
              })
            );
          })
        );
      } else {
        let body = {
          messageID: portalDocID,
          response: comment.trim(),
        };
        dispatch(
          savePortalMessgeComment(body, () => {
            setComment("");
            setEditorText("");
          })
        );
      }
    } else {
      toast.error("Comment string is empty");
    }
  };

  const DirectMessageComment = () => {
    if (comment && comment.trim().length > 0) {
      let comBody = {
        ...ad_Dm_Api_Body,
        response: comment.trim(),
        username: "",
      };

      dispatch(
        Dmessage_CommentAddedByToken_Json_Action(comBody, (res) => {
          dispatch(
            Dmessage_GetAllCommentsByToken_Json_Action(ad_Dm_Api_Body, () => {})
          );
          setComment("");
          setEditorText("");
        })
      );
    } else {
      toast.error("Comment string is empty");
    }
  };

  useMemo(() => {
    if (Code) {
      return;
    } else {
      dispatch(
        getMessagerequest({ messageID: portalDocID }, (res) => {
          setComment("");
          setEditorText("");
        })
      );
    }
  }, [portalDocID]);

  const renderCommnet = useMemo(() => {
    return (
      <Box className="p-2">
        {requestcommentList.length > 0 &&
          requestcommentList.map((item, key) => (
            <Box
              className="d-flex align-items-center justify-content-center"
              key={key}
            >
              {ClientName ? (
                <Box
                  className="d-flex align-items-center justify-content-center border-bottom p-3 mb-2 flex-wrap flex-sm-nowrap rounded "
                  sx={{ background: "#ffffff", width: "100%" }}
                >
                  <Box className="d-flex align-items-start my-2 justify-content-center">
                    <>
                      <img
                        src={user}
                        height={30}
                        width={30}
                        // sx={{ width: 20, height: 20 }}
                        className="me-2"
                      />{" "}
                    </>
                  </Box>
                  <Box className="w-100 ms-2 ">
                    <Box
                      className="d-flex align-items-center justify-content-between "
                      sx={{ height: "40px" }}
                    >
                      <p className=" font-12 text-wrap lato-semibold">
                        The following response was{" "}
                        {item?.Commenter ? "received" : "sent"}:
                      </p>
                      <p>{GetrequestTime(item?.DateOfRemark)}</p>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-12" sx={{ color: "#808080" }}>
                        {item?.Remark}
                      </span>
                      {/* <Button className="btn-blue">Learn More</Button> */}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  className="d-flex align-items-center justify-content-center  border-bottom p-3 m-2 flex-wrap flex-sm-nowrap rounded "
                  sx={{ background: "#ffffff", width: "100%" }}
                >
                  <Box className="d-flex align-items-start my-2 justify-content-center">
                    <>
                      <img
                        src={user}
                        height={30}
                        width={30}
                        // sx={{ width: 20, height: 20 }}
                        className="me-2"
                      />{" "}
                    </>
                  </Box>
                  <Box className="w-100 ms-2 ">
                    <Box
                      className="d-flex align-items-center justify-content-between "
                      sx={{ height: "40px" }}
                    >
                      <p className=" font-12 text-wrap lato-semibold">
                        The following response was{" "}
                        {item?.Commenter ? "sent" : "received"}:
                      </p>
                      <p>{GetrequestTime(item?.DateOfRemark)}</p>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-12" sx={{ color: "#808080" }}>
                        {item?.Remark}
                      </span>
                      {/* <Button className="btn-blue">Learn More</Button> */}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
      </Box>
    );
  }, [requestcommentList]);

  return (
    <Box className="" sx={{ background: "#f5f5f5", padding: "15px" }}>
      <Box sx={{ height: "225px" }} className="mb-2">
        <Typography
          variant="h3"
          className="font-18 text-black lato-semibold mb-2"
        >
          Comment
        </Typography>

        {/* <DocuTextField rows={8} sx={{ background: "#fff", height: '120px' }} size={"Normal"} label={'dfadfadsf'} /> */}

        <Box className="editor-height-200 mt-2">
          <HtmlEditorDX
            templateDataMarkup={editorText} // Correct binding to state
            handleEditorChange={handleEditorChange}
          />
        </Box>

        {Code ? (
          <Box className="d-flex align-items-center justify-content-end comment-button">
            <Button
              variant="contained"
              className="btn-blue mt-2 "
              startIcon={<CommentIcon />}
              onClick={DirectMessageComment}
            >
              <CommentIcon className="font-20 me-1" />
              comment
            </Button>
          </Box>
        ) : (
          <Box className="d-flex align-items-center justify-content-end comment-button">
            <Button
              variant="contained"
              className="btn-blue mt-2 "
              startIcon={<CommentIcon />}
              onClick={saveMessageComment}
            >
              <CommentIcon className="font-20 me-1" />
              comment
            </Button>
          </Box>
        )}
      </Box>

      <Box>
        <Box
          className="my-2"
          sx={{
            maxHeight: "calc(100vh - 350px)",
            overflow: "auto",
          }}
        >
          <div
          // dangerouslySetInnerHTML={{ __html: portalMessageComments }}
          ></div>

          {renderCommnet}

          {/* <Typography className="font-16 lato-semibold"
                        variant="h3"
                    >
                        On Mon 20 MAy 2024 at 8:30px the following response was recived :
                    </Typography>

                    <Typography
                        className="font-13 text-gray"
                        sx={{
                            marginTop: "20x",
                        }}
                        variant="p"
                    >
                        Dont Know What to do here
                    </Typography> */}
          {/* <hr /> */}

          {/* <Divider sx={{ border: "1px solid #555", marginTop: "10px" }} /> */}
        </Box>
      </Box>
    </Box>
  );
}

export default Comments;
