import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../components/AutoComplete";
import { AllCountriesList } from "../../custom-hook/AllCountriesList";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import {
  Json_GetConfiguration_Redux,
  Json_GetConfiguration_Redux1,
} from "../../redux/ak_api_helper";
import { Ad_SetClientIdReducer } from "../../redux/adityaReducer";

export default function ClientCreateContect({
  formData,
  setformData,
  manager,
  rolestest,
  setManager,
  OriginatorNo,
  ad_ClientId,
}) {
  const dispatch = useDispatch();
  // const reqObj = useLocalStorage("required");
  const selectedoriginator = JSON.parse(localStorage.getItem('selectedoriginator') )
console.log(selectedoriginator , "selectedorifigonmodal")
  const { roles, managers } = useSelector((state) => state.ak);
  const { CompanyReference } = useSelector((state) => state.ak);
  console.log(CompanyReference , "companyRefe")
  const Email = localStorage.getItem('EmailClient')
  const password =localStorage.getItem('passwordClient')
  const agrno = localStorage.getItem('accIdClient')
const reqObj = {
  Email,
  password,
  agrno
}
  const handleFnameInputChange = (event) => {
    const { name, value } = event.target;
    setformData({ ...formData, [name]: value });
  };
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange1 = (event) => {
    const newEmail = event.target.value;
    const { name, value } = event.target;
    setformData({ ...formData, [name]: value });
    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email address");
    }
  };

  // const [switchStates, setSwitchStates] = useState({
  //   inActive: false,
  //   mainContact: false,
  //   sms: false,
  // });

  // const handleSwitchChange = (event) => {
  //   const { name, checked } = event.target;

  //   setformData({ ...formData, [name]: checked });

  //   if (name === "inActive" && checked) {
  //     setformData({
  //       ...formData,
  //       [name]: checked,
  //       mainContact: 'false',
  //       sms: false,
  //     });
  //     setSwitchStates({
  //       inActive: true,
  //       mainContact: false,
  //       sms: false,
  //     });
  //   } else {
  //     setSwitchStates((prevState) => ({
  //       ...prevState,
  //       [name]: checked,
  //     }));
  //   }
  // };

  useEffect(() => {
    dispatch(Json_GetConfiguration_Redux1(reqObj));
  }, []);

  useEffect(() => {
    dispatch(Json_GetConfiguration_Redux(reqObj));
    return () => dispatch(Ad_SetClientIdReducer());
  }, []);

  useEffect(() => {
    if (CompanyReference && ad_ClientId) {
      const company = CompanyReference.find(
        (com) => com?.ClientId === ad_ClientId
      );
      if (company) {
        setformData({
          ...formData,
          // Company: selectedoriginator ?  selectedoriginator['Company Name'] : '',
          CCode: selectedoriginator ? selectedoriginator['OriginatorNo'] : '',
        });
      }
    }
  }, [CompanyReference, ad_ClientId]);

  useEffect(() => {
    if (managers) {
      let defaultUser1 = managers.find(
        (manager) => manager.UserId == localStorage.getItem("UserId")
      );
      setManager(defaultUser1);
      setformData({
        ...formData,
        AssignedManager: defaultUser1?.UserId || 0,
        ManagerName:  "",
      });
    }
  }, [managers]);

  useEffect(() => {
    setformData({
      ...formData,
      Salutation: "Mr",
      Role: roles[0]?.RoleName || "",
      // Company: selectedoriginator ?  selectedoriginator['Company Name'] : '',
      CCode: selectedoriginator ? selectedoriginator['OriginatorNo'] : '',
      Country: AllCountriesList[0].label || '',
      AssignedManager: manager?.UserId || 0,
      ManagerName: ""
    });
  }, [dispatch, manager]);

  useEffect(() => {
  }, [formData]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={4}>
          <Box className="custom-autocomplete">
            <AutoComplete
              ComData={titleData}
              target="label"
              defaultValue={formData.Salutation}
              comFun={(e, newValue) => {
                newValue && setformData({ ...formData, Salutation: newValue.label });
              }}
              lable="Title"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={4}>
          <TextField
            className="w-100"
            label="First Name"
            id="outlined-size-small"
            required
            name="FirstName"
            size="small"
            value={formData.FirstName}
            onChange={handleFnameInputChange} // Step 5: Update state on input change
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={4}>
          <TextField
            className="w-100"
            label="Last Name"
            id="outlined-size-small"
            name="LastName"
            required
            value={formData.LastName}
            size="small"
            onChange={handleFnameInputChange}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={4}>
          <Box className="custom-autocomplete">
            <AutoComplete
              ComData={roles}
              defaultValue={formData?.Role}
              target="RoleName"
              // comFun={(e, newValue) => {
              //   newValue &&
              //     setformData({ ...formData, Role: newValue?.RoleName });
              // }}
              lable="Role"
            />
          </Box>
        </Grid> */}
        {!OriginatorNo && (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box className="custom-autocomplete">
              <AutoComplete
                ComData={CompanyReference}
                target="Client"
                defaultValue={selectedoriginator ?  selectedoriginator['Company Name'] : ''}
                // comFun={(e, newValue) => {
                //   newValue &&
                //     setformData({
                //       ...formData,
                //       Company: newValue.Client,
                //       CCode: newValue.ClientId,
                //     });
                // }}
                lable="Company"
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            className="w-100"
            fullWidth
            label="Email"
            id="outlined-size-small"
            size="small"
            required
            name="contactEmail"
            value={formData.contactEmail}
            onChange={handleEmailChange1}
            error={!!emailError}
            helperText={emailError}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            className="w-100"
            label="Mobile Number"
            id="outlined-size-small"
            required
            name="Mobile"
            size="small"
            value={formData.Mobile}
            onChange={handleFnameInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={12}>
          <TextField
            className="w-100"
            label="Telephone"
            id="outlined-size-small"
            name="Tel"
            size="small"
            value={formData.Tel}
            onChange={handleFnameInputChange}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Box className="d-flex">
            <Box className="me-4" item>
              <Typography variant="body2">Main Contact</Typography>
              <Switch
                size="small"
                name="mainContact"
                // checked={switchStates.mainContact || formData.maincontact}
                // defaultValue={}
                // onChange={handleSwitchChange}
                disabled
              />
            </Box>

            <Box item className="me-4">
              <Typography variant="body2">In Active</Typography>
              <Switch
                size="small"
                name="inActive"
                // checked={switchStates.inActive}
                // onChange={handleSwitchChange}
                disabled
              />
            </Box>
          </Box>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Box item className="main-accordian w-100">
            <Accordion fullWidth defaultExpanded sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                spacing={2}
              >
                <Typography expandIcon={<ExpandMoreIcon />}>Address</Typography>
              </AccordionSummary>

              <AccordionDetails fullWidth>
                <Grid container spacing={2} className="my-1">
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="w-100"
                      fullWidth
                      label="Address Line 1"
                      id="outlined-size-small"
                      size="small"
                      name="Add1"
                      value={formData.Add1}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="w-100"
                      fullWidth
                      label="Address Line 2"
                      id="outlined-size-small"
                      size="small"
                      name="Add2"
                      value={formData.Add2}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="w-100"
                      fullWidth
                      label="Address Line 3"
                      id="outlined-size-small"
                      size="small"
                      name="Add3"
                      value={formData.Add3}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="w-100"
                      fullWidth
                      label="City"
                      id="outlined-size-small"
                      size="small"
                      name="Town"
                      value={formData.Town}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <TextField
                      className="w-100"
                      fullWidth
                      label="Status"
                      id="outlined-size-small"
                      size="small"
                      name="State"
                      value={formData.Salutation}
                      onChange={handleFnameInputChange}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <Box
                      display="flex custom-autocomplete"
                      alignItems="center"
                      mb={2}
                    >
                      <AutoComplete
                        ComData={AllCountriesList}
                        defaultValue={formData.Country}
                        target="label"
                        comFun={(e, newValue) => {
                          newValue &&
                            setformData({
                              ...formData,
                              Country: newValue.label,
                            });
                        }}
                        lable="Country"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className="d-flex align-items-center gap-2">
                      <TextField
                        className="w-100"
                        label="Zip Code"
                        id="outlined-size-small"
                        size="small"
                        name="PostCode"
                        value={formData.PostCode}
                        onChange={handleFnameInputChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
const titleData = [
  { label: "Mr", year: 1994 },
  { label: "Mrs", year: 1972 },
  { label: "Miss", year: 1974 },
  { label: "Ms", year: 2008 },
  { label: "Dr", year: 1957 },
  { label: "Hr", year: 1957 },
  { label: "N/A", year: 1957 },
];
