import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import { GetDocumentRequests_Json_Redux } from "../../redux/api_helper";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, TextField } from "@mui/material";
import { NavLink, useSearchParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";

const pageSizes = [20, 50, 100];
export default function AllRequests({ pmrv }) {
  let objReq = useLocalStorage("accid");
  const [loading, setLoading] = React.useState(true);
  const [searchString, setSearchString] = React.useState("");

  //Remove Object and add new with replace name
  let newObject = { ...objReq, accId: objReq.accid };
  delete newObject.accid;
  let dispatch = useDispatch();
  const { documentRequests } = useSelector(({ docu }) => docu);
  const [getAllRequests, setAllRequests] = React.useState([]);
  const [renderData, setRenderData] = React.useState([]);
  const [searchParams] = useSearchParams();
  const emailForFilter = searchParams.get("email");
  const { pathname } = window.location;

  React.useEffect(() => {
    let isMounted = true; // Prevent state updates if component unmounts

    setLoading(true);

    getAllRequests.length === 0 &&
      dispatch(
        GetDocumentRequests_Json_Redux(newObject, (res) => {
          if (!isMounted) return; // Prevent state update if component unmounted

          if (emailForFilter) {
            const filteredData = res.filter(
              (itm) => itm.EmailId === emailForFilter
            );
            setAllRequests(filteredData);
            setRenderData(filteredData);
          } else {
            setAllRequests(res);
            setRenderData(res);
          }

          // ⏳ Delay setting loading to false by 2s
          const timer = setTimeout(() => {
            if (isMounted) setLoading(false);
          }, 2000);

          return () => clearTimeout(timer); // Cleanup timeout on unmount
        })
      );

    return () => {
      isMounted = false; // Cleanup function to prevent memory leaks
    };
  }, [dispatch, emailForFilter]);

  // React.useEffect(() => {
  //   let timer;
  //   if (searchString.length > 2 && getAllRequests.length > 0) {
  //     setLoading(true);
  //     timer = setTimeout(() => {
  //       let fltData = getAllRequests.filter((d) =>
  //         d.Request.trim()
  //           .toLowerCase()
  //           .includes(searchString.trim().toLowerCase())
  //       );
  //       setRenderData(fltData);
  //       setLoading(false);
  //     }, 2000);
  //   } else {
  //     setRenderData(getAllRequests);
  //     setLoading(false);
  //   }
  //   return () => clearTimeout(timer);
  // }, [searchString]);

  const getPageTitle = () => {
    switch (true) {
      case pathname.includes("templates"):
        return "Template List";
      case pathname.includes("portalmessage/sent"):
        return "Portal Sent Messages ";
      case pathname.includes("share-box"):
        return "Share Box";
      case pathname.includes("contacts/contact-details"):
        return "Contact Detail";
      case pathname.includes("contact/contacts"):
        return "Contact List";
      case pathname.includes("contact/companies"):
        return "Company List";
      case pathname.includes("user/client-details"):
        return "Company Details";
      case pathname.includes("incoming-message-viewer"):
        return "Incoming Message";
      case pathname.includes("all-requests"):
        return "All Request";
      default:
        return "Portal Messages";
    }
  };

  useEffect(() => {
    console.log("✌️loading --->", loading);
  }, [loading]);

  // console.log("✌️getAllRequests --->", getAllRequests);

  const onSearchStringChange = (e) => {
    setSearchString(e.target.value);
    let value = e.target.value
    // Clear existing timers
    let timer;
    if (value.length >=3 && getAllRequests.length > 0) {
      console.log("✌️e.target.value.length --->", value,"searchString ===>",searchString);
      timer = setTimeout(() => {
        setLoading(true); // Set loading true AFTER stopping typing
        let fltData = getAllRequests.filter((d) =>
          d.Request.trim()
            .toLowerCase()
            .includes(value.trim().toLowerCase())
        );
        setRenderData(fltData);

        setTimeout(() => {
          setLoading(false); // ✅ Set loading false after 1s
        }, 2000);
      }, 2000); // Wait 2s before filtering
    } else {
      setTimeout(() => {
        console.log("✌️e.target.value.length --222->", e.target.value.length);

      setRenderData(getAllRequests);
      setLoading(false);
      },2000)
    }

    return () => clearTimeout(timer); // ✅ Only clear the main timer
  };

  return (
    <Box>
      <Box className="d-flex flex-wrap align-items-center justify-content-start justify-content-sm-end mt-2">
        <h2 className="title mb-0 nowrap d-sm-none d-block font-16">
          {getPageTitle()}
        </h2>
        <Box
          className="search-input"
          sx={{
            width: "100%",
            maxWidth: "340px",
          }}
        >
          <SearchIcon />
          <TextField
            fullWidth
            label="Search"
            size="small"
            onChange={onSearchStringChange}
          />
        </Box>
      </Box>

      {loading ? (
        <CustomLoader />
      ) : (
        <Box className="white-box p-2">
          {renderData.length > 0 ? (
            <DataGrid
              dataSource={renderData}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
              className="table-grid table-grid2"
              style={{
                maxHeight: "calc(100vh - 150px)",
                overflowY: "auto",
              }}
            >
              {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
              <Column dataField="Request" caption="Request" dataType="string" />
              <Column dataField="EmailId" caption="Email" dataType="string" />
              <Column
                dataField="Individual"
                caption="Individual"
                dataType="string"
              />
              <Column
                dataField="DocTempId"
                caption="Action"
                cellRender={(data) => {
                  return (
                    <NavLink
                      to={
                        pmrv
                          ? `/user/pmrv-detail?id=${data.data.DocTempId}&request=${data.data.Request}&EmailId=${data.data.EmailId}`
                          : `/user/all-invites?id=${data.data.DocTempId}&request=${data.data.Request}&EmailId=${data.data.EmailId}`
                      }
                    >
                      <Button
                        className="btn-blue"
                        startIcon={<VisibilityIcon className="font-14" />}
                      >
                        View Detail
                      </Button>
                    </NavLink>
                  );
                }}
              />
              <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
              <Paging defaultPageSize={20} />
            </DataGrid>
          ) : (
            <NoData messsage="No Data here" />
          )}
        </Box>
      )}
    </Box>
  );
}
