import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  RequestStatusTotalCountByContact_Json,
  RequestStatusTotalCount_Json,
} from "../../../redux/dev_chetan_api_helper";
import Overview from "./Overview";
import RequestsDetails from "./RequestDetails";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AllInvites({ pmrv }) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const id = searchParams.get("id");
  const request = searchParams.get("request");
  const EmailId = searchParams.get("EmailId");
  const [value, setValue] = React.useState(1);
  React.useEffect(() => {
    a11yProps(1);
    setValue(id ? 1 : 0);
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(RequestStatusTotalCount_Json());
    dispatch(RequestStatusTotalCountByContact_Json(EmailId));
  }, []);

  return (
    <Box className={pmrv ? "container-fluid mt-4" : "mt-2"}>
      {/* <Typography className="title">Invite Overview</Typography> */}

      <Box sx={{ width: "100%" }} className="custom-tabs">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {!pmrv && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="flex-wrap-tabs"
            >
              <Tab label="OVERVIEW" {...a11yProps(0)} />
              <Tab label="REQUESTS" {...a11yProps(1)} />
              {/* <Tab label="MESSAGES" {...a11yProps(2)} />
            <Tab label="HISTORY" {...a11yProps(3)} />
            <Tab label="OPTIONS" {...a11yProps(4)} /> */}
            </Tabs>
          )}
        </Box>
        
        <CustomTabPanel className="p-0" value={value} index={0}>
          <Overview />
        </CustomTabPanel>
        <CustomTabPanel className="p-0" value={value} index={1}>
          <RequestsDetails id={id} request={request} pmrv={pmrv}/>
        </CustomTabPanel>
        <CustomTabPanel className="p-0" value={value} index={2}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel className="p-0" value={value} index={3}>
          HISTORY
        </CustomTabPanel>
        <CustomTabPanel className="p-0" value={value} index={4}>
          OPTIONS
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default AllInvites;
