import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import { RequestedDocumentAsByteArray_Json_Redux } from "../../../redux/api_helper";

import moment from "moment";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { setOpenApproveModal } from "../../../redux/modalSlice";
import {
  GetDocTypeIconRequests,
  GetStatusChipRequests,
  GetStatusRequests,
  UserRequestFileDownloadAndPreview,
  UserStatusClass,
} from "../../../utils/RequestsFunction";
import UserRequestButtons from "./UserRequestButtons";

// import pdf from "../../assets/images/files-icon/pdf.png";

const UserRenderRequest = ({ accordionItem, id }) => {
  const [loading, setLoading] = React.useState(false);
  let objReq = useLocalStorage("accid");

  const dispatch = useDispatch();

  return (
    accordionItem &&
    accordionItem.length > 0 &&
    accordionItem.map((data, i) => (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        xxl={6}
        className={`d-flex ${UserStatusClass(data)}`}
        key={i}
      >
        <Box
          className={`mb-0 white-box main-details-box w-100 p-2 ${UserStatusClass(
            data
          )}`}
        >
          <Box className="d-flex align-items-center justify-content-between">
            <h2 className="lato-medium font-12">{data?.DocName}</h2>

            <Box className="d-flex ms-2 align-items-center">
              {GetStatusRequests(data.Status)}

              {GetStatusChipRequests(data.RequestDocStatus, data.StatusName)}
            </Box>
          </Box>

          <hr />
          {/* <Box>
            <Typography className="lato-medium mb-1">
              data.Status : {data.Status}
            </Typography>

            <Typography className="lato-medium mb-1">
              data.RequestDocStatus : {data.RequestDocStatus}
            </Typography>
            <Typography className="lato-medium mb-1">
              data.DocTempId : {data.DocTempId}
            </Typography>
            <Typography className="lato-medium mb-1">
              data.DocId : {data.DocId}
            </Typography>
          </Box> */}
          {data?.RequestDocStatus != "2" && (
            <Box className="details-box-row d-flex flex-wrap mb-1">
              {/* <Typography className="title">Details</Typography> */}
              {data?.Individual && (
                <Box className="details-box">
                  <Typography className="lato-medium mb-1">
                    Uploaded by
                  </Typography>
                  <Typography className="font-12 badge text-bg-info">
                    <PersonIcon className="font-18" /> {data?.Individual}
                  </Typography>
                </Box>
              )}

              {data?.UploadedDate && (
                <Box className="details-box">
                  <Typography className="lato-medium mb-1">
                    Uploaded on
                  </Typography>
                  <Typography className="font-12 badge text-bg-primary">
                    <CalendarMonthIcon className="font-18" />
                    {data?.UploadedDate
                      ? moment(data.UploadedDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
              )}
              {data?.IsApproved === "True" && (
                <Box className="details-box">
                  <Typography className="lato-medium mb-1">
                    Approved date
                  </Typography>
                  <Typography className="font-12 badge text-bg-success">
                    <CalendarMonthIcon className="font-18" />
                    {data?.ApprovalDate
                      ? moment(data.ApprovalDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
              )}
              {data?.IsApproved && (
                <Box className="details-box">
                  <Typography className="lato-medium mb-1">Approved</Typography>
                  {data?.IsApproved === "True" ? (
                    <Typography className="font-12 badge text-bg-success">
                      <CheckCircleIcon className="font-18" />
                      Yes
                    </Typography>
                  ) : (
                    <Typography className="font-12 badge text-bg-danger">
                      <CheckCircleIcon className="font-18" />
                      No
                    </Typography>
                  )}
                </Box>
              )}
              {data?.IsApproved === "True" && data?.ApprovedByUser && (
                <Box className="details-box">
                  <Typography className="lato-medium mb-1">
                    Approved by
                  </Typography>
                  <Typography className="font-12 badge text-bg-success">
                    <VerifiedUserIcon className="font-18" />
                    {data?.ApprovedByUser}
                  </Typography>
                </Box>
              )}

              {data?.ExpiryDate && (
                <Box className="details-box">
                  <Typography className="lato-medium mb-1">
                    Expiry date
                  </Typography>
                  <Typography className="font-12 badge text-bg-danger">
                    <CalendarMonthIcon className="font-18" />
                    {data?.ExpiryDate
                      ? moment(data.ExpiryDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {/* render document */}
          <Box>
            <Typography>{loading ? "Loading..." : ""}</Typography>
            {data?.DocExtension && data?.DocName && (
              <Box
                onDoubleClick={() =>
                  UserRequestFileDownloadAndPreview(
                    data,
                    objReq,
                    dispatch,
                    "preview"
                  )
                } // Double-click event handler
                role="button"
                sx={{
                  border: "1px solid #f1f1f1",
                  boxShadow: "none",
                }}
                className="white-box d-flex align-items-center justify-content-between p-2 mb-3"
              >
                <Box className="d-flex align-items-center">
                  {GetDocTypeIconRequests(data?.DocExtension, data.DocName)}
                  <Typography className="lato-medium font-12">
                    {data?.DocExtension
                      ? `${data.DocName}${
                          data.DocExtension.startsWith(".")
                            ? data.DocExtension
                            : `.${data.DocExtension}`
                        }`
                      : ""}
                  </Typography>
                </Box>

                <Box className="d-flex">
                  {}
                  {["3", "4", "5", "6", "7"].includes(
                    data?.RequestDocStatus
                  ) ? (
                    <Tooltip title="Download" arrow>
                      <Button
                        className="min-width-auto ms-2 text-bg-info"
                        size="small"
                        onClick={() =>
                          UserRequestFileDownloadAndPreview(
                            data,
                            objReq,
                            dispatch,
                            "download"
                          )
                        }
                      >
                        <DownloadIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {data?.RequestDocStatus && data?.RequestDocStatus === "3" ? (
                    <Tooltip title="Approve" arrow>
                      <Button
                        className="min-width-auto ms-2 text-bg-primary"
                        size="small"
                        onClick={() =>
                          dispatch(
                            setOpenApproveModal({
                              id: id,
                              type: "a",
                              data: data,
                              isOpen: true,
                            })
                          )
                        }
                      >
                        <ThumbUpIcon className=" font-20" />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {data?.Status !== "3" && data?.RequestDocStatus === "3" ? (
                    <Tooltip title="Disapprove" arrow>
                      <Button
                        className="min-width-auto ms-2 text-bg-danger"
                        size="small"
                        onClick={() =>
                          dispatch(
                            setOpenApproveModal({
                              id: id,
                              type: "d",
                              data: data,
                              isOpen: true,
                            })
                          )
                        }
                      >
                        <ThumbDownAltIcon className="font-20" />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            )}

            <UserRequestButtons data={data} />

            {data?.notes && (
              <Box container spacing={0}>
                <Box
                  item
                  sm={12}
                  sx={{
                    background: "#fff",
                    borderRadius: "8px",
                    // padding: "20px",
                    // maxHeight:'100px'
                    maxHeight: "100px",
                    overflow: "auto",
                  }}
                >
                  <Box>
                    <Typography className="lato-medium font-12 border-bottom pb-1 mb-1">
                      Reason for disapproval
                    </Typography>
                    <Typography className="font-12 text-">
                      {data?.notes}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    ))
  );
};

export default UserRenderRequest;
