import {
  Box,
  Grid,
  Typography,
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DocuTextField from "../../cmp-utils/DocuTextField";
import EditIcon from "@mui/icons-material/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import QRCode from "qrcode.react";
import CustomeSignature from "../../components/CustomeSignature";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import {
  GetSignature_Json_Redux,
  SignatureRemoved,
} from "../../redux/ak_api_helper";
import { useDispatch, useSelector } from "react-redux";

function ClientSetting() {
  const [SignatureSettingVisible, setSignatureSettingVisible] = useState(true);
  const [authenticationVisible, setAuthenticationVisible] = useState(false);
  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] =
    useState(true);
  const [openSignatur, setOpenSignatur] = React.useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  // const ImageBox = styled(Box)({});

  const handleSignatureSetting = () => {
    setSignatureSettingVisible(false);
  };

  const handleScanner = () => {
    setAuthenticationVisible(!authenticationVisible);
  };

  // Modal
  const handleClickOpenSignatur = () => {
    dispatch(SignatureRemoved(obj12));
    setOpenSignatur(true);
  };
  const SignaturhandleClose = () => {
    setOpenSignatur(false);
  };

  const dispatch = useDispatch();

  const portalObject = useLocalStorage("accid");
  useEffect(() => {
    dispatch(
      GetSignature_Json_Redux({
        ...portalObject
      })
    );
  });

  const { signature } = useSelector((state) => state.ak);
  const obj12 = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };

  const base64String = signature; // Replace with your Base64 string
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
    alignItems: "center",
  });
  return (
    <>
      <h2 className="title">Settings:</h2>

      <Box className="white-box">
        <Grid container rowSpacing={0} columnSpacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            {/* {signatureSetupVisible ? (
              <>
                <Typography className="font-18 lato-medium mb-2"
                  variant="Body2">
                  Signature Settings:
                </Typography>

                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    background: "#fff",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <ImageBox
                    component="img"
                    src={`data:image/${format};base64,${base64String}`}
                    alt="Base64 Image"
                    sx={{ width: 300, height: 95 }}
                  />
                </Box>

                <Box className='mt-2 mb-3'>
                  <Button
                    variant="contained"
                    size='small'
                    className="btn-blue"
                    // onClick={handleSignatureSetup}
                    onClick={handleClickOpenSignatur}
                    startIcon={<EditIcon />}
                  >
                    Change Signature{" "}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <CustomeSignature
                  setSignatureSetupVisibleSetting={
                    setSignatureSetupVisibleSetting
                  }
                  setSignaturePreview={setSignaturePreview} setSignatureSetup={setSignatureSetup}
                />
              </>

            )}  */}

            <Typography className="font-18 lato-medium mb-2" variant="Body2">
              Signature Settings:
            </Typography>

            <Box
              sx={{
                border: "1px solid #f1f1f1",
                borderRadius: "10px",
                background: "#fff",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <ImageBox
                component="img"
                src={`data:image/${format};base64,${base64String}`}
                alt="Base64 Image"
                sx={{ width: 300, height: 95 }}
              />
            </Box>

            <Box className="mt-2 mb-3">
              <Button
                variant="contained"
                size="small"
                className="btn-blue"
                // onClick={handleSignatureSetup}
                onClick={handleClickOpenSignatur}
                startIcon={<EditIcon />}
              >
                Change Signature{" "}
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Typography className="font-18 lato-medium mb-2" variant="Body2">
              Two Factor Authentication
            </Typography>

            <Box className="clearfix">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={authenticationVisible}
                    onChange={handleScanner}
                  />
                }
                label="Enable"
                sx={{ marginTop: "10px" }}
              />

              {authenticationVisible && (
                <>
                  <Grid container rowSpacing={0} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Box>
                        <Typography className="font-12 mb-2">
                          <span className="lato-semibold">Step 1:</span> Scan
                          the QR code on your Google
                          <br /> Authenticator app
                        </Typography>
                        <QRCode value="https://example.com" size={100} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Box>
                        <Typography className="font-12">
                          <span className="lato-semibold">Step 2:</span>{" "}
                          Enter your Google Authenticator Pin
                        </Typography>

                        <Box className="d-flex align-items-center">
                          <DocuTextField label={"Enter Pin"} size="small" />
                          <Button
                            // startIcon={<CheckIcon />}
                            variant="contained"
                            size="small"
                            className="btn-blue mt-2 ms-2 px-5"
                            onClick={handleSignatureSetting}
                          >
                            Save
                            {/* <span className="d-inline-block px-2"></span> */}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <hr />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Typography variant="body1" className="font-18 lato-medium">
              Change Password
            </Typography>

            <Box
              sx={
                {
                  // gap: 2,
                  // marginTop: "30px",
                }
              }
            >
              <DocuTextField
                size="small"
                // sx={{ background: "#fff", borderRadius: "10px" }}
                label={"Current Password"}
                fieldName="currentPassword"
                fieldValue={currentPassword}
                onChange={handleCurrentPasswordChange}
                fieldLabel={"password"}
              />

              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <DocuTextField
                    size="small"
                    // sx={{ background: "#fff", borderRadius: "10px" }}
                    label={"New Password"}
                    fieldName="newPassword"
                    fieldValue={newPassword}
                    onChange={handleNewPasswordChange}
                    fieldLabel={"password"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <DocuTextField
                    size="small"
                    // sx={{ background: "#fff", borderRadius: "10px" }}
                    label={"Confirm Password"}
                    fieldName="confirmPassword"
                    fieldValue={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    fieldLabel={"password"}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Typography className="font-18 lato-medium mb-2" variant="Body2">
              Security Settings
            </Typography>

            <Grid container rowSpacing={0} columnSpacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <DocuTextField
                  size="small"
                  // sx={{ background: "#fff", borderRadius: "10px" }}
                  label={"Birth Date"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <DocuTextField
                  size="small"
                  // sx={{ background: "#fff", borderRadius: "10px" }}
                  label={"Account Created"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <DocuTextField
                  size="small"
                  // sx={{ background: "#fff", borderRadius: "10px" }}
                  label={"Memorable Word"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <DocuTextField
                  size="small"
                  // sx={{ background: "#fff", borderRadius: "10px" }}
                  label={"Hint"}
                />
              </Grid>
            </Grid>

            {/* <hr /> */}
          </Grid>
        </Grid>

        <hr />

        <Box className="text-end">
          <Button
            variant="contained"
            // size='small'
            className="btn-blue mt-2"
            onClick={handleSignatureSetting}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openSignatur}
        onClose={SignaturhandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>Signature Setup</DialogTitle>
          </Box>
          <Button onClick={SignaturhandleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CustomeSignature
              setOpenSignatur={setOpenSignatur}
              setSignaturePreview={setSignaturePreview}
              setSignatureSetup={setSignatureSetup}
              setSignatureSetupVisibleSetting={setSignatureSettingVisible}
            />
          </DialogContentText>

          <DialogActions className="pt-4 px-0">
            {/* <Button onClick={SignaturhandleClose} className='btn-red' variant="outlined">Cancel</Button>
            <Button onClick={SignaturhandleClose} autoFocus variant="outlined" className='btn-blue'>
              Create Contact
            </Button> */}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ClientSetting;
