import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Autocomplete,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import dayjs from "dayjs";
import {
  ad_Json_CRMSaveUDFValues_Action,
  ad_Json_GetAllClientList_Action,
  ad_Json_GetAllContacts_Action,
  ad_Json_GetCRMContactUDFValues_Action,
  ad_Json_UpdateContact_Action,
  ad_UpdateContactField_Json_Action,
} from "../../../redux/dev_aditya_action";
import { getAllContactsByClientID } from "../../../redux/dev_chetan_api_helper";
import UserUdfEditField from "../UserUdfEditField";
import ContactMainForm from "./ContactMainForm";

let folderData;
let clientData;
let defaultclientData;
let clientName;
function AddContacts({
  addContactData,
  contactDetails,
  handleClose51,
  refreshData,
}) {
  // console.log(addContactData, "addContactData11111", contactDetails);
  const [contact, setContact] = useState([]);
  const getAllStateReduxSonam = useSelector((state) => state.counter);
  const { getClientIdMethod, getFolderObject } = useSelector(({ ak }) => ak);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const contactNo = searchParams.get("contactNo");
  const orgNo = searchParams.get("orgNo");
  const folderId = searchParams.get("folderId");

  const [agrno, setAgrNo] = useState(localStorage.getItem("agrno"));
  const [Importcontactdata, setImportcontactdata] = useState({});
  const [clientNames, setclientNames] = useState("");
  const [value, setValue] = React.useState(0);
  const [ImportContact, setImportContact] = useState([]);
  const [contactlistdata, setContactlistdata] = useState([]);
  const [defaultClient, setdefaultClient] = useState(null);
  const localObj = useLocalStorage();
  const [txtValue, setTxtValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [contactUdfFields, setContactUdfFields] = useState([]);
  const [udfFieldChange, setUdfFieldChange] = useState([]);

  const [userContactDetails, setContactDetails] = useState({
    Title: "",
    FirstName: "",
    LastName: "",
    ReferenceName: "",
    MainContact: false,
    Inactive: false,
    GreetingName: "",
    EmailName: contactDetails?.[0]["E-Mail"]
      ? contactDetails?.[0]["E-Mail"]
      : "",
    MainUserId: -1,
    MainUserName: "",
    MainLine1Name: "",
    MainLine2Name: "",
    MainLine3Name: "",
    MainTownName: "",
    MainPostcodeName: "",
    Maincontactcountry: "",
    MainTelephoneName: "",
    MainMobileName: "",
    mainCountry: "",
    billingsCountry: "",
    ragistersCountry: "",
    ReferenceID: "",
    BirthDate: "",
    RolesData: "",
    Base64ImgData: "",
    CreatePortal: false,
    Notes: "",
  });

  // upload document modal start
  const location = useLocation();
  const navigation = useNavigate();
  const { ad_GetAllContacts, ad_GetAllClientList } = useSelector(
    ({ aditya }) => aditya
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(contactData, "contactData");
    if (contactDetails && contactDetails.length > 0) {
      let contactData = {
        FirstName: userContactDetails.FirstName
          ? userContactDetails.FirstName
          : "",
        LastName: userContactDetails.LastName
          ? userContactDetails.LastName
          : "",
        Add1: userContactDetails.MainLine1Name
          ? userContactDetails.MainLine1Name
          : "",
        Add2: userContactDetails.MainLine2Name
          ? userContactDetails.MainLine2Name
          : "",
        Add3: userContactDetails.MainLine3Name
          ? userContactDetails.MainLine3Name
          : "",
        Town: userContactDetails.MainTownName
          ? userContactDetails.MainTownName
          : "",
        PostCode: userContactDetails.MainPostcodeName
          ? userContactDetails.MainPostcodeName
          : "",
        Country: userContactDetails.mainCountry
          ? userContactDetails.mainCountry
          : "United Kingdom",
        ManagerName:
          userContactDetails.FirstName + " " + userContactDetails.LastName,
        Role: userContactDetails.RolesData ? userContactDetails.RolesData : "",
        Tel: userContactDetails.MainTelephoneName
          ? userContactDetails.MainTelephoneName
          : "",
        Mobile: userContactDetails.MainMobileName
          ? userContactDetails.MainMobileName
          : "",
        greeting: userContactDetails.GreetingName
          ? userContactDetails.GreetingName
          : "",
        Contactemail: userContactDetails.EmailName
          ? userContactDetails.EmailName
          : "",
        note: userContactDetails.Notes ? userContactDetails.Notes : "",
        emailupdate: contactDetails[0]["E-Mail"]
          ? contactDetails[0]["E-Mail"]
          : "",
        CActive: userContactDetails.Inactive === false ? "Yes" : "No",
        AssignedManager: userContactDetails.MainUserId
          ? userContactDetails.MainUserId
          : -1,
        maincontact: userContactDetails.MainContact
          ? userContactDetails.MainContact
          : false,
        CCode: contactDetails[0].OriginatorNo
          ? contactDetails[0].OriginatorNo
          : "-1",
        Salutation: userContactDetails.Title ? userContactDetails.Title : "",
        accid: agrno,
      };

      dispatch(
        ad_Json_UpdateContact_Action(contactData, (res) => {
          // dispatch(setAllContactsByClientID([]));
          dispatch(
            getAllContactsByClientID(
              contactDetails[0]?.OriginatorNo,
              folderId ? folderId :getFolderObject
            )
          );
          if (location.pathname === "/user/portal-messages") {
            navigation("/user/portal-messages");
          }

          toast.success("Contact updated");
          handleClose51();
        })
      );
      let contactBody = {
        ClientId: contactDetails[0]?.OriginatorNo
          ? contactDetails[0]?.OriginatorNo
          : "-1",
        ContactNo: contactDetails[0]?.ContactNo,
        fieldName: "BirthDate",
        fieldValue: userContactDetails.BirthDate,
        projectid: "171",
      };
      dispatch(ad_UpdateContactField_Json_Action(contactBody, (res) => {}));
    }

    HandleUpdateUdf();
    refreshData && refreshData();
  };

  useEffect(() => {
    dispatch(
      ad_Json_GetAllContacts_Action((res) => {
        // console.log(res,"===== ad_Json_GetAllContacts_Action res")
      })
    );

    dispatch(
      ad_Json_GetAllClientList_Action((res) => {
        // console.log(res,"=== ad_Json_GetAllClientList_Action res");
        if (res && res.length > 0) {
          const filtercontact = res.find(
            (obj) => obj.ClientId == contactDetails[0].OriginatorNo
          );
          setdefaultClient(filtercontact);
        } else {
          const filteredData = res.find(
            (obj) => obj.ClientId === addContactData.Clientid
          );

          if (filteredData) {
            setdefaultClient(filteredData);
            defaultclientData = filteredData.ClientId;
            console.log(filteredData, "filteredData", res);
          }
        }
      })
    );
    let UdfBody = {
      ClientId: orgNo ? orgNo : getClientIdMethod,
      ContactId: contactNo,
      ProjectId: folderId ? folderId : getFolderObject,
    };
   (orgNo || getClientIdMethod) && dispatch(
      ad_Json_GetCRMContactUDFValues_Action(UdfBody, (res) => {
        setContactUdfFields(res);
      })
    );
  }, [dispatch]);

  const updateReferenceID = (client) => {
    let data = { ...userContactDetails };
    data = { ...data, ReferenceID: client };
    console.log(data, "Update ReferenceID");
    setContactDetails(data);
  };

  const onChangebussines = (event, value) => {
    event.preventDefault();
    if (value) {
      console.log(value, "valueclientid");
      clientData = value.ClientId;
      defaultclientData = value.ClientId;
      localStorage.setItem("origiNator", clientData);
      clientName = value.Client;
      setclientNames(clientName);
      // setDefaultFolders(value);
      setdefaultClient(value);
      updateReferenceID(value.Client);
    } else {
    }
  };

  const onChangecontactlist = (event, value) => {
    event.preventDefault();
    if (value) {
      let result = contactlistdata.filter(
        (el) => el["EmailName"] === value.EMailId
      );
      setContact(result[0]);
    } else {
    }
  };

  const onChangeImportData = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    console.log(inputValue, "import_data");
  };

  const handleOptionClick = (item) => {
    console.log(item, "onSelectData");
    let data = { ...userContactDetails };
    data = {
      ...data,
      ["Title"]: "",
      ["FirstName"]: "",
      ["LastName"]: "",
      ["ReferenceName"]: "",
      ["MainContact"]: "",
      ["Inactive"]: "",
      ["GreetingName"]: "",
      ["EmailName"]: "",
      ["MainUserId"]: -1,
      ["MainLine1Name"]: "",
      ["MainLine2Name"]: "",
      ["MainLine3Name"]: "",
      ["MainTownName"]: "",
      ["Notes"]: "",
      ["MainPostcodeName"]: "",
      ["Maincontactcountry"]: "",
      ["MainTelephoneName"]: "",
      ["MainMobileName"]: "",
      ["mainCountry"]: "",
      ["billingsCountry"]: "",
      ["ragistersCountry"]: "",
      ["ReferenceID"]: "",
      ["CreatePortal"]: "",
      ["Base64ImgData"]: "",
      ["RolesData"]: null,
    };
    setContactDetails(data);
    setImportcontactdata("");
    setTxtValue(item);
    setOpen(false);
    setImportcontactdata(item);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      console.log(e.target.value, "onChangeImportData");
    }
  };

  const handleChangeTextArea = (e) => {
    e.preventDefault();
    let data = { ...userContactDetails };
    data = { ...data, ["Notes"]: e.target.value };
    setContactDetails(data);
  };

  useEffect(() => {
    setAgrNo(localStorage.getItem("agrno"));
    // setIntUserid(localStorage.getItem("UserId"));
    if (contactDetails && contactDetails.length > 0) {
      // console.log(contactDetails, "contactdetailssonam");
      let item = contactDetails[0];
      let data = { ...userContactDetails };
      data = {
        ...data,
        ["Title"]: item.Title,
        ["FirstName"]: item["First Name"],
        ["LastName"]: item["Last Name"],
        ["ReferenceName"]: "",
        ["MainContact"]: item["Main Contact"],
        ["Inactive"]: item.Active === "Yes" ? false : true,
        ["GreetingName"]: item.Greeting,
        ["EmailName"]: item["E-Mail"],
        ["MainUserId"]: item["Assigned Manager"],
        ["MainLine1Name"]: item["Address 1"],
        ["MainLine2Name"]: item["Address 2"],
        ["MainLine3Name"]: item["Address 3"],
        ["MainTownName"]: item.Town,
        ["Notes"]: item.Note,
        ["MainPostcodeName"]: item.Postcode,
        ["Maincontactcountry"]: item.Country,
        ["MainTelephoneName"]: item.Tel,
        ["MainMobileName"]: item.Mobile,
        ["mainCountry"]: "",
        ["billingsCountry"]: "",
        ["ragistersCountry"]: "",
        ["ReferenceID"]: clientNames,
        ["CreatePortal"]: item["Portal User"],
        ["Base64ImgData"]: item.imgPath,
        ["BirthDate"]: item["Date Of Birth"],
        ["RolesData"]: item?.Role,
      };
      setContactDetails(data);
    }
    const clientName = localStorage.getItem("ClientName");
    // Update userContactDetails state with the retrieved value
    if (clientName) {
      setContactDetails((prevState) => ({
        ...prevState,
        ReferenceID: clientName,
      }));
    }
  }, []);

  const clearDataCard = () => {
    console.log("cleardata", getAllStateReduxSonam);
    setdefaultClient(null);
    let data = { ...userContactDetails };
    data = {
      ...data,
      ["Title"]: "",
      ["FirstName"]: "",
      ["LastName"]: "",
      ["ReferenceName"]: "",
      ["MainContact"]: "",
      ["Inactive"]: "",
      ["GreetingName"]: "",
      ["EmailName"]: "",
      ["MainUserId"]: -1,
      ["MainLine1Name"]: "",
      ["MainLine2Name"]: "",
      ["MainLine3Name"]: "",
      ["MainTownName"]: "",
      ["Notes"]: "",
      ["MainPostcodeName"]: "",
      ["Maincontactcountry"]: "",
      ["MainTelephoneName"]: "",
      ["MainMobileName"]: "",
      ["mainCountry"]: "",
      ["billingsCountry"]: "",
      ["ragistersCountry"]: "",
      ["ReferenceID"]: "",
      ["CreatePortal"]: "",
      ["Base64ImgData"]: "",
      ["RolesData"]: null,
    };
    setContactDetails(data);
  };

  // Udf update Functions start
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const HandleUdfSwitch = (e, data) => {
    setContactUdfFields((prevState) => ({
      ...prevState,
      Table1: prevState.Table1.map((udfField) =>
        udfField.UserDefFieldID === data
          ? {
              ...udfField,
              switchValue: udfField?.switchValue
                ? !udfField?.switchValue
                : true,
            }
          : udfField
      ),
    }));
  };

  const HandleUdfField = (event, index) => {
    // Helper function to update Table1
    const updateTable1 = (value) => {
      setContactUdfFields((prevData) => ({
        ...prevData,
        Table1: prevData?.Table1.map((item) =>
          item.UserDefFieldID === index ? { ...item, UdfValue: value } : item
        ),
      }));
    };

    if (event?.target?.value || event?.target?.id) {
      // Handle text or dropdown fields
      const { value, innerHTML } = event.target;
      const fieldValue =
        (value && value.trim()) || (innerHTML && innerHTML.trim());

      updateTable1(fieldValue);
      HandleUdfFieldChange(fieldValue, index);
    } else if (event) {
      // Handle date fields
      const formattedDate = dayjs(event).format("DD/MM/YYYY");
      console.log("Selected date:", formattedDate);

      // Update the value in Table1
      updateTable1(formattedDate);
      HandleUdfFieldChange(formattedDate, index);
    }
  };

  const HandleUdfFieldChange = (fieldValue, index) => {
    // contactUdfFields
    setUdfFieldChange((prevData) => {
      // Find the matching field from Table3
      const matchedField = contactUdfFields?.Table1.find(
        (item) => item?.UserDefFieldID === index
      );

      if (!matchedField) {
        console.error("Field not found in Table3");
        return prevData; // Return unchanged state if the field is not found
      }

      // Update the matched field with the new UdfValue
      const updatedField = { ...matchedField, UdfValue: fieldValue };

      if (prevData.length > 0) {
        // Check if the folder exists in udfFieldChange
        const folderIndex = prevData.findIndex(
          (item) => item.folderId === matchedField?.ProjectId
        );

        if (folderIndex !== -1) {
          // Folder exists; update or add the field
          const updatedFolder = {
            ...prevData[folderIndex],
            field: prevData[folderIndex].field.some(
              (field) => field.UserDefFieldID === index
            )
              ? prevData[folderIndex].field.map((field) =>
                  field.UserDefFieldID === index
                    ? updatedField // Update the field
                    : field
                )
              : [...prevData[folderIndex].field, updatedField], // Add the field
          };

          // Return updated state with the modified folder
          return prevData.map((item, i) =>
            i === folderIndex ? updatedFolder : item
          );
        }
      }

      // Add a new folder entry if it doesn't exist
      return [
        ...prevData,
        {
          folderId: updatedField?.ProjectId,
          field: [updatedField],
        },
      ];
    });
  };

  const HandleUpdateUdf = async () => {
    if (udfFieldChange.length > 0) {
      udfFieldChange.forEach((udf) => {
        let ProjectId = udf.folderId;

        if (udf.field.length > 0) {
          let ClientUDFString = udf.field
            .map((udfField) => {
              // Only include fields that have a valid UdfValue
              if (udfField?.UdfValue) {
                return `${udfField?.UserDefFieldID}_${
                  udfField?.UserDefFieldTypeID
                }_${
                  udfField?.TextControlValue
                }_UDF:${udfField?.UdfValue.trim()}`;
              }
              return null; // Return null for fields that don't meet the condition
            })
            .filter(Boolean) // Remove null or undefined values
            .join(","); // Join the array into a single string separated by commas
          let udfBody = {
            OriginatorNo: getClientIdMethod,
            ProjectId,
            ClientUDFString: "",
            ContactUDFString: ClientUDFString,
            ContactNo: contactNo,
          };

          dispatch(
            ad_Json_CRMSaveUDFValues_Action(udfBody, (res) => {
              let UdfBody = {
                ClientId: getClientIdMethod,
                ContactId: contactNo,
                ProjectId: folderId ? folderId : getFolderObject,
              };
              dispatch(
                ad_Json_GetCRMContactUDFValues_Action(UdfBody, (res) => {
                  setContactUdfFields(res);
                })
              );
              setUdfFieldChange([]);
            })
          );
        }
      });
    }
  };

  return (
    <Box className="container-fluid p-2">
      <Box sx={{ width: "100%", typography: "body1" }} className="">
        <Box className="general-tab white-box">
          <Box className="d-lg-flex">
            <Box className="mb-3 pe-2 me-2">
              <Box className="position-sticky top-0">
                <textarea
                  className="form-control textarea-2 mt-2"
                  placeholder="Notes.."
                  name="Notes"
                  value={userContactDetails?.Notes}
                  onChange={handleChangeTextArea}
                ></textarea>
              </Box>
            </Box>

            <Box className="mb-3 w-100">
              <Box className="well mb-4">
                <h2 className="font-20 mb-3 text-black">Contact Detail</h2>
                <Box className="well well-2 mb-3">
                  <Grid container spacing={2}>
                    <Grid item xs={10} md={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <h2 className="font-12 bold mb-2 text-black">
                            Import Existing DocuSoft Contact
                          </h2>
                          <Box className="custom-autocomplete">
                            <Autocomplete
                              // {...contactlist}
                              options={ad_GetAllContacts}
                              key={`contactlistkey`}
                              getOptionLabel={(option) => option.Email}
                              id="contactlist"
                              clearOnEscape
                              onChange={onChangecontactlist}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  name="contactlist"
                                  size="small"
                                  value={userContactDetails.EmailName}
                                  label="Enter Contact Email"
                                />
                              )}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <h2 className="font-12 bold mb-2 text-black">
                            Import from Companies House
                          </h2>
                          <Box className="custom-autocomplete">
                            <Autocomplete
                              fullWidth
                              size="small"
                              options={ImportContact} // Pass the entire ImportContact array
                              getOptionLabel={(option) =>
                                option.FirstName + " " + option.LastName
                              }
                              onChange={(e, value) => setImportContact(value)}
                              onKeyDown={handleKeyDown}
                              // inputValue={ImportContact}
                              noOptionsText="No matches found"
                              filterOptions={(x) => x}
                              autoComplete
                              includeInputInList
                              value={txtValue}
                              open={open} // Controlled by state
                              onOpen={() => setOpen(true)} // Open the Autocomplete dropdown
                              onClose={() => setOpen(false)} // Close the Autocomplete dropdown
                              renderOption={(props, option) => {
                                return (
                                  !option.resigned_on && (
                                    <div>
                                      <li
                                        {...props}
                                        onClick={() => {
                                          handleOptionClick(option); // Pass the id directly
                                        }}
                                      >
                                        <Grid container alignItems="center">
                                          <Grid
                                            item
                                            sx={{
                                              width: "calc(100% - 44px)",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {option.FirstName +
                                              " " +
                                              option.LastName}
                                          </Grid>
                                        </Grid>
                                      </li>
                                    </div>
                                  )
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  name="importclient"
                                  onChange={onChangeImportData}
                                  label="Enter Company Name"
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      md={1}
                      className="d-flex align-items-end ps-0 pb-1"
                    >
                      <Button className="min-width-auto text-danger">
                        <HighlightOffIcon
                          className="font-32"
                          onClick={clearDataCard}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="well well-2 mb-3">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box className="custom-autocomplete">
                        <Autocomplete
                          options={ad_GetAllClientList}
                          key={"someDynamicValue"} // Use a dynamic and unique key here
                          getOptionLabel={(option) => option.Client}
                          id="clear-on-escape-teams"
                          clearOnEscape
                          value={defaultClient || null}
                          onChange={onChangebussines}
                          filterOptions={(options, { inputValue }) =>
                            options.filter((option) =>
                              option.Client.toLowerCase().includes(
                                inputValue.toLowerCase()
                              )
                            )
                          }
                          autoHighlight // Highlight the first suggestion
                          selectOnFocus // Select suggestion when input is focused
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Selectteamsa"
                              value={""}
                              size="small"
                              label="Reference List"
                              variant="outlined"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <ContactMainForm
                      clientNames={clientNames}
                      contact={contact}
                      setContact={setContact}
                      contactlistdata={contactlistdata}
                      userContactDetails={userContactDetails}
                      setContactDetails={setContactDetails}
                      Importcontactdata={Importcontactdata}
                      setImportcontactdata={setImportcontactdata}
                      contactDetails={contactDetails}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* <hr /> */}

              <Box className="well mb-4" style={{ display: "none" }}>
                <h2 className="font-20 mb-3 text-black">AML Details</h2>
              </Box>

              {/* <hr /> */}

              {contactUdfFields &&
                contactUdfFields?.Table &&
                contactUdfFields?.Table.length > 0 && (
                  <Box className="well mb-4">
                    <h2 className="font-20 mb-3 text-black">UDF Details</h2>
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: 224,
                      }}
                    >
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                          borderRight: 1,
                          borderColor: "divider",
                          width: 200,
                        }}
                      >
                        {contactUdfFields?.Table.map((tbHead) => {
                          const tabData = contactUdfFields?.Table1.find(
                            (udfFieId) =>
                              Number(tbHead?.TagID) === Number(udfFieId?.Tag)
                          );
                          if (tabData) {
                            return (
                              <Tab
                                label={tbHead?.TagName}
                                {...a11yProps(0)}
                                sx={{ padding: "0px 20px" }}
                              />
                            );
                          } else return;
                        })}

                        {/* {contactUdfFields?.Table.map((tbHead) => (
                        <Tab
                          label={tbHead?.TagName}
                          {...a11yProps(0)}
                          sx={{ padding: "0px 20px" }}
                        />
                      ))} */}
                      </Tabs>
                      {contactUdfFields?.Table.map((tbHead, index) => {
                        const udfFilterData = contactUdfFields?.Table1.filter(
                          (udfFieId) =>
                            Number(tbHead?.TagID) === Number(udfFieId?.Tag)
                        );
                        return (
                          <TabPanel value={value} index={index}>
                            <Grid container key={index} spacing={2}>
                              {udfFilterData.length > 0
                                ? udfFilterData.map((udfFieId, udfIndex) => (
                                    <Grid item xs={12} md={6} key={udfIndex}>
                                      <Box className=" gap-2 w-100">
                                        <Box className="d-flex justify-content-between gap-2 align-items-center">
                                          <Typography className="">
                                            {udfFieId?.Name}
                                          </Typography>
                                          <Switch
                                            checked={
                                              udfFieId?.switchValue
                                                ? udfFieId?.switchValue
                                                : false
                                            }
                                            name={`blnProcess-${udfFieId?.UserDefFieldID}`}
                                            onChange={(e) =>
                                              HandleUdfSwitch(
                                                e,
                                                udfFieId?.UserDefFieldID
                                              )
                                            }
                                          />
                                        </Box>

                                        <UserUdfEditField
                                          udfFieId={udfFieId}
                                          changeFun={HandleUdfField}
                                        />
                                      </Box>
                                    </Grid>
                                  ))
                                : "No data found"}
                            </Grid>
                          </TabPanel>
                        );
                      })}
                    </Box>
                  </Box>
                )}

              {/* <Button onClick={HandleUpdateUdf}>Update Udf</Button> */}

              {addContactData && addContactData == {} ? (
                <Button
                  style={{ marginTop: "5px" }}
                  variant="contained"
                  //  disabled={!clientData || !selectedFolderID}
                  onClick={handleSubmit}
                  className="btn-blue"
                  startIcon={<AddIcon />}
                >
                  Add New Contact
                </Button>
              ) : contactDetails && contactDetails.length > 0 ? (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  className="btn-blue"
                  startIcon={<UpdateIcon />}

                >
                  Update Contact
                </Button>
              ) : (
                <Button
                  style={{ marginTop: "5px" }}
                  variant="contained"
                  disabled={!defaultclientData}
                  onClick={handleSubmit}
                  className="btn-blue"
                  startIcon={<AddIcon />}
                >
                  Add New Contact
                </Button>
              )}
            </Box>
          </Box>

          <Box className="mb-3">
            {/* <MainContact 
      userContactDetails={userContactDetails}
      setContactDetails={setContactDetails}
      /> */}
            <Grid container spacing={3}></Grid>
          </Box>
        </Box>
        {/* end */}
      </Box>
    </Box>
  );
}
export default AddContacts;
