import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetAllReceivedMessages_Json_Redux,
  GetAllSentMessages_Json_Redux,
  GetRetractedMessages_Json_Redux,
} from "../../../redux/api_helper";
import {
  ad_MessageDeleted_Action,
  ad_MessageRetracted_Action,
  ad_MessageUnRetracted_Action,
  ad_SendReminderByMsgId_Json_Action,
} from "../../../redux/dev_aditya_action";
import { markMessageAsViewed } from "../../../redux/dev_chetan_api_helper";
import { userBodyDAtaa } from "../../../utils/RequestsFunction";
import { OpenPendingMessageModalReducer } from "../../../redux/adityaReducer";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

function Row(props) {
  const { row, value, originalData, selectedItem, setSelectedItem, isChecked } =
    props;
  const [open, setOpen] = React.useState(false);

  // dropdown
  const [MessageanchorEl, MessagesetAnchorEl] = React.useState(null);
  const Messageopen = Boolean(MessageanchorEl);
  const handleClick = (event) => {
    MessagesetAnchorEl(event.currentTarget);
  };

  const handleTableBodyCheck = (e) => {
    const checkValue = e.target.checked;
    if (checkValue) {
      setSelectedItem((prevdata) => [...prevdata, row]);
    } else {
      const filterData = selectedItem.filter(
        (item) => item?.MessageNo !== row?.MessageNo
      );
      setSelectedItem(filterData);
    }
  };

  const MessagehandleClose = () => {
    // dispatch(ad_MessageRetracted_Action())
    MessagesetAnchorEl(null);
  };

  const getSentMassage = () => {
    let body = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };

    let receivedObj = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };

    dispatch(GetAllSentMessages_Json_Redux(body, function (res) {}));
    // dispatch(GetAllReceivedMessages_Json_Redux(receivedObj, function (res) {}));
    // dispatch(GetRetractedMessages_Json_Redux(receivedObj, function (res) {}));
  };

  const HandleUntractMessage = (data) => {
    MessagesetAnchorEl(null);
    dispatch(
      ad_MessageRetracted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );

    MessagesetAnchorEl(null);
  };

  const HandleDeleteMessaage = (data) => {
    MessagesetAnchorEl(null);
    dispatch(
      ad_MessageDeleted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );
  };

  const HandleReminderMessge = () => {
    MessagesetAnchorEl(null);
    let body = {
      messageID: row?.MessageNo,
      forApproval: row?.ApprovalRequested,
      isAppcodeReq: false,
      forApprovalId: "",
      contactEmail: row.History[3]?.customerId,
      copyEmail: true,
    };
    dispatch(
      ad_SendReminderByMsgId_Json_Action(body, (res) => {
        console.log("✌️res --->", res);
      })
    );
  };

  const HandleLaunchNewTab = () => {
    if (row?.MessageNo) {
      dispatch(markMessageAsViewed(row?.MessageNo));
    }
    if (value === "2") {
      let customerId = row.History[2].customerId;
      let url = `/user/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${
        row.MessageNo
      }&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(
        row.Issued
      )}&Viewed=${formatDate(row.Viewed)}&ClientName=${
        row.ReferenceName
      }&customerId=${customerId}&Status=Yes&TabIndex=${value}`;
      window.open(url, "_blank");
    } else {
      let customerId = row.History[3].customerId;
      let url = `/user/portalmessage/sent/outgoing-message-viewer?PortalDocID=${
        row.MessageNo
      }&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(
        row.Issued
      )}&Viewed=${formatDate(row.Viewed)}&ClientName=${
        row.ReferenceName
      }&Status=Yes&MessageID=${
        row.MessageID
      }&customerId=${customerId}&TabIndex=${value}`;
      window.open(url, "_blank");
    }
  };

  const HandleUnRetractMessage = (data) => {
    MessagesetAnchorEl(null);
    dispatch(
      ad_MessageUnRetracted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );
  };

  const OnViewMessage = () => {
    MessagesetAnchorEl(null);
    let customerId = row.History[3].customerId;
    const data = {
      PortalDocID: row.MessageNo,
      Section: row.DueDate,
      Subject: row.Subject,
      Issued: formatDate(row.Issued),
      Viewed: formatDate(row.Viewed),
      ClientName: row.ReferenceName,
      Status: "Yes", // Assuming the status is "Yes" in this case
      MessageID: row.MessageID,
      customerId: customerId,
      TabIndex: value,
    };

    // Navigate with the state
    navigate("/user/portalmessage/sent/outgoing-message-viewer", {
      state: data,
    });
    closeModal();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (date) => moment(date).format("DD-MM-YYYY");

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Pending":
        return "badge text-bg-warning";
      case "Unread":
        return "badge text-bg-primary";
      case "Disapproved":
        return "badge text-bg-danger";
      default:
        return "badge text-bg-success";
    }
  };

  const isValidDate = (date) => dayjs(date, "DD/MM/YYYY", true).isValid();
  const closeModal = () => dispatch(OpenPendingMessageModalReducer(false));
  return (
    <React.Fragment>
      {
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          onDoubleClick={() => {
            // dispatch(markMessageAsViewed(row?.["Message No"]));
            // navigate(
            //   `/user/portalmessage/sent/outgoing-message-viewer?PortalDocID=${
            //     row.MessageNo
            //   }&Section=${row.DueDate}&Subject=${
            //     row.Subject
            //   }&Issued=${formatDate(row.Issued)}&Viewed=${formatDate(
            //     row.Viewed
            //   )}&ClientName=${row.ReferenceName}&Status=Yes&MessageID=${
            //     row.MessageID
            //   }&customerId=${customerId}&TabIndex=${value}`
            // );

            let customerId = row.History[3].customerId;
            const data = {
              PortalDocID: row.MessageNo,
              Section: row.DueDate,
              Subject: row.Subject,
              Issued: formatDate(row.Issued),
              Viewed: formatDate(row.Viewed),
              ClientName: row.ReferenceName,
              Status: "Yes", // Assuming the status is "Yes" in this case
              MessageID: row.MessageID,
              customerId: customerId,
              TabIndex: value,
            };

            // Navigate with the state
            navigate("/user/portalmessage/sent/outgoing-message-viewer", {
              state: data,
            });
            closeModal();
          }}
        >
          <TableCell className="table-arrow">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <Checkbox onChange={handleTableBodyCheck} checked={isChecked} size="small" className="font-12" />
            </IconButton>
          </TableCell>
          <TableCell scope="row" align="left" data-th="Subject">
            {row.Subject}
          </TableCell>
          <TableCell align="center" data-th="Recipient">
            <div className="clearfix">
              <p>{row.Recipient}</p>
              <span className="badge text-bg-primary">
                {row?.ReferenceName}
              </span>
            </div>
          </TableCell>
          <TableCell align="center" data-th="Approval Requested">
            <span
            //   className={
            //     row.ApprovalRequested === "Yes"
            //       ? "badge text-bg-success"
            //       : "badge text-bg-primary"
            //   }
            >
              {row.Section.includes(".") ? row.Section.slice(3) : row.Section}
            </span>
          </TableCell>
          <TableCell align="center" data-th="Issued On">
            {row.IssuedOn}
          </TableCell>
          {/* <TableCell align="center" data-th="Due Date">
              <span
                className={
                  row?.DueDate === "Not available" && "badge text-bg-warning"
                }
              >
                {row.DueDate}
              </span>
            </TableCell> */}

          <TableCell data-th="Status">
            <Box className="d-flex align-items-center justify-content-center">
              {/* <Box>
                  <span className={getStatusBadgeClass(row.Status)}>
                    {row.Status}{" "}
                  </span>
                </Box> */}
              <Box className="ms-2">
                <IconButton
                  id="basic-button"
                  aria-controls={Messageopen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Messageopen ? "true" : undefined}
                  onClick={handleClick}
                  size="small"
                  className="p-1 btn-icon"
                >
                  <MoreVertIcon className="font-18" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={MessageanchorEl}
                  open={Messageopen}
                  onClose={MessagehandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                >
                  {/* {value === 1 ? (
                        <MenuItem onClick={MessagehandleClose}>
                          <UnsubscribeIcon className="me-2" /> Retract Message
                        </MenuItem>
                    ) : value === 2 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <UnsubscribeIcon className="me-2" /> Docusoft Message
                      </MenuItem>
                    ) : (
                      <MenuItem 
                      // onClick={MessagehandleClose}
                      onClick={()=> console.log("=====>")}
                      >
                        <UnsubscribeIcon className="me-2" />
                        Unretract Message
                      </MenuItem>
                    )}
                    {value === 1 ? (
                        <MenuItem onClick={MessagehandleClose}>
                          <InfoIcon className="me-2" /> Change to Information Only
                        </MenuItem>
                    ) : (
                      ""
                    )} */}
                  {Number(value) === 1 && (
                    <MenuItem onClick={() => HandleUntractMessage(row)}>
                      <UnsubscribeIcon className="me-2" />
                      Retract Message
                    </MenuItem>
                  )}

                  {Number(value) === 3 && (
                    <MenuItem onClick={() => HandleUnRetractMessage(row)}>
                      <UnsubscribeIcon className="me-2" />
                      Unretract Message
                    </MenuItem>
                  )}

                  {Number(value) === 1 && (
                    <MenuItem onClick={() => HandleDeleteMessaage(row)}>
                      <DeleteIcon className="me-2" /> Delete Message
                    </MenuItem>
                  )}
                  <MenuItem onClick={HandleReminderMessge}>
                    <NotificationsActiveOutlinedIcon className="me-2" /> Send
                    Reminder
                  </MenuItem>
                  <MenuItem onClick={OnViewMessage}>
                    <DraftsOutlinedIcon className="me-2" /> View Message
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      }

      {/* <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
            colSpan={7}
            className="table-before-none"
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="well-box mt-2 p-2">
                <Box className="mb-0">
                  <Grid container spacing={0} className="mb-">
                    <Grid item lg={5} xs={12} md={5}>
                      <Box className="table-inbox me-0">
                        <Table
                          size="small"
                          aria-label="purchases"
                          className="table-grid"
                        >
                          <TableBody>
                            {row?.History?.map((historyRow) => (
                              <TableRow key={historyRow.date}>
                                <TableCell
                                  // component="th"
                                  scope="row"
                                  className="lato-semibold"
                                >
                                  {historyRow.date}
                                </TableCell>
                                <TableCell>{historyRow.customerId}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Grid>
                    <Grid item lg={7} xs={8} md={7}></Grid>
                  </Grid>
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
    </React.Fragment>
  );
}

const PendingMessageComponanat = ({
  TableHeder,
  TableBodyData,
  setSelectedItem,
  selectedItem,
  originalData,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChecked = (e) => {
    const chackValue = e.target.checked;
    setChecked(chackValue);
    chackValue ? setSelectedItem(originalData) : setSelectedItem([]);
  };

  useEffect(() => {
    originalData.length === selectedItem.length
      ? setChecked(true)
      : setChecked(false);
  }, [selectedItem, originalData]);

  return (
    <div>
      <Table
        className="table custom-table table-mobile"
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            {TableHeder &&
              TableHeder.length > 0 &&
              TableHeder.map((item, index) =>
                index === 0 ? (
                  <React.Fragment key={index}>
                    <TableCell
                    //   className="lato-semibold text-white"
                    //   align="center"
                    >
                      <Checkbox
                        onChange={handleChecked}
                        checked={checked}
                        sx={{ zIndex: 10000 }}
                      />
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    {
                      <TableCell className="lato-semibold" align="center">
                        {item}
                      </TableCell>
                    }
                  </React.Fragment>
                )
              )}
          </TableRow>
        </TableHead>

        <TableBody>
          {TableBodyData &&
            TableBodyData.length > 0 &&
            TableBodyData?.map((row, index) => {
              const isChecked = selectedItem.some(
                (item) => item.MessageNo === row.MessageNo
              );

              return (
                <Row
                  key={index}
                  row={row}
                  value={1}
                  setSelectedItem={setSelectedItem}
                  selectedItem={selectedItem}
                  originalData={originalData}
                  isChecked={isChecked}
                />
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default PendingMessageComponanat;
