import { combineReducers, configureStore } from "@reduxjs/toolkit";

import docuReducer from "./docuSlice";
import modalReducer from "./modalSlice";
import dev_chetan_reducer from "./dev_chetan_slice";
import dev_sonam_reducer from "./dev_sonam_slice";
import akReducer from "./akSlice";
import adityaReducer from "./adityaReducer";

//Old Store

// export default configureStore({
//     reducer: {
//         docu: docuReducer,
//         modals: modalReducer,
//         dev_chetan: dev_chetan_reducer,
//         dev_sonam: dev_sonam_reducer,
//         ak: akReducer,
//         aditya:adityaReducer
//     }
// })

// New Store craeated

// Combine all reducers
const combinedReducer = combineReducers({
  docu: docuReducer,
  modals: modalReducer,
  dev_chetan: dev_chetan_reducer,
  dev_sonam: dev_sonam_reducer,
  ak: akReducer,
  aditya: adityaReducer,
});

// Root reducer that resets state on "RESET_STATE" action
const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined; // Reset all slices
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
