import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Switch,
  Button,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUdfValuesAction } from "../redux/dev_aditya_action";

const EditUDFClientCard = React.memo(({ data }) => {
  const [selectManager, setselectManagers] = useState([]);
  const [selectedDatetest, setSelectedDatetest] = useState([]);
  const dispatch = useDispatch();

  const getOrigantryNumber = useSelector(
    (state) => state.modals?.getOrigantryNumber
  );

  const handleUpdate = () => {
    dispatch(UpdateUdfValuesAction(setSelectedDatetest));
  };

  const Json_GetForwardUserList = () => {
    let obj = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("Password"),
      ProjectId: localStorage.getItem("ProjectId"),
      SectionId: -1,
    };
  };

  const typingTimeoutRef = useRef(null);

  const handleInputChange = (e, data) => {
    e.preventDefault();
    const { id, value } = e.target;
    const spt = id.split("-");

    setSelectedDatetest((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      dispatch(
        UpdateUdfValuesAction({ value, data, getOrigantryNumber }, (res) =>
          console.log("field updated")
        )
      );
    }, 2000);

    const newData = spt[0] + ":" + value;
  };

  const handleInputOnDateChage = (e, value, data) => {
    let date = dayjs(e).format("YYYY/MM/DD");
    setSelectedDatetest((prevData) => ({
      ...prevData,
      [value]: date,
    }));

    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      dispatch(
        UpdateUdfValuesAction(
          { value: date, data, getOrigantryNumber },
          (res) => console.log("field updated")
        )
      );
    }, 2000);
  };

  const handleInputOnSelect = (e, value, data) => {
    const { id } = e.target;
    let spt = id.split("-");
    const newData = spt[0] + ":" + value;
    setSelectedDatetest((prevData) => ({
      ...prevData,
      [spt[0]]: value,
    }));

    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      dispatch(
        UpdateUdfValuesAction({ value, data, getOrigantryNumber }, (res) =>
          console.log("field updated")
        )
      );
    }, 2000);
  };

  useEffect(() => {
    Json_GetForwardUserList();
  }, []);

  useEffect(() => { }, [selectedDatetest]);

  const renderTextField = (data, type) => (
    <TextField
      fullWidth
      size="small"
      label={data.Name}
      variant="outlined"
      type={type}
      id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_${data.TextControlValue}_UDF`}
      defaultValue={data.UdfValue || ""}
      onChange={(e) => handleInputChange(e, data)}
      InputProps={{
        readOnly: !data.ClientEdit, // Set readOnly to true when not editable
      }}
      name={data.Name}
    />
  );

  const renderDatePicker = (data) => {
    let formattedDate;
    if (data?.UdfValue) {
      const dateStr = data?.UdfValue;
      const [month, day, year] = dateStr.split(" ");
      formattedDate = `${year}-${(
        "0" +
        (new Date(Date.parse(month + " 1, " + year)).getMonth() + 1)
      ).slice(-2)}-${("0" + day).slice(-2)}`;
    } else {
      formattedDate = null;
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={[
            "DatePicker",
            "TimePicker",
            "DateTimePicker",
            "DateRangePicker",
          ]}
        >
          <DatePicker
            // className="custom-date-2"
            defaultValue={dayjs(formattedDate)}
            label={data.Name}
            id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_${data.TextControlValue}_UDF`}
            onChange={(e) =>
              handleInputOnDateChage(
                e,
                `${data.UserDefFieldID}_${data.UserDefFieldTypeID}_${data.TextControlValue}_UDF`,
                data
              )
            }
            InputProps={{
              readOnly: true,
            }}
            disabled={!data.ClientEdit}
            sx={{
              '& .MuiInputBase-root': {
                height: 36,  // Adjust input height
                fontSize: '0.875rem', // Adjust font size 
              },
              '& .MuiInputLabel-root': {
                fontSize: '0.75rem', // Adjust label font size 
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.0rem', // Adjust the calendar icon size 
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  };

  const renderAutocomplete = (data, options) => (
    <>
      <Box className="custom-autocomplete">
        <Autocomplete
          id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_UDF`}
          options={options}
          defaultValue={data.UdfValue}
          onChange={(event, value) => handleInputOnSelect(event, value, data)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" label={data.Name} />
          )}
          disabled={!data.ClientEdit}
          size="small"
        />
      </Box>
    </>
  );

  const renderDynamicInput = (data) => {
    switch (data?.UserDefFieldTypeID) {
      case 1: // Text input
        switch (data?.TextControlValue) {
          case "Integer":
            return renderTextField(data, "number");
          case "String":
            return renderTextField(data, "text");
          case "Decimal":
          case "Currency":
            return renderTextField(data, "text");
          case "Date":
            return renderDatePicker(data);
          case "Memo":
            return renderTextField(data, "text");
        }
        break;

      case 2: // Select input (Autocomplete)
        const options =
          data?.Options?.split("@;")?.filter((option) => option.trim()) || [];
        return renderAutocomplete(data, options);

      case 10: // Boolean Switch
        return (
          <FormControlLabel
            control={
              <Switch
                id={`${data.UserDefFieldID}_${data.UserDefFieldTypeID}_UDF`}
                onChange={handleInputChange}
              />
            }
            label={data.Name}
          />
        );

      case 15: // ComboBox
        const selectOptions =
          selectManager.map((option) => option.ForwardTo) || [];
        return renderAutocomplete(data, selectOptions);

      default:
        return null;
    }
  };

  return (
    <>
      <Box className="white-box">
        <h2 className="title font-18">UDF values</h2>
        {/* <hr /> */}
        <Grid className="mt-0 date-padding-0" container spacing={2}>
          {data?.map((udf, i) => {

            console.log('testing by jay prakash data' , udf,   udf.ClientView , udf.Name)
            return (
              udf.ClientView ? (
                <Grid item xs={12} sm={6} md={12} lg={6} key={i}>
                  {renderDynamicInput(udf)}
                </Grid>
              ) : null
            );
          })}
          {/* <Button className="mx-4 mt-4" onClick={handleUpdate}>
            Update
          </Button> */}
        </Grid>
      </Box>
    </>
  );
});

export default EditUDFClientCard;
