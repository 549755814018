import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useCallback } from "react";
import { Outlet } from "react-router-dom";
import AllModals from "../modals/AllModals";
import { DrawerHeader } from "../style-utils/sidebarNavUtils";
import CustomAppbar from "./CustomAppbar";
import CustomDrawer from "./CustomDrawer";
import { IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";

const SideBar = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const isOpenOnboardingAlert = useSelector(state=> state.dev_chetan.isOpenOnboardingAlert);

  const [open, setOpen] = React.useState(!isMobile);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomAppbar open={open} handleDrawerOpen={handleDrawerToggle} />
      <CustomDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      {/* {isMobile && open && <CustomDrawer open={open} handleDrawerToggle={handleDrawerToggle} />}
    {!isMobile && <CustomDrawer open={open} handleDrawerToggle={handleDrawerToggle} />} */}
      <Box component="main" sx={{ flexGrow: 1, px: 2, py: 1, width: '85%' }}>

        <DrawerHeader />

        
        {isOpenOnboardingAlert && <Box class="alert alert-warning mb-0 mt-2 d-flex align-items-center justify-content-between py-1" role="alert">
          <p className="fw-semibold">Onboarding in progress, 4/6 steps completed. <a href="#" className="text-red underline" onClick={() => {
            ('client/onboarding')
          }}>Continue</a></p>
        </Box>}
       

        <AllModals />
        <Outlet />
      </Box>
    </Box>
  ); 
};

export default SideBar;
