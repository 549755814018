import { Box, StepContext } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import SetupInvite from './SetupInvite';
import StepContacts from './StepContacts';
import StepRequests from './StepRequests';
import EditIcon from '@mui/icons-material/Edit';
import AdditionalSettings from './AdditionalSettingsModal';
import AdditionalSettingsModal from './AdditionalSettingsModal';

function StepReview() {
  return (
    <div>

      <Box className='d-flex justify-content-between flex-wrap my-2'>
        <h2 className='title'>4. Review your invite</h2>
        <Box>
          <Button variant="contained" className='btn-blue me-3'>
            Send Invite
          </Button>
        </Box>
      </Box>

      <SetupInvite />

      <hr />

      <StepContacts />

      <hr />
      <StepRequests />

      <hr />
      <Box className='d-flex justify-content-between align-items-center'>
        <h2 className='title'>Additional Settings</h2>
        {/* <Button variant="contained" className=''>
          <EditIcon />
        </Button> */}

        <AdditionalSettingsModal />
      </Box>

      <div className="addition-icon">
        <p>
          <svg data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-envelope fa-w-16 fa-lg"><g transform="translate(256 256)" className=""><g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)" className=""><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" transform="translate(-256 -256)" className=""></path></g></g></svg> <span> Almost Due Reminder: <span> 1 day before due</span></span></p>
        <p>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-envelope fa-w-16 fa-lg"><g transform="translate(256 256)" className=""><g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)" className=""><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" transform="translate(-256 -256)" className=""></path></g></g></svg><span> Overdue Reminder: <span> Every 2 days</span></span></p>
        <p>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="cloud" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="svg-inline--fa fa-cloud fa-w-20 fa-lg"><g transform="translate(320 256)" className=""><g transform="translate(-64, 0)  scale(0.875, 0.875)  rotate(0 0 0)" className=""><path fill="currentColor" d="M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96z" transform="translate(-320 -256)" className=""></path></g></g></svg><span> Cloud Storage: <span> Disabled </span></span></p>
        <p>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="key" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-key fa-w-16 fa-lg"><g transform="translate(256 256)" className=""><g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)" className=""><path fill="currentColor" d="M320 48c79.529 0 144 64.471 144 144s-64.471 144-144 144c-18.968 0-37.076-3.675-53.66-10.339L224 368h-32v48h-48v48H48v-96l134.177-134.177A143.96 143.96 0 0 1 176 192c0-79.529 64.471-144 144-144m0-48C213.965 0 128 85.954 128 192c0 8.832.602 17.623 1.799 26.318L7.029 341.088A24.005 24.005 0 0 0 0 358.059V488c0 13.255 10.745 24 24 24h144c13.255 0 24-10.745 24-24v-24h24c13.255 0 24-10.745 24-24v-20l40.049-40.167C293.106 382.604 306.461 384 320 384c106.035 0 192-85.954 192-192C512 85.965 426.046 0 320 0zm0 144c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z" transform="translate(-256 -256)" className=""></path></g></g></svg><span> Password Protected: <span> No </span></span></p>
        <p>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-user fa-w-14 fa-lg" ><g transform="translate(224 256)" className=""><g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)" className=""><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" transform="translate(-224 -256)" className=""></path></g></g></svg><span> Display User: <span> No User Selected </span></span></p>
        <p>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-user fa-w-14 fa-lg" ><g transform="translate(224 256)" className=""><g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)" className=""><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" transform="translate(-224 -256)" className=""></path></g></g></svg> <span> Display User: </span> No User Selected
        </p>
      </div>

      {/*
      <StepReview /> */}


    </div>
  )
}

export default StepReview