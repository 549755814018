import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../components/CustomLoader";
import NoData from "../components/NoData";
import { ad_Json_CompanyHouseDetails_Action } from "../redux/dev_aditya_action";

const CompanyChModal = ({
  handleClose,
  setCompanyFormData,
  setContactFormData,
  setAllAddressData,
}) => {
  const { ad_OpenChModal, ad_CompanyHouseData } = useSelector(
    ({ aditya }) => aditya
  );
  const dispatch = useDispatch();

  const [chModalOpen, setChModalOpen] = useState(false);
  const [chfieldValue, setChfieldValue] = useState("");
  const [companyHouseAllDetail, setCompanyHouseAllDetail] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [showCompanyList, setShowCompanyList] = useState(true);
  const [showCompanyDetail, setShowCompanyDetail] = useState(false);
  const [showContactList, setShowContactList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataNot, setDataNot] = useState(true);

  const handleChOnchange = (event) => {
    setChfieldValue(event.target.value);
    // console.log("✌️event.target.value --->", event.target.value);
    setShowCompanyList(false);
    setShowCompanyDetail(false);
    setShowContactList(false);
  };

  useEffect(() => {
    const searchChCompanies = setTimeout(() => {
      if (chfieldValue.trim() && chfieldValue.trim().length > 2) {
        setLoading(true);
        let chBody = { CompanyName_Number: chfieldValue.trim() };
        dispatch(
          ad_Json_CompanyHouseDetails_Action(chBody, (res) => {
            if (res) {
              console.log("✌️res --CompanyHouseDetails->", res);
              setCompanyHouseAllDetail(res);
              setLoading(false);
              setDataNot(false);
              setShowCompanyList(true);
            } else {
              setLoading(false);
              setDataNot(true);
            }
          })
        );
      }
    }, 1000);

    return () => clearTimeout(searchChCompanies);
  }, [chfieldValue]);

  const handleClickCompany = (com) => {
    // console.log("✌️com --->", com);
    setLoading(true);
    setShowCompanyList(false);
    setShowCompanyDetail(true);
    let chBody = { CompanyName_Number: com?.company_number.trim() };
    dispatch(
      ad_Json_CompanyHouseDetails_Action(chBody, (res) => {
        if (res) {
          let data = { ...res, CompanyDetails: res?.CompanyDetails[0] };
          setCompanyData(data);
          setShowCompanyList(false);
          setShowCompanyDetail(true);
          setShowContactList(false);
          setLoading(false);
          setDataNot(false);
        } else {
          setLoading(false);
          setDataNot(true);
        }
      })
    );
  };

  const handleSelectCompany = (obj) => {
    if (!obj?.CompanyDetails) return; // Ensure valid data
    console.log("handleSelectCompany called with obj:", obj);
    setDataNot(false);
    let clientData = {
      Name: obj?.CompanyDetails?.company_name,
      Town: "",
      "Post Code": obj?.CompanyDetails?.postal_code,
      "Address Line 1": obj?.CompanyDetails?.address_line_1,
      "Address Line 2": obj?.CompanyDetails?.address_line_2,
      "Address Line 3": "",
      status: obj?.CompanyDetails?.company_status,
      Country: obj?.CompanyDetails?.country,
    };
    let add = {
      Add1: obj?.CompanyDetails?.address_line_1,
      Add2: obj?.CompanyDetails?.address_line_2,
      Add3: "",
      Town: obj?.CompanyDetails?.locality,
      County: obj?.CompanyDetails?.region,
      Postcode: obj?.CompanyDetails?.postal_code,
      Country: obj?.CompanyDetails?.country?obj?.CompanyDetails?.country:"United Kingdom",
    };
    setCompanyFormData((prevD) => ({ ...prevD, ...clientData }));
    setAllAddressData((prev) => {
      if (prev.length === 0) return [add];
      return [{ ...prev[0], ...add }, ...prev.slice(1)];
    });

    setShowCompanyList(false);
    setShowCompanyDetail(false);
    setShowContactList(true);
  };

  const handleSelectContact = (d) => {
    console.log("✌️d --->", d);
    let data = {
      Add1: d?.address_line_1,
      Add2: d?.address_line_2,
      CActive: "Yes",
      Country: d?.country?d?.country:"United Kingdom",
      FirstName: d?.FirstName,
      LastName: d?.LastName,
      PostCode: d?.postal_code,
      Town: "",
      maincontact: true,
    };

    setContactFormData((prevData) => ({ ...prevData, ...data }));
    handleClose();
  };

  return (
    <>
      <TextField
        fullWidth
        // label="Search ....."
        id="outlined-size-small"
        size="small"
        value={chfieldValue}
        name="Name"
        onChange={handleChOnchange}
        placeholder="Search here...."
      />
      {!loading && dataNot && <NoData message="No Data Here" />}
      {loading ? (
        <CustomLoader />
      ) : (
        <Box>
          {companyHouseAllDetail && (
            <Grid container>
              {showCompanyList &&
                companyHouseAllDetail?.CompanyBasicDetails &&
                companyHouseAllDetail?.CompanyBasicDetails?.map(
                  (com, index) => (
                    <Grid item xs={6} key={index}>
                      <Box
                        sx={{ marginRight: 2 }}
                        onClick={() => handleClickCompany(com)}
                      >
                        <Typography className="" variant="h6">
                          {com?.title}
                        </Typography>
                        <Typography>{com?.date_of_creation}</Typography>
                      </Box>
                      <hr />
                    </Grid>
                  )
                )}
            </Grid>
          )}

          {showCompanyDetail &&
            Object.keys(companyData?.CompanyDetails || {}).length > 0 && (
              <Box>
                {Object.entries(companyData.CompanyDetails).map(
                  ([key, value]) =>
                    value && value !== false && value !== "False" ? (
                      <Box
                        key={key}
                        className="d-flex align-items-center gap-3 mt-2"
                      >
                        <Typography>{key.replace(/_/g, " ")}</Typography>
                        <Typography>{value}</Typography>
                      </Box>
                    ) : null
                )}
                <Button
                  autoFocus
                  variant="outlined"
                  className="btn-blue"
                  onClick={() => handleSelectCompany(companyData)}
                >
                  Select
                </Button>
              </Box>
            )}

          {showContactList &&
            companyData?.ContactDetails.length > 0 &&
            companyData?.ContactDetails.map((data, key) => (
              <Box key={key}>
                <Box>
                  <Box>
                    <Typography className="" variant="h6">
                      {data?.name}
                    </Typography>
                    <Button
                      autoFocus
                      variant="outlined"
                      className="btn-blue"
                      onClick={() => handleSelectContact(data)}
                    >
                      Set as Main Contact
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </>
  );
};

export default CompanyChModal;
