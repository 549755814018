import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import EditUDFClientCard from "../../components/EditUDFClientCard";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import profileBG from "../../assets/images/profile.jpg";
import FileUpload from "../../components/FileUpload";
import {
  ContectDetails_Redux,
  GetClientContactDetailasProfile,
  GetPortalContactDetailsByEmail,
  GetSignature_Json_Redux,
  Json_Portal_GetClientListByEmail_Redux,
  UDF_Add_Redux1,
} from "../../redux/ak_api_helper";
import {
  setOrigantryNumber,
  setWhatappUpdate,
  setWhatsAppNumber,
  setWhatsAppValidModal,
} from "../../redux/modalSlice";

import AMLCheckViewEdit from "../../components/AMLCheckViewEdit";
import { ContactViewEdit } from "../../components/ContactViewEdit";
import CustomeSignature from "../../components/CustomeSignature";
import { ad_GetAllContactsByCCode_Json, GetCRMContactUDFValueAction } from "../../redux/dev_aditya_action";
import {
  clientBodyaccIdData,
  clientBodyaccidData,
} from "../../utils/RequestsFunction";
import ClientContactGrid from "./ClientContactGrid";

function ClientProfile() {
  const [openSignature, setOpenSignature] = React.useState(false);

  let requestObjecGetUdfDetailas = {
    accid: localStorage.getItem("accIdClient"),
    username: localStorage.getItem("EmailClient"),
    sPassword: localStorage.getItem("passwordClient"),
  }

  const handleClickOpenSignature = () => {
    dispatch(GetSignature_Json_Redux(requestObjecGetUdfDetailas));
    setOpenSignature(true);
  };

  const handleCloseSignature = () => {
    setOpenSignature(false);
  };
  const { udf1 } = useSelector((state) => state.ak);
  
  const dispatch = useDispatch();

  const obj1 = {
    accId: localStorage.getItem("accIdClient")
      ? localStorage.getItem("accIdClient")
      : "",
    username: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
    sPassword: localStorage.getItem("passwordClient")
      ? localStorage.getItem("passwordClient")
      : "",
  };

  useEffect(() => {
    dispatch(Json_Portal_GetClientListByEmail_Redux());
    dispatch(GetPortalContactDetailsByEmail(obj1));
    dispatch(GetClientContactDetailasProfile(obj1));
  }, []);

  const { originFullDetails } = useSelector((state) => state.ak);
  // const {data}
  const getClientContactDetailas = useSelector(
    (state) => state.ak.getClientContactDetailas?.getClientContactDetailas || ""
  );

const {allDataPortalContact} = useSelector((state) => state.ak)

const isMainContact = allDataPortalContact?.["Table6"]?.some(contact => contact["Main Contact"] === true) || false;

  const [countryName, setCountryName] = useState("");
  const [number, setNumber] = useState("");
  const [verifiedWhatapp, setVerifiedWhatapp] = useState();
  const [countryData, setCountryData] = useState(null);
  const [error, setError] = useState("");
  const { UdfNameId } = useSelector((state) => state.ak);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectOrnitoNo, setselectOrginitoNo] = useState({});
  const [amlDoc, setAmlDoc] = useState([]);


console.log(UdfNameId , "ufdNameId");


  const { signature } = useSelector((state) => state.ak);
 
  useEffect(() => {
    if (Object.keys(UdfNameId).length > 0) {
      setselectOrginitoNo(UdfNameId[0]);
      localStorage.setItem('selectedoriginator', JSON.stringify(UdfNameId[0]));

      let reqObj = {
        AccID: localStorage.getItem("accIdClient"),
        strEmailId: localStorage.getItem("EmailClient"),
        password: localStorage.getItem("passwordClient"),
        CliientId: UdfNameId[0].OriginatorNo,
      };
      dispatch(ContectDetails_Redux(reqObj));
      let obj12 = {
        ...clientBodyaccIdData,
        strProjectId: "15",
        strOrignatorNumber: UdfNameId[0].OriginatorNo,
      };

      dispatch(UDF_Add_Redux1(obj12));
    }
  }, [UdfNameId]);

  useEffect(() => {
    if (Object.keys(selectOrnitoNo).length > 0) {
      dispatch(setOrigantryNumber(selectOrnitoNo.OriginatorNo));
      dispatch(
        GetCRMContactUDFValueAction({
          Accid: localStorage.getItem("accIdClient"),
          username: localStorage.getItem("EmailClient"),
          sPassword: localStorage.getItem("passwordClient"),
          ClientId: selectOrnitoNo.OriginatorNo,
        }, () => { })
      );
    }
  }, [selectOrnitoNo]);

  const [avatarSrc, setAvatarSrc] = useState("/static/images/avatar/1.jpg");
  const [anchorEl, setAnchorEl] = useState(null);

  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] = useState(true);

  const handleSelect = (event, value) => {
    try {
      if (value) {
        setselectOrginitoNo(value);
        localStorage.setItem('selectedoriginator', JSON.stringify(value));
        dispatch(UDF_Add_Redux1(
         { ...clientBodyaccIdData,
          strProjectId: "15",
          strOrignatorNumber: JSON.parse(localStorage.getItem('selectedoriginator'))?.OriginatorNo}
        ));
        dispatch(setOrigantryNumber(value.OriginatorNo));
        setSelectedOption(value);
      } else {
        setSelectedOption({ "Company Name": "", OriginatorNo: null });
      }
    } catch (error) {
      console.error("An error occurred in h8andleSelect:", error);
    }
  };

  useEffect(() => {
    handleSelect();
      const payloadForgetContacts = {
        accId : obj1?.accId,
        username : obj1?.username,
        sPassword : obj1?.sPassword,
        CCode : JSON.parse(localStorage.getItem('selectedoriginator'))?.OriginatorNo
      }
        dispatch(ad_GetAllContactsByCCode_Json(payloadForgetContacts))
  }, []);

  const openWhatAppModal = () => {
    dispatch(setWhatsAppValidModal(true));
  };

  useEffect(() => {
    if (originFullDetails != "") {
      setCountryName(
        getClientContactDetailas && getClientContactDetailas.Country
      );
      setVerifiedWhatapp(
        getClientContactDetailas && getClientContactDetailas.blnContactVarified
      );

      setNumber(
        getClientContactDetailas && getClientContactDetailas.Mobile
          ? getClientContactDetailas.Mobile
          : ""
      );
      if (
        (getClientContactDetailas &&
          getClientContactDetailas.Mobile == undefined) ||
        (getClientContactDetailas && getClientContactDetailas.Mobile == null) ||
        (getClientContactDetailas && getClientContactDetailas.Mobile == "") ||
        (getClientContactDetailas.getClientContactDetailas &&
          getClientContactDetailas.Mobile == "") ||
        (getClientContactDetailas &&
          getClientContactDetailas.blnContactVarified == false)
      ) {
        dispatch(setWhatappUpdate(true));
      }
    }
  }, [handleSelect, openWhatAppModal]);

  useEffect(() => {
    setTimeout(() => {
      console.log('testing by jayprakash data recived 1', getClientContactDetailas.Country)
      setCountryName(getClientContactDetailas && getClientContactDetailas.Country)
    }, 3000);
    setTimeout(() => {
      if (getClientContactDetailas.Country == '' && getClientContactDetailas.Country == undefined && getClientContactDetailas.Country == null) {
        setError("The input field cannot be empty");
        console.log('testing by jayprakash data recived 2')
        // dispatch(setWhatsAppNumber(number));
        setCountryData(null);
        return;
      }
      const finalURL = `https://restcountries.com/v3.1/name/${getClientContactDetailas && getClientContactDetailas.Country.trim()}?fullText=true`;
      fetch(finalURL)
        .then((response) => response.json())
        .then((data) => {
          console.log('testing by jayprakash data recived 3')
          if (data.message === "Not Found") {
            setError("Country Information is not Found");
            setCountryData(null);
          } else if (data.length === 0) {
            setError("Please enter a valid country name.");
            setCountryData(null);
          } else {
            setError("");

            let countryData = data[0].idd.root + data[0].idd.suffixes;
            countryData = countryData && countryData.replace("+", "");
            console.log('testing by jay data with country code', +data[0].idd.root + data[0].idd.suffixes, getClientContactDetailas.Mobile, +data[0].idd.root + data[0].idd.suffixes + getClientContactDetailas && getClientContactDetailas.Mobile)
            dispatch(setWhatsAppNumber(+data[0].idd.root + data[0].idd.suffixes + getClientContactDetailas.Mobile));
          }
        })
        .catch(() => {
          setError("An error occurred while fetching data.");
          setCountryData(null);
        });
    }, 5000);
  }, [getClientContactDetailas]);

  const handleAvatarClick = () => {
    document.getElementById("avatar-input").click();
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSignature = async () => {
    setSignaturePreview(false);
    setSignatureSetup(true);
  };

  const base64String = signature;
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
  });

  let profile = 'ClientProfile'

  return (
    <Box>
      <Box className="container-fluid p-0 mt-2">
        <Box className="profile-box white-box p-0">
          <Box className="relative">
            <Button
              className="btn-blue me-2 btn-signature"
              startIcon={<EditIcon />}
              onClick={handleClickOpenSignature}
            >
              {" "}
              Add Signature
            </Button>
          </Box>
          <Box className="client-profile-bg">
            <img src={profileBG} className="w-100" />
          </Box>

          <Box className="d-block d-md-flex justify-content-between align-items-center px-0 flex-wrap border-bottom pb-2 mb-2">
            <Box className="">
              <Box className="d-flex mt-2 mb-1">
                <PhoneIphoneIcon className="font-32 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium">
                    Contact Number
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {/* +911234567890 */}
                    {getClientContactDetailas && getClientContactDetailas.Mobile
                      ? getClientContactDetailas.Mobile
                      : ""}
                  </Typography>
                </Box>
              </Box>

              <Box className="d-flex mb-1">
                <WhatsAppIcon className="font-32 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium">WhatsApp</Typography>
                  {verifiedWhatapp ? (
                    <>
                      <Typography className="font-12 text-success lato-medium">
                        <span>
                          <CheckCircleOutlineIcon className="font-20" />{" "}
                          Verified
                        </span>
                      </Typography>

                      {/* <Typography
                        className="font-16 text-gray lato-medium "
                        onClick={openWhatAppModal}
                      >
                        Not Verified{" "}
                        <Button
                          id="basic-button"
                          aria-haspopup="true"
                          className="btn-blue me-3"
                          onClick={openWhatAppModal}
                          sx={{ height: "20px", width: "130px" }}
                        >
                          Click to Verified
                        </Button>
                      </Typography> */}

                      {/* <Typography
                        className="font-16 text-gray lato-medium "
                        onClick={openWhatAppModal}
                      >
                        Not Verified{" "}
                        <Button
                          id="basic-button"
                          aria-haspopup="true"
                          className="btn-blue me-3"
                          onClick={openWhatAppModal}
                          sx={{ height: "20px", width: "130px" }}
                        >
                          Click to Verified
                        </Button>
                      </Typography> */}
                    </>
                  ) : (
                    <>
                      <Typography
                        className="font-16 text-gray lato-medium "
                        onClick={openWhatAppModal}
                      >
                        Not Verified{" "}
                        <Button
                          id="basic-button"
                          aria-haspopup="true"
                          className="btn-blue me-3"
                          onClick={openWhatAppModal}
                          sx={{ height: "20px", width: "130px" }}
                        >
                          Click to Verified
                        </Button>
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="text-center order-first order-md-0 m-auto">
              <Stack className="m-auto d-inline-block user-profile-img">
                <Box className="inner-user-profile-img">
                  <Avatar
                    alt="Mr."
                    src={avatarSrc}
                    sx={{
                      width: 60,
                      height: 60,
                      cursor: "pointer",
                      margin: "0 auto",
                    }}
                    onClick={handleAvatarClick}
                  />
                  <input
                    type="file"
                    id="avatar-input"
                    style={{ display: "none" }}
                    onChange={handleAvatarChange}
                  />

                  <Tooltip title="UK" arrow>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/2048px-United-kingdom_flag_icon_round.svg.png"
                      className="profile-flat"
                    />
                  </Tooltip>
                </Box>
                <Box className="mt-2">
                  <Typography className="font-18 lato-medium">
                    {originFullDetails ? originFullDetails.Title : ""}{" "}
                    {originFullDetails ? originFullDetails["First Name"] : ""}{" "}
                    {originFullDetails ? originFullDetails["Last Name"] : ""}
                  </Typography>
                  <Typography className="font-12 text-gray">
                    {originFullDetails?.Role}
                  </Typography>

                  <Box className="mt-2">
                    <Button
                      sx={{ cursor: "pointer" }}
                      onClick={handleMenuClick}
                      className="btn-blue"
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      {selectOrnitoNo["Company Name"] || ""}
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      className="custom-dropdown"
                    >
                      {UdfNameId.map((client) => (
                        <MenuItem
                          key={client.OriginatorNo}
                          onClick={(event) => {
                            handleSelect(event, client);
                            handleMenuClose();
                          }}
                        >
                          {client["Company Name"]}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>

                  
                </Box>
              </Stack>
            </Box>
            <Box>
              <Box className="d-flex mb-1 mt-2">
                <EmailIcon className="font-32 me-2 text-blue" />
                <Box>
                  <Typography className="lato-medium">E-Mail ID</Typography>
                  <Typography className="font-12 text-gray">
                    {getClientContactDetailas &&
                      getClientContactDetailas.EMailId
                      ? getClientContactDetailas.EMailId
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* editModal */}

        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            className="d-flex"
          >
            <Box className="white-box w-100 aml-document-box">
              <h2 className="title font-18">AML Documents</h2>
              {/* <hr /> */}

              <Box className="">
                <FileUpload
                  selectedFiles={amlDoc}
                  setSelectedFiles={setAmlDoc}
                />
              </Box>
            </Box>
          </Grid>

          {/* editModal */}
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <ContactViewEdit profile={profile} />
            <AMLCheckViewEdit />

            {udf1 && udf1.length > 0 && <EditUDFClientCard data={udf1} />}

            { isMainContact && <ClientContactGrid/> }

            {/* <ClientContactGrid/> */}
          </Grid>
        </Grid>
      </Box>

      {/* add signature */}
      <Dialog
        open={openSignature}
        onClose={handleCloseSignature}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
        sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "600px" } }}
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle className="">{"Sign & Approve Document"}</DialogTitle>
          </Box>

          <Button onClick={handleCloseSignature} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              {signaturePreview && (
                <>
                  <Box className="d-flex justify-content-between">
                    <Typography
                      variant="h3"
                      className="font-14 bold text-black"
                    >
                      {"Signature Setting"}
                    </Typography>

                    <IconButton
                      sx={{ marginTop: "-15px" }}
                      onClick={handleChangeSignature}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        border: "1px solid black",
                        borderRadius: "10px",
                        background: "#fff",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {base64String.length > 0 ? (
                        <ImageBox
                          component="img"
                          src={`data:image/${format};base64,${base64String}`}
                          alt="Base64 Image"
                          sx={{ width: 300, height: 95 }}
                        />
                      ) : (
                        <h2 className="title font-18">No Signature</h2>
                      )}
                    </Box>

                    {/* <hr /> */}
                  </Box>
                </>
              )}

              {signatureSetup && (
                <>
                  <Box>
                    <CustomeSignature
                      setSignaturePreview={setSignaturePreview}
                      setSignatureSetup={setSignatureSetup}
                      setSignatureSetupVisibleSetting={setSignatureSetupVisibleSetting}
                    />
                  </Box>
                </>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ClientProfile;
