import DeleteIcon from "@mui/icons-material/Delete";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";

import {
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  TableSortLabel,
  Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetAllReceivedMessages_Json_Redux,
  GetAllSentMessages_Json_Redux,
  GetRetractedMessages_Json_Redux,
} from "../redux/api_helper";
import {
  ad_MessageDeleted_Action,
  ad_MessageRetracted_Action,
  ad_MessageUnRetracted_Action,
} from "../redux/dev_aditya_action";
import { markMessageAsViewed } from "../redux/dev_chetan_api_helper";
import { userBodyDAtaa } from "../utils/RequestsFunction";

function Row(props) {
  const { row, value } = props;
  const [open, setOpen] = React.useState(false);
  // dropdown
  const [MessageanchorEl, MessagesetAnchorEl] = React.useState(null);
  const Messageopen = Boolean(MessageanchorEl);
  const handleClick = (event) => {
    MessagesetAnchorEl(event.currentTarget);
  };
  const MessagehandleClose = () => {
    // dispatch(ad_MessageRetracted_Action())
    MessagesetAnchorEl(null);
  };

  const getSentMassage = () => {
    let body = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };

    let receivedObj = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };

    dispatch(GetAllSentMessages_Json_Redux(body, function (res) {}));
    dispatch(GetAllReceivedMessages_Json_Redux(receivedObj, function (res) {}));
    dispatch(GetRetractedMessages_Json_Redux(receivedObj, function (res) {}));
  };

  const HandleUntractMessage = (data) => {
    dispatch(
      ad_MessageRetracted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );

    MessagesetAnchorEl(null);
  };

  const HandleDeleteMessaage = (data) => {
    MessagesetAnchorEl(null);
    dispatch(
      ad_MessageDeleted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );
  };

  const HandleLaunchNewTab = () => {
    if (row?.MessageNo) {
      dispatch(markMessageAsViewed(row?.MessageNo));
    }
    if (value === "2") {
      let customerId = row.History[2].customerId;
      let url = `/user/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${
        row.MessageNo
      }&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(
        row.Issued
      )}&Viewed=${formatDate(row.Viewed)}&ClientName=${
        row.ReferenceName
      }&customerId=${customerId}&Status=Yes&TabIndex=${value}`;
      window.open(url, "_blank");
    } else {
      let customerId = row.History[3].customerId;
      let url = `/user/portalmessage/sent/outgoing-message-viewer?PortalDocID=${
        row.MessageNo
      }&Section=${row.DueDate}&Subject=${row.Subject}&Issued=${formatDate(
        row.Issued
      )}&Viewed=${formatDate(row.Viewed)}&ClientName=${
        row.ReferenceName
      }&Status=Yes&MessageID=${
        row.MessageID
      }&customerId=${customerId}&TabIndex=${value}`;
      window.open(url, "_blank");
    }
  };

  const HandleUnRetractMessage = (data) => {
    dispatch(
      ad_MessageUnRetracted_Action({ messageID: data?.MessageNo }, (res) =>
        getSentMassage()
      )
    );
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (date) => moment(date).format("DD-MM-YYYY");

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Pending":
        return "badge text-bg-warning";
      case "Unread":
        return "badge text-bg-primary";
      case "Disapproved":
        return "badge text-bg-danger";
      default:
        return "badge text-bg-success";
    }
  };

  const isValidDate = (date) => dayjs(date, "DD/MM/YYYY", true).isValid();

  const SendReminderMessaage = () => {
    console.log("✌️SendReminderMessaage --->");
  };

  const HandleViewMessage = () => {
    // for Recieved Message
    if (value === "2") {
      if (row?.MessageNo && !row?.Viewed) {
        dispatch(markMessageAsViewed(row?.MessageNo));
      }
      let customerId = row.History[2].customerId;

      const data = {
        PortalDocID: row.MessageNo,
        Section: row.DueDate,
        Subject: row.Subject,
        Issued: formatDate(row.Issued),
        Viewed: formatDate(row.Viewed),
        ClientName: row.ReferenceName,
        Status: "Yes", // Assuming Status is "Yes" for this case
        customerId: customerId,
        TabIndex: value, // Assuming 'value' is defined somewhere
      };

      // Use navigate with state
      navigate("/user/portalmessage/inboxes/incoming-message-viewer", {
        state: data,
      });
    }
    // for retract and sent message Message
    else {
      let customerId = row.History[3].customerId;

      const data = {
        PortalDocID: row.MessageNo,
        Section: row.DueDate,
        Subject: row.Subject,
        Issued: formatDate(row.Issued),
        Viewed: formatDate(row.Viewed),
        ClientName: row.ReferenceName,
        Status: "Yes", // Assuming the status is "Yes" in this case
        MessageID: row.MessageID,
        customerId: customerId,
        TabIndex: value,
        messageStatus: row.Status,
      };

      // Navigate with the state
      navigate("/user/portalmessage/sent/outgoing-message-viewer", {
        state: data,
      });
    }
  };

  return (
    <React.Fragment>
      {value === "2" ? (
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          onDoubleClick={() => {
            if (row?.MessageNo && !row?.Viewed) {
              dispatch(markMessageAsViewed(row?.MessageNo));
            }
            let customerId = row.History[2].customerId;

            // navigate(
            //   `/user/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${
            //     row.MessageNo
            //   }&Section=${row.DueDate}&Subject=${
            //     row.Subject
            //   }&Issued=${formatDate(row.Issued)}&Viewed=${formatDate(
            //     row.Viewed
            //   )}&ClientName=${
            //     row.ReferenceName
            //   }&customerId=${customerId}&Status=Yes&TabIndex=${value}`
            // );

            const data = {
              PortalDocID: row.MessageNo,
              Section: row.DueDate,
              Subject: row.Subject,
              Issued: formatDate(row.Issued),
              Viewed: formatDate(row.Viewed),
              ClientName: row.ReferenceName,
              Status: "Yes", // Assuming Status is "Yes" for this case
              customerId: customerId,
              TabIndex: value, // Assuming 'value' is defined somewhere
            };

            // Use navigate with state
            navigate("/user/portalmessage/inboxes/incoming-message-viewer", {
              state: data,
            });
          }}
        >
          {/* <TableCell className="table-arrow">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}

          <TableCell data-th="Subject" align="left" scope="row">
            {row.Subject}
          </TableCell>
          <TableCell align="left" data-th="Sender">
            <p>{row.Recipient}</p>
            <span className="badge text-bg-primary">{row?.ReferenceName}</span>
          </TableCell>
          {/* badge text-bg-danger */}
          <TableCell align="left" data-th="Issue On">
            {row?.IssuedOn}
          </TableCell>
          <TableCell align="left" data-th="Viewed On">
            {row?.Viewed}
          </TableCell>
          <TableCell align="left" data-th="Section">
            {row.DueDate}
          </TableCell>

          <TableCell data-th="Status" className="relative">
            <Box className="d-flex align-items-center justify-content-cente">
              <Box className="hover-status-heading">
                <span className={"text-blue"}>Reference ID</span>

                <Box className="hover-status-box shadow">
                  <Box className="d-flex justify-content-center">
                    <Box className="lato-bold pe-2 text-black">
                      <p>Email ID</p>
                    </Box>
                    <Box>
                      <p>{row.History[2]?.customerId}</p>
                    </Box>
                  </Box>

                  {/* {row?.History?.map((historyRow) => (
                    <Box key={historyRow.date} className='d-flex'>
                      <Box
                        className="lato-bold pe-2 text-black"
                      >
                        <p>{historyRow.date}</p>
                      </Box>
                      <Box>
                        <p>{historyRow.customerId}</p>
                      </Box>
                    </Box>
                  ))} */}
                </Box>
              </Box>
            </Box>
          </TableCell>

          <TableCell align="left">
            <Box className="d-flex align-items-center">
              <Box className="ms-2">
                <IconButton
                  id="basic-button"
                  aria-controls={Messageopen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Messageopen ? "true" : undefined}
                  onClick={handleClick}
                  className="p-1"
                >
                  <MoreVertIcon className="font-18" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={MessageanchorEl}
                  open={Messageopen}
                  onClose={MessagehandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="custom-dropdown"
                >
                  {value === 1 ? (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" /> Retract Message
                    </MenuItem>
                  ) : value === 2 ? (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" /> Docusoft Message
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {value === 1 ? (
                    <MenuItem onClick={MessagehandleClose}>
                      <InfoIcon className="me-2" /> Change to Information Only
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem onClick={() => HandleDeleteMessaage(row)}>
                    <DeleteIcon className="me-2" /> Delete Message
                  </MenuItem>

                  <MenuItem onClick={HandleViewMessage}>
                    <DraftsOutlinedIcon className="me-2" /> View Message
                  </MenuItem>

                  {/* <MenuItem onClick={HandleLaunchNewTab}>
                    <LaunchIcon className="me-2" /> Launch in new tab
                  </MenuItem> */}
                </Menu>
              </Box>
            </Box>
          </TableCell>
          {/* <TableCell>
            Action
          </TableCell> */}
        </TableRow>
      ) : (
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          onDoubleClick={() => {
            // dispatch(markMessageAsViewed(row?.["Message No"]));
            let customerId = row.History[3].customerId;
            // navigate(
            //   `/user/portalmessage/sent/outgoing-message-viewer?PortalDocID=${
            //     row.MessageNo
            //   }&Section=${row.DueDate}&Subject=${
            //     row.Subject
            //   }&Issued=${formatDate(row.Issued)}&Viewed=${formatDate(
            //     row.Viewed
            //   )}&ClientName=${row.ReferenceName}&Status=Yes&MessageID=${
            //     row.MessageID
            //   }&customerId=${customerId}&TabIndex=${value}`
            // );

            const data = {
              PortalDocID: row.MessageNo,
              Section: row.DueDate,
              Subject: row.Subject,
              Issued: formatDate(row.Issued),
              Viewed: formatDate(row.Viewed),
              ClientName: row.ReferenceName,
              Status: "Yes", // Assuming the status is "Yes" in this case
              MessageID: row.MessageID,
              customerId: customerId,
              TabIndex: value,
              messageStatus: row.Status,
            };

            // Navigate with the state
            navigate("/user/portalmessage/sent/outgoing-message-viewer", {
              state: data,
            });
          }}
        >
          {/* <TableCell className="table-arrow">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
          <TableCell
            align="left"
            data-th="Approval Requested"
            sx={{
              width: "50px",
            }}
          >
            {row.ApprovalRequested === "Yes" ? (
              <Tooltip title="For Approval" arrow>
                <strong className="badge text-bg-success">A</strong>
              </Tooltip>
            ) : (
              <Tooltip title="For Information" arrow>
                <strong className="badge text-bg-primary px-1">
                  <InfoOutlinedIcon className="font-13" />
                </strong>
              </Tooltip>
            )}
            {/* <span
              className={
                row.ApprovalRequested === "Yes"
                  ? "badge text-bg-success"
                  : "badge text-bg-primary"
              }
            >
              {row.ApprovalRequested}
            </span> */}
          </TableCell>

          <TableCell scope="row" align="left" data-th="Subject">
            {row.Subject}
          </TableCell>
          <TableCell align="left" data-th="Recipient">
            <div className="clearfix">
              <p>{row.Recipient}</p>
              <span className="badge text-bg-primary">
                {row?.ReferenceName}
              </span>
            </div>
          </TableCell>

          <TableCell align="left" data-th="Issued On">
            {row.IssuedOn}
          </TableCell>
          <TableCell align="left" data-th="Due Date">
            <span
              // className={
              //   isValidDate(row.DueDate.trim()) ? "" : "badge text-bg-warning"
              // }
              className={
                row?.DueDate === "Not available" && "badge text-bg-warning"
              }
            >
              {row.DueDate}
            </span>
          </TableCell>

          <TableCell data-th="Status" className="relative">
            <Box className="d-flex align-items-center justify-content-cente">
              <Box className="hover-status-heading">
                <span className={getStatusBadgeClass(row.Status)}>
                  {row.Status}{" "}
                </span>

                <Box className="hover-status-box shadow">
                  {row?.History?.map((historyRow) => (
                    <Box key={historyRow.date} className="d-flex">
                      <Box className="lato-bold pe-2 text-black">
                        <p>{historyRow.date}</p>
                      </Box>
                      <Box>
                        <p>{historyRow.customerId}</p>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </TableCell>

          <TableCell data-th="Action" align="">
            {/* <DeleteOutlineOutlinedIcon className="text-red pointer font-20 me-2" />
            <CloseOutlinedIcon className="text-red pointer font-20" /> */}

            <Box className="ms-0">
              <IconButton
                id="basic-button"
                aria-controls={Messageopen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Messageopen ? "true" : undefined}
                onClick={handleClick}
                size="small"
                className="p-0"
              >
                <MoreVertIcon className="font-18" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={MessageanchorEl}
                open={Messageopen}
                onClose={MessagehandleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                className="custom-dropdown"
              >
                {/* {value === 1 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <UnsubscribeIcon className="me-2" /> Retract Message
                      </MenuItem>
                  ) : value === 2 ? (
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" /> Docusoft Message
                    </MenuItem>
                  ) : (
                    <MenuItem 
                    // onClick={MessagehandleClose}
                    onClick={()=> console.log("=====>")}
                    >
                      <UnsubscribeIcon className="me-2" />
                      Unretract Message
                    </MenuItem>
                  )}
                  {value === 1 ? (
                      <MenuItem onClick={MessagehandleClose}>
                        <InfoIcon className="me-2" /> Change to Information Only
                      </MenuItem>
                  ) : (
                    ""
                  )} */}
                {Number(value) === 1 && (
                  <MenuItem onClick={() => HandleUntractMessage(row)}>
                    <UnsubscribeIcon className="me-2" />
                    Retract Message
                  </MenuItem>
                )}

                {Number(value) === 3 && (
                  <>
                    <MenuItem onClick={() => HandleUnRetractMessage(row)}>
                      <UnsubscribeIcon className="me-2" />
                      Unretract Message
                    </MenuItem>
                    <MenuItem onClick={HandleViewMessage}>
                      <DraftsOutlinedIcon className="me-2" /> View Message
                    </MenuItem>
                  </>
                )}

                {Number(value) === 1 && (
                  <>
                    <MenuItem onClick={() => HandleDeleteMessaage(row)}>
                      <DeleteIcon className="me-2" /> Delete Message
                    </MenuItem>
                    {row.Status === "Pending" && (
                      <MenuItem onClick={SendReminderMessaage}>
                        <NotificationsActiveOutlinedIcon className="me-2" />{" "}
                        Send Reminder
                      </MenuItem>
                    )}
                    <MenuItem onClick={HandleViewMessage}>
                      <DraftsOutlinedIcon className="me-2" /> View Message
                    </MenuItem>
                    <MenuItem onClick={MessagehandleClose}>
                      <UnsubscribeIcon className="me-2" /> Retract Message
                    </MenuItem>
                  </>
                )}
                {/* <MenuItem onClick={HandleLaunchNewTab}>
                      <LaunchIcon className="me-2" /> Launch in new tab
                  </MenuItem> */}
              </Menu>
            </Box>
          </TableCell>
        </TableRow>
      )}

      {/* sadik <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={7}
          className="table-before-none"
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="well-box mt-2 p-2">
              <Box className="mb-0">
                <Grid container spacing={0} className="mb-">
                  <Grid item lg={5} xs={12} md={5}>
                    <Box className="table-inbox me-0">
                      <Table
                        size="small"
                        aria-label="purchases"
                        className="table-grid "
                      >
                        <TableBody>
                          {row?.History?.map((historyRow) => (
                            <TableRow key={historyRow.date}>
                              <TableCell
                                scope="row"
                                className="lato-semibold"
                              >
                                {historyRow.date}
                              </TableCell>
                              <TableCell>{historyRow.customerId}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                  <Grid item lg={7} xs={8} md={7}></Grid>
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

function CustomePortalMessageTable({ TableHeder, TableData, value }) {
  const [sortDirection, setSortDirection] = React.useState("asc");

  const handleSort = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const sortedTableData = React.useMemo(() => {
    // if (!TableData || TableData.length >0) return [];
    const sortedData = [...TableData].sort((a, b) => {
      if (sortDirection === "asc") {
        return a.ApprovalRequested === b.ApprovalRequested
          ? 0
          : a.ApprovalRequested === "Yes"
          ? -1
          : 1;
      } else {
        return a.ApprovalRequested === b.ApprovalRequested
          ? 0
          : a.ApprovalRequested === "Yes"
          ? 1
          : -1;
      }
    });
    return sortedData;
  }, [TableData, sortDirection]);
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", height: "calc(100vh - 220px)" }}
      className="table-bg"
    >
      <Table
        className="table custom-table table-mobile pointer-table"
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            {TableHeder &&
              TableHeder.length > 0 &&
              TableHeder.map((item, index) =>
                index === 0 ? (
                  <React.Fragment key={index}>
                    <TableCell className="lato-semibold">{item}</TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    {item === "Approval Requested" ? (
                      <TableCell className="lato-semibold" align="left">
                        <TableSortLabel
                          active
                          direction={sortDirection}
                          onClick={handleSort}
                        >
                          {item}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell className="lato-semibold" align="left">
                        {item}
                      </TableCell>
                    )}
                  </React.Fragment>
                )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTableData &&
            sortedTableData.length > 0 &&
            sortedTableData?.map((row, index) => (
              <Row key={index} row={row} value={value} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomePortalMessageTable;
