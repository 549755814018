import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Json_Portal_GetClientListByEmail_Redux } from "../redux/ak_api_helper";
import { ad_Json_GetUserItemDetailsByCCodeByPubUser_action } from "../redux/dev_aditya_action";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import doc from "../../src/assets/images/files-icon/doc.png";
import xls from "../../src/assets/images/files-icon/xls.png";
import csv from "../../src/assets/images/files-icon/csv.png";
import rtf from "../../src/assets/images/files-icon/rtf.png";

import ListIcon from "@mui/icons-material/List";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

const UploadDocumentModal = ({ open, handleClose }) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [reference, setReference] = useState("");
  const [description, setDescription] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const dispatch = useDispatch();
  const { UdfNameId } = useSelector((state) => state.ak);

  useEffect(() => {
    if (UdfNameId?.length > 0 && !reference) {
      setReference(UdfNameId[0]["OriginatorNo"]);
    }
  }, [UdfNameId]);

  useEffect(() => {
    dispatch(Json_Portal_GetClientListByEmail_Redux());
  }, [dispatch]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const filteredFiles = selectedFiles.filter((file) => {
      return allowedFileTypes.includes(file.type);
    });

    if (filteredFiles.length < selectedFiles.length) {
      toast.error("Only PDF, XLSX, DOCX, CSV, and RTF files are allowed.");
    }

    setFiles([...files, ...filteredFiles]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const Json_AddUserItemDataForPubUser = async (ItemId, CCode) => {
    try {
      const payloadForGetDocs = {
        Email: localStorage.getItem("EmailClient"),
        password: localStorage.getItem("passwordClient"),
        agrno: localStorage.getItem("accIdClient"),
        CCode,
        ItemId,
        IsForwarded: false,
        IsUploaded: false,
        WorkID: 0,
        UserId: 0,
      };

      const response = await axios.post(
        "https://docusoftpractice.com/PracticeServices.asmx/Json_AddUserItemDataForPubUser",
        payloadForGetDocs
      );

      console.log(response, "Json_AddUserItemDataForPubUser response");
    } catch (error) {
      console.error("Error calling Json_AddUserItemDataForPubUser:", error);
    }
  };

  const allowedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/csv",
    "text/rtf",
  ];
  const getFileNameWithoutExtension = (filename) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  };

  const handleUploadDocument = async () => {
    setIsRegistering(true);
    const accIdClient = localStorage.getItem("accIdClient");
    const emailClient = localStorage.getItem("EmailClient");
    const passwordClient = localStorage.getItem("passwordClient");

    const getFormattedDate = () => {
      const today = new Date();
      return today.toISOString().split("T")[0].replace(/-/g, "/");
    };

    const todayDate = getFormattedDate();

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };

    try {
      if (!files || files.length === 0) {
        toast.error("No files selected.");
        setIsRegistering(false);
        return;
      }
      if (!description || description.trim() === "") {
        toast.error("Description is required");
        setIsRegistering(false);
        return;
      }

      for (const file of files) {
        try {
          const base64String = await convertToBase64(file);
          const fileNameWithoutExt = getFileNameWithoutExtension(file.name);
          const fileDescription =
            files.length > 1
              ? `${description} - ${fileNameWithoutExt}`
              : description;
          const payload = {
            agrno: accIdClient,
            Email: emailClient,
            password: passwordClient,
            deptId: 0,
            sectionId: 1,
            folderId: 15,
            categoryId: 0,
            subSectionId: 0,
            retForMonth: "-1",
            deptName: "",
            folderName: "Client",
            originatorId: reference,
            senderId: reference,
            sectionName: "01. General Correspondence",
            extDescription: "IRCTC Next Generation eTicketing System",
            docDirection: "Incoming",
            description: fileDescription,
            priority: "",
            stickyNote: "",
            fileName: file.name,
            forActionList: "",
            forInformationList: "",
            forGroupList: "",
            uDFList: "",
            sUDFList: "",
            clientname: "Aakash Singh",
            receiveDate: todayDate,
            actionByDate: "1900/01/01",
            actionDate: todayDate,
            docViewedDate: todayDate,
            strb64: base64String,
            strtxt64: "",
            EmailMessageId: "",
          };

          const response = await axios.post(
            "https://docusoftpractice.com/PracticeServices.asmx/Json_RegisterItemByPubUser",
            payload
          );

          const responseData = JSON.parse(response.data.d);

          if (responseData.Status === "true") {
            const itemId = responseData.ItemId;
            await Json_AddUserItemDataForPubUser(itemId, reference);
            toast.success(`File "${file.name}" uploaded successfully.`);
          } else {
            toast.error(`Failed to upload "${file.name}".`);
          }
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          toast.error(`Error uploading ${file.name}: ${error.message}`);
        }
      }

      dispatch(ad_Json_GetUserItemDetailsByCCodeByPubUser_action());

      setIsRegistering(false);
      setFiles([]);
      setStep(1);
      setReference("");
      setDescription("");
      handleClose();
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("Failed to Upload Files.");
    }
  };

  const docTypeIcon = (target) => {
    switch (target) {
      case "pdf":
        return pdf;
      case "docx":
        return doc;
      case "xlsx":
        return xls;
      case "csv":
        return csv;
      case "rtf":
        return rtf;
      default:
        return;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Upload Document ({files.length})
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="pt-3">
        {step === 1 ? (
          <Box>
            <Box class="row MuiBox-root css-0">
              <Box class="col-12  MuiBox-root css-0">
                <Box
                  class="file-upload-2 mt-4 MuiBox-root css-0"
                  role="presentation"
                  tabindex="0"
                >
                  <input
                    type="file"
                    multiple
                    // accept=".pdf,.xlsx,.docx,.csv,.rtf"
                    style={{ display: "none" }}
                    id="file-upload"
                    onChange={handleFileChange}
                  />
                  <label class="file-upload-2-label" for="file-upload">
                    <Box class="text-center MuiBox-root css-0">
                      <CloudUploadIcon
                        color="primary"
                        sx={{ fontSize: 50, cursor: "pointer" }}
                      />

                      <Box class="upload-content-2 MuiBox-root css-0">
                        <Typography className="font-18 semibold ">
                          Select or drag files here
                        </Typography>
                        <Typography className="font-14" variant="p">
                          PDF, XLSX, DOCX, CSV, RTF
                        </Typography>
                      </Box>
                    </Box>
                  </label>
                </Box>
              </Box>
            </Box>

            <Grid container spacing={1} className="mt-2 main-email-attachment" >
              {files?.map((file, index) => (
                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className="email-attachment w-100 p-0"
                    sx={{ flexWrap: "nowrap" }}
                  >
                    <Grid item className="d-flex align-items-center">
                      <img
                        src={docTypeIcon(
                          file?.name.slice(file?.name.lastIndexOf(".") + 1)
                        )}
                        style={{ width: 28 }}
                        alt="documents"
                      />
                      <Typography className="font-12 semibold ms-2">
                        {file.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        onClick={() => removeFile(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
            <TextField
              label="Reference"
              value={reference}
              size="small"
              select
              onChange={(e) => setReference(e.target.value)}
              fullWidth
              sx={{ gridColumn: "span 2" }}
            >
              {UdfNameId?.map((item, index) => (
                <MenuItem key={index} value={item["OriginatorNo"]}>
                  {item["Company Name"]}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              size="small"
              multiline
              rows={1}
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        )}
      </DialogContent> 
      <DialogActions className="me-3">
        {step === 2 && (
          <Button
            onClick={() => setStep(1)}
            disabled={isRegistering}
            className="btn-blue"
            startIcon={<SkipPreviousIcon />}
          >
            Previous
          </Button>
        )}
        {step === 1 ? (
          <Button
            onClick={() => setStep(2)}
            disabled={files.length === 0}
            className="btn-blue"
            startIcon={<ListIcon />}
          >
            Index
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadDocument}
            disabled={isRegistering}
            className="btn-blue"
            startIcon={<SendIcon />}
          >
            {isRegistering ? "Submitting..." : "Submit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadDocumentModal;
