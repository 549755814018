import React, { useEffect, useState } from "react";
import axios from "axios";
import { Menu, IconButton, Typography, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { ad_Json_GetUserItemDetailsByCCodeByPubUser_action } from "../../redux/dev_aditya_action";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import CustomLoader from "../../components/CustomLoader";
import DownloadIcon  from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';

const ActionColumn = ({ doc, onDownload }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  
  const Json_SearchDocById = async (ItemId) => {
    
    const payload = {
      Email: localStorage.getItem("EmailClient"),
      password: localStorage.getItem("passwordClient"),
      agrno: localStorage.getItem("accIdClient"),
      ItemId: ItemId,
    };

    try {
      const response = await axios.post(
        "https://docusoftpractice.com/practiceservices.asmx/Json_SearchDocByIdForPubUer",
        payload
      );

      const res = JSON.parse(response?.data?.d)['Table1'];
      if (res && res.length > 0) {
      
        const docData = res[0];
        const previewURL = `https://mydocusoft.com/viewer.html?GuidG=${docData.Guid}&srtAgreement=${localStorage.getItem(
          "accIdClient"
        )}&strItemId=${docData["Registration No."]}&filetype=${docData.Type}&ViewerToken=${localStorage.getItem('ViewerToken')}&IsApp=${""}&PortalID=${""}&TokenCode=${""}`;

        window.open(previewURL, "_blank");
      }
    } catch (error) {
      console.error("Error fetching document details:", error);
    }
  };

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handlePreview = () => {
    Json_SearchDocById(doc.PItemId);
    handleMenuClose();
  };

  const handleDownload = () => {
    onDownload(doc);
    handleMenuClose();
  };

  return (
    <>
      <IconButton className="" onClick={handleMenuClick}>
        <MoreVertIcon className="" />
      </IconButton>

      <Menu className="custom-dropdown" anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
        <MenuItem onClick={handlePreview}>
          <ListItemIcon>
            <PreviewIcon fontSize="small" />
          </ListItemIcon>
          Preview Document
        </MenuItem>
        <MenuItem onClick={handleDownload}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          Download
        </MenuItem>
      </Menu>
    </>
  );
};



const MyDocuments = () => {

  const { rtk_getAllDocumentsPubUser } = useSelector((state) => state.aditya);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [viewerToken, setViewerToken] = useState(localStorage.getItem("ViewerToken") || "");

  useEffect(() => {
    if (!viewerToken) {
      Json_getViewerToken();
    }
  }, []);

  const Json_getViewerToken = async () => {
    const payload = {
      Email: localStorage.getItem("EmailClient"),
      password: localStorage.getItem("passwordClient"),
      agrno: localStorage.getItem("accIdClient"),
    };

    try {
      const response = await axios.post(
        "https://docusoftpractice.com/practiceservices.asmx/Json_getViewerTokenByPubUser",
        payload
      );
      const token = response?.data?.d;
      setViewerToken(token);
      localStorage.setItem("ViewerToken", token);
    } catch (error) {
      console.error("Error fetching viewer token:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(ad_Json_GetUserItemDetailsByCCodeByPubUser_action())
      .then((response) => {
        if (!response || response.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
        setNoData(true);
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  const Json_GetItemBase64DataByIdByPubUser = async (doc) => {
    const { PItemId, Description, Type } = doc;

    const payload = {
      Email: localStorage.getItem("EmailClient"),
      password: localStorage.getItem("passwordClient"),
      agrno: localStorage.getItem("accIdClient"),
      ItemId: PItemId,
    };

    try {
      const response = await axios.post(
        "https://docusoftpractice.com/PracticeServices.asmx/Json_GetItemBase64DataByIdByPubUser",
        payload
      );
      let base64 = await JSON.parse(response?.data?.d);
      if (base64) {
        downloadBase64File(base64, `${Description}.${Type}`, getMimeType(Type));
      }
    } catch (error) {
      console.log(error, "Error in Json_GetItemBase64DataByIdByPubUser");
    }
  };

  const downloadBase64File = (base64Data, fileName, mimeType) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getMimeType = (extension) => {
    const mimeTypes = {
      pdf: "application/pdf",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      xls: "application/vnd.ms-excel",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      doc: "application/msword",
      txt: "text/plain",
      csv: "text/csv",
      png: "image/png",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      zip: "application/zip",
    };
    return mimeTypes[extension.toLowerCase()] || "application/octet-stream";
  };



  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : noData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography variant="h6" color="textSecondary">
            No Documents Available
          </Typography>
        </Box>
      ) : (
        <DataGrid
          dataSource={rtk_getAllDocumentsPubUser}
          keyExpr="PItemId"
          showBorders={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          className="mt-2 dx-widget  table-responsive table-grid table-height custom-table">
          <SearchPanel visible={true} width={250} placeholder="Search..." />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Column dataField="Type" caption="Type" />
          <Column dataField="Description" caption="Description" />
          <Column dataField="Section" caption="Section" />
          <Column dataField="Client" caption="Client Name" />
          <Column dataField="ReceivedBy" caption="Sent By" />
          <Column
            dataField="Received Date"
            caption="Received On"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column
            caption="Registration Number"
            calculateCellValue={(rowData) => rowData["Registration No."]}
            alignment="left"
          />
          <Column
            caption="Action"
            cellRender={({ data }) => (
              <ActionColumn
                doc={data}
                onDownload={Json_GetItemBase64DataByIdByPubUser}
              />
            )}
          />
        </DataGrid>
      )}
    </>
  );
};

export default MyDocuments;
