import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { setOpenDocumentPreviewModal } from "../redux/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  setDocDetailForPreviewURL,
  setshareboxDocForPreviewURL,
} from "../redux/dev_chetan_slice";

function DocumentPreviewModal({ open }) {
  const dispatch = useDispatch();
  const { docDetailForPreview, shareboxDocForPreviewURL } = useSelector(
    (state) => state.dev_chetan
  );
  // console.log("✌️setDocDetailForPreview --->", docDetailForPreview);
  const loadingURL =
    "https://res.cloudinary.com/bytesizedpieces/image/upload/v1656084931/article/a-how-to-guide-on-making-an-animated-loading-image-for-a-website/animated_loader_gif_n6b5x0.gif";
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = () => {
    dispatch(setOpenDocumentPreviewModal(false));
  };

  useEffect(() => {
    return () => {
      dispatch(setshareboxDocForPreviewURL(""));
      dispatch(setDocDetailForPreviewURL(null));
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
      fullWidth
      maxWidth="md"
    >
      <Box className="d-flex align-items-center justify-content-between modal-head p-0">
        <Box className="clearfix">
          <DialogTitle>
            {docDetailForPreview
              ? docDetailForPreview?.PortalName
              : "Document Preview"}
          </DialogTitle>
        </Box>

        {/*  */}
        <Button onClick={handleClose} autoFocus>
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent className="p-0">
        <iframe
          src={isLoading ? loadingURL : shareboxDocForPreviewURL}
          onLoad={() => {
            setIsLoading(false);
          }}
          width="100%"
          height="570px"
          frameBorder="0"
          allowFullScreen
          title="Embedded Content"
        ></iframe>
      </DialogContent>
    </Dialog>
  );
}

export default DocumentPreviewModal;
