import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Switch, Typography, } from "@mui/material";
import React, { useState } from "react";
import QRCode from "qrcode.react";
import DocuTextField from "../../../cmp-utils/DocuTextField";
import AutoComplete from "../../../components/AutoComplete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';

function UserSetting({ companyData }) {
  console.log("Company Data in UserSetting", companyData);
  const [authenticationVisible, setAuthenticationVisible] = useState(false);

  const handleScanner = () => {
    setAuthenticationVisible(!authenticationVisible);
  };

  return (
    <Box className="white-box mt-2">

      <Box className=" mt-2">
        {" "}
        <span className="Request_Detailas">
          Your Agreement Number{" "}
        </span>{" "}
        <span className="font-12 lato-medium textw">{companyData}</span>

        <span className="font-12 lato-medium textw text-primary"><KeyboardArrowRightIcon /> <a href="https://www.docusoft.net/">Help</a></span>
      </Box>

      <Grid container rowSpacing={0} columnSpacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
          <Box className=" mt-2">
            
            <Box className="custom-autocomplete">
              <AutoComplete lable="Default Folder" renderInput={(params) => (
                <TextField {...params} label="Default Folder" size="small" />
              )}></AutoComplete>
            </Box>
          </Box>

          <Box className=" mt-2">
            <Box className="custom-autocomplete">
              <AutoComplete lable="Default Folder" renderInput={(params) => (
                <TextField {...params} label="Default Folder" size="small" />
              )}></AutoComplete>
            </Box>
          </Box>

          <Box className="mb-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  "DatePicker",
                  "TimePicker",
                  "DateTimePicker",
                  "DateRangePicker",
                ]}
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  // className="custom-date-2"
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "12px", // Adjust font size here
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "12px", // Adjust label font size
                    },
                  }}
                />

              </DemoContainer>
            </LocalizationProvider>

          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
          <Box className="mt-2 mt-sm-0 card p-3">

            <Box className="d-flex align-items-center">
              <Typography className="font-18 lato-bold me-3 border-en pe-3" variant="Body2">
                Two Factor Authentication
              </Typography>

              <FormGroup>
                <FormControlLabel
                  checked={authenticationVisible}
                  onChange={handleScanner}
                  control={<Switch defaultChecked />} label="Enable"
                />
              </FormGroup>
            </Box>

            <Divider />

            <Box className="clearfix mt-3">
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={authenticationVisible}
                    onChange={handleScanner}
                  />
                }
                label="Enable"
                sx={{ marginTop: "-10px" }}
              /> */}

              {authenticationVisible && (
                <>
                  <Box className='text-center mt-3'>
                    {/* <Typography className="font-12 mb-2">
                          <span className="lato-semibold">Step 1:</span> Scan
                          the QR code on your Google
                          <br /> Authenticator app
                        </Typography> */}
                    <QRCode value="https://example.com" size={100} />

                    <Box>
                      {/* <Typography className="font-12">
                          <span className="lato-semibold">Step 2:</span>{" "}
                          Enter your Google Authenticator Pin
                        </Typography> */}

                      <Box className="">

                        <div className="">
                          <label className="semibold">Verification Code</label>
                          <div className="verification-input d-flex align-items-center justify-content-center">
                            <DocuTextField
                              label={"Enter Pin"}
                              size="small"
                              fullWith
                            />
                            <DocuTextField
                              label={"Enter Pin"}
                              size="small"
                              fullWith
                            />
                            <DocuTextField
                              label={"Enter Pin"}
                              size="small"
                              fullWith
                            />
                            <DocuTextField
                              label={"Enter Pin"}
                              size="small"
                              fullWith
                            />
                            <DocuTextField
                              label={"Enter Pin"}
                              size="small"
                              fullWith
                            />
                          </div>
                        </div>



                        <Box className=" d-flex justify-content-end">
                          <Button
                            // startIcon={<SaveIcon />}
                            variant="contained"
                            size="small"
                            className="btn-blue mt-1 ms-0 px-2 w-100"
                          // onClick={handleSignatureSetting}
                          >
                            Save
                            {/* <span className="d-inline-block px-2"></span> */}
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                  </Box>

                </>
              )}

            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>

        </Grid>
      </Grid>
    </Box>
  );
}

export default UserSetting;
