import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AMLCheck from "../../components/AMLCheck";
import {
  ad_Json_GetOnboardingDetailsByMultipleClientIdPubUser_Action,
  ad_Json_GetPortalMessageDetailsByTaskIdPubUser_Action,
  ad_Json_ToGetEngagementTaskID_Action,
  GetOnboardingDetailsByMultipleClientIdPubUser
} from "../../redux/dev_aditya_action";
import AdditionalInformation from "./AdditionalInformation";
import DocuRequest from "./DocuRequest";
import NewProfileSetup from "./NewProfileSetup";
import Proposal from "./Proposal";
import { PortalUsersInboxNewByMsgId_Json_Test } from "../../redux/dev_chetan_api_helper";
import EngagementLetter from "./EngagementLetter";
import RecievedForms from "./RecievedForms";
import { PracticeServices } from "../../redux/AxiosInstance";

function Onboarding() {

  const [statgesStatus, setStagesStatus] = React.useState({});

  const onBoardingDetailsByClientId = sessionStorage.getItem("onBoardingDetailsByClientId") ? JSON.parse(sessionStorage.getItem("onBoardingDetailsByClientId")) : [];

  const dispatch = useDispatch();
  const { signature } = useSelector((state) => state.ak);

  const { messageData } = useSelector(state => state.dev_chetan.proposalCompData);
  const taskIdPubUserData = useSelector(state => state.dev_chetan.onBoardingDetails.portalMessageDetailsByTaskId[0]);
  const [open, setOpen] = React.useState(true);
  const [currentTab, setCurrentTab] = React.useState("1");
  console.log(taskIdPubUserData, "= =========taskIdPubUserData");

  const [currentComponent, setCurrentComponent] = React.useState(
    <NewProfileSetup />
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  const handleCurrentTab = (val) => {
    activeTab(val)
    setCurrentTab(val);
    handleSetCurrentTabComp(val);
  };

  const handleSetCurrentTabComp = (val) => {
    switch (val) {
      case "1":
        setCurrentComponent(<NewProfileSetup />);
        break;
      case "2":
        setCurrentComponent(<Proposal />);
        break;
      case "3":
        setCurrentComponent(<RecievedForms />);
        break;
      case "4":
        setCurrentComponent(<EngagementLetter />);
        break;
      case "5":
        setCurrentComponent(<DocuRequest />);
        break;
      case "6":
        setCurrentComponent(
          <div>
            <AdditionalInformation />
          </div>
        );
        break;
      case "7":
        setCurrentComponent(<AMLCheck />);
        break;
      case "8":
        setCurrentComponent(<div>Review Details</div>);
        break;
      default:
        break;
    }
  };
  const stateData = {
    PortalDocID: "fec4f2bfded345c59d2c5a783ec8b722",
    Subject: "NABS 17 jan 1st Document Request",
    Section: "07 VAT & Bookkeeping",
    ActionBy: "17/01/2025",
    Status: "Yes",
    ReceivedDate: "17/01/2025",
    ExpiryDate: "19/01/2025",
    EmailID: "aditya.tripathi@docusoft.net",
    messageStatus: "Pending",
    isClient: true,
    senderName: "InfoManager",
  };

  const Json_ToGetStageCompletionDetailsByPubUser = async () => {
    try {
      const res = await PracticeServices.post("/Json_ToGetStageCompletionDetailsByPubUser", {
        agrno: localStorage.getItem("accIdClient") ? localStorage.getItem("accIdClient") : "",
        Email: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "",
        password: localStorage.getItem("passwordClient") ? localStorage.getItem("passwordClient") : "",
        OnboardingGUID: sessionStorage.getItem("OnboardingGUID") ? sessionStorage.getItem("OnboardingGUID") : ""
      });

      if(res.data && res.data.d && JSON.parse(res.data.d) && JSON.parse(res.data.d).Table){
        let stages = JSON.parse(res.data.d).Table;
        let allStages = stages.reduce((acc,itm)=>{
          return {...acc, [itm.StageNumber]: itm.StageCompletedOn};
        },{});
        setStagesStatus(allStages);
      }
      
    } catch (error) {
      console.error("Error while calling Json_ToGetStageCompletionDetailsByPubUser", error);
      
    }
  }

  useEffect(() => {
    if (messageData.length > 0) { } else {
      dispatch(PortalUsersInboxNewByMsgId_Json_Test(sessionStorage.getItem("ProposalTaskID")));
    }
    Json_ToGetStageCompletionDetailsByPubUser();
  }, []);

  const activeTab = (tab) => {
    return currentTab == tab ? "active" : "";
  };

  const isDisabled = (num) => {
    switch (num) {
      case "2": {
        if (Boolean(onBoardingDetailsByClientId[0]?.["FirstName"]) === false || Boolean(signature) === false) {
          return "disabled";
        }else{
          return "";
        }
      }
      case "3": {
        if (statgesStatus[2] === null) {
          return "disabled";
        }else{
          return "";
        }
      }
      case "4": {
        if (messageData.length === 0) {
          return "disabled";
        }else{
          return "";
        }
      }
      case "5": {
        if (messageData.length === 0) {
          return "disabled";
        }else{
          return "";
        }
      }
      case "6": {
        if (statgesStatus[5] === null && statgesStatus[2] === null) {
          return "disabled";
        }else{
          return "";
        }
      }
      case "7": {
        if (statgesStatus[6] === null) {
          return "disabled";
        }else{
          return "";
        }
      }
      default: 
        break;
    }
  }
  return (
    <Box className="">

      <Box className="d-block d-md-flex">
        <Box className="onboarding-sidebar">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="navi"
          >
            <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("1")}
              className={`p-1 ps-2 ${activeTab("1")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <PermIdentityOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Profile Setup"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("2")}
              className={`p-1 ps-2 ${activeTab("2")} ${isDisabled("2")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <HandshakeOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Proposal"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("3")}
              className={`p-1 ps-2 ${activeTab("3")} ${isDisabled("3")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <MarkEmailUnreadOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Recieved Forms"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            {/* <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("3")}
              className={`p-1 ps-2 ${activeTab("3")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <MarkEmailUnreadOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Letter of Engagement"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton> */}

            <ListItemButton
              size="small"
              onClick={() => {
                navigate(
                  `/client/onboarding?EmailId=${localStorage.getItem('EmailClient')}&PortalDocId=${taskIdPubUserData?.PortalDocId}`);
                handleCurrentTab("4");
              }}
              className={`p-1 ps-2 ${activeTab("4")}  ${isDisabled("4")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <FactCheckOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Requested Documents"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("5")}
              className={`p-1 ps-2 ${activeTab("5")}  ${isDisabled("5")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <InfoOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Additional Information"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("6")}
              className={`p-1 ps-2 ${activeTab("6")}  ${isDisabled("6")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <SendIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="AML Details"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            <ListItemButton
              size="small"
              onClick={() => handleCurrentTab("7")}
              className={`p-1 ps-2 ${activeTab("7")}  ${isDisabled("7")}`}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <PreviewOutlinedIcon sx={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Review Details"
                sx={{
                  fontSize: "12px",
                  "& .MuiTypography-root": { fontSize: "12px" },
                }}
              />
            </ListItemButton>

            {/* <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                      <InboxIcon />
                  </ListItemIcon>
                  <ListItemText className='font-12' primary="Inbox" />
                  {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                              <StarBorder />
                          </ListItemIcon>
                          <ListItemText className='font-12' primary="Starred" />
                      </ListItemButton>
                  </List>
              </Collapse> */}
          </List>
        </Box>
        <Box className="right-main-box w-100">{currentComponent}</Box>
      </Box>
    </Box>
  );
}

export default Onboarding;
